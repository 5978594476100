import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
declare let $: any;

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {

  cactiveColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  iconColor: any
  borderColor: any;
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';
  to_Rate_Date:any;
  name = "Angular " + VERSION.major;
  page: number = 1;
  tree_card_Buyer: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  selectedUser = null;
  selectedSize = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  isCheckDataBase: boolean = false;
  SizeMasterList: any = [];
  ItemCategoryList: any = [];
  progress: number = 0;
  
  uploadForm = new FormGroup({
    currency_Code: new FormControl('', Validators.required),
    currency_Name: new FormControl('', Validators.required),
    conversion_Rate: new FormControl('', Validators.required),
    rate_Date: new FormControl('', Validators.required),
  });

  uploadFormEdit = new FormGroup({
    currency_Code: new FormControl({ value: '', disabled: true }, Validators.required),
    currency_Name: new FormControl('', Validators.required),
    conversion_Rate: new FormControl('', Validators.required),
    rate_Date: new FormControl('', Validators.required),
  });

  constructor( 
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private _fb: FormBuilder,
    private httpClient: HttpClient
  ) {
     
  }

  
  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  
  scroll(tree) {
    //console.log(tree);
    tree.treeModel.getNodeById(6).focus();
    tree.treeModel.getNodeById(6).scrollIntoView();
  }

  ngOnInit(): void {
    this.loadTreeBuyer(); 
    let url = "Currency/Listtree";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card_Buyer = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card_Buyer
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
            }
          });

        } else {

        }
      }
    );
  }

  loadTreeBuyer = () => {
    let url = "Currency/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        let dataList = "";
        if (data.status === 'OK') {
          this.uploadForm.reset();
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = data12[0];
          this.tree_card_Buyer.forEach(element => {
            dataList += "<tr><td>" + element.Currency_Code + "</td><td>" + element.Currency_Name + "</td><td>" + element.Conversion_Rate + "</td><td>" + element.Conversion_Rate_Date + "</td><td>" + element.Multiply + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;

        } else {

        }
      }
    );
  }
  deleteDialog = () => {
    this.dialogDelete = true;
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.selectedSize = null;
    this.loadTreeBuyer();
    this.uploadForm.reset();
  }

  TreeListBinding = (Item_Code, id) => {
    try {
      let data = {
        Currency_Code: Item_Code
      } 
      this.selectedUser = null;
      this.selectedSize = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url = "Currency/Single";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            debugger
            this.tree_edit_list = data12[0][0];
            this.uploadFormEdit = this._fb.group({
              currency_Code: [this.tree_edit_list.Currency_Code],
              currency_Name: [this.tree_edit_list.Currency_Name],
              conversion_Rate: [this.tree_edit_list.Conversion_Rate],
              rate_Date: [this.tree_edit_list.Conversion_Rate_Date],
            });
            this.to_Rate_Date=this.datePipe.transform(this.tree_edit_list.Conversion_Rate_Date, 'yyyy-MM-dd');
            //this.to_Rate_Date = this.tree_edit_list.Conversion_Rate_Date;
            this.loadTreeBuyer();

          } else {
           
          }

        }
      );
    }
    catch (e) {

    }

  }


  CodeAlredyExist = "";
  CodeCheckUnique(code) {
    // let url = "Buyer/EditBuyerCodeAndNameList";
    // this.webService.loadTreetListEdit(url, code).subscribe(data => {
    //   if (data.status === 'OK') {
    //     this.CodeAlredyExist = "Code Alredy Exist, Please another code ";
    //   }
    //   else {
    //     this.CodeAlredyExist = "";
    //   }
    // });
    
  }

  reset() {
    this.uploadForm.reset();
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/currency']);
    });
  }

  onSubmit() {
    if (this.uploadForm.valid) {
      let data = {
        Currency_Code: this.uploadForm.value.currency_Code, 
        Currency_Name: this.uploadForm.value.currency_Name,
        Conversion_Rate: this.uploadForm.value.conversion_Rate,
        Conversion_Rate_Date: this.uploadForm.value.rate_Date,
      }
      let url = "Currency/Add";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            this.uploadForm.reset();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;">Currency Added successfully</p>',
              showConfirmButton: false,
              timer: 2500
            });
            this.loadTreeBuyer();
            
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
              showConfirmButton: false,
              timer: 2500
            });
          }
        }
      );
    }
    else {
      this.validateAllFormFields(this.uploadForm);
    }

  }
  
  onEditSubmit() {
    let data = {
      Currency_Code: this.uploadFormEdit.value.currency_Code, 
        Currency_Name: this.uploadFormEdit.value.currency_Name,
        Conversion_Rate: this.uploadFormEdit.value.conversion_Rate.toString(),
        Conversion_Rate_Date: this.uploadFormEdit.value.rate_Date,
    }
    let url = "Currency/Edit";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Currency updated successfully</p>',
            showConfirmButton: false,
            timer: 2500
          });
          this.uploadForm.reset();
          this.loadTreeBuyer();
          this.TreeListBinding(this.uploadFormEdit.value.currency_Code,'');

        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }
      }
    );
    
  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Value</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
