<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Bank</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img src="./assets/images/Add.png" style="width: 40px;"/></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png" style="width: 40px;"/></span></a>
                </li>
            </ul>
        </section>
        <aside style="width: 350px;">
            
            <ul class="top" style="margin-top: 0px; height: 700px; background-color: white;text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                <div id="lazy" class="demo"></div>
            </ul>
        </aside>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form name="myForm" #myForm="ngForm">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                        Employee</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Code</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" type="text" (keyup)="CodeCheckUnique(emp_Code.value)"
                                        #emp_Code />
                                </div>
                                <div class="grid_6">
                                    <div *ngIf="CodeAlredyExist != ''" class="alert alert-danger">
                                        {{CodeAlredyExist}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>First Name</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #fName />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Middle Name</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #mName />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Last Name</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #lName />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Address</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Add1 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Address</strong> </label>
                                </div>
                                <div class="grid_8" >
                                    <input class="required" type="text" #Add2 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Address</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text"  #Add3 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Sex</strong> </label>
                                </div>
                                <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; width: 50px;">
                                    <input class="required" maxlength="10" type="radio" #Male  name="Item2" value="M" [(ngModel)]="isCheckedFM" style="width: 16px;"/>
                                    <span style="padding: 11px 8px 8px 8px;"> Male</span>
                                </div>
    
                                <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; ">
                                    <input class="required" type="radio" #female value="F" name="Item6" [(ngModel)]="isCheckedFM" style="width: 16px;" />
                                    <span style="padding: 11px 8px 8px 8px;">Female</span>
                                </div>

                                <div class="grid_4" style=" display: flex; padding: 0px 0 0px 5px; ">
                                    <input class="required" type="checkbox" #texPayee value="Y" name="Item2" [checked]="isCheckedBoxFM" style="width: 16px;" />
                                    <span style="padding: 11px 8px 8px 8px;">Tex Payee</span>
                                </div>
    
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Birth Date</strong> </label>
                                </div>
                                <div class="grid_3">
                                    <input class="required" type="date" #dob />
                                </div>
                             
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Joining Date</strong> </label>
                                </div>
                                <div class="grid_3">
                                    <input class="required" type="date" #doj />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Department</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #dept_code />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Phone</strong> </label>
                                </div>
                                <div class="grid_3">
                                    <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #Phone />
                                </div>
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Mobile No.</strong> </label>
                                </div>
                                <div class="grid_3">
                                    <input class="required" type="text" #Mobile />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Email</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="email" #Email />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Leaving Date</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="date" #leavingDate />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="button"
                                    (click)="addBankMaster(emp_Code.value, fName.value,mName.value,lName.value,Add1.value,Add2.value,Add3.value,Male.checked,female.checked,texPayee.checked,dob.value,doj.value,dept_code.value,Phone.value,Mobile.value,Email.value,leavingDate.value)"
                                    value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </section>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="addIn" style="padding-left: 0;">
            <div class="grid_12">
                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                    Employee</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <div class="content" style="padding: 15px;">
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Code</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input #editemp_Code class="required" type="text" value="{{this.tree_edit_list.emp_code || '' }}" disabled />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>First Name</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editfName  value="{{this.tree_edit_list.emp_fname || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Middle Name</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editmName  value="{{this.tree_edit_list.emp_mname || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Last Name</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editlName value="{{this.tree_edit_list.emp_lname || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Address</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd1  value="{{this.tree_edit_list.add1 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong></strong> </label>
                            </div>
                            <div class="grid_8" >
                                <input class="required" type="text" #editAdd2  value="{{this.tree_edit_list.add2 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong></strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text"  #editAdd3 value="{{this.tree_edit_list.add3 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Sex</strong> </label>
                            </div>
                            <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; width: 50px;">
                                <input class="required" maxlength="10" type="radio" #editMale  name="Item3" [(ngModel)]="isChecked"  style="width: 16px;" value="M" />
                                <span style="padding: 11px 8px 8px 8px;"> Male</span>
                            </div>
                       
                            <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; ">
                                <input class="required" type="radio" #editfemale  name="Item2" [(ngModel)]="isChecked" style="width: 16px;" value="F" />
                                <span style="padding: 11px 8px 8px 8px;">Female</span>
                            </div>

                            <div class="grid_4" style=" display: flex; padding: 0px 0 0px 5px; ">
                                <input class="required" type="checkbox" #edittexPayee  [(ngModel)]="isCheckedBox" style="width: 16px;" value="Y" />
                                <span style="padding: 11px 8px 8px 8px;">Tex Payee</span>
                            </div>

                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Birth Date</strong> </label>
                            </div>
                            <div class="grid_3">
                                <input class="required" type="date" #editdob value="{{this.tree_edit_list.dob || '' }}" />
                            </div>
                         
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Joining Date</strong> </label>
                            </div>
                            <div class="grid_3">
                                <input class="required" type="date" #editdoj value="{{this.tree_edit_list.doj || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Department</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editdept_code value="{{this.tree_edit_list.dept_code || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Phone</strong> </label>
                            </div>
                            <div class="grid_3">
                                <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #editPhone [(ngModel)]="tree_edit_list.phone" />
                            </div>
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Mobile No.</strong> </label>
                            </div>
                            <div class="grid_3">
                                <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #editMobile value="{{this.tree_edit_list.mobile || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Email</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="email" #editEmail [(ngModel)]="tree_edit_list.email"  />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Leaving Date</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="date" #editleavingDate [(ngModel)]="tree_edit_list.DOL" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">
                        <div class="right" style="margin-left: 12px;">
                            <button (click)="editBankMaster(editemp_Code.value, editfName.value,editmName.value,editlName.value,editAdd1.value,editAdd2.value,editAdd3.value,editMale.checked,editfemale.checked,edittexPayee.checked,editdob.value,editdoj.value,editdept_code.value,editPhone.value,editMobile.value,editEmail.value,editleavingDate.value)" class="grey cancel"
                            style="height: 30px;margin-top: 7px;width: 100px;font-size: small;background-image: linear-gradient(#042f66,#195e83);color: white;">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>