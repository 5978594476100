import {
  OnInit,
  Component,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-trun-order',
  templateUrl: './trun-order.component.html',
  styleUrls: ['./trun-order.component.css']
})

export class TrunOrderComponent implements OnInit {
  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
  report_list: any = [];
  tree_edit_list_print: any = [];
  selectedData: any;
  selectedDataBuyer: any = "";
  selectedDataSuplier: any = "";
  tree_card_Suppler: any = [];
  tree_card_Buyer: any = [];
  tree_ContactBuyerConsignee: any = [];
  SizeCurrencyList: any = [];
  page: number = 1;
  Onword: any = "";
  curency: any = "";
  rundate: any = "";
  showcontentLView: any = "";
  allcurency = "all";
  allbuyer = "all";
  allSuplier = "all";
  isCheckPage: any = true;
  readioSelected: any;
  showcontent: boolean = false;
  isCheckPage1: any = false;
  quarterList: any = [];
  private chart: Highcharts.Chart;
  list: any[];
  constructor(
    private webService: WebServiceService,
  ) {

  }

  ngOnInit(): void {
    this.loadTreeSuppler();
    this.loadTreeBuyer();
    this.loadCurrency();

  }

  private showValues(): void {
    document.getElementById('alpha-value').innerHTML = String(
      this.chart.options.chart.options3d.alpha
    );
    document.getElementById('beta-value').innerHTML = String(
      this.chart.options.chart.options3d.beta
    );
  }



  private createOptions(): ChartOptions {
    return {
      responsive: true,
      // maintainAspectRatio: false,
      legend: { position: "right" },
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red', 'blue'],
          precision: 2
        }
      },
    };
  }

  loadCurrency = () => {

    this.SizeCurrencyList = [];

    let url = "Currency/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            this.SizeCurrencyList = data12[0];

          }
          else {
            this.SizeCurrencyList = [""];
          }

        } else {

        }
      }
    );
  }

  shareCheckedList(item: any[]) {
    console.log(item + 'p');
    this.selectedDataBuyer = item;
  }
  shareIndividualCheckedList(item) {
    console.log(item.name);
    // this.selectedDataBuyer = item.name;
  }

  shareCheckedListSuplier(item: any[]) {
    console.log(item + 'p');
    this.selectedDataSuplier = item;
  }
  shareIndividualCheckedListSuplier(item) {
    console.log(item.name);
    //this.selectedDataSuplier = item.name;
  }

  loadTreeBuyer = () => {
    this.quarterList = [];
    let url = "ReportViewer/Buyer";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          debugger
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = dataList[0];
          if (dataList[0].length > 0) {

            dataList[0].forEach(element => {
              this.quarterList.push({ name: element.Buyer_Name, value: element.Buyer_Code, checked: false })
            })
          }
          else {
            this.tree_ContactBuyerConsignee = [""];
          }

        } else {

        }
      }
    );
  }

  loadTreeSuppler = () => {
    let url = "ReportViewer/Suppler";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            //this.tree_card_Suppler = dataList[0];
            dataList[0].forEach(element => {
              this.tree_card_Suppler.push({ name: element.Supplier_Name, value: element.Supplier_Code, checked: false })
            })
          }
          else {
            this.tree_card_Suppler = [""];
          }


        } else {

        }
      }
    );
  }
  closePage = () => {
    this.isCheckPage = true;
    this.isCheckPage1 = false;
  }


  searchValueitem = (FromDate, ToDate, CurrencyCode) => {
    var SupplierList, BuyerList,SupplierList1, BuyerList1, StartDate, EndDate;
    debugger
    if (this.selectedDataSuplier == '') {
      SupplierList = 'NOData';
      SupplierList1 = "NOData";
    }
    else {
      SupplierList = Array.prototype.map.call(this.selectedDataSuplier, function (item) { return item; }).join("','");
      SupplierList1 = "All";
    }
    if (this.selectedDataBuyer == '') {
      BuyerList = 'NOData';
      BuyerList1="NOData";
    }
    else {
      BuyerList = Array.prototype.map.call(this.selectedDataBuyer, function (item) { return item; }).join("','");
      BuyerList1="All";
    }
    

    if (FromDate == '') {
      StartDate = '00';
    }
    else {
      StartDate = FromDate;

    }
    if (ToDate == '') {
      EndDate = '00';
    }
    else {
      EndDate = ToDate;

    }
    this.isCheckPage = false;
    this.isCheckPage1 = true;
    this.report_list = [];
    if (this.readioSelected == 'SupplierReport') {
      this.showcontentLView = 'SupplierReport';
    }
    else {
      this.showcontentLView = 'BuyerReport';
    }
    this.showcontent = this.readioSelected;
    let checkedValue = this.readioSelected;
    if (CurrencyCode == 'all') {
      Swal.fire({
        position: 'top-end',
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select Currency Code</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
       if (checkedValue == undefined) {
        Swal.fire({
          position: 'top-end',
          background: '#FFFFFF',
          html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please checked Supplier Wise and Buyer Wise </p>',
          showConfirmButton: false,
          timer: 2500
        });
      }
      else {

        let url = "ReportViewer/TrunOverReport?Cun_Code=" + CurrencyCode + "&startDate=" + StartDate + "&endDate=" + EndDate + "&buyerCode=" + BuyerList + "&supplierCode=" + SupplierList + "&buyerCode2=" + BuyerList1 + "&supplierCode2=" + SupplierList1 + "&inquery=" + checkedValue;
        this.webService.loadTreeLiat(url).subscribe(
          (data: any) => {
            let dataList = "";
            if (data.status === 'OK') {

              let returnUrl = "trun-order-view?FromDate=" + FromDate + "&ToDate=" + ToDate + "&CurrencyCode=" + CurrencyCode + "&action=" + checkedValue;
              //this.router.navigateByUrl(returnUrl);
              // Calculate the center position for the popup window
              let screenWidth = window.screen.availWidth;
              let screenHeight = window.screen.availHeight;
              let windowWidth = 1200; // Adjust as needed
              let windowHeight = 600; // Adjust as needed
              let leftPosition = (screenWidth - windowWidth) / 2;
              let topPosition = (screenHeight - windowHeight) / 2;
              // Define window features
              let windowFeatures = 'width=' + windowWidth + ',height=' + windowHeight + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes';
              // Open the popup window
              let popupWindow = window.open(returnUrl, '_popupWin', windowFeatures);
              // Focus on the popup window
              popupWindow.focus();
            } else {

            }
          }
        );
      }
    }
  }
  onPreview = () => {
    window.print();
  }
  onPreview2 = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      .pagination {
        margin: -15px 0;
        display: flex;
        justify-content: center;
    
      }
    
      .ngx-pagination {
        margin-left: 0 !important;
        margin-bottom: 1rem !important;
        line-height: 20px !important;
        padding: 7px 15px 7px !important;
        font-size: 12px !important;
      }
    
      .ngx-pagination::before,
      .ngx-pagination::after {
        content: " " !important;
        display: table !important;
      }
    
      .ngx-pagination::after {
        clear: both !important;
      }
    
      .ngx-pagination li {
        -moz-user-select: none !important;
        -webkit-user-select: none !important;
        -ms-user-select: none !important;
        margin-right: 0.2rem !important;
        border-radius: 0 !important;
      }
    
      .ngx-pagination li {
        display: inline-block !important;
      }
    
      .ngx-pagination a,
      .ngx-pagination button {
        color: #0a0a0a !important;
        display: block !important;
        background-color: rgb(248, 248, 248) !important;
        color: black !important;
        border: 2px solid #8b8b8b !important;
        padding: 0.5rem 1rem !important;
        border-radius: 9% !important;
      }
    
      .ngx-pagination a:hover,
      .ngx-pagination button:hover {
        background: #788b8d88 !important;
      }
    
      .ngx-pagination .current {
        padding: 0.1875rem 0.625rem !important;
        background: #2199e8 !important;
        color: #fefefe !important;
        cursor: default !important;
        border: 2px solid #8b8b8b !important;
        padding: 0.5rem 1rem !important;
        border-radius: 9% !important;
      }
    
      .ngx-pagination .disabled {
        padding: 0.1875rem 0.625rem !important;
        color: #cacaca00 !important;
        cursor: default !important;
      }
    
      .ngx-pagination .disabled:hover {
        background: transparent !important;
      }
    
      .ngx-pagination a,
      .ngx-pagination button {
        cursor: pointer !important;
      }
    
      .ngx-pagination .pagination-previous a::before,
      .ngx-pagination .pagination-previous.disabled::before {
        font-family: FontAwesome;
        content: "\f053" !important;
        display: inline-block !important;
        margin-right: 0rem !important;
      }
    
      .ngx-pagination .pagination-next a::after,
      .ngx-pagination .pagination-next.disabled::after {
        font-family: FontAwesome !important;
        content: "\f054" !important;
        display: inline-block !important;
        margin-left: 0rem !important;
      }
    
      .ngx-pagination .show-for-sr {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
      }
    
      .ngx-pagination .small-screen {
        display: none !important;
      }
    
      @media screen and (max-width: 601px) {
        .ngx-pagination.responsive .small-screen {
          display: inline-block !important;
          color: black !important;
          background-color: rgb(248, 248, 248) !important;
          border: 2px solid #8b8b8b !important;
          padding: 0.5rem 1rem !important;
          border-radius: 9% !important;
        }
    
        .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
          display: none !important;
        }
      }
    
      .table>thead>tr>th {
        vertical-align: bottom;
        border-bottom: 2px solid #67676a !important;
        border-top: 1px solid #dddddd00 !important;
      }
    
      .table>tbody>tr>td,
      .table>tbody>tr>th,
      .table>tfoot>tr>td,
      .table>tfoot>tr>th,
      .table>thead>tr>td,
      .table>thead>tr>th {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-left: 2px solid #67676a !important;
        border-top: 1px solid #dddddd00 !important;
      }
    
      tr td {
        border-color: #dddddd00 !important;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
        <th>Buyer Name</th>
        <th>Total No. Of Orders</th>
        <th>Total Quantity</th>
        <th>Total Value</th>
        <th>Avg. Price</th>
        <th>%age Of TurnOver</th>
      </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      <div class="row">
      <div class="col-sm-12" >
        <div style="display: block;width: 700px;margin-left: 200px;">
          <canvas baseChart *ngIf="pieChartData"
            [data]="pieChartData" 
            [labels]="pieChartLabels" 
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins"
            [legend]="pieChartLegend">
          </canvas>
        </div>
        
      </div>
    </div>
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
