import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commission-note',
  templateUrl: './commission-note.component.html',
  styleUrls: ['./commission-note.component.css']
})
export class CommissionNoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
