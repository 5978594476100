<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Copy Spec Sheet for Item</div>
        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/Add.png"
                                style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>

        <section id="content" class="container_12 clearfix" data-sort="true"
            style="padding: 30px 175px 30px 175px;background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="grid_12">

                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 25px;left: 15px;background: white;width: 285px;z-index: 999;position: relative;">
                    Copy Spec Sheet for New Item</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <div class="content" style="padding: 45px;">
                        <div class="row">
                            <div class="grid_12">
                               
                                <div class="row">
                                    <div class="grid_2">
                                        <label for="v1_normal_input"><strong>Item</strong> </label>
                                    </div>
                                    <div class="grid_10">
                                        <input class="required" type="text" #bank_Name />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="grid_2">
                                        <label for="v1_normal_input"><strong>Spec Sheet</strong> </label>
                                    </div>
                                    <div class="grid_10">
                                        <input class="required" type="text" #bank_Name />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="grid_2">
                                        <label for="v1_normal_input"><strong>New Item</strong> </label>
                                    </div>
                                    <div class="grid_10">
                                        <input class="required" type="text" name="v1_normal_input" id="v1_normal_input"
                                            #bank_Code />
                                    </div>
                                </div>
                            </div>
                          
                        </div>


                    </div>
                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">

                        <div class="right">
                            <input type="submit" value="Save" name="submit" style="width: 100px;font-size: small;" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>