<div class="container">
    <form [formGroup]="saveFileForm" (ngSubmit)="onExpSubmit()" enctype="multipart/form-data">
        <div class="row">
            <div class="col-md-12" style="padding-left: 0;"><b>Order No</b></div>
            <div class="col-md-12" style="padding-left: 0;">
                <input type="text" class="form-control" formControlName="OrderNo" placeholder="Order No" style="margin: 4px 4px 4px 6px;" readonly>
            </div>
            <app-field-error-display [displayError]="isFieldValid('OrderNo')" errorMsg="Please  enter Order No">
            </app-field-error-display>
        </div>

        <div class="row">
            <div class="col-md-12" style="padding-left: 0;"><b>Item</b></div>
            <div class="col-md-12" style="padding-left: 0;">
                <input type="text" class="form-control" formControlName="Item" placeholder="Item" style="margin: 4px 4px 4px 6px;">
            </div>
            <app-field-error-display [displayError]="isFieldValid('Item')" errorMsg="Please  enter Order No">
            </app-field-error-display>
        </div>

        <div class="row">
            <div class="col-md-12" style="padding-left: 0;"><b>Spec Sheet</b></div>
            <div class="col-md-12" style="padding-left: 0;">
                <input type="text" class="form-control" formControlName="SpecSheet" placeholder="Spec Sheet" style="margin: 4px 4px 4px 6px;">
            </div>
            <app-field-error-display [displayError]="isFieldValid('SpecSheet')" errorMsg="Please  enter Order No">
            </app-field-error-display>
        </div>

        <div class="row">
            <div class="col-md-12" style="padding-left: 0;"><b>File Upload</b></div>
            <div class="col-md-12" style="padding-left: 0;">
                <input type='file' class="form-control" #resumeInput style="margin: 4px 4px 4px 6px;padding: 7px;">
            </div>
        </div>

        <div class="row" align="center" style=" padding: 15px; width: 100px; float: right; position: relative; ">
            <button type="submit" class="button" class="btn btn-primary" color="primary">Save
            </button>
        </div>
    </form>
</div>