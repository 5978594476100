import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-delete',
  templateUrl: './bulk-delete.component.html',
  styleUrls: ['./bulk-delete.component.css']
})
export class BulkDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
