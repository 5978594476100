<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Merchandising Details</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreviewGShipement()"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>

        <section id="content" class="container_12 clearfix" data-sort="true"
            style="padding: 30px;background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="grid_12">
                <fieldset>
                    <legend> Order Detalls </legend>
                    <table style=" border: 2px solid #666666;" height="50" width="100%">
                        <tr>
                            <td style=" border: none; ">Order No.</td>
                            <td style=" border: none; width: 30%;">
                                <mat-form-field class="width" appearance="outline"
                                    style="width: 100%;padding-top: 12px;">
                                    <mat-label>Order No. Contains</mat-label>
                                    <input matInput placeholder="Order No. Contains" aria-label="Order No. Contains"
                                        [matAutocomplete]="auto" [formControl]="OrderNoContainsCtrl" required
                                        [(ngModel)]="search_text_order_no" #orderCode />
                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="onSearchIOrderNoSeletion($event.option.value)">
                                        <mat-option *ngFor="let country of filteredCountry | async"
                                            [value]="country.name">
                                            <span>{{ country.name }}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <td style="border: none;">Order Date</td>
                            <td style="border: none;">
                                <input type="Date" [value]="search_text_order_date | date:'yyyy-MM-dd'" #OrderDate />
                            </td>
                        </tr>
                    </table>

                </fieldset>
                <fieldset>
                    <legend>Follow-Up Details</legend>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="scroll-table-container">
                                <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                    <thead>
                                        <tr style="font-size: medium;">
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Category</th>
                                            <th>Text</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">
                                        <tr
                                            *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                            <td>{{item3.id}}</td>
                                            <td>{{item3.folowupDate}}</td>
                                            <td>{{item3.cat_name}}</td>
                                            <td>{{item3.text}}</td>
                                            <td>{{item3.remarks}}</td>
                                            <td><input type="button" (click)="loadVIewSingle(item3.id)" value="View" name="submit"
                                                    style="width: 50px;font-size: small;height: 30px;color: white;" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div class="row">
                        <div class="col-sm-12" style="top: 20px;">
                            <div class="box validate" style="padding: 40px 15px 15px 15px;">
                                <fieldset>
                                    <legend>Add/Update Follow-Ups </legend>
                                    <input type="hidden" value="{{this.AddUpdateFollowUpsID}}" #followId />
                                    <table style=" border: none; width: 100%;">
                                        <tr style=" border: none; ">
                                            <td style=" border: none; ">
                                                Follow-Up Category
                                            </td>
                                            <td style="border: none;">
                                                <select id="buyer" style="width: 100%;" [(ngModel)]="selectedCategory" #selectedCategoryId>
                                                    <option *ngFor="let sip_mode of proFillModeList" [ngValue]="sip_mode.CATEGORY_CODE">
                                                        {{ sip_mode.CATEGORY_NAME }}
                                                    </option>
                                                </select>
                                            </td>

                                            <td style=" border: none; ">
                                                Follo-Up Date
                                            </td>
                                            <td style=" border: none; ">
                                                <input type="date" name=" Debit Note Date" size="15" maxlegth="30"  [value]="FOLLOWUP_DATE | date:'yyyy-MM-dd'" #FolloUpDate />
                                            </td>
                                        </tr>
                                        <tr style=" border: none; ">
                                            <td style=" border: none; ">
                                                Text
                                            </td>
                                            <td style=" border: none; " colspan="3">
                                                <textarea rows="6" style="width: 100%;" #Text>{{ FOLLOWUP_TEXT || '' }}</textarea>
                                            </td>
                                        </tr>
                                        <tr style=" border: none;">
                                            <td style=" border: none;">
                                            </td>
                                            <td style=" border: none; " colspan="3">
                                                <textarea rows="6" style="width: 100%;" #Remarks>{{ RemarksData || "" }}</textarea>
                                            </td>
                                        </tr>
                                    </table>

                                </fieldset>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <div class="row">
                        <div class="col-sm-12" style="top: 20px;">
                            <div class="box validate" style="padding: 40px 15px 15px 15px;">
                                <div class="actions">

                                    <div class="right">
                                        <input type="button" (click)="AddOrderFollowUP(followId.value, selectedCategoryId.value,FolloUpDate.value, Text.value,Remarks.value)" value="Save" name="submit"
                                            style="width: 100px;font-size: small;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>


        </section>

    </div>
</div>