<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Supplier Master</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img
                                src="./assets/images/Add.png" style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>
        <aside style="width: 350px;">
            <ul class="top"
                style="margin-top: 0px; height: 700px; background-color: white;text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                <div id="lazy" class="demo"></div>
            </ul>
        </aside>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form name="myForm" #myForm="ngForm">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                        Supplier Master</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Code</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" (keyup)="CodeCheckUnique(Supplier_Code.value)" type="text"
                                        name="v1_normal_input" id="v1_normal_input" #Supplier_Code />
                                </div>
                                <div class="grid_6">
                                    <div *ngIf="CodeAlredyExist != ''" class="alert alert-danger">
                                        {{CodeAlredyExist}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Name</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Supplier_Name />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Address</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Add1 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong></strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Add2 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong></strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Add3 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Phone</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #Phone />
                                </div>
                                <div class="grid_1">
                                    <label for="v1_normal_input"><strong>Fax</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" type="text" #Fax />
                                </div>
                                <div class="grid_1">
                                    <label for="v1_normal_input"><strong>Telex</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" type="text" #Telex />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Type</strong> </label>
                                </div>
                                <div class="grid_1" style=" display: flex;">
                                    <input class="required" type="radio" #Item1 name="Item1" value="I" checked />
                                    <span style="padding: 8px 8px 8px 8px;"> Item</span>
                                </div>

                                <div class="grid_1" style=" display: flex;">
                                    <input class="required" type="radio" #Item2 name="Item1" value="A" />
                                    <span style="padding: 8px 8px 8px 8px;"> Accessory</span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Email</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="email" #Email />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Bank</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <select #Bank_Name name=v2_select id=v2_select [(ngModel)]="selectedUser"
                                        data-placeholder="Choose a Name" style="width: 100%;padding: 4px 6px 2px 6px;top: 5px;">
                                        <option [ngValue]="null" disabled>Choose your profile</option>
                                        <option *ngFor="let bank of bankMasterList" [ngValue]="bank.Bank_Code">
                                            {{bank.Bank_Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">
                            <div class="right">
                                <input type="submit"
                                    (click)="addSupplerMaster(Supplier_Code.value, Supplier_Name.value,Add1.value,Add2.value,Add3.value,Phone.value,Fax.value,Telex.value,Item1.value,Item2.value, Email.value,Bank_Name.value)"
                                    value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </section>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="addIn" style="padding-left: 0;">
            <div class="grid_12">
                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                    Supplier Master</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <div class="content" style="padding: 15px;">
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Code</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input #editSupplier_Code class="required" type="text"
                                    value="{{this.tree_edit_list.Supplier_Code || '' }}" disabled />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Name</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input #editSupplier_Name class="required" type="text"
                                    value="{{this.tree_edit_list.Supplier_Name || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Address</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd1
                                    value="{{this.tree_edit_list.Add1 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong></strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd2
                                    value="{{this.tree_edit_list.Add2 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong></strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd3
                                    value="{{this.tree_edit_list.Add2 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Phone</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #editPhone
                                    value="{{this.tree_edit_list.Phone || ''}}" />
                            </div>
                            <div class="grid_1">
                                <label for="v1_normal_input"><strong>Fax</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input class="required" type="text" #editFax
                                    value="{{this.tree_edit_list.Fax || ''}}" />
                            </div>
                            <div class="grid_1">
                                <label for="v1_normal_input"><strong>Telex</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input class="required" type="text" #editTelex
                                    value="{{this.tree_edit_list.Telex || ''}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Type</strong> </label>
                            </div>
                            <div class="grid_1" style=" display: flex;">
                                <input class="required" maxlength="10" type="radio" #editItem1 name="Item2" value="I"
                                    [checked]="isChecked" style="margin: 0px 0 0;" />
                                <span style="padding: 8px 8px 8px 8px;"> Item</span>
                            </div>

                            <div class="grid_1" style=" display: flex;">
                                <input class="required" type="radio" #editItem2 value="A" name="Item2"
                                    [checked]="isChecked" style="margin: 0px 0 0;" />
                                <span style="padding: 8px 8px 8px 8px;">Accessory</span>
                            </div>

                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Email</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="email" #editEmail
                                    value="{{this.tree_edit_list.Email || ''}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Bank</strong> </label>
                            </div>
                            <div class="grid_8">
                               <select name=v2_select id=v2_select #editBank [(ngModel)]="selectedUser"
                                    data-placeholder="Choose a Name" style="width: 100%;padding: 8px;top: 5px;left: 6px;">
                                    <option [ngValue]="null" disabled>Choose your profile</option>
                                    <option *ngFor="let bank of bankMasterList" [ngValue]="bank.Bank_Code">
                                        {{bank.Bank_Name}}
                                    </option>
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 42px;left: 15px;background: white;width: 169px;z-index: 999;position: relative;">
                    Contact Person</h1>
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <table class="dynamic styled" data-filter-Bar="always" data-table-tools='{"display":false}'>
                        <!--<table class="styled full table table-striped table-bordered table-sm row-border hover" style="display: inline-table;">-->
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Contact Person Code</th>
                                <th>Contact Person Name</th>
                                <th>Designation</th>
                                <th>Phone</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gradeX" *ngFor="let item1 of this.ContactPersion_list let i = index">
                                <td class="center">{{i + 1 }}</td>
                                <td class="center">{{item1.Contact_Person_Code}}</td>
                                <td class="center">{{item1.Contact_Person_Name}}</td>
                                <td class="center">{{item1.Designation}}</td>
                                <td class="center">{{item1.Phone}}</td>
                                <td class="center">{{item1.Mobile}}</td>
                                <td class="center">{{item1.Email}}</td>
                                <td class="center">
                                    <!-- <div class="right" style="margin-left: 12px;">
                                        <button (click)="deleteDialog()" class="grey cancel"
                                            style=" height: 30px; margin-top: 7px;">Delete</button>
                                    </div> -->
                                    <div class="right">
                                        <button class="submit" (click)="editDialog(item1.Contact_Person_Code)"
                                            style="padding: 7px 18px; height: 30px; margin-top: 8px;">Edit</button>

                                        <div class="modal fade" id="modalEdit{{item1.Contact_Person_Code}}"
                                            tabindex="-1" role="dialog">
                                            <div class="modal-dialog" role="document">
                                                <form id="modal_form_signup" novalidate="novalidate">
                                                    <div class="modal-content">
                                                        <div class="modal-header"
                                                            style="text-align: start;display: flex;">
                                                            <h5 class="modal-title"> Add Contact Person </h5>
                                                            <button type="button" class="close"
                                                                (click)="closeDialog(item1.Contact_Person_Code)"
                                                                aria-label="Close"
                                                                style=" right: 0; position: absolute;">
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="row">
                                                                <div class="grid_6">
                                                                    <label for="d2_username"
                                                                        style="width: 100%;text-align: start;">
                                                                        <strong>Contact Person Code</strong>
                                                                    </label>
                                                                    <div>
                                                                        <input #editContactPersonCode class="required"
                                                                            type="text" style="margin: 0px 0px 0px 0px;"
                                                                            value="{{item1.Contact_Person_Code}}"
                                                                            disabled />
                                                                    </div>
                                                                </div>
                                                                <div class="grid_6">
                                                                    <label for="d2_email"
                                                                        style="width: 100%;text-align: start;">
                                                                        <strong>Contact Person Name</strong>
                                                                    </label>
                                                                    <div>
                                                                        <input #editContactPersonName class="required"
                                                                            type="text" style="margin: 0px 0px 0px 0px;"
                                                                            value="{{item1.Contact_Person_Name}}" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="grid_6">
                                                                    <label for="d2_email"
                                                                        style="width: 100%;text-align: start;">
                                                                        <strong>Designation</strong>
                                                                    </label>
                                                                    <div>
                                                                        <input #editDesignation class="required"
                                                                            type="text" style="margin: 0px 0px 0px 0px;"
                                                                            value="{{item1.Designation}}" />
                                                                    </div>
                                                                </div>
                                                                <div class="grid_6">
                                                                    <label for="d2_email"
                                                                        style="width: 100%;text-align: start;">
                                                                        <strong>Phone</strong>
                                                                    </label>
                                                                    <div>
                                                                        <input #editPhone class="required" type="text"
                                                                            style="margin: 0px 0px 0px 0px;"
                                                                            value="{{item1.Phone}}" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="grid_6">
                                                                    <label for="d2_email"
                                                                        style="width: 100%;text-align: start;">
                                                                        <strong>Mobile</strong>
                                                                    </label>
                                                                    <div>
                                                                        <input #editMobile class="required" type="text"
                                                                            style="margin: 0px 0px 0px 0px;"
                                                                            value="{{item1.Mobile}}" />
                                                                    </div>
                                                                </div>
                                                                <div class="grid_6">
                                                                    <label for="d2_email"
                                                                        style="width: 100%;text-align: start;">
                                                                        <strong>Email</strong>
                                                                    </label>
                                                                    <div>
                                                                        <input #editEmail class="required" type="text"
                                                                            style="margin: 0px 0px 0px 0px;"
                                                                            value="{{item1.Email}}" />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary"
                                                                (click)="closeDialog(item1.Contact_Person_Code)"
                                                                style=" background: repeating-radial-gradient(black, transparent 100px); ">CANCEL</button>
                                                            <button
                                                                (click)="editSaveDialog(editContactPersonCode.value, editContactPersonName.value, editDesignation.value, editPhone.value, editMobile.value, editEmail.value)"
                                                                type="button" class="btn btn-primary">Edit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div class="actions">
                        <div class="right" style="margin-left: 12px;">
                            <button
                                (click)="editSupplerMaster(editSupplier_Code.value, editSupplier_Name.value,editAdd1.value,editAdd2.value,editAdd3.value,editPhone.value,editFax.value,editTelex.value,editItem1.value,editItem2.value, editEmail.value,editBank.value)"
                                class="grey cancel" style=" height: 30px; margin-top: 7px;">Save</button>
                        </div>
                        <div class="right">
                            <button class="submit" (click)="AddDialog()"
                                style="padding: 7px 18px; height: 30px; margin-top: 8px;">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="modal_signup" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <form id="modal_form_signup" novalidate="novalidate">
                    <div class="modal-content">
                        <div class="modal-header" style="text-align: start;display: flex;">
                            <h5 class="modal-title"> Add Contact Person </h5>
                            <button type="button" class="close" (click)="closeDialog('add')" aria-label="Close"
                                style=" right: 0; position: absolute;">
                                <span>&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="grid_6">
                                    <label for="d2_username">
                                        <strong>Contact Person Code</strong>
                                    </label>
                                    <div>
                                        <input #addContactPersonCode class="required" type="text" name="d2_username"
                                            id="d2_username" value="{{this.ContactPersion_listCountAdd}}"
                                            style="margin: 0px 0px 0px 0px;" disabled />
                                    </div>
                                </div>
                                <div class="grid_6">
                                    <label for="d2_email">
                                        <strong>Contact Person Name</strong>
                                    </label>
                                    <div>
                                        <input #addContactPersonName [(ngModel)]="contactPersonName1" class="required"
                                            type="text" name="d2_email" id="d2_email"
                                            style="margin: 0px 0px 0px 0px;" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="grid_6">
                                    <label for="d2_email">
                                        <strong>Designation</strong>
                                    </label>
                                    <div>
                                        <input #addDesignation [(ngModel)]="designation1" class="required" type="text"
                                            name="d2_email" id="d2_email" style="margin: 0px 0px 0px 0px;" />
                                    </div>
                                </div>
                                <div class="grid_6">
                                    <label for="d2_email">
                                        <strong>Phone</strong>
                                    </label>
                                    <div>
                                        <input #addPhone [(ngModel)]="phone1" class="required" type="text"
                                            name="d2_email" id="d2_email" style="margin: 0px 0px 0px 0px;" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="grid_6">
                                    <label for="d2_email">
                                        <strong>Mobile</strong>
                                    </label>
                                    <div>
                                        <input #addMobile [(ngModel)]="mobile1" class="required" type="text"
                                            name="d2_email" id="d2_email" style="margin: 0px 0px 0px 0px;" />
                                    </div>
                                </div>
                                <div class="grid_6">
                                    <label for="d2_email">
                                        <strong>Email</strong>
                                    </label>
                                    <div>
                                        <input #addEmail [(ngModel)]="email1" class="required" type="text"
                                            name="d2_email" id="d2_email" style="margin: 0px 0px 0px 0px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" (click)="closeDialog('add')">CANCEL</button>
                            <button
                                (click)="addSaveDialog(addContactPersonCode.value, addContactPersonName.value, addDesignation.value, addPhone.value, addMobile.value, addEmail.value)"
                                type="submit" class="btn btn-primary">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>