import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-spec-sheet',
  templateUrl: './order-spec-sheet.component.html',
  styleUrls: ['./order-spec-sheet.component.css']
})
export class OrderSpecSheetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
