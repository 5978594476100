import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';  
import { WebServiceService } from 'src/app/services/web-service.service'; 
import Swal from 'sweetalert2';
declare let $: any;

@Component({
  selector: 'app-suppler',
  templateUrl: './suppler.component.html',
  styleUrls: ['./suppler.component.css']
})
export class SupplerComponent implements OnInit {
  @ViewChild('lazy') private lazyTree!: ElementRef;
  tree_card: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  addvlaue: any = 'add';
  tree_edit_list_print:any;
  addIn: boolean = false;
  isChecked:boolean = false;
  ContactPersion_listCountAdd:any;
  supplierCode:any;
  selectedUser = null;
  dialogIn:boolean =false;
  dialogEdit:boolean =false;
  dialogDelete:boolean =false;
  contactPersonName1: string = '';
  designation1: string = '';
  phone1: string = '';
  mobile1: string = '';
  email1: string = '';

  constructor( 
    private webService: WebServiceService,
    private route: ActivatedRoute, 
    private router: Router,
  ) {
    
  }

  showSnackbarCssStyles(content, action, duration) {
		 
	}


  ngOnInit(): void {
    this.loadTreeSupplie();
    let url = "Suppler/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
              
            }
          });

        } else {

        }
      }
    );
    this.loadBankMaster();
  }

  loadTreeSupplie = () => {
    let url = "Suppler/TreeSupplieList";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList="";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card = data12[0];
          this.tree_card.forEach(element => {
            dataList += "<tr><td>" + element.Supplier_Code + "</td><td>" + element.Supplier_Name + "</td><td>" + element.Email + "</td><td>" + element.Phone + "</td><td>" + element.Telex + "</td><td>" + element.Fax + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.Bank_Code + "</td><td>" + element.Created_By + "</td><td>" + element.Created_Date + "</td><td>" + element.Modified_By + "</td><td>" + element.Modified_Date + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;
        } else {

        }
      }
    );
  }
  CodeAlredyExist = "";
  CodeCheckUnique(code) {

    let url = "Suppler/EditSupplierCodeAndNameList";
    this.webService.loadTreetListEdit(url, code).subscribe(data => {
      if (data.status === 'OK') {
        this.CodeAlredyExist = "Supplier Code already exists";
      }
      else {
        this.CodeAlredyExist = "Supplier Code is correct";
      }
    });
  }

  deleteDialog = () => {
    this.dialogDelete =true;
  }
  
  clearInputFields() {
    this.contactPersonName1 = '';
    this.designation1 = '';
    this.phone1 = '';
    this.mobile1 = '';
    this.email1 = '';
  }

  AddDialog = () => {
    this.clearInputFields();
    $('#modal_signup').modal('show');
  }

  editDialog = (id) => {
    $('#modalEdit' + id).modal('show');
  }

  closeDialog = (id) => {
    if(id == "add"){
      $('#modal_signup').modal('hide');
    }else{
      $('#modalEdit' + id).modal('hide');
    }
    
  }

 
  
   
  editSaveDialog = (ContactPersonCode, ContactPersonName, Designation, Phone, Mobile, Email) => {
    let data = {
      supplierCode:this.supplierCode, 
      contactPersonCode:ContactPersonCode,
      contactPersonName:ContactPersonName,
      designation:Designation,
      phone:Phone,
      mobile:Mobile,
      email:Email
    }
    let url = "Suppler/EditSupplierContactInfo";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
           Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;">Edit Supplier Contact Info</p>',
            showConfirmButton: false,
            timer: 2500
          });
          $('#modalEdit'+ContactPersonCode).modal('hide');
          this.loadContactPersionList(this.supplierCode);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Is not Edit Supplier Contact Info</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );
  }

  addSaveDialog = (ContactPersonCode, ContactPersonName, Designation, Phone, Mobile, Email) => {
    let data = {
      supplierCode:this.supplierCode, 
      contactPersonCode:ContactPersonCode,
      contactPersonName:ContactPersonName,
      designation:Designation,
      phone:Phone,
      mobile:Mobile,
      email:Email
    }
    debugger
    let url = "Suppler/AddSupplierContactInfo";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Add Supplier Contact Info</p>',
            showConfirmButton: false,
            timer: 2500
          });
          $('#modal_signup').modal('hide');
          this.loadContactPersionList(this.supplierCode);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Not Add Supplier Contact Info</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );
  }

  cancelDialog = () => {
    this.dialogIn =false;
    this.dialogEdit =false;
    this.dialogDelete =false;
  }

  openDialog = () => {
    this.dialogIn =true;
  }

  loadBankMaster = () => {
    let url = "BankMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.status === 'OK') {
          debugger
          let data12 = (new Function("return [" + data.data + "]")());
          this.bankMasterList = data12[0];
        } else {

        }
      }
    );
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.loadBankMaster();
  }

  TreeListBinding = (data, id) => {
    try{
      this.selectedUser = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url = "Suppler/EditSupplierCodeAndNameList";
      this.webService.loadTreetListEdit(url,data).subscribe(
         (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.tree_edit_list = data12[0][0];
            this.supplierCode = this.tree_edit_list.Supplier_Code;
            if(this.tree_edit_list.Accessory_Or_Item == 'I')
            {
              this.isChecked = true;
            }
            else if(this.tree_edit_list.Accessory_Or_Item == 'A')
            {
              this.isChecked = true;
            }
            else
            {
              this.isChecked = false;
            }
            this.loadContactPersionList(this.tree_edit_list.Supplier_Code);
            this.selectedUser = this.tree_edit_list.Bank_Code;
            this.loadBankMaster();
            
          } else {
  
          }
  
        }
      );
    }
    catch(e){

    }
    
  }

  loadContactPersionList = (data = "") => {
    this.ContactPersion_list = [];
    let url = "Suppler/SupplierCode";
    this.webService.loadTreetListEdit(url,data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_listCountAdd =  data12[0].length + 1;
          this.ContactPersion_list = data12[0];
        } else {

        }

      }
    );
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/suppler']);
    });
   }

  addSupplerMaster = (Supplier_Code, Supplier_Name, Add1, Add2, Add3, Phone, Fax, Telex, Item1, Item2, Email, Bank_Name) => {
    let item;
    if (Item1 === 'I') {
      item = 'I';
    } else if (Item2 === 'A') {
      item = 'A';
    }
    let data = {
      supplier_Code:Supplier_Code, 
      supplier_Name:Supplier_Name,
      add1:Add1,
      add2:Add2,
      add3:Add3,
      phone:Phone,
      fax:Fax,
      telex:Telex,
      accessory_Or_Item:item,
      email:Email,
      bank_Code:Bank_Name.split(': ')[1]
    }
    let url = "Suppler/AddSupplie";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Added Supplier</p>',
            showConfirmButton: false,
            timer: 2500
          });
          this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.loadContactPersionList(Supplier_Code);
          
          this.loadBankMaster();
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Not Add Supplier</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );

  }

  editSupplerMaster = (Supplier_Code, Supplier_Name, Add1, Add2, Add3, Phone, Fax, Telex, Item1, Item2, Email, Bank_Name) => {
    let item;
    if (Item1 === 'I') {
      item = 'I';
    } else if (Item2 === 'A') {
      item = 'A';
    }
    let data = {
      supplier_Code:Supplier_Code, 
      supplier_Name:Supplier_Name,
      add1:Add1,
      add2:Add2,
      add3:Add3,
      phone:Phone,
      fax:Fax,
      telex:Telex,
      accessory_Or_Item:item,
      email:Email,
      bank_Code:Bank_Name.split(': ')[1]
    }
    let url = "Suppler/EditSupplie";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Edited Supplier</p>',
            showConfirmButton: false,
            timer: 2500
          });
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.loadBankMaster();
          this.loadContactPersionList(Supplier_Code);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Not Edited Supplier</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong></h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h2 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Supplier Master</strong></h2>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Email</th>
		      <th>Phone</th>
          <th>Telex</th>
          <th>Fax</th>
		      <th>Addrss</th>
          <th>Created_By</th>
          <th>Created_Date</th>
		      <th>Modified_By</th>
          <th>Modified_Date</th> 
        </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
