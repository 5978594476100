<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
  .pagination {
    margin: -15px 0;
    display: flex;
    justify-content: center;

  }

  .ngx-pagination {
    margin-left: 0 !important;
    margin-bottom: 1rem !important;
    line-height: 20px !important;
    padding: 7px 15px 7px !important;
    font-size: 12px !important;
  }

  .ngx-pagination::before,
  .ngx-pagination::after {
    content: " " !important;
    display: table !important;
  }

  .ngx-pagination::after {
    clear: both !important;
  }

  .ngx-pagination li {
    margin-right: 0.2rem !important;
    border-radius: 0 !important;
  }

  .ngx-pagination li {
    display: inline-block !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    color: #0a0a0a !important;
    display: block !important;
    background-color: rgb(248, 248, 248) !important;
    color: black !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: #788b8d88 !important;
  }

  .ngx-pagination .current {
    padding: 0.1875rem 0.625rem !important;
    background: #2199e8 !important;
    color: #fefefe !important;
    cursor: default !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination .disabled {
    padding: 0.1875rem 0.625rem !important;
    color: #cacaca00 !important;
    cursor: default !important;
  }

  .ngx-pagination .disabled:hover {
    background: transparent !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    cursor: pointer !important;
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    font-family: FontAwesome;
    content: "\f053" !important;
    display: inline-block !important;
    margin-right: 0rem !important;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    font-family: FontAwesome !important;
    content: "\f054" !important;
    display: inline-block !important;
    margin-left: 0rem !important;
  }

  .ngx-pagination .show-for-sr {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  .ngx-pagination .small-screen {
    display: none !important;
  }

  @media screen and (max-width: 601px) {
    .ngx-pagination.responsive .small-screen {
      display: inline-block !important;
      color: black !important;
      background-color: rgb(248, 248, 248) !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
    }

    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
      display: none !important;
    }
  }

  .table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-left: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  tr td {
    border-color: #dddddd00 !important;
  }
</style>
<main>
  <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
  <!-- invoice Description -->
  <div class="card-body pt-20 mt-0" style="margin: 40px;position: relative;margin-top: 80px!important;font-size: large;">
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive invoice-table mb-4 mt-0">
          <div class="" style="border: 1px solid #e5e5e5;">
            <div class="title"
              style=" margin-top: -58px; font-size: x-large; position: absolute;background-color: aliceblue; padding-left: 10px; width: 350px; ">
              Multiple Order Enquiry Report</div>
            <div class="content">
              <form action="#">
                <div class="user-details">
                  <div class="input-box" style="display: flex;margin: 12px;font-size: initial;">
                    <span class="details" style="width: 20%;">Order No. Contains</span>
                    <input type="text" #OrderNoContains placeholder="Order No. Contains" required
                      style=" width: 80%; font-size: initial;" />
                  </div>

                  <div class="input-box" style="display: flex;margin: 12px;">
                    <span class="details" style="width: 20%;">Buyer</span>
                    <select #Buyer class="search required" data-placeholder="Buyer"
                      style="width: 80%;top: 5px;height: auto;padding: 6px;font-size: initial;">
                      <option [ngValue]="allbuyer">Select Buyer</option>
                      <option *ngFor="let size of tree_card_Buyer" value="{{size.Buyer_Code}}">
                        {{size.Buyer_Name}}
                      </option>
                    </select>

                  </div>

                  <div class="input-box" style="display: flex;margin: 12px;">
                    <span class="details" style="width: 20%;">Supplier</span>
                    <select #Supplier class="search required" data-placeholder="Supplier"
                      style="width: 80%;top: 5px;height: auto;padding: 6px;font-size: initial;">
                      <option [ngValue]="allSuplier">Select Supplier</option>
                      <option *ngFor="let size1 of tree_card_Suppler" value="{{size1.Supplier_Code}}">
                        {{size1.Supplier_Name}}
                      </option>
                    </select>

                  </div>
                  <div class="input-box" style="display: flex;margin: 12px;">
                    <span class="details" style="width: 20%;">Item Category</span>
                    <select #ItemCategory class="search required" data-placeholder="Item Category"
                      style="width: 80%;top: 5px;height: auto;padding: 6px;font-size: initial;">
                      <option [ngValue]="ItemCategory">Select Item Category</option>
                      <option *ngFor="let size1 of this.quarterList_article_category"
                        [ngValue]="size1.article_category_Code">
                        {{size1.Name}}
                      </option>
                    </select>

                  </div>
                  <div class="input-box" style="display: flex;margin: 12px;">
                    <span class="details" style="width: 20%;">Article Code</span>
                    <select #ArticleCode class="search required" data-placeholder="Article Code"
                      style="width: 80%;top: 5px;height: auto;padding: 6px;font-size: initial;">
                      <option [ngValue]="ArticleCode">Select Article Code</option>
                      <option *ngFor="let size1 of this.tree_article_master" [ngValue]="size1.article_code">
                        {{size1.article_name}}
                      </option>
                    </select>

                  </div>
                  <div class="input-box" style="display: flex; margin: 12px;">
                    <span class="details" style="width: 20%;">Status</span>
                    <div class="details" style="width: 80%;">
                      <input type="radio" [(ngModel)]="radioSelected" value="All" name="statusFilter" id="All" />
                      <label for="All" style="margin: 10px 15px 5px 2px;">All</label>
                      <input type="radio" [(ngModel)]="radioSelected" value="UnderExec" name="statusFilter"
                        id="UnderExecution" />
                      <label for="UnderExecution" style="margin: 10px 15px 5px 2px;">Under Execution</label>
                      <input type="radio" [(ngModel)]="radioSelected" value="Completed" name="statusFilter"
                        id="Finished" />
                      <label for="Finished" style="margin: 10px 15px 5px 2px;">Finished</label>
                      <input type="radio" [(ngModel)]="radioSelected" value="Cancelled" name="statusFilter"
                        id="Cancelled" />
                      <label for="Cancelled" style="margin: 10px 15px 5px 2px;">Cancelled</label>
                      <div class="button"
                        style="background-color: white; color: black; border: 0px solid #008CBA; padding: 0px 0px; text-align: center; text-decoration: none; display: inline-block; font-size: 12px; margin: 0px 0px; transition-duration: 0.4s; cursor: pointer; border-radius: 8px;">
                        <input
                          (click)="loadTreeSupplie(OrderNoContains.value,Buyer.value,Supplier.value,ItemCategory.value,ArticleCode.value)"
                          type="button" value="Filter >>"  style=" color: white; font-size: initial; ">
                      </div>
                    </div>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-12">
        <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>List Of Buyers</strong></h5>
      </div>
      <div class="col-sm-12">
        <div class="scroll-table-container">
          <table class="scroll-table dynamic styled" data-filter-Bar="always" data-table-tools='{"display":false}'>
            <thead>
              <tr>
                <th>Order Code</th>
                <th>Order Date</th>
                <th>Buyer Code</th>
                <th>Supplier Code</th>
                <th>Dispatch Date</th>
                <th>Currency Code</th>
                <th>Order Completed</th>
                <th>Order Cancelled</th>
                <th>Article Code</th>
                <th>SHIPMENT SERIAL_NO</th>
                <th>Article Name</th>
                <th>EXPORT DATE</th>
                <th>SHIPPING MODE</th>
                <th>DELIVERY DATE</th>
                <th>ITEM QTY</th>
                <th>ITEM PRICE</th>
                <th>Total Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.report_list"  (dblclick)="redirectToLink(item.Order_Code)">
                <td>{{item.Order_Code}}</td>
                <td>{{item.Order_Date}}</td>
                <td>{{item.Buyer_Code}}</td>
                <td>{{item.Supplier_Code}}</td>
                <td>{{item.Dispatch_Date}}</td>
                <td>{{item.Currency_Code}}</td>
                <td>{{item.Order_Completed}}</td>
                <td>{{item.Order_Cancelled}}</td>
                <td>{{item.Article_Code}}</td>
                <td>{{item.SHIPMENT_SERIAL_NO}}</td>
                <td>{{item.Article_Name}}</td>
                <td>{{item.EXPORT_DATE}}</td>
                <td>{{item.SHIPPING_MODE}}</td>
                <td>{{item.DELIVERY_DATE}}</td>
                <td>{{item.ITEM_QTY}}</td>
                <td>{{item.ITEM_PRICE}}</td>
                <td>{{item.Total_Quantity}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="pagination">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div> -->
    </div>

  </div>

  <!-- invoice Table -->

</main>