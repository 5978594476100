import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service';
import { TableRow } from '../util/table-row';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, catchError, map, of } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-color-details',
  templateUrl: './order-color-details.component.html',
  styleUrls: ['./order-color-details.component.css'],
  styles: [`tfoot{text-align: right;} 
  .myerror{color:red} 
  .requiredInput.divmessage{display:none} 
  .requiredInput.divmessage.show{display:block !important} 
  .requiredInput.divmessage.hide{display:none}`]

})
export class OrderColorDetailsComponent implements OnInit {
  selectedValue: string = "2";
  shipment_serialList: any = [];
  proShowOrderColorDetalls: any = [];
  proShowRecordsListGS: any = [];
  originalValuesGS: any = [];
  proFillModeList: any[];
  proShowOrderDetailsList: any = [];
  proShowRecordsList: any[];
  ItemCategoryList: any = [];
  proShowOrderDetailsListColorDetalls: any = [];
  shipment_status: any;
  order_Code_Sipment: any;
  service: WebServiceService;
  orderCode: any = "";
  orderCode_value: any;
  ShipmentNo = "";
  Modeofshipment: any;
  ShipmentNo_Data: any = "0";
  buyer: any;
  supplier: any;
  TotalShipQty: any;
  ShipmentTotal: any;
  order_article_no: any;
  get_Color_Id: any;
  get_order_id: any;
  ischekInsert: any = "NOK";
  dataDeleteColor = false;
  dataCheckColor = false;
  isDropdownDisabled = false;
  constructor(
    service: WebServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private webService: WebServiceService,
    private fb: FormBuilder
  ) {
    this.service = service;
  }

  userForm: FormGroup;
  employee = [];


  ngOnInit() {
    this.proFillMode();
    this.loadItemCategory();
    this.proFillModeColor_Id();
    this.route.queryParamMap.subscribe((params) => {
      this.orderCode_value = params['params'].orderCode;
      this.Modeofshipment = params['params'].Modeofshipment;
      this.get_order_id = params['params'].order_id;
      this.ShipmentNo_Data = params['params'].ShipmentNo;
      debugger
      this.proshowPopupGenerateShipment(this.orderCode_value);

      this.proshowPopupGenerateShipmentColorDetalls(this.orderCode_value, this.ShipmentNo_Data);

    });
    this.userForm = this.fb.group({
      users: this.fb.array([])
    });
  }

  showPopupColorDetalls = (order_Code_Sipment, order_id_number, ShipmentNo_Data12, sip_mode_shipiment) => {
    this.ShipmentNo_Data = ShipmentNo_Data12;
    if (sip_mode_shipiment === "") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Sorry please fill Shipment details</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      this.ShipmentNo_Data = ShipmentNo_Data12;
      this.router.navigate([], {
        relativeTo: this.route, // You may need to inject ActivatedRoute as well
        queryParams: { ShipmentNo: ShipmentNo_Data12 },
        queryParamsHandling: 'merge', // Preserve existing query parameters
      });
    }
  }

  getEmployee() {
    const control = <FormArray>this.userForm.get('users');

    // Clear the FormArray
    while (control.length !== 0) {
      control.removeAt(0);
    }

    for (const emp of this.employee) {
      const grp = this.fb.group({
        color_name: [emp.color_name, Validators.required],
        composition: [emp.composition, [Validators.required]],
        lab_dip_yarn_bit: [emp.lab_dip_yarn_bit, [Validators.min(10)]],
        sent_date: [emp.sent_date, Validators.required]
      });
      control.push(grp);
    }
  }


  initiatForm(): FormGroup {
    return this.fb.group({
      color_name: ['', Validators.required],
      composition: ['', [Validators.required]],
      lab_dip_yarn_bit: ['', [Validators.min(10)]],
      sent_date: ['']
    });
  }

  get getFormData(): FormArray {
    return <FormArray>this.userForm.get('users');
  }

  addUser() {
    const control = <FormArray>this.userForm.get('users');
    control.push(this.initiatForm());
  }

  remove(index: number) {
    const control = <FormArray>this.userForm.get('users');
    control.removeAt(index);
  }

  save() {
    console.log('isValid', this.userForm.valid);
    console.log('value', this.userForm.value);
  }

  editRowColor(index: number) {
    this.proShowOrderDetailsListColorDetalls[index].isEditing = true;
  }

  saveRowColor(index: number) {
    this.proShowOrderDetailsListColorDetalls[index].isEditing = false;
  }

  cancelEditColor(index: number) {
    this.proShowOrderDetailsListColorDetalls[index] = { ...this.originalValuesGS[index] };
    this.proShowOrderDetailsListColorDetalls[index].isEditing = false;
  }

  clearForm() {
    // Reset the form to its initial state
    this.userForm.reset();
  
    // Clear the FormArray
    const control = <FormArray>this.userForm.get('users');
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }
  
  clearList() {
    // Clear the list of items
    this.proShowOrderDetailsListColorDetalls = [];
    this.originalValuesGS = []; // Optionally, clear the original values list
  }

  changeTreeContactShipmentColor = (event, strOrderNo) => {
    this.clearForm();
    this.clearList();
    let strShipmentNo = event.target.value;
    if (this.orderCode_value == "") {
      this.proshowPopupGenerateShipmentColorDetalls(this.orderCode_value, strShipmentNo);
    }
    else {
      this.ShipmentNo_Data = strShipmentNo;

      this.showPopupColorDetalls(this.orderCode_value, this.get_order_id, strShipmentNo, this.Modeofshipment)
    }

  }

  saveAllChangesColor(orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue) {
    // debugger
    let url = `ColorDetails/ColorDelete?order_code=${orderCode}&shipment_serial_no=${ShipmentNo}`;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.status === 'OK') {
          this.proShowOrderDetailsListColorDetalls.forEach(item => {
            item.isEditing = false;
          });
          this.userForm.value.users.forEach((item, index) => {
            this.AddandUpdateColor(orderCode, this.get_order_id, this.ShipmentNo_Data, this.order_article_no, this.get_Color_Id, index + 1, item.color_name.trim(), item.composition.trim(), item.lab_dip_yarn_bit.trim(), item.sent_date)
            this.ischekInsert = "OK";
          });
        } else {
          this.proShowOrderDetailsListColorDetalls.forEach(item => {
            item.isEditing = false;
          });
          this.userForm.value.users.forEach((item, index) => {
            this.AddandUpdateColor(orderCode, this.get_order_id, this.ShipmentNo_Data, this.order_article_no, this.get_Color_Id, index + 1, item.color_name.trim(), item.composition.trim(), item.lab_dip_yarn_bit.trim(), item.sent_date)
            this.ischekInsert = "OK";
          });
        }
      }
    );
    // const result = this.DeleteColor(order_code, shipment_serial_no);
    // if (result === true) {
    //   debugger
      
    //   });
    //   // this.proFillMode();
    //   // this.loadItemCategory();
    //   // this.proFillModeColor_Id();
    // }
    // else {
    //   this.proShowOrderDetailsListColorDetalls.forEach(item => {
    //     data.push({ ...item });
    //     item.isEditing = false;
    //   });
    //   this.userForm.value.users.forEach((item, index) => {
    //     data1.push({
    //       color: item.color_name.trim(),
    //       Composition: item.composition.trim(),
    //       LabDipYarnBit: item.lab_dip_yarn_bit.trim(),
    //       SentDate: item.sent_date
    //     })
    //     this.AddandUpdateColor(orderCode, this.get_order_id, this.ShipmentNo_Data, this.order_article_no, this.get_Color_Id, index + 1, item.color_name.trim(), item.composition.trim(), item.lab_dip_yarn_bit.trim(), item.sent_date)
    //     this.ischekInsert = "OK";
    //   });
    // }

    if (this.ischekInsert == 'OK') {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Order Color has been added.</p>',
        showConfirmButton: false,
        timer: 2500
      });
      //alert('Order Color has been added.')
    }
    else {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Order Color has been not added. Please try agian</p>',
        showConfirmButton: false,
        timer: 2500
      });
      //alert('Order Color has been not added. Please try agian')
    }
  }

  loadItemCategory = () => {
    let url = "ItemCategory/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.ItemCategoryList = data12[0];
        } else {

        }
      }
    );
  }

  proFillModeColor_Id = () => {
    this.proFillModeList = [];
    let url = "ColorDetails/ItemColorId";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          debugger
          this.get_Color_Id = data12[0][0].color_id;
        } else {

        }
      }
    );
  }

  proFillMode = () => {
    this.proFillModeList = [];
    let url = "Order/proFillMode";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          this.proFillModeList = data12[0];
        } else {

        }
      }
    );
  }

  proshowPopupGenerateShipment = (code) => {
    this.shipment_serial_GS(code);
    this.proShowOrderDetailsList = [];
    let url = "Order/proShowOrderDetails?strOrderCode=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            this.proShowOrderDetailsList = data12[0][0];
            this.buyer = this.proShowOrderDetailsList.buyer_Name;
            this.supplier = this.proShowOrderDetailsList.supplier_name

          } else {
            this.order_Code_Sipment = this.order_Code_Sipment;
            this.buyer = "";
            this.supplier = "";
            //this.shipment_serial(this.order_Code_Sipment);
          }
        } else {
          //  this.shipment_serial(code);
        }
      }
    );

  }

  proshowPopupGenerateShipmentColorDetalls = (codedata, sid) => {
    this.proShowOrderDetailsListColorDetalls = [];
    let url = "ColorDetails/ItemList?order_code=" + codedata + "&sidno=" + sid;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              this.proShowOrderDetailsListColorDetalls.push(
                {
                  id: index + 1,
                  order_article_code: item.ORDER_ARTICLE_CODE,
                  item_description: item.Item_Description,
                });
            });
            this.showOrderColorDetalls(codedata, sid, this.proShowOrderDetailsListColorDetalls[0].order_article_code);
          }

        } else {
          //  this.shipment_serial(code);
        }
      }
    );

  }

  CheckFillModeColor = (order_code, shipment_serial_no) => {
    this.dataCheckColor = false;
    let url = `ColorDetails/ColorCheck?order_code=${order_code}&shipment_serial_no=${shipment_serial_no}`;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          debugger
          this.dataCheckColor = true;
        } else {
          this.dataCheckColor = true;
        }
      }
    );
  }

  DeleteColor2 = (order_code, shipment_serial_no) => {
    let url = `ColorDetails/ColorDelete?order_code=${order_code}&shipment_serial_no=${shipment_serial_no}`;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          return true;
        } else {
          return false;
        }
      }
    );
  }
 

  AddandUpdateColor = (order_code, order_id, shipment_serial_no, article_no, color_id, color_detail_id, color_name, composition, labDipYarnBit, lab_dip_yarn_bit_date) => {
    let labDipYarnBitdatabase;
    if (labDipYarnBit == "" || labDipYarnBit == undefined) {
      labDipYarnBitdatabase = null;
    }
    else {
      labDipYarnBitdatabase = labDipYarnBit;
    }
    const dateToSend = new Date(lab_dip_yarn_bit_date);
    const formattedDate = dateToSend.toISOString();
    let url = "ColorDetails/ColorDetailsofArticleAdd?order_code=" + order_code + "&order_id=" + order_id + "&shipment_serial_no=" + shipment_serial_no + "&article_no=" + article_no + "&color_id=" + color_id + "&color_detail_id=" + color_detail_id + "&color_name=" + color_name + "&composition=" + composition + "&labDipYarnBit=" + labDipYarnBitdatabase + "&lab_dip_yarn_bit_date=" + formattedDate;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {

          // alert('Order Color has been added.')
        } else {


        }
      }
    );
  }

  showOrderColorDetalls = (order_code, sid, article_no) => {
    this.order_article_no = article_no;
    this.employee = [];
    let url = "ColorDetails/ColorDetailsofArticle?order_code=" + order_code + "&shipment_serial_no=" + this.ShipmentNo_Data + "&article_no=" + article_no;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataue = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              this.employee.push({
                color_name: item.color_name,
                composition: item.composition,
                lab_dip_yarn_bit: item.lab_dip_yarn_bit,
                sent_date: item.LAB_DIPS_SENT_DATE
              });
            });
            this.getEmployee();
          } else {

          }
        } else {

          //  this.shipment_serial(code);
        }
      }
    );
  }

  shipment_serial_GS = (code) => {
    this.shipment_serialList = [];
    let url = "Order/shipment_serial?code=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          this.shipment_serialList = data12[0];
          this.proShowRecordsGS(this.shipment_serialList[0].shipment_serial_no, code)
        } else {

        }
      }
    );
  }

  proShowRecordsGS = (strShipmentNo, strOrderNo) => {
    this.proShowRecordsListGS = [];
    this.shipment_status = [];
    this.originalValuesGS = [];
    let url = "Order/showRecordsGenerateShipment?strShipmentNo=" + strShipmentNo + "&strOrderNo=" + strOrderNo;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          debugger
          this.proShowRecordsListGS = data12[0];
          this.proShowRecordsListGS.forEach(item => {
            this.originalValuesGS.push({ ...item });
          });

          this.shipment_status = this.proShowRecordsListGS[0].SHIPMENT_STATUS;

        } else {

        }
      }
    );
  }

}
