import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.css']
})
export class UserMasterComponent implements OnInit {

  constructor() { }
  addvlaue:any='';
  ngOnInit(): void {
  }

  addSublier = (id) =>{

  }

  onPreview = ()=>{

  }
}
