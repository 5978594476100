// doc-upload.component.ts

import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.css']
})
export class DocUploadComponent implements OnInit {
  order_id: any;
  order_code: any;
  @ViewChild('resumeInput', { static: true }) resumeInput: ElementRef;
  selectedFile: File | any = null;
  imageUrl: any;
  isConditionTrue: any = false;
  fileToUpload: File | any = null;
  saveFileForm: any;
  lstFileDetails: any;
  report_list: any = [];
  private _orderNo: string;
  @Input()
  set OrderNo(value: any) {

    if (Array.isArray(value)) {
      this.order_id = value[1];

      this.order_code = value[0];
      console.log(this.order_code)

      this.updateModalContent();
    } else {
      this._orderNo = value[0];
      this.updateModalContent();
    }
  }

  get OrderNo(): string {
    // console.log(this._orderNo)
    return this._orderNo;
  }

  constructor(
    private webService: WebServiceService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['OrderNo'] && !changes['OrderNo'].firstChange) {
      if (this.order_code != undefined) {
        this.saveFileForm.patchValue({
          OrderNo: this.order_code
        });
        this.ReportViewPrint(this.order_code);

        this.updateModalContent();
      }


    }

  }

  private updateModalContent() {
    this.order_id = this.order_id;

  }

  clearFileInput() {
    // Reset the value of the file input
    this.resumeInput.nativeElement.value = '';
  }

  isFieldValid(field: string) {
    return !this.saveFileForm.get(field).valid && this.saveFileForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  ngOnInit(): void {
    this.saveFileForm = this.formBuilder.group({
      OrderNo: ['', [Validators.required]],
    });
  }

  ReportViewPrint = async (order_code) => {
    this.report_list = [];
    if (order_code == "") {
      console.log(order_code);
    }
    else {
      let url = "DocFileUpload/GetFileDetails?itemCode=" + order_code;
      await this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          let dataList = "";
          debugger
          if (data.status === 'OK') {
            this.report_list = data.data;
          } else {

          }
        }
      );
    }
  }

  fileDelete = async (fileName) => {
    const result = await Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
    //console.log(result);

    if (result.isConfirmed) {
      let url = "DocFileUpload/DeleteFileDetails?itemCode=" + this.order_code + "&filename=" + fileName;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.ReportViewPrint(this.order_code);
            Swal.fire({
              icon: 'success',
              iconColor: '#000',
              background: '#FFFFFF',
              title: 'Deletion',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">Deleted successfully</p>',
              showConfirmButton: false,
              timer: 2500
            });
          } else {
            this.ReportViewPrint(this.order_code);
            Swal.fire({
              icon: 'error',
              title: 'Deletion',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">Something wrong want</p>',
              showConfirmButton: false,
              timer: 2500
            });
          }
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      // User clicked "No, cancel"
      // Do nothing or show a message
      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        iconColor: '#000',
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Deletion was cancelled</p>',
        showConfirmButton: false,
        timer: 2500
      });

    }

  }


  closePopupStyle(id) {
    $('#' + id).css('display', 'none');
    document.body.style.overflow = 'auto';
    if (id == 'showPopupDocUpload') {
      $('#showPopupDocUpload').css('display', 'none');
      $('#showPopupDocUpload').modal('hide');
      const existingModal = $('#showPopupDocUpload').data('bs.modal');
      if (existingModal) {
        existingModal.dispose(); // Destroy the modal
        $('#showPopupDocUpload').removeData('bs.modal'); // Remove modal data
      }
    }

  }

  validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      // console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  downloadFile(fileName, ext) {
    this.webService.downloadFile(this.order_code, fileName).subscribe(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName+'-YF.'+ext;  // Replace with the desired file name
      link.click();
    });
  }

  openLargeView(url: string) {
    this.imageUrl = url;
    this.isConditionTrue = true;
  }

  closeLargeView() {
    this.isConditionTrue = false;
    this.imageUrl = null;
  }

  onExpSubmit() {
    debugger
    if (this.saveFileForm.invalid) {
      this.validateAllFormFields(this.saveFileForm);
      return;
    } else {
      let formData = new FormData();
      formData.append('DocFile', this.resumeInput.nativeElement.files[0]);
      formData.append('OrderNo', this.saveFileForm.value.OrderNo);
      this.webService.AddFileDetails(formData).subscribe((result: any) => {
        // console.log(result);
        // this.closePopupStyle('showPopupDocUpload');
        if (result.status === 'OK') {
          this.clearFileInput();
          this.ReportViewPrint(this.order_code);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">'+result.message+'</p>',
            showConfirmButton: false,
            timer: 2500
          });

        } else {
          this.ReportViewPrint(this.order_code);
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">'+result.message+'</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      });
    }
  }
}
