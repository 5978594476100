import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component, 
  ViewEncapsulation,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service'; 
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-single-order-enquiry-report-list',
  templateUrl: './single-order-enquiry-report-list.component.html',
  styleUrls: ['./single-order-enquiry-report-list.component.css']
})
export class SingleOrderEnquiryReportListComponent implements OnInit {
  
  report_list: any = [];
  tree_edit_list_print:any=[];
  page: number = 1;
  qtyOrder:any;
  PriceOrder:any;
  InvPriceOrder:any;
  TotalPriceOrder:any;
  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private webService: WebServiceService,
  ) {
   
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.loadTreeSupplie(params['params'].order_no);
      if(params['params'].model == "model"){
        // $('#headerSkillIndia').css('display', 'none');
        // $('#footerSkillIndia').css('display', 'none');
      }
      else{
        // $('#headerSkillIndia').css('display', 'flex');
        // $('#footerSkillIndia').css('display', 'flex');
      }
      
    });
    
  }  
  
  closeWindow() {
    window.close();
    $('#headerSkillIndia').css('display', 'flex');
        $('#footerSkillIndia').css('display', 'flex');
  }

  printPage1() {
    window.print();

  }
  
  loadTreeSupplie = (order_no) => {
     
    let url = "ReportViewer/singleOrderEnquery?order_no="+order_no;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList="";
        if (data.status === 'OK') {
          let qty = 0;
          let Price = 0;
          let InvPrice = 0;
          let TotalPrice = 0;
          let data12 = (new Function("return [" + data.data + "]")());
          this.report_list = data12[0];
          this.report_list.forEach(element => {
            console.log(element.TOTAL_FINAL_PRICE);
        
            // Ensure that the values are parsed as numbers
            const itemQty = parseFloat(element.ITEM_PRICE_DETAIL_QTY);
            const itemPrice = parseFloat(element.ITEM_PRICE);
            const itemInvPrice = parseFloat(element.ITEM_INVOICE_PRICE);
            const itemTotalPrice = parseFloat(element.TOTAL_FINAL_PRICE);
        
            // Check if the parsed values are valid numbers
            if (!isNaN(itemQty) && !isNaN(itemPrice) && !isNaN(itemInvPrice) && !isNaN(itemTotalPrice)) {
                qty += itemQty;
                Price += itemPrice;
                InvPrice += itemInvPrice;
                TotalPrice += itemTotalPrice;
            }
        });
          this.qtyOrder = qty;
          this.PriceOrder= Price;
          this.InvPriceOrder= InvPrice;
          this.TotalPriceOrder= TotalPrice;
          console.log(TotalPrice);
          this.tree_edit_list_print = dataList;
        } else {

        }
      }
    );
  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Addrss</th>
		      <th>Phone</th>
          <th>Fax</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}
