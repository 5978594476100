<nav class="navbar navbar-expand-lg navbar-light bg-light" style="position: relative; display: flex;">
	<div class="navbar-header">
		<a class="navbar-brand" style="padding: 15px 8px 8px 15px;">
			<span style="font-size: x-large;">Young Fashion India</span>
		</a>
	</div>
	<div class="container-fluid" style="width: fit-content;font-size: large;">

		<div class="collapse navbar-collapse">

			<ul class="nav navbar-nav navbar-right" id="ulDistrictLabelHeader">
				<li class="nav-item dropdown" routerLinkActive="active">
					<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true"
					  aria-expanded="false">
					  Master
					</a>
					<ul class="dropdown-menu">
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/suppler']">Suppler </a></li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/employee']">Employee </a></li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/buyer']">Buyer </a></li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/courlerAgent']">Courler Agent</a></li>
						<li class="dropdown-submenu"><a href="javascript:void(0)">Accessories</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/accessories/category']">Accessories Category</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/accessories/master']">Accessories Master</a></li>
							</ul>
						</li>
						<!-- <li class="nav-item" routerLinkActive="active"><a [routerLink]="['/shippingAgent']">Shipping Agent</a></li> -->
						<li class="dropdown-submenu"><a href="javascript:void(0)">Items</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/item/category']">Item Category</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/item/master']">Item Master</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/item/price']">Item Price</a></li>
							</ul>
						</li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/bank']">Bank</a></li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/currency']">Currency</a></li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/sizemaster']">Size Master</a></li>
					</ul>
				</li>
				<li class="dropdown">
					<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true"
					  aria-expanded="false">
					  Transaction
					</a>
					<ul class="dropdown-menu">
						<li class="dropdown-submenu"><a href="javascript:void(0)">Order</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/OrderDetails']">Order Details</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/copy-order']">Copy Order</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/OrderFollow-Up']">Order Follow-Up</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/DocumentUpload']">Document Upload</a></li>
							</ul>
						</li>
						<li class="dropdown-submenu"><a href="javascript:void(0)">Shipping</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/GenerateShipments']">Generate Shipments</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ShipmentDetails']">Shipment Details</a></li>
							</ul>
						</li>
						<li class="dropdown-submenu"><a href="javascript:void(0)">Merchandising</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/MerchandisingDetails']">Merchandising Details</a></li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Specfication Sheet</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ItemSpecSheet']">Item Spec Sheet</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/AttachSpecSheet']">Attach Spec Sheet</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/OrderSpecSheet']">Order Spec Sheet</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/CopySpecSheetForItem']">Copy Spec Sheet For Item</a>
										</li>
									</ul>
								</li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Color Details</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ItemColorDetails']">Item Color Details</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ItemColorSheetDetails']">Item Color Sheet Details</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/AttachColorSheet']">Attach Color Sheet</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/OrderColorDetails']">Order Color Details</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/OrderColorSheet']">Order Color Sheet</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/OrderItemColorImage']">Order Item Color Image</a></li>
									</ul>
								</li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/AccessoriesSheet']">Accessories Sheet</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ProductionDetails']">Production Details</a></li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Testing</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/TestingDocsUpload']">Testing Docs Upload</a></li>
									</ul>
								</li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/InspectionSheet']">Inspection Sheet</a></li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Accounts</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/DebtNote']">Debt Note</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/CommissionNote']">Commission Note</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ClaimNote']">Claim Note</a></li>
									</ul>
								</li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Sampling</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/SamplingAssignmentEntry']">Sampling Assignment Entry</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/SamplingAssignmentPrice']">Sampling Assignment Price</a></li>
									</ul>

								</li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/DalyCouries']">Daly Couries </a></li>
							</ul>
						</li>

					</ul>
				</li>
				 
				<li class="dropdown">
					 
					<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true"
					  aria-expanded="false">
					  Options
					</a>
					<ul class="dropdown-menu">
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/ChangePassword']">Change Password </a></li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/CompanyInformation']">Company Information</a>
						<li class="dropdown-submenu"><a href="javascript:void(0)">User Access Control</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/UserMaster']">User Master</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/GroupMaster']">Group Master</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/UserGroupMapping']">User Group Mapping</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/GroupPermissions']">Group Permissions</a></li>
							</ul>
						</li>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/BulkDelete']">Bulk Delete</a>
						<li class="nav-item" routerLinkActive="active"><a (click)="logoutData()">Exit</a>
						<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/UpdateOrderId']">Update Order Id</a>
					</ul>
				</li>
				<li class="dropdown">
					<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true"
					  aria-expanded="false">
					  Reports
					</a>
					<ul class="dropdown-menu">
						<li class="dropdown-submenu"><a href="javascript:void(0)">Masters</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/buyer-report']">Buyer</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/suppler-report']">Suppler</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/employee-report']">Employee</a></li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Item Related</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/item-article-report']">Item/Article</a></li>
									</ul>
								</li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/accessories-report']">Accessorory</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/product-type-report']">Product Type</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/measurement-type-report']">Measrement Type</a></li>
							</ul>
						</li>
						<li class="dropdown-submenu"><a href="javascript:void(0)">MIS Reports</a>
							<ul class="dropdown-menu">
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/single-order-enquiry-report']">Single Order Enquiry</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/multiple-order-enquiry-report']">Multiple Order Enquiry</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/dispatch-receipt-register-report']">Dispatch/Receipt
										Register</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/sampling-register-report']">Sampling Register</a></li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">Orders</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/order-wise-report']">Order Wise</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/buyer-wise-report']">Buyer Wise</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/supplier-wise-report']">Supplier Wise</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/article-wise-report']">Article Wise</a></li>
									</ul>
								</li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/price-enquiry-report']">Price Enquiry Report</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/status-report']">Status Report</a></li>
								<li class="dropdown-submenu"><a href="javascript:void(0)">LC Status</a>
									<ul class="dropdown-menu">
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/order-wise-report']">Order Wise</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/buyer-wise-report']">Buyer Wise</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/supplier-wise-report']">Supplier Wise</a></li>
										<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/article-wise-report']">Article Wise</a></li>
									</ul>
								</li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/inspection-details-report']">Inspection Details</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/trun-order-report']">Trun Order</a></li>
								<li class="nav-item" routerLinkActive="active"><a [routerLink]="['/production-load-report']">Production Load</a></li>
							</ul>
						</li>
					</ul>
				</li>


			</ul>


		</div>
	</div>

	<div class="navbar-header" style="padding: 15px; color: white;">
		<button (click)="logoutData()" style="color: #ffffff; font-size: medium;">Logout</button>
	</div>
</nav>