import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { AuthGuard } from "./services/auth.guard";
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { SupplerComponent } from './components/suppler/suppler.component';
import { BuyerComponent } from './components/buyer/buyer.component';
import { BankComponent } from './components/bank/bank.component';
import { CourlerAgentComponent } from './components/courler-agent/courler-agent.component';
import { AccessoriesCategoryComponent } from './components/accessories-category/accessories-category.component';
import { AccessoriesMasterComponent } from './components/accessories-master/accessories-master.component';
import { ShippingAgentComponent } from './components/shipping-agent/shipping-agent.component';
import { ItemCategoryComponent } from './components/item-category/item-category.component';
import { ItemPriceComponent } from './components/item-price/item-price.component';
import { ItemMasterComponent } from './components/item-master/item-master.component';
import { MerchandiseMliestoneTypeComponent } from './components/merchandise-mliestone-type/merchandise-mliestone-type.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { MeasrementTypeComponent } from './components/measrement-type/measrement-type.component';
import { FlowUpCategoryComponent } from './components/flow-up-category/flow-up-category.component';
import { TestingTypeComponent } from './components/testing-type/testing-type.component';
import { SizeMasterComponent } from './components/size-master/size-master.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { BuyerReportComponent } from './components/buyer-report/buyer-report.component';
import { SupplerReportComponent } from './components/suppler-report/suppler-report.component';
import { EmployeeReportComponent } from './components/employee-report/employee-report.component';
import { CopyOrderComponent } from './components/copy-order/copy-order.component';
import { ItemArticleReportComponent } from './components/item-article-report/item-article-report.component';
import { SingleOrderEnquiryComponent } from './components/single-order-enquiry/single-order-enquiry.component';
import { MultipleOrderEnquiryComponent } from './components/multiple-order-enquiry/multiple-order-enquiry.component';
import { DispatchReceiptRegisterComponent } from './components/dispatch-receipt-register/dispatch-receipt-register.component';
import { SamplingRegisterComponent } from './components/sampling-register/sampling-register.component';
import { PriceEnquiryReportComponent } from './components/price-enquiry-report/price-enquiry-report.component';
import { StatusReportComponent } from './components/status-report/status-report.component';
import { InspectionDetailsComponent } from './components/inspection-details/inspection-details.component';
import { TrunOrderComponent } from './components/trun-order/trun-order.component';
import { ProductionLoadComponent } from './components/production-load/production-load.component';
import { OrderWiseComponent } from './components/order-wise/order-wise.component';
import { BuyerWiseComponent } from './components/buyer-wise/buyer-wise.component';
import { SupplierWiseComponent } from './components/supplier-wise/supplier-wise.component';
import { ArticleWiseComponent } from './components/article-wise/article-wise.component';
import { MeasurementTypeComponent } from './components/measurement-type/measurement-type.component';
import { AccessoriesComponent } from './components/accessories/accessories.component';
import { GenerateShipmentsComponent } from './components/generate-shipments/generate-shipments.component';
import { ShipmentDetailsComponent } from './components/shipment-details/shipment-details.component';
import { MerchandisingDetailsComponent } from './components/merchandising-details/merchandising-details.component';
import { AttachSpecSheetComponent } from './components/attach-spec-sheet/attach-spec-sheet.component';
import { ItemSpecSheetComponent } from './components/item-spec-sheet/item-spec-sheet.component';
import { OrderSpecSheetComponent } from './components/order-spec-sheet/order-spec-sheet.component';
import { CopySpecSheetForItemComponent } from './components/copy-spec-sheet-for-item/copy-spec-sheet-for-item.component';
import { ItemColorDetailsComponent } from './components/item-color-details/item-color-details.component';
import { ItemColorSheetDetailsComponent } from './components/item-color-sheet-details/item-color-sheet-details.component';
import { AttachColorSheetComponent } from './components/attach-color-sheet/attach-color-sheet.component';
import { OrderColorDetailsComponent } from './components/order-color-details/order-color-details.component';
import { OrderColorSheetComponent } from './components/order-color-sheet/order-color-sheet.component';
import { OrderItemColorImageComponent } from './components/order-item-color-image/order-item-color-image.component';
import { AccessoriesSheetComponent } from './components/accessories-sheet/accessories-sheet.component';
import { ProductionDetailsComponent } from './components/production-details/production-details.component';
import { TestingDocsUploadComponent } from './components/testing-docs-upload/testing-docs-upload.component';
import { InspectionSheetComponent } from './components/inspection-sheet/inspection-sheet.component';
import { ClaimNoteComponent } from './components/claim-note/claim-note.component';
import { CommissionNoteComponent } from './components/commission-note/commission-note.component';
import { DebtNoteComponent } from './components/debt-note/debt-note.component';
import { SamplingAssignmentEntryComponent } from './components/sampling-assignment-entry/sampling-assignment-entry.component';
import { SamplingAssignmentPriceComponent } from './components/sampling-assignment-price/sampling-assignment-price.component';
import { DalyCouriesComponent } from './components/daly-couries/daly-couries.component';
import { CompanyInformationComponent } from './components/company-information/company-information.component';
import { UserMasterComponent } from './components/user-master/user-master.component';
import { GroupMasterComponent } from './components/group-master/group-master.component';
import { UserGroupMappingComponent } from './components/user-group-mapping/user-group-mapping.component';
import { GroupPermissionsComponent } from './components/group-permissions/group-permissions.component';
import { BulkDeleteComponent } from './components/bulk-delete/bulk-delete.component';
import { UpdateOrderIdComponent } from './components/update-order-id/update-order-id.component';
import { SingleOrderEnquiryReportListComponent } from './components/single-order-enquiry-report-list/single-order-enquiry-report-list.component';
import { SingleOrderEnquiryReportsComponent } from './components/single-order-enquiry-reports/single-order-enquiry-reports.component';
import { TrunOrderViewComponent } from './components/trun-order-view/trun-order-view.component';

const subfolder = "";
const routes: Routes = [
  { path: subfolder, component: LoginComponent, data: { loggedIn: false } },
  //{ path: 'home', redirectTo: '/suppler', canActivate:[AuthGuard] },
  { path: subfolder + 'login', component: LoginComponent, data: { loggedIn: false }},
  { path: subfolder + 'reset-password', component: ResetPasswordComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'suppler', component: SupplerComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'employee', component: EmployeeComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'buyer', component: BuyerComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'courlerAgent', component: CourlerAgentComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'bank', component: BankComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'accessories/category', component: AccessoriesCategoryComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'accessories/master', component: AccessoriesMasterComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'shippingAgent', component: ShippingAgentComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'item/category', component: ItemCategoryComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'item/master', component: ItemMasterComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'item/price', component: ItemPriceComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'merchandisemliestoneType', component: MerchandiseMliestoneTypeComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'currency', component: CurrencyComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'measrementtype', component: MeasrementTypeComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'OrderFollow-Up', component: FlowUpCategoryComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'testingtype', component: TestingTypeComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'sizemaster', component: SizeMasterComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'OrderDetails', component: OrderDetailsComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'copy-order', component: CopyOrderComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'buyer-report', component: BuyerReportComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'suppler-report', component: SupplerReportComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'employee-report', component: EmployeeReportComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'item-article-report', component: ItemArticleReportComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'single-order-enquiry-report', component: SingleOrderEnquiryComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'multiple-order-enquiry-report', component: MultipleOrderEnquiryComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'dispatch-receipt-register-report', component: DispatchReceiptRegisterComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'sampling-register-report', component: SamplingRegisterComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'price-enquiry-report', component: PriceEnquiryReportComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'status-report', component: StatusReportComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'inspection-details-report', component: InspectionDetailsComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'trun-order-report', component: TrunOrderComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'production-load-report', component: ProductionLoadComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'order-wise-report', component: OrderWiseComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'buyer-wise-report', component: BuyerWiseComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'supplier-wise-report', component: SupplierWiseComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'article-wise-report', component: ArticleWiseComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'measurement-type-report', component: MeasurementTypeComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'accessories-report', component: AccessoriesComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'product-type-report', component: ProductTypeComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'GenerateShipments', component: GenerateShipmentsComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'ShipmentDetails', component: ShipmentDetailsComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'MerchandisingDetails', component: MerchandisingDetailsComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'AttachSpecSheet', component: AttachSpecSheetComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'ItemSpecSheet', component: ItemSpecSheetComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'OrderSpecSheet', component: OrderSpecSheetComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'CopySpecSheetForItem', component: CopySpecSheetForItemComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'ItemColorDetails', component: ItemColorDetailsComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'ItemColorSheetDetails', component: ItemColorSheetDetailsComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'AttachColorSheet', component: AttachColorSheetComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'OrderColorDetails', component: OrderColorDetailsComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'OrderColorSheet', component: OrderColorSheetComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'OrderItemColorImage', component: OrderItemColorImageComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'AccessoriesSheet', component: AccessoriesSheetComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'ProductionDetails', component: ProductionDetailsComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'TestingDocsUpload', component: TestingDocsUploadComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'InspectionSheet', component: InspectionSheetComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'ClaimNote', component: ClaimNoteComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'CommissionNote', component: CommissionNoteComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'DebtNote', component: DebtNoteComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'SamplingAssignmentEntry', component: SamplingAssignmentEntryComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'SamplingAssignmentPrice', component: SamplingAssignmentPriceComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'DalyCouries', component: DalyCouriesComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'CompanyInformation', component: CompanyInformationComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'UserMaster', component: UserMasterComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'GroupMaster', component: GroupMasterComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'UserGroupMapping', component: UserGroupMappingComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'GroupPermissions', component: GroupPermissionsComponent , canActivate:[AuthGuard]},
  { path: subfolder + 'BulkDelete', component: BulkDeleteComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'UpdateOrderId', component: UpdateOrderIdComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'SingleOrderEnquiryReportList', component: SingleOrderEnquiryReportListComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'single-order-enquiry-reports', component: SingleOrderEnquiryReportsComponent, canActivate:[AuthGuard] },
  { path: subfolder + 'trun-order-view', component: TrunOrderViewComponent, canActivate:[AuthGuard] },
 
  { path: '**', redirectTo: '/' }
]; 
//
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
