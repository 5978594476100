<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
  .table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-left: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  tr td {
    border-color: #dddddd00 !important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid #f48181;
    border-radius: 2px;
    background-image: linear-gradient(#195e83,#042f66);
    color: #fff !important;
    box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, .48);
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 1px solid #f48181;
    border-radius: 2px;
    background-image: linear-gradient(#195e83,#042f66);
    color: #fff !important;
    box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, .48);
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: inherit !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.05);
    background: linear-gradient(to bottom, rgb(205 214 255) 0%, rgb(248 210 210) 100%);
  }
  .dataTables_wrapper .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    color: inherit;
    padding: 4px;
    position: relative;
    min-width: 53px;
    margin-bottom: 12px;
}
</style>
<main class="container" id="section-to-print">
  <!-- invoice Description -->
  <div class="card-body border-top pt-20 mt-0">
    <div class="row">
      <div class="col-sm-12">
        <div class="invoice-details">
          <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong></h5>
          <div class="invoice-details-inner mt-2">
            <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
              <br /> INDIA
            </p>
            <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
            <p><strong>Email:</strong> mail@youngfashionindia.com</p>
          </div>
        </div>
        <hr style="border-bottom: 1px solid #67676a;" />
      </div>
      <div class="col-sm-12">
        <h3 class="mb-10 text-18 text-capitalize"  style="text-align: center;"><strong>List Of Buyers</strong></h3>
      </div>
    </div>
  </div>
  <!-- invoice Table -->
  <div class="table-responsive invoice-table mb-4 mt-0">
    <div class="dt-buttons"><button (click)="printPage()" class="dt-button buttons-print" style="background: blueviolet;" type="button"><span><i style="font-size:24px;color:#ffffff" class="fa fa fa-print fa-2x"></i></span></button> </div>
    <table class="table table-striped table-bordered table-sm row-border hover" id="table_companies">
      <thead>
        <tr>
          <th>Buyer Code</th>
          <th>Name</th>
          <th>Address</th>
          <th>Phone</th>
          <th>Fax</th>
          <th>Telex</th>
          <th>Bank Code</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    
  </div>
</main>