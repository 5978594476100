<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Courler Agent</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img src="./assets/images/Add.png" style="width: 40px;"/></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png" style="width: 40px;"/></span></a>
                </li>
            </ul>
        </section>
        <aside style="width: 350px;">
            <ul class="top" style="margin-top: 0px; height: 700px; background-color: white;text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                <div id="lazy" class="demo"></div>
            </ul>
        </aside>
        <!-- <aside style="width: 350px;">
            <ul class="top"
                style="margin-top: 0px; height: 700px; text-align: justify; overflow: scroll; white-space: nowrap;">
                <li><a href="javascript:void(0);">Courier Agent</a>
                    <ul *ngFor="let item of this.tree_card_bank">
                        <div style="padding: 8px;"><a href="javascript:void(0);"
                                (click)="TreeListBinding(item.Courier_Code)">{{item.Courier_Name}}</a></div>
                    </ul>
                </li>
            </ul>
        </aside> -->
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form name="myForm" #myForm="ngForm">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                        Courier Agent</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Code</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" type="text" name="v1_normal_input" id="v1_normal_input"
                                        #bank_Code />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Name</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #bank_Name />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Address</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Add1 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong></strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Add2 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong></strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text"  #Add3 />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Phone</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #Phone />
                                </div>
                                <div class="grid_1">
                                    <label for="v1_normal_input"><strong>Fax</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" type="text" #Fax />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Email</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="email" #Email />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Contact Person</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #contact_Person />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit"
                                    (click)="addBankMaster(bank_Code.value, bank_Name.value,Add1.value,Add2.value,Add3.value,Phone.value,Fax.value,Email.value,contact_Person.value)"
                                    value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </section>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="addIn" style="padding-left: 0;">
            <div class="grid_12">
                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                    Courier Agent</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <div class="content" style="padding: 15px;">
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Code</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input #editBank_Code class="required" type="text" value="{{this.tree_edit_list.Courier_Code || '' }}" disabled />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Name</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input #editBank_Name class="required" type="text" value="{{this.tree_edit_list.Courier_Name || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Address</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd1 value="{{this.tree_edit_list.Add1 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Address</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd2 value="{{this.tree_edit_list.Add2 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Address</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #editAdd3 value="{{this.tree_edit_list.Add2 || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Phone</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input class="required" maxlength="10" pattern="^\d{1,10}$" type="text" #editPhone value="{{this.tree_edit_list.Phone || ''}}" />
                            </div>
                            <div class="grid_1">
                                <label for="v1_normal_input"><strong>Fax</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input class="required" type="text" #editFax value="{{this.tree_edit_list.Fax || ''}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Email</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="email" #editEmail value="{{this.tree_edit_list.Email || ''}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Contact Person</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input class="required" type="text" #edit_contact_Person  value="{{this.tree_edit_list.Contact_Person || ''}}"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">
                        <div class="right" style="margin-left: 12px;">
                            <button (click)="editBankMaster(editBank_Code.value, editBank_Name.value,editAdd1.value,editAdd2.value,editAdd3.value,editPhone.value,editFax.value,editEmail.value, edit_contact_Person.value)" class="grey cancel"
                            style="height: 30px;margin-top: 7px;width: 100px;font-size: small;background-image: linear-gradient(#042f66,#195e83);color: white;">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>