<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
  .pagination {
    margin: -15px 0;
    display: flex;
    justify-content: center;

  }

  .ngx-pagination {
    margin-left: 0 !important;
    margin-bottom: 1rem !important;
    line-height: 20px !important;
    padding: 7px 15px 7px !important;
    font-size: 12px !important;
  }

  .ngx-pagination::before,
  .ngx-pagination::after {
    content: " " !important;
    display: table !important;
  }

  .ngx-pagination::after {
    clear: both !important;
  }

  .ngx-pagination li {
    margin-right: 0.2rem !important;
    border-radius: 0 !important;
  }

  .ngx-pagination li {
    display: inline-block !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    color: #0a0a0a !important;
    display: block !important;
    background-color: rgb(248, 248, 248) !important;
    color: black !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: #788b8d88 !important;
  }

  .ngx-pagination .current {
    padding: 0.1875rem 0.625rem !important;
    background: #2199e8 !important;
    color: #fefefe !important;
    cursor: default !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination .disabled {
    padding: 0.1875rem 0.625rem !important;
    color: #cacaca00 !important;
    cursor: default !important;
  }

  .ngx-pagination .disabled:hover {
    background: transparent !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    cursor: pointer !important;
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    font-family: FontAwesome;
    content: "\f053" !important;
    display: inline-block !important;
    margin-right: 0rem !important;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    font-family: FontAwesome !important;
    content: "\f054" !important;
    display: inline-block !important;
    margin-left: 0rem !important;
  }

  .ngx-pagination .show-for-sr {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  .ngx-pagination .small-screen {
    display: none !important;
  }

  @media screen and (max-width: 601px) {
    .ngx-pagination.responsive .small-screen {
      display: inline-block !important;
      color: black !important;
      background-color: rgb(248, 248, 248) !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
    }

    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
      display: none !important;
    }
  }

  .table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-left: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  tr td {
    border-color: #dddddd00 !important;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    font-size: larger;
    margin: 0px 0px 5px 0px;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }
</style>
<main class="container">
  <!-- invoice Description -->
  <div class="card-body pt-20 mt-0">
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive invoice-table mb-4 mt-0">
          <div class="container" style="border: 1px solid #e5e5e5;">
            <div class="title"
              style=" margin-top: -58px; font-size: x-large; position: absolute;background-color: aliceblue; padding-left: 10px; width: 350px; ">
              Single Order Enquiry Report</div>
            <div class="content">
              <form action="#">
                <div class="user-details">
                  <div class="row">
                    <div class="col-sm-7">
                      <div class="input-box" style="display: flex;margin: 0px;">
                        <span class="details" style="width: 40%;margin: 8px;font-size: initial;">Order No.
                          Contains</span>
                        <mat-form-field class="width" appearance="outline">
                          <mat-label>Order No. Contains</mat-label>
                          <input matInput placeholder="Country" aria-label="Country" [matAutocomplete]="auto"
                            [formControl]="OrderNoContainsCtrl" required [(ngModel)]="search_text_order_no"
                            #OrderNoContains />
                          <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="onSearchIOrderNoSeletion($event.option.value)">
                            <mat-option *ngFor="let country of filteredCountry | async" [value]="country.name">
                              <span>{{ country.name }}</span>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="input-box" style="display: flex;margin: 0px;">
                        <span class="details" style="width: 30%;margin: 8px;font-size: initial;">Shipment No.</span>
                        <!-- <input type="text" #OrderNoContains placeholder="Order No. Contains" required
                          style=" width: 80%; " /> -->
                        <mat-form-field class="width" appearance="outline">
                          <mat-label>Shipment No.</mat-label>
                          <input matInput placeholder="Country" aria-label="Country" [matAutocomplete]="shipmentNo"
                            [formControl]="reportTypeShipmentNo" #ShipmentNoOrder />
                          <mat-autocomplete #shipmentNo="matAutocomplete">
                            <mat-option *ngFor="let country of filteredShipmentNoReportTypes | async"
                              [value]="country.name">
                              <span>{{ country.name }}</span>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-box" style="display: flex;margin: 0px;">
                        <span class="details" style="width: 16.5%;margin: 8px;font-size: initial;">Articles</span>

                        <mat-form-field appearance="outline" style="width: 84.5%;">
                          <mat-label>Articles</mat-label>
                          <div style="overflow: auto;height: 160px;">
                            <textarea matInput name="questionText" placeholder="Articles"
                              style="height: 0px;display: none;"> </textarea>
                            <div *ngFor="let country of checkboxes">
                              <label>
                                <input type="checkbox" [(ngModel)]="country.isChecked" name="{{ country.name }}">
                                <span style="position: relative; font-weight: 500; bottom: 3px; left: 5px;">{{
                                  country.name
                                  }}</span>
                              </label>
                            </div>
                          </div>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-box" style="display: flex;margin: 0px;">
                        <span class="details" style="width: 16.5%;margin: 8px;font-size: initial;">Buyer</span>
                        <mat-form-field appearance="outline" style="width: 84.5%;">
                          <mat-label>Buyer</mat-label>
                          <input matInput placeholder="Buyer" [value]="BuyerOrder" #Buyer>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-box" style="display: flex;margin: 0px;">
                        <span class="details" style="width: 16.5%;margin: 8px;font-size: initial;">Supplier</span>
                        <mat-form-field appearance="outline" style="width: 84.5%;">
                          <mat-label>Supplier</mat-label>
                          <input matInput placeholder="Supplier" [value]="SuplierOrder" #Suplier>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-box" style="display: flex;margin: 0px;">
                        <span class="details" style="width: 20%;margin: 8px;font-size: initial;">Report Type</span>
                        <!-- <input type="text" #OrderNoContains placeholder="Order No. Contains" required
                      style=" width: 80%; " /> -->
                        <mat-form-field class="width" appearance="outline">
                          <input matInput placeholder="Report Type" aria-label="ReportType"
                            [matAutocomplete]="reportType" [formControl]="reportTypeCtrl" [(ngModel)]="search_text"
                            #ReportTypeOrder />
                          <mat-autocomplete #reportType="matAutocomplete"
                            (optionSelected)="onSearchItemSeletion($event.option.value)">
                            <mat-option *ngFor="let country of filteredReportTypes | async" [value]="country.name">
                              <span>{{ country.name }}</span>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-box" style="display: flex;float: right;">
                        <button mat-raised-button color="primary"
                          style=" padding: 3px 20px 3px 20px; margin: 0px 15px 0px 0px; font-size: medium; color: white; ">Clear</button>
                        <button mat-raised-button
                          (click)="onSubmit(OrderNoContains.value,ShipmentNoOrder.value,Buyer.value,Suplier.value,ReportTypeOrder.value)"
                          color="primary" type="submit"
                          style=" padding: 3px 20px 3px 20px; margin: 0px 15px 0px 0px; font-size: medium; color: white; ">
                          Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>