import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-order-spec-sheet-file-upload',
  templateUrl: './order-spec-sheet-file-upload.component.html',
  styleUrls: ['./order-spec-sheet-file-upload.component.css']
})
export class OrderSpecSheetFileUploadComponent implements OnInit {
  order_id: any;
  order_code: any;
  @ViewChild('resumeInput', { static: true }) resumeInput: ElementRef;
  selectedFile: File | any = null;
  imageUrl: any;
  fileToUpload: File | any = null;
  saveFileForm: any;
  lstFileDetails: any;

  private _orderNo: string;

  @Input()
  set OrderNo(value: any) {
    console.log(value)
    if (Array.isArray(value)) {
      this.order_id = value[1];
      this.order_code = value[0];
      this.updateModalContent();
    } else {
      this._orderNo = value[0];
      this.updateModalContent();
    }
  }

  get OrderNo(): string {
    return this._orderNo;
  }

  constructor(
    private webService: WebServiceService,
    private formBuilder: FormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['OrderNo'] && !changes['OrderNo'].firstChange) {
      this.saveFileForm.patchValue({
        OrderNo: this.order_code
      });
      this.updateModalContent();
    }
  }

  private updateModalContent() {
    this.order_id = this.order_id;
  }

  clearFileInput() {
    // Reset the value of the file input
    this.resumeInput.nativeElement.value = '';
  }

  isFieldValid(field: string) {
    return !this.saveFileForm.get(field).valid && this.saveFileForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  ngOnInit(): void {
    this.saveFileForm = this.formBuilder.group({
      OrderNo: ['', [Validators.required]],
      Item: ['', [Validators.required]],
      SpecSheet: ['', [Validators.required]]
    });
  }

  closePopupStyle(id) {
    document.body.style.overflow = 'auto';
    $('#showPopupOrderSpecSheet').css('display', 'none');
    $('#showPopupOrderSpecSheet').modal('hide');
    const existingModal = $('#showPopupOrderSpecSheet').data('bs.modal');
    if (existingModal) {
      existingModal.dispose(); // Destroy the modal
      $('#showPopupOrderSpecSheet').removeData('bs.modal'); // Remove modal data
    }

  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onExpSubmit() {
    debugger
    if (this.saveFileForm.invalid) {
      this.validateAllFormFields(this.saveFileForm);
      return;
    } else {
      let formData = new FormData();
      formData.append('DocFile', this.resumeInput.nativeElement.files[0]);
      formData.append('OrderNo', this.saveFileForm.value.OrderNo);
      formData.append('Item', this.saveFileForm.value.Item);
      formData.append('SpecSheet', this.saveFileForm.value.SpecSheet);
      formData.append('order_id', this.order_id);
      this.webService.AddFileDetails1(formData).subscribe((result: any) => {
        console.log(result);
        this.closePopupStyle('showPopupOrderSpecSheet');
        if (result.status === 'OK') {
          this.clearFileInput();
          this.saveFileForm.patchValue({
            Item: ""
          });
          this.saveFileForm.patchValue({
            SpecSheet: ""
          });
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">File uploaded successfully.</p>',
            showConfirmButton: false,
            timer: 2500
          });

        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      });
    }
  }
}
