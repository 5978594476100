import { Component, OnInit } from '@angular/core'; 
import { WebServiceService } from '../../services/web-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import Swal from "sweetalert2";
declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [[WebServiceService]]
})
export class LoginComponent implements OnInit {
  loggedIn: boolean = false;
  alreadyLoggedIn: boolean = false;
  constructor( 
    private webService: WebServiceService,
    private router: Router,
  ) { 
    if (this.webService.isAuthenticated()) {
      this.router.navigateByUrl('/suppler');
    } 
  }

  errors = [];
  userMobile: any = "";
  password: any = "";
  userID: any = "";

  ngOnInit() {
     
  }

  loginUser(mobileNo, password) {
    this.webService.loginUser(mobileNo, password).subscribe((response) => {
      if (response.status === "OK") {
        //this.tostr.success(response.message,"ML Infomap !!");
        //  this.router.navigate(['/suppler']);
        window.location.replace("/suppler");
        // Swal.fire('ML Infomap!', response.message, 'info');
      }
      else {
        Swal.fire({
          icon: 'info',
          iconColor: '#727252',
          background: '#FFFDDE',
          html: '<p style="font-weight: 500;color: black;">UserName and Password is not wrong. Please try agian</p>',
          showConfirmButton: false,
          timer: 2500
        });
      }
    });
  }

  logoutUser(mobileNo, password) {
    this.errors = [];
    if (mobileNo === 'admin' && password === 'admin') {
      this.router.navigate(['/suppler']);
    } else {
      this.errors.push("UserName and Password is not wrong. Please try agian");
    }
     
  }
}
