<div role="main" id="main" class="container_12 clearfix" style="width: 100%; margin-left: 0; margin-right: 0;">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large; color: black;">Copy Order</div>
        </div>
        <section id="content" data-sort="true" class="container_12 clearfix" style="padding-left: 0; background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="container">
                <form [formGroup]="uploadForm" (ngSubmit)="onExpSubmit()" enctype="multipart/form-data" style="margin: 85px 400px 185px 400px;">  
                    <div class="row">
                        <div class="col-md-12" style="padding-left: 0;"><b>Old Order</b></div>
                        <div class="col-md-12" style="padding-left: 0;"><input type="text" formControlName="oldOrder" placeholder="Old Order" class="form-control ng-untouched ng-pristine ng-invalid" style="margin: 4px 4px 4px 6px;"></div>
                        <app-field-error-display 
                        [displayError]="isFieldValid('oldOrder')"
                        errormsg="Please  enter old Order">
                        </app-field-error-display>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="padding-left: 0;"><b>New Order</b></div>
                        <div class="col-md-12" style="padding-left: 0;"><input type="text" formControlName="newoldOrder" placeholder="New Order" class="form-control ng-untouched ng-pristine ng-invalid" style="margin: 4px 4px 4px 6px;"></div>
                        <app-field-error-display 
                        [displayError]="isFieldValid('newoldOrder')"
                        errormsg="Please  enter New Order">
                        </app-field-error-display>
                       
                    </div>
                    <div align="center" class="row" style="padding: 15px; width: 100px; float: right; position: relative;">
                        <div class="col-md-12" style="padding-left: 0;"><button type="submit" color="primary" class="btn btn-primary">Save </button></div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</div>