<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Merchandise Mliestone Type</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img src="./assets/images/Add.png" style="width: 40px;"/></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png" style="width: 40px;"/></span></a>
                </li>
            </ul>
        </section>
        <aside style="width: 350px;">
            <ul class="top"
                style="margin-top: 0px; height: 300px; text-align: justify; overflow: scroll; white-space: nowrap;">
                <li><a href="javascript:void(0);" style=" font-size: large; font-weight: 600;" >Merchandise Mliestone Type</a>
                    <ul *ngFor="let item of this.tree_card_bank">
                        <div style="padding: 8px;"><a href="javascript:void(0);"
                                (click)="TreeListBinding(item.Accessory_Category_Code)">{{item.Name}}</a></div>
                    </ul>
                </li>
            </ul>
        </aside>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form name="myForm" #myForm="ngForm">
                <div class="grid_12" style="margin-top: -40px;">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 42px;left: 15px;background: white;width: 261px;z-index: 999;position: relative;">
                        Merchandise Mliestone Type</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Code</strong> </label>
                                </div>
                                <div class="grid_2">
                                    <input class="required" type="text" name="v1_normal_input" id="v1_normal_input"
                                        #Code />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Name</strong> </label>
                                </div>
                                <div class="grid_8">
                                    <input class="required" type="text" #Name />
                                </div>
                            </div>
                            <div class="row">
                                <div class="grid_2">
                                    <label for="v1_normal_input"><strong>Type</strong> </label>
                                </div>
                                <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; width: 100px;">
                                    <input class="required" maxlength="10" type="radio" #Product  name="Item2" value="M" [checked]="isChecked" style="width: 16px;"/>
                                    <span style="padding: 11px 8px 8px 8px;"> Product</span>
                                </div>
    
                                <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; ">
                                    <input class="required" type="radio" #Process value="F" name="Item2" [checked]="isChecked" style="width: 16px;" />
                                    <span style="padding: 11px 8px 8px 8px;">Process</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit"
                                    (click)="add(Code.value, Name.value)"
                                    value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </section>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="addIn" style="padding-left: 0;">
            <div class="grid_12" style="margin-top: -40px;">
                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 42px;left: 15px;background: white;width: 261px;z-index: 999;position: relative;">
                    Merchandise Mliestone Type</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <div class="content" style="padding: 15px;">
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Code</strong> </label>
                            </div>
                            <div class="grid_2">
                                <input #edit_Code class="required" type="text" value="{{this.tree_edit_list.Accessory_Category_Code || '' }}" disabled />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Name</strong> </label>
                            </div>
                            <div class="grid_8">
                                <input #edit_Name class="required" type="text" value="{{this.tree_edit_list.Name || '' }}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="grid_2">
                                <label for="v1_normal_input"><strong>Type</strong> </label>
                            </div>
                            <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; width: 100px;">
                                <input class="required" maxlength="10" type="radio" #editProduct  name="Item2" value="PD" [checked]="isChecked" style="width: 16px;"/>
                                <span style="padding: 11px 8px 8px 8px;"> Product</span>
                            </div>

                            <div class="grid_3" style=" display: flex; padding: 0px 0 0px 5px; ">
                                <input class="required" type="radio" #editProcess value="PC" name="Item2" [checked]="isChecked" style="width: 16px;" />
                                <span style="padding: 11px 8px 8px 8px;">Process</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">
                        <div class="right" style="margin-left: 12px;">
                            <button (click)="edit(edit_Code.value, edit_Name.value)" class="grey cancel"
                            style="height: 30px;margin-top: 7px;width: 100px;font-size: small;background-image: linear-gradient(#042f66,#195e83);color: white;">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>