import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, Pipe, PipeTransform, VERSION } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { WebServiceService } from 'src/app/services/web-service.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

declare let $: any;
import Swal from 'sweetalert2';

Pipe({
  name: 'dateFormat'
})

interface Order {
  Order_Id: number;
  Order_Code: string;
  // Other properties...
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']

})
export class OrderDetailsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  progres: number;
  message: string;
  transform(value: string): string {
    const [month, day, year] = value.split('/');
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return formattedDate;
  }

  @Output() public onUploadFinished = new EventEmitter();
  isActive: boolean = true; // Set this to true or false based on your logic
  colorCode: string = 'black'; // Active color
  inactiveColorCode: string = 'white'; // Inactive color
  colorbgCodelatest: string = 'lightblue'; // Background color for active items
  get_Invoice_price: any;
  get_totalPrice: any;
  get_ITEM_PRICE: any;
  ItemMasterList: any = [];
  itemListHistory: any = [];
  report_list: any = [];
  qtyOrder: any;
  PriceOrder: any;
  InvPriceOrder: any;
  TotalPriceOrder: any;

  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  iconColor: any
  borderColor: any;
  dragging: boolean = false;
  codeorder_id: any;
  strShipmentNoColor: any;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';

  activeColor1: string = 'green';
  baseColor1: string = '#ccc';
  overlayColor1: string = 'rgba(255,255,255,0.5)';
  iconColor1: any
  borderColor1: any;
  dragging1: boolean = false;
  codeorder_id1: any;
  strShipmentNoColor1: any;
  loaded1: boolean = false;
  imageLoaded1: boolean = false;
  imageSrc1: string = '';
  PrintGrenteShipment: any;
  total_final_price_shipment: any;
  CountUserSip: any;
  show = false;
  fullScreen = true;
  template = '';
  searchText;
  searchItemMasterList;
  order_code_list: any = [];
  area_carddata_list: any = [];
  tree_Items_Details: any = [];
  order_id_number: any = '';
  order_code_number: any;
  name = "Angular " + VERSION.major;
  page: number = 1;
  fileUpload: any;
  fileUploadDoc: any;
  tree_card_Buyer_item_prize: any = [];
  tree_card_Buyer: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_Order_Details: any = [];
  tree_edit_list_print: any;
  tree_Contact_ContactItemList: any = [];
  tree_Contact_ContactItemSizeList: any = [];
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isCheckedCompleted: boolean = false;
  isCheckedCancelled: boolean = false;
  isChecked: boolean = false;
  shipment_serial_id: any;
  buyerCode: any;
  selectedUser = null;
  selectedSize = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  isCheckDataBase: boolean = false;
  isCheckDataBase1: boolean = false;
  SizeMasterList: any = [];
  ItemCategoryList: any = [];
  selectedMode: any;
  progress: number = 0;
  RolltexName: number = 0;
  YFICommName: number = 0;
  totalValueOrder: number = 0;
  private _onDestroy = new Subject<void>();
  selectedOrderNo: any = null;
  selectedBuyer: any = null;
  tree_card_Suppler: any = [];
  selectedSuppler: any = null;
  tree_card_OrderDropdown: any = [];
  selectedOrderDropdown: any = null;
  tree_ContactBuyer: any = [];
  selectedContactBuyer: any = null;
  tree_ContactSuppler: any = [];
  selectedContactSuppler: any = null;
  selectedBank_Name: any = null;
  selectedCurrency: any = null;
  SizeCurrencyList: any = [];
  tree_ContactBuyerConsignee: any = [];
  proFillModeList: any = [];
  shipment_serialList: any = [];
  proShowRecordsList: any = [];
  proShowOrderDetailsList: any = [];
  proShowRecordsListGS_Gnrated:any = [];
  selectedContactBuyerConsignee: any = null;
  ShipmentTotal: number = 0;
  TotalShipQty: number = 0;
  ConversionCurrencyValue: any;
  isReadonly = false;
  proShowRecordsListGS: any = []
  originalValuesGS: any[] = [];
  proShowOrderDetailsList_print: any[] = [];
  isReadItemCode = false;
  articalName: '';
  order_Code_Sipment: any = "all_order";
  shipment_status: any = "In-Progress";
  sip_mode_shipiment: any = "";
  buyer_shipment: any = "";
  supplier_shipment: any = "";
  selectedOption: any;
  ArticleCodeColor: any;
  order_id_no_file_upload: string; // or the appropriate type
  formattedDate: any;
  imageUrl: string;
  placeholderImageUrl: string = './assets/img/image_placeholder.png';

  uploadForm = new FormGroup({
    pageUpdateInsert: new FormControl('', Validators.required),
    OrderNo: new FormControl('', Validators.required),
    Order_id: new FormControl('', Validators.required),
    buyer: new FormControl('', Validators.required),
    bContactPerson: new FormControl('', Validators.required),
    Department: new FormControl(''),
    Supplier: new FormControl('', Validators.required),
    sContactPerson: new FormControl('', Validators.required),
    Bank: new FormControl('', Validators.required),
    Consignee: new FormControl(''),
    Currency: new FormControl('', Validators.required),
    ConvRate: new FormControl('', Validators.required),
    PJNo: new FormControl(''),
    LCNo: new FormControl(''),
    LCDate: new FormControl(null),
    DelDateOnLC: new FormControl(null),
    LCSentOn: new FormControl(null),
    Category: new FormControl(''),
    OrderDate: new FormControl('', Validators.required),
    OrderCompleted: new FormControl(null),
    DispatchDate: new FormControl(null),
    OrderCancelled: new FormControl(null),
    TotalQuentity: new FormControl(''),
    OrderComm: new FormControl('', Validators.required),
    RolltexComm: new FormControl('', Validators.required),
    YFIComm: new FormControl('', Validators.required),
    TotalOrderValue: new FormControl('0'),
    SizeRange: new FormControl(''),
    Status: new FormControl(''),
    oDate: new FormControl(''),
    Remarks: new FormControl(''),
    PAYMENT_TREMS: new FormControl(''),
    Packing: new FormControl(''),
  });

  item1 = {
    ITEM_QTY: 0, // You can initialize this with the desired value
    EXPORT_DATE: new Date()
  };

  isImageEmpty(): boolean {
    return !this.imageUrl || this.imageUrl.trim() === '';
  }
  handleImageError(): void {
    //console.log('Image not found or unable to load.');
    // You can perform actions when the image is not found or unable to load
  }
  tableHeaders = ['Color', 'Composition', 'Lab Dip/Yarn Bit', 'Sent Date'];

  tableRowsWithId: any[][] = [
    ['12', 'WHITE ( 5100 SETS)', '100% COTTON', 'SENT', '2008-12-08'],
  ];

  dataType = ['string', 'string', 'string', 'string', 'string'];

  imageObject = [{
    image: this.placeholderImageUrl,
    thumbImage: this.placeholderImageUrl
  }];

  constructor(
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private _fb: FormBuilder,
    private httpClient: HttpClient,
    private readonly changeDetectorRef: ChangeDetectorRef,

  ) {
    this.formattedDate = this.datePipe.transform(this.item1.EXPORT_DATE, 'dd.MM.yy');
    if (sessionStorage.getItem('order_code')) {
      this.editOrderListSearch(sessionStorage.getItem('order_code'));
    }
    else {
      sessionStorage.removeItem('order_code');
    }
    
  }

  imageClickHandler(e) {
    //console.log('image click', e);
  }
  
  handleDragEnter() {
    //console.log('handleDragEnter');
    this.dragging = true;
  }

  handleDragLeave() {
    //console.log('handleDragLeave');
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
    this.iconColor = this.overlayColor;
  }

  handleInputChange(e) {
    //console.log('input change');
    //const file = (e.target as HTMLInputElement).files[0];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();

    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  handleInputChangedata(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.fileUpload = e.target.files;
    var reader = new FileReader();
    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    //console.log('_handleReaderLoaded');
    this.isCheckDataBase = true;
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }

  _setActive() {
    this.borderColor = this.activeColor;
    if (this.imageSrc.length === 0) {
      this.iconColor = this.activeColor;
    }
  }

  _setInactive() {
    this.borderColor = this.baseColor;
    if (this.imageSrc.length === 0) {
      this.iconColor = this.baseColor;
    }
  }


  uploadFile = (OrderNo, Item, SpecSheet) => {
    if (this.fileUpload.length === 0) {
      return;
    }

    let fileToUpload = <File>this.fileUpload[0];

    const formData = new FormData();
    formData.append('Item_Code', OrderNo);
    formData.append('Article_Code', Item);
    formData.append('SpecSheet', SpecSheet);
    formData.append('file', fileToUpload, fileToUpload.name);

    this.httpClient.post('https://localhost:5001/api/upload', formData, { reportProgress: true, observe: 'events' })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            this.message = 'Upload success.';
            this.onUploadFinished.emit(event.body);
          }
        },
        error: (err: HttpErrorResponse) => console.log(err)
      });
  }

  handleDragEnter1() {
    //console.log('handleDragEnter');
    this.dragging1 = true;
  }

  handleDragLeave1() {
    //console.log('handleDragLeave');
    this.dragging1 = false;
  }

  handleDrop1(e) {
    e.preventDefault();
    this.dragging1 = false;
    this.handleInputChange1(e);
  }

  handleImageLoad1() {
    this.imageLoaded1 = true;
    this.iconColor1 = this.overlayColor1;
  }

  handleInputChange1(e) {
    //console.log('input change');
    //const file = (e.target as HTMLInputElement).files[0];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var reader = new FileReader();

    this.loaded1 = false;

    reader.onload = this._handleReaderLoaded1.bind(this);
    reader.readAsDataURL(file);
  }

  handleInputChangedata1(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.fileUploadDoc = e.target.files;
    var reader = new FileReader();
    this.loaded1 = false;
    reader.onload = this._handleReaderLoaded1.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded1(e) {
    //console.log('_handleReaderLoaded');
    this.isCheckDataBase1 = true;
    var reader = e.target;
    this.imageSrc1 = reader.result;
    this.loaded1 = true;
  }

  _setActive1() {
    this.borderColor1 = this.activeColor1;
    if (this.imageSrc1.length === 0) {
      this.iconColor1 = this.activeColor1;
    }
  }

  _setInactive1() {
    this.borderColor1 = this.baseColor1;
    if (this.imageSrc1.length === 0) {
      this.iconColor1 = this.baseColor1;
    }
  }

  // changedRolltexOrderNo = (value) => {
  //   this.order_Code_Sipment = value.target.value
  //   sessionStorage.removeItem('order_code');
  //   this.editOrderListSearch(value.target.value);
  // }

  changedRolltexOrderNo = (value) => {
    this.order_Code_Sipment = value;
    this.closePopup('singleModel');
    sessionStorage.removeItem('order_code');
    this.editOrderListSearch(value);
  }

  editOrderListSearch = (code) => {
   
    try {
      let data = {
        order_Code: code
      }
      this.order_code_number = code.toString();
      //this.order_id_number = id.toString();
      this.tree_Order_Details = [];
      let url = "Order/SingleSearch";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            if (data12[0].length > 0) {

              this.tree_Order_Details = data12[0][0];
              // this.isReadonly = true;
              // this.uploadForm.patchValue({
              //   OrderNo: code
              // });

              let urldata = "Order/Edit";
              this.singleSliderOrder(this.tree_Order_Details.Order_Code, this.tree_Order_Details.Order_Id);
              this.TreeContactBuyerEdit(this.tree_Order_Details.Buyer_Code);
              this.TreeContactSupplerEdit(this.tree_Order_Details.Supplier_Code);
              this.TreeContactItemList(this.tree_Order_Details.Order_Code, this.tree_Order_Details.Order_Id);
              this.TreeContactItemSizeList(this.tree_Order_Details.Order_Code, this.tree_Order_Details.Order_Id);
              if (this.tree_Order_Details.Order_Cancelled === 'Y') {
                this.isCheckedCancelled = true;
              }
              else {
                this.isCheckedCancelled = false;
              }
              if (this.tree_Order_Details.Order_Completed === 'Y') {
                this.isCheckedCompleted = true;
              }
              else {
                this.isCheckedCompleted = false;
              }
              this.order_Code_Sipment = this.tree_Order_Details.Order_Code.toString();
              this.order_id_number = this.tree_Order_Details.Order_Id.toString();
              this.order_code_list = data;
              let totalvalue = "0";

              if (this.tree_Order_Details.totalValue !== null) {
                totalvalue = this.tree_Order_Details.totalValue;
              }
              this.ConversionCurrencyValue = this.tree_Order_Details.Conversion_Rate
              let Inspection_Date = this.datePipe.transform(this.tree_Order_Details.Inspection_Date, 'yyyy-MM-dd');
              if (Inspection_Date == '1900-01-01') {
                Inspection_Date = null;
              }
              else {
                Inspection_Date = this.datePipe.transform(this.tree_Order_Details.Inspection_Date, 'yyyy-MM-dd');
              }
              let LC_NR = this.datePipe.transform(this.tree_Order_Details.LC_NR, 'yyyy-MM-dd');
              if (LC_NR == '1900-01-01') {
                LC_NR = null;
              }
              else {
                LC_NR = this.datePipe.transform(this.tree_Order_Details.LC_NR, 'yyyy-MM-dd');
              }
              let Delivery_Date_On_LC = this.datePipe.transform(this.tree_Order_Details.Delivery_Date_On_LC, 'yyyy-MM-dd');
              if (Delivery_Date_On_LC == '1900-01-01') {
                Delivery_Date_On_LC = null;
              }
              else {
                Delivery_Date_On_LC = this.datePipe.transform(this.tree_Order_Details.Delivery_Date_On_LC, 'yyyy-MM-dd');
              }
              let LC_Sent_On = this.datePipe.transform(this.tree_Order_Details.LC_Sent_On, 'yyyy-MM-dd');
              if (LC_Sent_On == '1900-01-01') {
                LC_Sent_On = null;
              }
              else {
                LC_Sent_On = this.datePipe.transform(this.tree_Order_Details.LC_Sent_On, 'yyyy-MM-dd');
              }
              let Order_Date = this.datePipe.transform(this.tree_Order_Details.Order_Date, 'yyyy-MM-dd');
              if (Order_Date == '1900-01-01') {
                Order_Date = null;
              }
              else {
                Order_Date = this.datePipe.transform(this.tree_Order_Details.Order_Date, 'yyyy-MM-dd');
              }
              let Dispatch_Date = this.datePipe.transform(this.tree_Order_Details.Dispatch_Date, 'yyyy-MM-dd');
              if (Dispatch_Date == '1900-01-01') {
                Dispatch_Date = null;
              }
              else {
                Dispatch_Date = this.datePipe.transform(this.tree_Order_Details.Dispatch_Date, 'yyyy-MM-dd');
              }
              this.uploadForm.setValue(
                {
                  pageUpdateInsert: urldata,
                  OrderNo: this.tree_Order_Details.Order_Code,
                  Order_id: this.tree_Order_Details.Order_Id.toString(),
                  buyer: this.tree_Order_Details.Buyer_Code,
                  bContactPerson: this.tree_Order_Details.Buyer_Contact_Code,
                  Department: this.tree_Order_Details.Department,
                  Supplier: this.tree_Order_Details.Supplier_Code,
                  sContactPerson: this.tree_Order_Details.Supplier_Contact_Code,
                  Bank: this.tree_Order_Details.Bank_Code,
                  Consignee: this.tree_Order_Details.Consignee_Code,
                  Currency: this.tree_Order_Details.Currency_Code,
                  ConvRate: this.tree_Order_Details.Conversion_Rate.toString(),
                  PJNo: this.tree_Order_Details.PJ_No,
                  LCNo: this.tree_Order_Details.LC_No,
                  LCDate: LC_NR,
                  DelDateOnLC: Delivery_Date_On_LC,
                  LCSentOn: LC_Sent_On,
                  Category: this.tree_Order_Details.Order_Category,
                  OrderDate: Order_Date,
                  OrderCompleted: this.isCheckedCompleted,
                  DispatchDate: Dispatch_Date,
                  OrderCancelled: this.isCheckedCancelled,
                  TotalQuentity: this.tree_Order_Details.Total_Quantity.toString(),
                  OrderComm: this.tree_Order_Details.Total_Order_Commission.toString(),
                  RolltexComm: this.tree_Order_Details.Commission_percent.toString(),
                  YFIComm: this.tree_Order_Details.Debit_percent,
                  TotalOrderValue: '0',
                  SizeRange: this.tree_Order_Details.Size_Range,
                  Status: this.tree_Order_Details.Inspection_Status,
                  oDate: Inspection_Date,
                  Remarks: this.tree_Order_Details.Remarks,
                  PAYMENT_TREMS: this.tree_Order_Details.PAYMENT_TREMS,
                  Packing: this.tree_Order_Details.Packing,
                });

              let dataData = {
                Order_Code: this.tree_Order_Details.Order_Code.toString(),
                Order_id: this.tree_Order_Details.Order_Id.toString()
              }
              this.totalValueOrder = 0;
              let url = "Order/ItemSizeList";
              this.webService.loadPost(url, dataData).subscribe(
                (data: any) => {
                  if (data.status === 'OK') {
                    let totalValue = 0;
                    let totalqty = 0;
                    let data12 = (new Function("return [" + data.editList + "]")());
                    if (data12[0].length > 0) {
                      data12[0].forEach(item => {
                        totalValue += item.ITEM_QTY * item.ITEM_PRICE;
                        totalqty += item.ITEM_QTY;
                      });
                      
                    }
                    else {
                      this.area_carddata_list = "";
                    }
                    this.uploadForm.patchValue({
                      TotalOrderValue: totalValue.toFixed(2).toString()
                    });
                    //console.log(totalValue);
                    //console.log(totalqty);
                  } else {

                  }

                }
              );
              this.uploadForm.get('ConvRate').updateValueAndValidity();
            }
            else {
              this.order_id_number = '';
              this.TreeContactBuyerEdit("");
              this.TreeContactSupplerEdit("");
              this.TreeContactItemList("", "");
              this.TreeContactItemSizeList("", "");
              //this.uploadForm.reset();
              let url = "Order/Add";
              this.order_id_number = '';
              this.uploadForm.patchValue({
                pageUpdateInsert: url
              });
              this.uploadForm.patchValue({
                buyer: null
              });
              this.uploadForm.patchValue({
                Order_id: "2"
              });
              this.uploadForm.patchValue({
                bContactPerson: null
              });
              this.uploadForm.patchValue({
                Department: ""
              });
              this.uploadForm.patchValue({
                Currency: null
              });
              this.uploadForm.patchValue({
                ConvRate: ""
              });
              this.uploadForm.patchValue({
                PJNo: ""
              });
              this.uploadForm.patchValue({
                LCNo: ""
              });
              this.uploadForm.patchValue({
                LCDate: null
              });
              this.uploadForm.patchValue({
                DelDateOnLC: null
              });
              this.uploadForm.patchValue({
                LCSentOn: null
              });
              this.uploadForm.patchValue({
                Category: ""
              });
              this.uploadForm.patchValue({
                OrderDate: null
              });
              this.uploadForm.patchValue({
                OrderCompleted: ""
              });
              this.uploadForm.patchValue({
                DispatchDate: null
              });
              this.uploadForm.patchValue({
                OrderCancelled: ""
              });
              this.uploadForm.patchValue({
                TotalQuentity: "0"
              });
              this.uploadForm.patchValue({
                OrderComm: ""
              });
              this.uploadForm.patchValue({
                RolltexComm: ""
              });
              this.uploadForm.patchValue({
                YFIComm: ""
              });
              this.uploadForm.patchValue({
                TotalOrderValue: "0"
              });
              this.uploadForm.patchValue({
                SizeRange: ""
              });
              this.uploadForm.patchValue({
                Status: ""
              });
              this.uploadForm.patchValue({
                oDate: null
              });
              this.uploadForm.patchValue({
                Remarks: ""
              });
              this.uploadForm.patchValue({
                PAYMENT_TREMS: ""
              });
              this.uploadForm.patchValue({
                Packing: ""
              });

            }

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }
  filteredOrders: any = [];

  applyFilter(data) {
    const searchText2 = data;
    //console.log(searchText2);
    this.filteredOrders = this.tree_card_OrderDropdown.filter(order =>
      order.Order_Code.includes(searchText2)
    );
  }

  changedRolltex = (value) => {
    this.RolltexName = value.target.value
    this.uploadForm.patchValue({
      OrderComm: this.YFICommName + "+" + this.RolltexName
    });
  }

  changedYFIComm = (value) => {
    this.YFICommName = value.target.value
    this.uploadForm.patchValue({
      OrderComm: this.YFICommName + "+" + this.RolltexName
    });
  }


  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }


  editDialog = (id, idata, data) => {
    $('#datamodalEdit' + idata).modal('show');
    this.loadTreeBuyer_Item_Prize();

  }

  addItemModel = (data) => {
    //this.order_code=

    this.show = true;
    if (data == '') {
      alert('Please fill and save the order. Add item will not open until you save the order.');
      this.show = false;
    } else {
      this.loadItemMasterList(data)
    }

  }



  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(field => {
      //console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  reset() {
    sessionStorage.removeItem('order_code');
    this.reloadCurrentRoute();
    this.uploadForm.reset();
    let url = "Order/Add";
    this.uploadForm.patchValue({
      pageUpdateInsert: url
    });
    this.uploadForm.patchValue({
      Order_id: "2"
    });
  }

  options2 = {
    allowDrag: node => {
      return node.data.isDraggable;
    },
    allowDrop: (element, { parent, index }) => {
      // return true / false based on element, to.parent, to.index. e.g.
      ////console.log(parent.children?.includes(element));
      return parent.children?.includes(element);
    },
    getNodeClone: (node) => ({
      ...node.data,
      id: 1111,
      name: `copy of ${node.data.name}`
    })
  };

  options = {};

  scroll(tree) {
    ////console.log(tree);
    tree.treeModel.getNodeById(6).focus();
    tree.treeModel.getNodeById(6).scrollIntoView();
  }

  ngOnInit(): void {
    this.loadTreeBuyer();
    this.loadBankMaster();
    this.loadSizeMaster();
    this.loadCurrency();
    this.loadTreeSuppler();
    let url = "Order/Add";
    this.uploadForm.patchValue({
      pageUpdateInsert: url
    });
    this.uploadForm.patchValue({
      Order_id: "2"
    });
  }

  showSnackbarCssStyles(content, action, duration) {

  }

  ngAfterViewInit(): void {
    sessionStorage.removeItem('order_code');
  }


  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadItemMasterList = (data) => {
    this.ItemMasterList = [];
    let url = "Order/ItemMasterList?myList=" + data;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());

          this.ItemMasterList = data12[0];
          // data12[0].forEach(element => {
          //   overLayCard.push({
          //     Article_Category_Code: element.Article_Category_Code,
          //     Article_Code: element.Article_Code,
          //     Article_Name: element.Article_Name,
          //     Composition: element.Composition,
          //     GSM: element.GSM,
          //     Item_Code: element.Item_Code,
          //     Item_Description: element.Item_Description,
          //     Item_Size: element.Item_Size,
          //     checked: false
          //   });
          // });
          this.show = false;
          // this.ItemMasterList = overLayCard;
          document.body.style.overflow = 'hidden';
          $('#modalEditItemView').css('display', 'flex');
        } else {
          this.TreeContactBuyerEdit("");
          this.TreeContactSupplerEdit("");
          this.TreeContactItemList("", "");
          this.TreeContactItemSizeList("", "");
          //this.uploadForm.reset();
          let url = "Order/Add";
          this.order_id_number = '';
          this.uploadForm.patchValue({
            pageUpdateInsert: url
          });
          this.uploadForm.patchValue({
            buyer: null
          });
          this.uploadForm.patchValue({
            Order_id: "2"
          });
          this.uploadForm.patchValue({
            bContactPerson: null
          });
          this.uploadForm.patchValue({
            Department: ""
          });
          this.uploadForm.patchValue({
            Currency: null
          });
          this.uploadForm.patchValue({
            ConvRate: ""
          });
          this.uploadForm.patchValue({
            PJNo: ""
          });
          this.uploadForm.patchValue({
            LCNo: ""
          });
          this.uploadForm.patchValue({
            LCDate: null
          });
          this.uploadForm.patchValue({
            DelDateOnLC: null
          });
          this.uploadForm.patchValue({
            LCSentOn: null
          });
          this.uploadForm.patchValue({
            Category: ""
          });
          this.uploadForm.patchValue({
            OrderDate: null
          });
          this.uploadForm.patchValue({
            OrderCompleted: ""
          });
          this.uploadForm.patchValue({
            DispatchDate: null
          });
          this.uploadForm.patchValue({
            OrderCancelled: ""
          });
          this.uploadForm.patchValue({
            TotalQuentity: "0"
          });
          this.uploadForm.patchValue({
            OrderComm: ""
          });
          this.uploadForm.patchValue({
            RolltexComm: ""
          });
          this.uploadForm.patchValue({
            YFIComm: ""
          });
          this.uploadForm.patchValue({
            TotalOrderValue: "0"
          });
          this.uploadForm.patchValue({
            SizeRange: ""
          });
          this.uploadForm.patchValue({
            Status: ""
          });
          this.uploadForm.patchValue({
            oDate: null
          });
          this.uploadForm.patchValue({
            Remarks: ""
          });
          this.uploadForm.patchValue({
            PAYMENT_TREMS: ""
          });
          this.uploadForm.patchValue({
            Packing: ""
          });
        }
      }
    );
  }

  editOrderList = (id, code) => {
    sessionStorage.removeItem('order_code');
    try {
      let data = {
        order_Code: code,
        order_id: id.toString()
      }
      this.order_code_number = code.toString();
      this.order_id_number = id.toString();

      this.tree_Order_Details = [];
      let url = "Order/Single";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          this.closePopup('singleModel');
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            if (data12[0].length > 0) {
              this.tree_Order_Details = data12[0][0];
              this.isReadonly = true;
              document.body.style.overflow = 'auto';
              this.order_Code_Sipment = this.tree_Order_Details.Order_Code;
              let urldata = "Order/Edit";
              this.singleSliderOrder(this.tree_Order_Details.Order_Code, this.tree_Order_Details.Order_Id);
              this.TreeContactBuyerEdit(this.tree_Order_Details.Buyer_Code);
              this.TreeContactSupplerEdit(this.tree_Order_Details.Supplier_Code);
              this.TreeContactItemList(this.tree_Order_Details.Order_Code, this.tree_Order_Details.Order_Id);
              this.TreeContactItemSizeList(this.tree_Order_Details.Order_Code, this.tree_Order_Details.Order_Id);
              if (this.tree_Order_Details.Order_Cancelled === 'Y') {
                this.isCheckedCancelled = true;
              }
              else {
                this.isCheckedCancelled = false;
              }
              if (this.tree_Order_Details.Order_Completed === 'Y') {
                this.isCheckedCompleted = true;
              }
              else {
                this.isCheckedCompleted = false;
              }
              this.order_code_list = data;
              let Inspection_Date = this.datePipe.transform(this.tree_Order_Details.Inspection_Date, 'yyyy-MM-dd');
              if (Inspection_Date == '1900-01-01') {
                Inspection_Date = null;
              }
              else {
                Inspection_Date = this.datePipe.transform(this.tree_Order_Details.Inspection_Date, 'yyyy-MM-dd');
              }
              let LC_NR = this.datePipe.transform(this.tree_Order_Details.LC_NR, 'yyyy-MM-dd');
              if (LC_NR == '1900-01-01') {
                LC_NR = null;
              }
              else {
                LC_NR = this.datePipe.transform(this.tree_Order_Details.LC_NR, 'yyyy-MM-dd');
              }
              let Delivery_Date_On_LC = this.datePipe.transform(this.tree_Order_Details.Delivery_Date_On_LC, 'yyyy-MM-dd');
              if (Delivery_Date_On_LC == '1900-01-01') {
                Delivery_Date_On_LC = null;
              }
              else {
                Delivery_Date_On_LC = this.datePipe.transform(this.tree_Order_Details.Delivery_Date_On_LC, 'yyyy-MM-dd');
              }
              let LC_Sent_On = this.datePipe.transform(this.tree_Order_Details.LC_Sent_On, 'yyyy-MM-dd');
              if (LC_Sent_On == '1900-01-01') {
                LC_Sent_On = null;
              }
              else {
                LC_Sent_On = this.datePipe.transform(this.tree_Order_Details.LC_Sent_On, 'yyyy-MM-dd');
              }
              let Order_Date = this.datePipe.transform(this.tree_Order_Details.Order_Date, 'yyyy-MM-dd');
              if (Order_Date == '1900-01-01') {
                Order_Date = null;
              }
              else {
                Order_Date = this.datePipe.transform(this.tree_Order_Details.Order_Date, 'yyyy-MM-dd');
              }
              let Dispatch_Date = this.datePipe.transform(this.tree_Order_Details.Dispatch_Date, 'yyyy-MM-dd');
              if (Dispatch_Date == '1900-01-01') {
                Dispatch_Date = null;
              }
              else {
                Dispatch_Date = this.datePipe.transform(this.tree_Order_Details.Dispatch_Date, 'yyyy-MM-dd');
              }
              //console.log(this.datePipe.transform(this.tree_Order_Details.Inspection_Date));
              this.uploadForm = this._fb.group({
                pageUpdateInsert: [urldata],
                OrderNo: [this.tree_Order_Details.Order_Code],
                Order_id: [this.tree_Order_Details.Order_Id.toString()],
                buyer: [this.tree_Order_Details.Buyer_Code],
                bContactPerson: [this.tree_Order_Details.Buyer_Contact_Code],
                Department: [this.tree_Order_Details.Department],
                Supplier: [this.tree_Order_Details.Supplier_Code],
                sContactPerson: [this.tree_Order_Details.Supplier_Contact_Code],
                Bank: [this.tree_Order_Details.Bank_Code],
                Consignee: [this.tree_Order_Details.Consignee_Code],
                Currency: [this.tree_Order_Details.Currency_Code],
                ConvRate: [this.tree_Order_Details.Conversion_Rate.toString()],
                PJNo: [this.tree_Order_Details.PJ_No],
                LCNo: [this.tree_Order_Details.LC_No],
                LCDate: [LC_NR],
                DelDateOnLC: [Delivery_Date_On_LC],
                LCSentOn: [LC_Sent_On],
                Category: [this.tree_Order_Details.Order_Category],
                OrderDate: [Order_Date],
                OrderCompleted: [this.isCheckedCompleted],
                DispatchDate: [Dispatch_Date],
                OrderCancelled: [this.isCheckedCancelled],
                TotalQuentity: [this.tree_Order_Details.Total_Quantity.toString()],
                OrderComm: [this.tree_Order_Details.Total_Order_Commission.toString()],
                RolltexComm: [this.tree_Order_Details.Commission_percent.toString()],
                YFIComm: [this.tree_Order_Details.Debit_percent],
                TotalOrderValue: [this.tree_Order_Details.Another_Currency_Rate.toString()],
                SizeRange: [this.tree_Order_Details.Size_Range],
                Status: [this.tree_Order_Details.Inspection_Status],
                oDate: [Inspection_Date],
                Remarks: [this.tree_Order_Details.Remarks],
                PAYMENT_TREMS: [this.tree_Order_Details.PAYMENT_TREMS],
                Packing: [this.tree_Order_Details.Packing],
              });
            } else {

            }
            this.uploadForm.get('ConvRate').updateValueAndValidity();
          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }
  singleSliderOrder = (order_code, order_id) => {
    this.imageObject = [];
    let url = "Order/ItemSizeListsImage?order_code=" + order_code + "&order_id=" + order_id;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          if(data12[0].length>0){
            data12[0].forEach((item, index) => {
              //debugger
              this.imageObject.push(
                {
                  image: 'data:image/jpeg;base64,' + item.Article_Image_Detail,
                  thumbImage: 'data:image/jpeg;base64,' + item.Article_Image_Detail,
                });
            });
            //console.log(this.imageObject);
          }
          else{
            this.imageObject = [{
              image: this.placeholderImageUrl,
              thumbImage: this.placeholderImageUrl
            }];
          }
          
         
        } else {
          this.imageObject = [{
            image: this.placeholderImageUrl,
            thumbImage: this.placeholderImageUrl
          }];
        }
      }
    );
  }

  editDialogUpdate = (id, Acode, ORDER_CODE) => {
    this.colorbgCodelatest = '';
    this.show = true;
    try {
      this.tree_Items_Details = [];
      let url = "Order/ItemPrice?article_code=" + Acode;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.loadTreeBuyer_Item_Prize()
            this.colorbgCodelatest = '#ccc';
            let data12 = (new Function("return [" + data.editList + "]")());
            this.tree_Items_Details = data12[0];
            this.isReadonly = true;
            $('#modalEditdataItem' + id).css('display', 'block');
            //$('#modalEditdataItem' + id).modal({backdrop: 'static', keyboard: false});
            //$('#modalEditdataItem' + id).modal('show');//sudeshkumar is panding
            this.show = false;
          } else {

          }

        }
      );
    }
    catch (e) {

    }
  }

  onChangeItemSzie = (data, o_ITEM_PRICE) => {
    this.get_totalPrice = data * o_ITEM_PRICE;
  }

  updateItemQty(newQty: number) {
    this.item1.ITEM_QTY = newQty;
  }

  closeEditItemAmount = (id, ORDER_CODE, Order_Id) => {
    this.show = false;
    sessionStorage.removeItem('order_code');
    $('#modalEditdataItem' + id).css('display', 'none');
    this.editOrderList(Order_Id, ORDER_CODE)
  }


  editItemAmount = (id, ORDER_ARTICLE_CODE, ORDER_CODE, o_ItemCode, o_mode, o_ITEM_QTY, o_ITEM_PRICE, o_totalPrice, o_Invoice_price, Order_Id) => {
    this.show = true;

    let url = "Order/editItemAmount?ORDER_ARTICLE_CODE=" + ORDER_ARTICLE_CODE + "&ORDER_CODE=" + ORDER_CODE + "&o_ItemCode=" + o_ItemCode + "&o_mode=" + o_mode.split(': ')[1] + "&o_ITEM_QTY=" + o_ITEM_QTY + "&o_ITEM_PRICE=" + o_ITEM_PRICE + "&o_totalPrice=" + o_totalPrice + "&o_Invoice_price=" + o_Invoice_price + "&Order_Id=" + Order_Id;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          this.loadTreeBuyer_Item_Prize()
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Item updated successfully</p>',
            showConfirmButton: false,
            timer: 2500
          });
          this.show = false;
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );
  }

  loadTreeBuyer_Item_Prize = () => {
    let url = "ItemPrice/List_Price";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        let dataList = "";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer_item_prize = data12[0];
        } else {

        }
      }
    );
  }

  TreeListBinding = (Item_Code, Article_Code, Order_Code) => {

    this.loadTreeBuyer()
    try {
      this.selectedUser = null;
      this.selectedSize = null;
      this.addIn = false;
      this.addvlaue = "Add";
      this.tree_edit_list = [];
      let url = "Order/ItemPriceEditList?Item_Code=" + Item_Code + "&Article_Code=" + Article_Code + "&Order_Code=" + Order_Code;

      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            let data121 = (new Function("return [" + data.itemList + "]")());
            this.itemListHistory = data121[0];
            //console.log(this.itemListHistory);
            if (data12[0].length > 0) {
              this.isReadItemCode = true;
              this.tree_edit_list = data12[0][0];
              this.selectedMode = this.tree_edit_list.ITEM_MODE;
              this.get_Invoice_price = this.tree_edit_list.ITEM_PRICE;
              this.get_totalPrice = this.tree_edit_list.TOTAL_FINAL_PRICE;
              this.get_ITEM_PRICE = this.tree_edit_list.ITEM_INVOICE_PRICE;

              this.buyerCode = Item_Code;
            }
            else {
              this.isReadItemCode = false;
              this.tree_edit_list = [""];
              this.buyerCode = "";
            }
            // this.uploadForm = this._fb.group({
            //   ItemCode: [this.tree_edit_list.ITEM_CODE],
            //   mode: [this.tree_edit_list.ITEM_PRICE_MODE],
            //   CostPrice: [this.tree_edit_list.ITEM_COST_PRICE],
            //   SellingPrice: [this.tree_edit_list.ITEM_SELLING_PRICE],

            // });

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }




  // formatDate(event: MatDatepickerInputEvent<Date>) {
  //   // Assuming event.value is a Date object
  //   const formattedDate = this.formatDateToDesiredFormat(event.value);
  //   this.item1.EXPORT_DATE = formattedDate;
  // }

  formatDateToDesiredFormat(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based in JavaScript
    const year = date.getFullYear() % 100; // Get the last two digits of the year

    // Pad day and month with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day.toString();
    const formattedMonth = month < 10 ? `0${month}` : month.toString();

    // Format the date as desired (day.month.year)
    return `${formattedDay}.${formattedMonth}.${year}`;
  }

  editDialogUpdateChangeDate(id) {
    // Destroy the existing modal if it exists
    const existingModal = $('#editDialogUpdateChangeDate' + id).data('bs.modal');
    if (existingModal) {
      existingModal.dispose(); // Destroy the modal
      $('#editDialogUpdateChangeDate' + id).removeData('bs.modal'); // Remove modal data
    }

    // Open a new modal
    $('#editDialogUpdateChangeDate' + id).modal('show');
  }
  closePopupStyleDate(id) {
    this.show = false;
    sessionStorage.removeItem('order_code');
    $('#editDialogUpdateChangeDate' + id).modal('hide');
    const existingModal = $('#editDialogUpdateChangeDate' + id).data('bs.modal');
    if (existingModal) {
      existingModal.dispose(); // Destroy the modal
      $('#editDialogUpdateChangeDate' + id).removeData('bs.modal'); // Remove modal data
    }
  }

  handleDatepickerClosed(id, export_date, order_code, order_id, order_article_code) {
    let export_date1 = this.datePipe.transform(export_date, 'yyyy-MM-dd');
    let url = "Order/ChangeExportDate?export_date=" + export_date1 + "&order_code=" + order_code + "&order_id=" + order_id + "&order_article_code=" + order_article_code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          this.closePopupStyleDate(id);
          this.editOrderList(order_code, order_code)
          //this.TreeContactItemList(order_code, order_code);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Change Date successfully</p>',
            showConfirmButton: false,
            timer: 2500
          });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Something wrong want</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }
      }
    );
  }

  dateFilter = (date: Date): boolean => {
    return !date || !isNaN(date.getTime());
  };

  formatDate(inputDate: string): string {
    const parts = inputDate.split('/');
    if (parts.length === 3) {
      const [month, day, year] = parts;
      // Ensure the month and day are in the "MM" and "dd" format
      const formattedMonth = month.length === 1 ? `0${month}` : month;
      const formattedDay = day.length === 1 ? `0${day}` : day;

      // Create a new Date object using the formatted date string
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    }

    // Return the original input if the format is unexpected
    return inputDate;
  }


  TreeContactItemList = (code, o_id) => {
    try {
      let data = {
        Order_Code: code.toString(),
        Order_id: o_id.toString()
      }
      this.tree_Contact_ContactItemList = [];
      let url = "Order/ItemList";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            if (data12[0].length > 0) {
              //this.tree_Contact_ContactItemList = data12[0];
              data12[0].forEach((item, index) => {
                this.tree_Contact_ContactItemList.push(
                  {
                    ORDER_CODE: item.ORDER_CODE,
                    Order_Id: item.Order_Id,
                    ORDER_ARTICLE_CODE: item.ORDER_ARTICLE_CODE,
                    Item_Description: item.Item_Description,
                    ITEM_QTY: item.ITEM_QTY,
                    //                        EXPORT_DATE: this.datePipe.transform(item.EXPORT_DATE, 'yyyy-MM-dd'),
                    EXPORT_DATE: this.datePipe.transform(item.EXPORT_DATE, 'yyyy-MM-dd'),
                    GSM: item.GSM,
                    Unit: item.Unit,
                    Amendment_No: item.Amendment_No,
                    Amendment_Date: this.datePipe.transform(item.Amendment_Date, 'yyyy-MM-dd'),
                  });
              });
            }
            else {
              this.tree_Contact_ContactItemList = [""];
              //console.log('data not found tree_Contact_ContactItemList')
            }

          } else {
            //console.log('data not found')
          }

        }
      );
    }
    catch (e) {
      //console.log(e)
    }
  }

  TreeContactItemList_List = (code, o_id) => {
    try {
      let data = {
        Order_Code: code.toString(),
        Order_id: o_id.toString()
      }
      this.tree_Contact_ContactItemList = [];
      let url = "Order/ItemList";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            if (data12[0].length > 0) {
              //this.tree_Contact_ContactItemList = data12[0];
              data12[0].forEach((item, index) => {
                this.tree_Contact_ContactItemList.push(
                  {
                    ORDER_CODE: item.ORDER_CODE,
                    Order_Id: item.Order_Id,
                    ORDER_ARTICLE_CODE: item.ORDER_ARTICLE_CODE,
                    Item_Description: item.Item_Description,
                    ITEM_QTY: item.ITEM_QTY,
                    EXPORT_DATE: this.datePipe.transform(item.EXPORT_DATE, 'yyyy-MM-dd'),
                    GSM: item.GSM,
                    Unit: item.Unit,
                    Amendment_No: item.Amendment_No,
                    Amendment_Date: this.datePipe.transform(item.Amendment_Date, 'yyyy-MM-dd'),
                  });
              });
            }
            else {
              this.tree_Contact_ContactItemList = [""];
              //console.log('data not found tree_Contact_ContactItemList')
            }

          } else {
            //console.log('data not found')
          }

        }
      );
    }
    catch (e) {
      //console.log(e)
    }
  }

  TreeContactItemSizeList = (code, o_id) => {
    try {
      let data = {
        Order_Code: code.toString(),
        Order_id: o_id.toString()
      }
      this.tree_Contact_ContactItemSizeList = [];
      let url = "Order/ItemSizeList";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            if (data12[0].length > 0) {
              this.tree_Contact_ContactItemSizeList = data12[0];
              let area_carddata = Array.prototype.map.call(data12[0], function (item) { return item.ORDER_ITEM_CODE; }).join("','");
              this.area_carddata_list = area_carddata;
            }
            else {
              this.tree_Contact_ContactItemSizeList = [""];
              this.area_carddata_list = "";
            }
            ////console.log(area_carddata);
          } else {

          }

        }
      );
    }
    catch (e) {

    }
  }

  closePopupStyle(id) {
    this.show = false;
    sessionStorage.removeItem('order_code');
    $('#' + id).css('display', 'none');
    document.body.style.overflow = 'auto';
    if (id == 'showPopupDocUpload') {
      $('#showPopupDocUpload').css('display', 'none');
      $('#showPopupDocUpload').modal('hide');
      const existingModal = $('#showPopupDocUpload').data('bs.modal');
      if (existingModal) {
        existingModal.dispose(); // Destroy the modal
        $('#showPopupDocUpload').removeData('bs.modal'); // Remove modal data
      }
    }
    else if (id == 'showPopupOrderSpecSheet') {
      $('#showPopupOrderSpecSheet').css('display', 'none');
      $('#showPopupOrderSpecSheet').modal('hide');
      const existingModal = $('#showPopupOrderSpecSheet').data('bs.modal');
      if (existingModal) {
        existingModal.dispose(); // Destroy the modal
        $('#showPopupOrderSpecSheet').removeData('bs.modal'); // Remove modal data
      }
    }


  }

  closePopup(id) {
    this.tree_card_OrderDropdown = [];
    this.filteredOrders = [];
    sessionStorage.removeItem('order_code');
    this.show = false;
    if (id === 'singleModel') {
      $('#showPopup').css('display', 'none');
      $('#modalEditItemView').css('display', 'none');
      $('#showPopupShipmentDetails').css('display', 'none');
      $('#showPopupGenerateShipment').css('display', 'none');
      $('#showPopupColorDetalls').css('display', 'none');
      $('#showPopupShipmentDetailsSelectItem').css('display', 'none');

    }
    else {

      $('#datamodalEdit' + id).modal('hide');
      // $('#modalEditdataItem' + id).modal('hide');
      $('#modalEditdataItem' + id).css('display', 'none');
      $('#showPopup').css('display', 'none');
    }

    // Re-enable scrolling on the background page when the popup is closed
    document.body.style.overflow = 'auto';

  }

  viewDialog = () => {
    document.body.style.overflow = 'hidden';
    $('#showPopup').css('display', 'flex');
  }

  SearchOrder = (data) => {
    this.show = true;
    this.tree_card_OrderDropdown = [];
    this.filteredOrders = [];
    let url = "Order/SearchListOrder?Order_Code="+data;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            this.tree_card_OrderDropdown = dataList[0];
            this.filteredOrders = this.tree_card_OrderDropdown;
            this.show = false;
          }
          else {
            this.tree_card_OrderDropdown = [""];
            this.show = false;
          }
          $('#showPopup').css('display', 'flex');
          this.show = false;
        } else {
          this.show = false;
        }
      }
    );
  }

  addValueitem = (order_code, order_id, ConversionCurrencydata = '0') => {
    var t = this.ItemMasterList.filter(opt => opt.checked).map(opt => opt);
    let area_card = Array.prototype.map.call(t, function (item) { return item.Item_Code; }).join("','");
    let Article_Code = Array.prototype.map.call(t, function (item) { return item.Article_Code; }).join("','");

    let url = "Order/AddItemlist?myList=" + area_card + "&Article_Code=" + Article_Code + "&orderId=" + order_id + "&order_code=" + order_code + "&ConversionCurrency=" + ConversionCurrencydata;
    //console.log(url);
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          document.body.style.overflow = 'auto';
          $('#modalEditItemView').css('display', 'none');
          this.editOrderListSearch(order_code);
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            this.tree_card_Buyer = dataList[0];
            this.tree_ContactBuyerConsignee = dataList[0];
          }
          else {
            this.tree_card_Buyer = [""];
            this.tree_ContactBuyerConsignee = [""];
          }
          this.TreeContactItemSizeList(order_code, order_id);
        } else {

        }
      }
    );
  }

  loadTreeBuyer = () => {
    let url = "Buyer/TreeBuyerList";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = dataList[0];
          if (dataList[0].length > 0) {
            this.tree_ContactBuyerConsignee = dataList[0];
          }
          else {
            this.tree_ContactBuyerConsignee = [""];
          }

        } else {

        }
      }
    );
  }

  TreeContactBuyerEdit = (event) => {
    try {
      let data = {
        code: event
      }
      this.tree_ContactBuyer = [];
      let url = "Buyer/BuyerCode";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.data + "]")());
            if (data12[0].length > 0) {
              this.tree_ContactBuyer = data12[0];
            }
            else {
              this.tree_ContactBuyer = [""];
            }

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }
  TreeContactBuyer = (event) => {
    const Item_Code = event.target.value;
    try {
      let data = {
        code: Item_Code.split(': ')[1]
      }
      this.tree_ContactBuyer = [];
      let url = "Buyer/BuyerCode";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.data + "]")());
            if (data12[0].length > 0) {
              this.tree_ContactBuyer = data12[0];
            }
            else {
              this.tree_ContactBuyer = [""];
            }


          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  loadTreeSuppler = () => {
    let url = "Suppler/TreeSupplieList";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {


        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            this.tree_card_Suppler = dataList[0];
          }
          else {
            this.tree_card_Suppler = [""];
          }


        } else {

        }
      }
    );
  }
  TreeContactSupplerEdit = (event) => {

    try {
      let data = {
        code: event
      }

      this.tree_ContactSuppler = [];
      let url = "Suppler/SupplierCode";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.data + "]")());
            if (data12[0].length > 0) {
              this.tree_ContactSuppler = data12[0];
            }
            else {
              this.tree_ContactSuppler = [""];
            }

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }
  TreeContactSuppler = (event) => {

    try {
      let data;
      let Item_Code = event.target.value;
      data = {
        code: Item_Code.split(': ')[1]
      }

      this.tree_ContactSuppler = [];
      let url = "Suppler/SupplierCode";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.data + "]")());
            if (data12[0].length > 0) {
              this.tree_ContactSuppler = data12[0];
            }
            else {
              this.tree_ContactSuppler = [""];
            }

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  deleteDialog = () => {
    this.dialogDelete = true;
  }


  loadBankMaster = () => {
    this.bankMasterList = [];
    let url = "BankMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.status === 'OK') {

          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            this.bankMasterList = data12[0];
          }
          else {
            this.bankMasterList = [];
          }

        } else {

        }
      }
    );
  }

  loadCurrency = () => {
    this.SizeCurrencyList = [];
    let url = "Currency/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            this.SizeCurrencyList = data12[0];
          }
          else {
            this.SizeCurrencyList = [""];
          }

        } else {

        }
      }
    );
  }

  ConversionCurrency = (event) => {

    const Item_Code12 = event.target.value;

    try {
      let data = {
        Currency_Code: Item_Code12.split(': ')[1]
      }
      let url = "Currency/Single";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.uploadForm.patchValue({
              ConvRate: data12[0][0]['Conversion_Rate']
            });
            this.ConversionCurrencyValue = data12[0][0]['Conversion_Rate'];
            // this.uploadForm.get('ConvRate').setValue(data12)[0][0]['Conversion_Rate']
            // this.uploadForm.get('ConvRate').updateValueAndValidity();
          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  loadSizeMaster = () => {
    let url = "SizeMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.SizeMasterList = data12[0];
        } else {

        }
      }
    );
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.selectedSize = null;
    this.loadBankMaster();
  }

  CodeAlredyExist = "";
  CodeCheckUnique(code) {
    // let url = "Buyer/EditBuyerCodeAndNameList";
    // this.webService.loadTreetListEdit(url, code).subscribe(data => {
    //   if (data.status === 'OK') {
    //     this.CodeAlredyExist = "Code Alredy Exist, Please another code ";
    //   }
    //   else {
    //     this.CodeAlredyExist = "";
    //   }
    // });
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/OrderDetails']);
    });
    // let currentUrl = this.router.url;
    // this.router.navigateByUrl('/suppler', {skipLocationChange: true}).then(() => {
    //     this.router.navigate([currentUrl]);
    // });
  }

  editSaveDialog = (order_Code, Order_Id, item_code, or_article_code, finalAmount, mode, Quantity, amount, idata) => {

    let data = {
      Order_Code: order_Code,
      ItemCode: item_code,
      or_article_code: or_article_code,
      item_price: amount,
      quantity: Quantity,
      finalPrice: finalAmount,
      mode: mode.split(': ')[1]
    }
    let url = "Order/EditOrderAmount";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Edit Supplier Contact Info');
          this.editOrderList(Order_Id, order_Code)
          this.TreeContactItemList(order_Code, Order_Id);
          $('#datamodalEdit' + idata).modal('hide');
          this.TreeContactItemSizeList(order_Code, Order_Id)
          //        this.loadContactPersionList(this.buyerCode);
        } else {
          alert('Is not Edit Supplier Contact Info');
        }

      }
    );
  }

  editItemSize = (amount) => {

    let data = {
      amount: amount,
    }
    let url = "Buyer/EditBuyerContactInfo";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Edit Supplier Contact Info');

          $('#modalEdit' + amount).modal('hide');
          //        this.loadContactPersionList(this.buyerCode);
        } else {
          alert('Is not Edit Supplier Contact Info');
        }

      }
    );
  }

  onSubmit() {
    let OrderCompletedTrue, OrderCancelledTrue, SizeRange, StatusR, oDate, LCDate, DelDateOnLC, LCSentOn, DispatchDate;
    this.show = true;
    if (this.uploadForm.valid) {
      if (this.uploadForm.value.OrderCompleted === true) {
        OrderCompletedTrue = "Y";
      }
      else if (this.uploadForm.value.OrderCompleted === false) {
        OrderCompletedTrue = "N";
      }
      else {
        OrderCompletedTrue = "N";
      }

      if (this.uploadForm.value.OrderCancelled === true) {
        OrderCancelledTrue = "Y";
      }
      else if (this.uploadForm.value.OrderCancelled === false) {
        OrderCancelledTrue = "N";
      }
      else {
        OrderCancelledTrue = "N";
      }
      // if (this.uploadForm.value.pageUpdateInsert === "Order/Add") {
      //   if (this.uploadForm.value.OrderCompleted === true) {
      //     OrderCompletedTrue = "Y";
      //   }
      //   else if (this.uploadForm.value.OrderCompleted === false) {
      //     OrderCompletedTrue = "N";
      //   }
      //   else {
      //     OrderCompletedTrue = "N";
      //   }

      //   if (this.uploadForm.value.OrderCancelled === true) {
      //     OrderCancelledTrue = "Y";
      //   }
      //   else if (this.uploadForm.value.OrderCancelled === false) {
      //     OrderCancelledTrue = "N";
      //   }
      //   else {
      //     OrderCancelledTrue = "N";
      //   }
      // }
      // else {
      //   OrderCompletedTrue = this.uploadForm.value.OrderCompleted;
      //   OrderCancelledTrue = this.uploadForm.value.OrderCancelled;
      // }

      if (this.uploadForm.value.SizeRange === null || this.uploadForm.value.SizeRange === "") {
        SizeRange = "";
      }
      else {
        SizeRange = this.uploadForm.value.SizeRange.toString();
      }
      if (this.uploadForm.value.Status === null || this.uploadForm.value.Status === "") {
        StatusR = "";
      }
      else {
        StatusR = this.uploadForm.value.Status.toString();
      }
      if (this.uploadForm.value.oDate === null || this.uploadForm.value.oDate === "") {
        oDate = "01-01-1900";
      }
      else {
        oDate = this.uploadForm.value.oDate.toString();
      }
      if (this.uploadForm.value.LCDate === null || this.uploadForm.value.LCDate === "") {
        LCDate = "01-01-1900";
      }
      else {
        LCDate = this.uploadForm.value.LCDate.toString();
      }
      if (this.uploadForm.value.DelDateOnLC === null || this.uploadForm.value.DelDateOnLC === "") {
        DelDateOnLC = "01-01-1900";
      }
      else {
        DelDateOnLC = this.uploadForm.value.DelDateOnLC.toString();
      }
      if (this.uploadForm.value.LCDate === null || this.uploadForm.value.LCDate === "") {
        LCSentOn = "01-01-1900";
      }
      else {
        LCSentOn = this.uploadForm.value.LCDate.toString();
      }
      if (this.uploadForm.value.DispatchDate === null || this.uploadForm.value.DispatchDate === "") {
        DispatchDate = "01-01-1900";
      }
      else {
        DispatchDate = this.uploadForm.value.DispatchDate.toString();
      }

      let data = {
        Order_Code: this.uploadForm.value.OrderNo.toString(),
        Order_id: this.uploadForm.value.Order_id.toString(),
        Order_Date: this.uploadForm.value.OrderDate.toString(),
        Buyer_Code: this.uploadForm.value.buyer.toString(),
        Buyer_Contact_Code: this.uploadForm.value.bContactPerson.toString(),
        Supplier_Code: this.uploadForm.value.Supplier.toString(),
        Supplier_Contact_Code: this.uploadForm.value.sContactPerson.toString(),
        Dispatch_Date: DispatchDate,
        Order_Category: this.uploadForm.value.Category.toString(),
        Department: this.uploadForm.value.Department.toString(),
        Bank_Code: this.uploadForm.value.Bank.toString(),
        Consignee_Code: this.uploadForm.value.Consignee.toString(),
        PJ_No: this.uploadForm.value.PJNo.toString(),
        Currency_Code: this.uploadForm.value.Currency.toString(),
        Conversion_Rate: this.uploadForm.value.ConvRate.toString(),
        Remarks: this.uploadForm.value.Remarks.toString(), //not mact
        LC_No: this.uploadForm.value.LCNo.toString(),
        LC_NR: LCDate,
        Delivery_Date_On_LC: DelDateOnLC,
        LC_Sent_On: LCSentOn,
        PAYMENT_TREMS: this.uploadForm.value.PAYMENT_TREMS.toString(),
        Total_Quantity: this.uploadForm.value.TotalQuentity.toString(),
        Total_Order_Commission: this.uploadForm.value.OrderComm.toString(),
        Order_Completed: OrderCompletedTrue.toString(),
        Order_Cancelled: OrderCancelledTrue.toString(),
        Packing: this.uploadForm.value.Packing.toString(),
        Commission_percent: this.uploadForm.value.RolltexComm.toString(),
        Debit_percent: this.uploadForm.value.YFIComm.toString(),
        Another_Currency_Code: this.uploadForm.value.Currency.toString(),
        Another_Currency_Rate: this.uploadForm.value.TotalOrderValue.toString(),
        Size_Range: SizeRange,
        Inspection_Status: StatusR,
        Inspection_Date: oDate
      }
      let url = this.uploadForm.value.pageUpdateInsert;
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          this.show = false;
          if (data.page === 'Add') {
            if (data.status === 'OK') {
              this.uploadForm.reset();
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                iconColor: '#000',
                background: '#FFFFFF',
                html: '<p style="font-weight: 500;color: black;">Order has been added</p>',
                showConfirmButton: false,
                timer: 2500
              });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                iconColor: '#000',
                background: '#FFFFFF',
                html: '<p style="font-weight: 500;color: black;font-size: 20px;">Order has been not added. Please try agian</p>',
                showConfirmButton: false,
                timer: 2500
              });
            }
          } else {
            if (data.status === 'OK') {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                iconColor: '#000',
                background: '#FFFFFF',
                html: '<p style="font-weight: 500;color: black;">Order has been edited.</p>',
                showConfirmButton: false,
                timer: 2500
              });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                iconColor: '#000',
                background: '#FFFFFF',
                html: '<p style="font-weight: 500;color: black;font-size: 20px;">Order has not been edited</p>',
                showConfirmButton: false,
                timer: 2500
              });
            }
          }

        }
      );
    }
    else {
      this.show = false;
      this.validateAllFormFields(this.uploadForm);
    }

  }

  showPopupMerchandise = () => {
    sessionStorage.removeItem('order_code'); 
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      let returnUrl = "MerchandisingDetails?order_no=" + this.order_Code_Sipment + "&model=1";
      //this.router.navigateByUrl(returnUrl);

      // Calculate the center position for the popup window
      let screenWidth = window.screen.availWidth;
      let screenHeight = window.screen.availHeight;
      let windowWidth = 1200; // Adjust as needed
      let windowHeight = 600; // Adjust as needed
      let leftPosition = (screenWidth - windowWidth) / 2;
      let topPosition = (screenHeight - windowHeight) / 2;

      // Define window features
      let windowFeatures = 'width=' + windowWidth + ',height=' + windowHeight + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes';

      // Open the popup window
      let popupWindow = window.open(returnUrl, '_popupWin', windowFeatures);

      // Focus on the popup window
      popupWindow.focus();
    }
  }

  showPopupSpecSheet = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      this.order_id_no_file_upload = this.order_Code_Sipment;
      this.showPopupSpecSheetOpen();
    }
  }

  showPopupSpecSheetOpen() {
    // Destroy the existing modal if it exists
    const existingModal = $('#showPopupOrderSpecSheet').data('bs.modal');
    if (existingModal) {
      existingModal.dispose(); // Destroy the modal
      $('#showPopupOrderSpecSheet').removeData('bs.modal'); // Remove modal data
    }
    // Open a new modal
    $('#showPopupOrderSpecSheet').modal('show');
  }

  showPopupTesting = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      $('#showPopupFollowUpDetails').css('display', 'block');
    }
  }

  showPopupFollowUp = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      alert('Please select value order');
    }
    else {
      let returnUrl = "OrderFollow-Up?order_no=" + this.order_Code_Sipment + "&model=1";
      //this.router.navigateByUrl(returnUrl);

      // Calculate the center position for the popup window
      let screenWidth = window.screen.availWidth;
      let screenHeight = window.screen.availHeight;
      let windowWidth = 1200; // Adjust as needed
      let windowHeight = 600; // Adjust as needed
      let leftPosition = (screenWidth - windowWidth) / 2;
      let topPosition = (screenHeight - windowHeight) / 2;

      // Define window features
      let windowFeatures = 'width=' + windowWidth + ',height=' + windowHeight + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes';

      // Open the popup window
      let popupWindow = window.open(returnUrl, '_popupWin', windowFeatures);

      // Focus on the popup window
      popupWindow.focus();
    }
  }

  showPopupInspection = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      $('#showPopupInspection').css('display', 'block');
    }
  }


  showPopupCommissonNote = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      $('#showPopupCommissionNote').css('display', 'block');
    }
  }

  showPopupDebitNote = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      $('#showPopupDebitNote').css('display', 'block');
    }
  }

  showPopupClaimNote = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      $('#showPopupClaimNote').css('display', 'block');
    }
  }

  showPopupProduction = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      $('#showPopupProduction').css('display', 'block');
    }
  }

  openOrReloadModal() {
    // Destroy the existing modal if it exists
    const existingModal = $('#showPopupDocUpload').data('bs.modal');
    if (existingModal) {
      existingModal.dispose(); // Destroy the modal
      $('#showPopupDocUpload').removeData('bs.modal'); // Remove modal data
    }

    // Open a new modal
    $('#showPopupDocUpload').modal('show');
  }


  showPopupDocUpload = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === 'all_order') {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    } else {
      this.order_id_no_file_upload = this.order_Code_Sipment;
      // //console.log(this.order_id_no_file_upload);
      //this.updateModalContent(); // Update the modal content here
      this.openOrReloadModal(); // Open or reload the modal

    }
  };


  showPopupGenerateShipment = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      this.proFillMode();
      this.proshowPopupGenerateShipment_print_list(this.order_Code_Sipment);
      this.proshowPopupGenerateShipment(this.order_Code_Sipment);
      // this.shipment_serial(this.order_Code_Sipment);


    }
  }
  showPopupShipmentDetails = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      this.proFillMode();
      this.proShowOrderDetails(this.order_Code_Sipment);
      // this.shipment_serial(this.order_Code_Sipment);


    }

  }

  onRemove(row: any) {
    //console.log(row);
  }

  // showPopupColorDetalls2 = () => {
  //   this.service.createTableWithIds(this.tableHeaders, this.tableRowsWithId, this.dataType);
  //   $('#showPopupColorDetalls').css('display', 'block');
  //   // $('#showPopupColorDetalls').modal('show');

  // }

  showPopupColorDetalls = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else if (this.sip_mode_shipiment === "") {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Sorry please fill Shipment details</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      let returnUrl = "OrderColorDetails?orderCode=" + this.order_Code_Sipment + "&order_id=" + this.order_id_number + "&ShipmentNo=1&Modeofshipment=" + this.sip_mode_shipiment;
      //this.router.navigateByUrl(returnUrl);

      // Calculate the center position for the popup window
      let screenWidth = window.screen.availWidth;
      let screenHeight = window.screen.availHeight;
      let windowWidth = 1200; // Adjust as needed
      let windowHeight = 600; // Adjust as needed
      let leftPosition = (screenWidth - windowWidth) / 2;
      let topPosition = (screenHeight - windowHeight) / 2;

      // Define window features
      let windowFeatures = 'width=' + windowWidth + ',height=' + windowHeight + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes';

      // Open the popup window
      let popupWindow = window.open(returnUrl, '_popupWin', windowFeatures);

      // Focus on the popup window
      popupWindow.focus();
      //  // OrderColorDetails?orderCode=100&ShipmentNo=2&Modeofshipment=C&F=&buyer=ADAMO%20FASHION%20GmbH&supplier=JINDAL%20INDUSTRIES
      //   this.proFillMode();
      //   this.proshowPopupGenerateShipmentColorDetalls(this.order_Code_Sipment, 1);
      // this.shipment_serial(this.order_Code_Sipment);


    }
  }
  proShowOrderDetailsListColorDetalls: any = [];
  proshowPopupGenerateShipmentColorDetalls = (codedata, sid) => {
    this.proShowOrderDetailsListColorDetalls = [];
    let url = "ColorDetails/ItemList?order_code=" + codedata;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              this.proShowOrderDetailsListColorDetalls.push(
                {
                  id: index + 1,
                  order_article_code: item.ORDER_ARTICLE_CODE,
                  item_description: item.Item_Description,
                });
            });
            this.ArticleCodeColor = this.proShowOrderDetailsListColorDetalls[0].order_article_code;
            this.showOrderColorDetalls(codedata, sid, this.proShowOrderDetailsListColorDetalls[0].order_article_code);
          } else {
            // this.order_Code_Sipment=this.order_Code_Sipment;
            // this.buyer_shipment="";
            // this.supplier_shipment="";
            //this.shipment_serial(this.order_Code_Sipment);
          }

        } else {
          //  this.shipment_serial(code);
        }
      }
    );

    this.shipment_serial_GS(codedata);
    $('#showPopupColorDetalls').css('display', 'block');

  }

  proShowOrderColorDetalls: any = [];
  showOrderColorDetalls = (order_code, sid, article_no) => {
    this.proShowOrderColorDetalls = [];
    //this.tableRowsWithId = [];
    let url = "ColorDetails/ColorDetailsofArticle?order_code=" + order_code + "&shipment_serial_no=" + sid + "&article_no=" + article_no;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataue = [];
          let data12 = (new Function("return [" + data.editList + "]")());

          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              dataue.push([
                index + 1,
                item.color_name,
                item.composition,
                item.lab_dip_yarn_bit,
                item.LAB_DIPS_SENT_DATE
              ]);
            });
            this.proShowOrderColorDetalls = data12[0];

            setTimeout(() => {
              this.tableRowsWithId = dataue;

            }, 2000);

          } else {
          }
        } else {
          //  this.shipment_serial(code);
        }
      }
    );

  }

  editRowColor(index: number) {
    this.proShowOrderDetailsListColorDetalls[index].isEditing = true;
  }

  saveRowColor(index: number) {
    this.proShowOrderDetailsListColorDetalls[index].isEditing = false;
  }

  cancelEditColor(index: number) {
    this.proShowOrderDetailsListColorDetalls[index] = { ...this.originalValues[index] };
    this.proShowOrderDetailsListColorDetalls[index].isEditing = false;
  }

  saveAllChangesColor(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue) {
    let data = [];
    // Implement save logic for all changes here (e.g., update the backend)
    // After saving, reset editing states
    this.proShowRecordsList.forEach(item => {
      data.push({ ...item });
      item.isEditing = false;
    });
    if (data.length > 0) {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Update and added</p>',
        showConfirmButton: false,
        timer: 2500
      });
      this.closePopup('singleModel');
    }
    else {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">is not Update and added</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    //console.log(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue, data);
  }

  changeTreeContactShipmentColor = (event, strOrderNo) => {
    let strShipmentNo = event.target.value;
    this.proshowPopupGenerateShipmentColorDetalls(strOrderNo, strShipmentNo);

  }

  proFillMode = () => {
    this.proFillModeList = [];
    let url = "Order/proFillMode";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());

          this.proFillModeList = data12[0];
        } else {

        }
      }
    );
  }

  proShowOrderDetails = (code) => {
    this.proShowOrderDetailsList = [];
    let url = "Order/proShowOrderDetails?strOrderCode=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            this.proShowOrderDetailsList = data12[0][0];

            this.order_Code_Sipment = this.proShowOrderDetailsList.order_code;
            this.buyer_shipment = this.proShowOrderDetailsList.buyer_Name;
            this.supplier_shipment = this.proShowOrderDetailsList.supplier_name

          } else {
            this.order_Code_Sipment = this.order_Code_Sipment;
            this.buyer_shipment = "";
            this.supplier_shipment = "";
            this.shipment_serial(this.order_Code_Sipment);
          }
        } else {
          //  this.shipment_serial(code);
        }
      }
    );
    this.shipment_serial(code);
    $('#showPopupShipmentDetails').css('display', 'block');
  }



  proshowPopupGenerateShipment = (code) => {
    this.proShowOrderDetailsList = [];
    let url = "Order/proShowOrderDetails?strOrderCode=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            this.proShowOrderDetailsList = data12[0][0];
            this.order_Code_Sipment = this.proShowOrderDetailsList.order_code;
            this.buyer_shipment = this.proShowOrderDetailsList.buyer_Name;
            this.supplier_shipment = this.proShowOrderDetailsList.supplier_name

          } else {
            this.order_Code_Sipment = this.order_Code_Sipment;
            this.buyer_shipment = "";
            this.supplier_shipment = "";
            this.shipment_serial(this.order_Code_Sipment);
          }
        } else {
          //  this.shipment_serial(code);
        }
      }
    );
    this.shipment_serial_GS(code);
    $('#showPopupGenerateShipment').css('display', 'block');
  }

  proshowPopupGenerateShipment_print_list = (code) => {
    //debugger
    this.proShowOrderDetailsList_print = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=shipment_order_single&order_code=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let dataList = "";
          let total = 0;
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            this.proShowOrderDetailsList_print = data12[0];
            //console.log(data12[0]);
            data12[0].forEach((element, index) => {
              //debugger
              let id = index + 1;
              total += element.Total;
              dataList += "<tr><td>" + id + "</td><td>" + element.ARTICLE_CODE + "</td><td>" + element.Article_Name + "</td><td>" + element.ITEM_CODE + "</td><td>" + element.ITEM_QTY + "</td><td>" + element.ITEM_PRICE + "</td><td>" + element.ITEM_COMM + "</td><td>" + element.Total + "</td></tr>";
            });
            this.PrintGrenteShipment = dataList;
            this.total_final_price_shipment = total;
            //console.log(this.proShowOrderDetailsList_print[0].ARRIVAL_DATE);
          } else {
            this.proShowOrderDetailsList_print = [];
          }
        } else {
          this.proShowOrderDetailsList_print = [];
        }
      }
    );
  }

  //sudeshkumar

  shipment_serial = (code) => {
    this.shipment_serialList = [];
    let url = "Order/shipment_serial?code=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          this.shipment_serialList = data12[0];
          this.proShowRecords(this.shipment_serialList[0].shipment_serial_no, this.order_Code_Sipment);
        } else {

        }
      }
    );
  }

  shipment_seriald = (code) => {
    this.shipment_serialList = [];
    let url = "Order/shipment_serial?code=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          this.shipment_serialList = data12[0];
         // this.proShowRecords(this.shipment_serialList[0].shipment_serial_no, this.order_Code_Sipment);
        } else {

        }
      }
    );
  }

  showPopupShipmentDetailsSelectItem_data: any = [];
  showShipmentDetailsSelectItem = (order_code) => {
    this.showPopupShipmentDetailsSelectItem_data = [];
    this.proShowRecordsListGS_Gnrated = [];
   
    let url = "Order/showPopupShipmentDetailsSelectItem?order_code=" + order_code
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());

          if (data12[0].length > 0) {
            this.showPopupShipmentDetailsSelectItem_data = data12[0];
            this.CountUserSip = data12[0].length;
            this.showPopupShipmentDetailsSelectItem_data.forEach((item, index) => {
              this.proShowRecordsListGS_Gnrated.push(
                {
                  id: index + 1,
                  order_article_code: item.order_article_code,
                  item_description: item.item_description,
                  order_code:order_code
                });
            });
            
            $('#showPopupShipmentDetailsSelectItem').css('display', 'block');
          }
          else {
            this.showPopupShipmentDetailsSelectItem_data = [];
          }

          //this.proShowRecordsGS(this.shipment_serialList[0].shipment_serial_no,this.order_Code_Sipment)
        } else {

        }
      }
    );
  }

  shipment_serial_GS_nEW = (id, code, order_code) => {
    this.shipment_seriald (order_code);
    this.shipment_serialList = [];
    this.proShowRecordsListGS = [];
    let url = "Order/ItemSizeListsP?article_code=" + code+"&order_code="+order_code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          data12[0].forEach((item, index) => {
            this.proShowRecordsListGS.push(
              {
                ORDER_CODE: item.ORDER_CODE,
                ARTICLE_CODE: item.ARTICLE_CODE,
                ITEM_CODE: item.ITEM_CODE,
                SHIPPING_MODE: item.ITEM_MODE,
                ITEM_QTY: item.ITEM_QTY,
                ITEM_PRICE: item.ITEM_PRICE,
                ITEM_COMM: item.ITEM_COMM,
                ITEM_INVOICE_PRICE: item.ITEM_INVOICE_PRICE,
                FINAL_PRICE: item.FINAL_PRICE,
                TOTAL_FINAL_PRICE: item.TOTAL_FINAL_PRICE,
                Amendment_No: item.Amendment_No,
                Amendment_Date: item.Amendment_Date,
                Shipped_Qty: item.Shipped_Qty,
                SHIPMENT_SEQ_UNIQUE_ID: 1,
                SHIPMENT_SERIAL_NO: id,
                Bal_Qty: item.Bal_Qty,
                SAMPLING_ID: item.SAMPLING_ID,
              });
          });
          //  this.proShowRecordsListGS 
          $('#showPopupShipmentDetailsSelectItem').css('display', 'none');
          // this.proShowRecordsGS(this.shipment_serialList[0].shipment_serial_no,this.order_Code_Sipment)
        } else {
          this.proShowRecordsListGS = [];
        }
      }
    );
  }

  shipment_serial_GS = (code) => {
    this.shipment_serialList = [];
    let url = "Order/shipment_serial?code=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          this.shipment_serialList = data12[0];
          this.proShowRecordsGS(this.shipment_serialList[0].shipment_serial_no, this.order_Code_Sipment)
        } else {

        }
      }
    );
  }

  originalValues: any[] = [];
  proShowRecords = (strShipmentNo, strOrderNo) => {
    this.proShowRecordsList = [];
    let url = "Order/proShowRecords?strShipmentNo=" + strShipmentNo + "&strOrderNo=" + strOrderNo;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let qty = 0;
          let totalValue = 0;
          let data12 = (new Function("return [" + data.editList + "]")());

          this.proShowRecordsList = data12[0];
          this.proShowRecordsList.forEach(item => {

            totalValue += item.ITEM_QTY * item.ITEM_PRICE;
            qty += item.ITEM_QTY;
            this.originalValues.push({ ...item });
          });
          this.TotalShipQty = qty;
          this.ShipmentTotal = totalValue;
          this.shipment_status = this.proShowRecordsList[0].SHIPMENT_STATUS;

        } else {

        }
      }
    );
  }

  changeTreeContactShipment1 = (event, strOrderNo) => {
    const strShipmentNo = event.target.value;
    try {
      this.proShowRecordsList = [];
      let url = "Order/proShowRecords?strShipmentNo=" + strShipmentNo + "&strOrderNo=" + strOrderNo;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let qty = 0;
            let totalValue = 0;
            let data12 = (new Function("return [" + data.editList + "]")());

            this.proShowRecordsList = data12[0];
            this.proShowRecordsList.forEach(item => {

              totalValue += item.ITEM_QTY * item.ITEM_PRICE;
              qty += item.ITEM_QTY;
              this.originalValues.push({ ...item });
            });
            this.TotalShipQty = qty;
            this.ShipmentTotal = totalValue;
            this.shipment_status = this.proShowRecordsList[0].SHIPMENT_STATUS;

          } else {

          }
        }
      );
    }
    catch (e) {

    }

  }

  editRow(index: number) {
    this.proShowRecordsList[index].isEditing = true;
  }

  saveRow(index: number) {
    this.proShowRecordsList[index].isEditing = false;
  }

  cancelEdit(index: number) {
    this.proShowRecordsList[index] = { ...this.originalValues[index] };
    this.proShowRecordsList[index].isEditing = false;
  }

  saveAllChanges(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue) {
    let data = [];
    // Implement save logic for all changes here (e.g., update the backend)
    // After saving, reset editing states
    this.proShowRecordsList.forEach(item => {
      data.push({ ...item });
      item.isEditing = false;
    });
    if (data.length > 0) {
      this.editOrderListSearch(orderCode);
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Update and added</p>',
        showConfirmButton: false,
        timer: 2500
      });
      this.closePopup('singleModel');
    }
    else {
      this.editOrderListSearch(orderCode);
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">is not Update and added</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
  }



  sendShipmentData() {
    const tableRows = document.querySelectorAll('#yourTableId tbody tr');
    tableRows.forEach(row => {
      const cells = row.querySelectorAll('td');

      const rowData = {
        ARTICLE_CODE: cells[1].querySelector('input').value,
        REMARKS: cells[2].querySelector('input').value,
        ITEM_CODE: cells[3].querySelector('input').value,
        ITEM_QTY: cells[4].querySelector('input').value,
        ITEM_PRICE: cells[5].querySelector('input').value,
        ITEM_COMM: cells[6].querySelector('input').value,
      };
      //console.log(rowData);
    });
  }

  changeTreeContactShipment = async (event, strOrderNo) => {
    const strShipmentNo = event.target.value;
    try {
      $('#showPopupShipmentDetailsSelectItem').css('display', 'none');
      this.proShowRecordsListGS = [];
      let url = "Order/showRecordsGenerateShipment?strShipmentNo=" + strShipmentNo + "&strOrderNo=" + strOrderNo;
      await this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let overLayCard = [];
            let data12 = (new Function("return [" + data.editList + "]")());

            this.proShowRecordsListGS = data12[0];
            this.proShowRecordsListGS.forEach(item => {
              this.originalValuesGS.push({ ...item });
            });

            this.shipment_status = this.proShowRecordsListGS[0].SHIPMENT_STATUS;

          } else {

          }
        }
      );
    }
    catch (e) {

    }

  }

  proShowRecordsGS = (strShipmentNo, strOrderNo) => {
    
    $('#showPopupShipmentDetailsSelectItem').css('display', 'none');
    this.proShowRecordsListGS = [];
    let url = "Order/showRecordsGenerateShipment?strShipmentNo=" + strShipmentNo + "&strOrderNo=" + strOrderNo;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());

          this.proShowRecordsListGS = data12[0];
          this.sip_mode_shipiment = data12[0][0].SHIPPING_MODE;
          this.proShowRecordsListGS.forEach(item => {
            this.originalValuesGS.push({ ...item });
          });

          this.shipment_status = this.proShowRecordsListGS[0].SHIPMENT_STATUS;

        } else {

        }
      }
    );
  }

  editRowGS(index: number) {
    this.proShowRecordsListGS[index].isEditing = true;
  }

  saveRowGS(index: number) {
    this.proShowRecordsListGS[index].isEditing = false;
  }

  cancelEditGS(index: number) {
    this.proShowRecordsListGS[index] = { ...this.originalValuesGS[index] };
    this.proShowRecordsListGS[index].isEditing = false;
  }

  saveAllChangesGS(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue) {
    let data2 = [];
    debugger
    let url = "Order/GetMaxCode?fieldName="+orderCode;
     this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let overLayCard = [];
            debugger
            let SHIPMENT_SERIAL_NO =  data.count
            this.proShowRecordsListGS.forEach(item => {
              item.isEditing = false;
              data2.push({ ...item });
              let url = "Order/ediGenerateShipment?shipping_mode=" + item.SHIPPING_MODE + "&item_price=" + item.ITEM_PRICE + "&item_qty=" + item.ITEM_QTY + "&ITEM_COMM=" + item.ITEM_COMM + "&item_total=" + item.ITEM_QTY + "&ORDER_CODE=" + item.ORDER_CODE + "&article_code=" + item.ARTICLE_CODE + "&item_code=" + item.ITEM_CODE + "&shipment_seq_unique_id=" + item.SHIPMENT_SEQ_UNIQUE_ID + "&SHIPMENT_SERIAL_NO=" + SHIPMENT_SERIAL_NO;
              this.webService.loadTreeLiat(url).subscribe(
                (data: any) => {
                  //console.log(data);
                }
              );
            });
          }
        }
      );
    if (data2.length > 0) {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Update and added</p>',
        showConfirmButton: false,
        timer: 2500
      });
      this.closePopup('singleModel');
    }
    else {
      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Update and added</p>',
        showConfirmButton: false,
        timer: 2500
      });
      this.closePopup('singleModel');
    }
    //console.log(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue, data);
  }

  // ReportViewPrint = () => {
  //   if (this.order_Code_Sipment === "all_order") {

  //     Swal.fire({
  //       background: '#FFFFFF',
  //       html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
  //       showConfirmButton: false,
  //       timer: 2500
  //     });
  //   }
  //   else {
  //    // let url = "Order/reportView?orderCode=" + this.order_Code_Sipment;
  //     let url = "ReportViewer/singleOrderEnquery?order_no="+this.order_Code_Sipment;
  //     this.webService.loadTreeLiat(url).subscribe(
  //       (data: any) => {
  //         if (data.status === 'OK') {
  //           let overLayCard: any = [];
  //          // let data12 = (new Function("return [" + data.editList + "]")());
  //           let data12 = (new Function("return [" + data.data + "]")());
  //           let totalValue = 0;
  //           let totalqty = 0;
  //           let itemcomm = 0;
  //           let order_code, order_date, buyer_name, supplier_name, article_name, export_date, order_article_code;
  //           data12[0].forEach(element => {
  //             overLayCard += "<tr><td>" + element.order_item_code + "</td><td>" + element.item_mode + "</td><td>" + element.item_qty + "</td><td>" + element.item_price + "</td><td>" + element.item_commission + "</td><td>" + element.total_final_price + "</td></tr>";
  //             totalValue += element.item_price;
  //             totalqty += element.total_final_price;
  //             itemcomm += element.item_commission;
  //             order_code = element.order_item_code;
  //             order_date = element.order_date;
  //             buyer_name = element.buyer_name;
  //             supplier_name = element.supplier_name;
  //             article_name = element.article_name;
  //             export_date = element.export_date;
  //             order_article_code = element.order_article_code;
  //           });
  //           this.tree_edit_list_print = overLayCard;
  //           this.onPreview(overLayCard, totalValue, totalqty, itemcomm, order_code, order_date, buyer_name, supplier_name, article_name, export_date, order_article_code);
  //         } else {

  //         }
  //       }
  //     );
  //   }
  // }

  ReportViewPrint = () => {
    sessionStorage.removeItem('order_code');
    if (this.order_Code_Sipment === "all_order") {

      Swal.fire({
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please select value order</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      // let url = "Order/reportView?orderCode=" + this.order_Code_Sipment;
      let url = "ReportViewer/singleOrderEnquery?order_no=" + this.order_Code_Sipment;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          let dataList = "";
          if (data.status === 'OK') {
            let qty = 0;
            let Price = 0;
            let InvPrice = 0;
            let TotalPrice = 0;
            let data12 = (new Function("return [" + data.data + "]")());
            this.report_list = data12[0];
            this.report_list.forEach(element => {
              //debugger
              //console.log(element.ITEM_PRICE_DETAIL_QTY);
              dataList += "<tr><td>" + element.ORDER_ITEM_CODE + "</td><td>" + element.ITEM_MODE + "</td><td>" + element.ITEM_PRICE_DETAIL_QTY + "</td><td>" + element.ITEM_PRICE + "</td><td>" + element.ITEM_INVOICE_PRICE + "</td><td>" + element.TOTAL_FINAL_PRICE + "</td></tr>";
              // Ensure that the values are parsed as numbers
              const itemQty = parseFloat(element.ITEM_PRICE_DETAIL_QTY);
              const itemPrice = parseFloat(element.ITEM_PRICE);
              const itemInvPrice = parseFloat(element.ITEM_INVOICE_PRICE);
              const itemTotalPrice = parseFloat(element.TOTAL_FINAL_PRICE);

              // Check if the parsed values are valid numbers
              if (!isNaN(itemQty) && !isNaN(itemPrice) && !isNaN(itemInvPrice) && !isNaN(itemTotalPrice)) {
                qty += itemQty;
                Price += itemPrice;
                InvPrice += itemInvPrice;
                TotalPrice += itemTotalPrice;
              }
            });
            this.qtyOrder = qty;
            this.PriceOrder = Price;
            this.InvPriceOrder = InvPrice;
            this.TotalPriceOrder = TotalPrice;
            //console.log(TotalPrice);
            this.tree_edit_list_print = dataList;
            this.onPreview(qty, Price, InvPrice, TotalPrice);
          } else {

          }
        }
      );
    }
  }

  onDelete = async (order_article_code, order_code, Order_Id) => {

    const result = await Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
    //console.log(result);

    if (result.isConfirmed) {
      //console.log(order_article_code + " - " + order_code + " - " + Order_Id);

      let url = "Order/DeleteExportItem?order_code=" + order_code + "&order_id=" + Order_Id + "&order_article_code=" + order_article_code;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.TreeContactItemList(order_code, Order_Id);
            this.TreeContactItemSizeList(order_code, Order_Id);
            Swal.fire({
              icon: 'success',
              iconColor: '#000',
              background: '#FFFFFF',
              title: 'Deletion',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">Deleted successfully</p>',
              showConfirmButton: false,
              timer: 2500
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Deletion',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">Something wrong want</p>',
              showConfirmButton: false,
              timer: 2500
            });
          }
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      // User clicked "No, cancel"
      // Do nothing or show a message
      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        iconColor: '#000',
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Deletion was cancelled</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
  }

  onPreview = (qty, Price, InvPrice, TotalPrice) => {

    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
     
<!DOCTYPE html>
<html>
	<head>
		
		<style type="text/css">
			p {
			font-family: "Times New Roman";
			}
			
			.padding-main-divcls{
			padding: 5px;
			}
			
			.text-center{
			text-align: center
			}
			.width-full{
			width: 100%;
			}
			
			.box{
			border-style: solid;
			border-width: 1px;
			width: 65px;
			height: 100px;
			float: right;
			margin-right: 50px;
			font-size: 10px;
			padding: 5px;
			}
			.box-divcls{
			width: 100%;
			display: inline-block;
			}
			
			.TermsConditionTable, tr , td {
			padding: 4px !important;
			}
			tr, td {
			page-break-inside: avoid !important;
			}
			
			
			.break-after{
			page-break-after: always;
			}
			.top-border-cls{
			border-top: solid black 1.0pt;
			}
			table {
			font-family: arial, sans-serif;
			border-collapse: collapse;
			width: 100%;
			}
			
			td, th {
			border: 0px solid #dddddd;
			text-align: left;
			padding: 8px; 
			}
			
			.custom-list {
			list-style: none; /* Remove default marker */
			padding: 0; /* Remove default padding */
			}
			
			.custom-list li {
			unicode-bidi: isolate;
			font-variant-numeric: tabular-nums;
			text-transform: none;
			text-indent: 0px !important;
			text-align: start !important;
			text-align-last: start !important;
			margin-bottom: 10px; /* Adjust spacing between list items */
			}
			
		</style>
	</head>
	<body onload="window.print();window.close()">
		<div class="card-body border-top pt-20 mt-0">
			<div class="row">
				<div class="col-sm-12">
					<div class="invoice-details">
						<h5 class="mb-10 text-18 text-capitalize"><strong>YOUNG FASHION (INDIA)</strong></h5>
						<div class="invoice-details-inner mt-2">
							<p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
							<br /> INDIA </p>
							<p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
							<p><strong>Email:</strong> mail@youngfashionindia.com</p>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		<div>
			<div class="row" style="margin: 8px 0px 12px 0px">
				<table border="1" cellspacing="0" cellpadding="0" style="border: 1.6px solid #4e4e6d;">
					<tr>
						<th>
							<div class="invoice-details" style="margin-top: 12px;">
								<ul class="custom-list">
									<li><strong>Order No.:</strong> ${this.report_list[0].Order_Code}</li>
									<li><strong>Buyer:</strong> ${this.report_list[0].Buyer_Name}</li>
									<li><strong>Supplier:</strong> ${this.report_list[0].Supplier_Name}</li>
									<li><strong>Dispatch Date:</strong>${this.report_list[0].Dispatch_Date}</li>
									<li><strong>Department:</strong> ${this.report_list[0].Department}</li>
									<li><strong>Consignee:</strong> ${this.report_list[0].Consignee_name}</li>
									<li><strong>Currency:</strong> ${this.report_list[0].Currency_Code}</li>
									<li><strong>LC No.:</strong> ${this.report_list[0].LC_No}</li>
									<li><strong>Delivery Date on LC:</strong> ${this.report_list[0].Delivery_Date_On_LC}</li>
								</ul>
							</div>	
						</th>
						<th>
							<div class="invoice-details" style="margin-top: 12px;">
								<ul class="custom-list">
									<li><strong>Order Date:</strong> ${this.report_list[0].Order_Date}</li>
									<li><strong>Contact Person:</strong>${this.report_list[0].Contact_Person_Name}</li>
									<li><strong>Contact Person:</strong> ${this.report_list[0].Supp_Contact_Person}</li>
									<li><strong>Order Category:</strong>${this.report_list[0].Order_Category}</li>
									<li><strong>Bank:</strong> ${this.report_list[0].Bank_Name}</li>
									<li><strong>PJ No.:</strong> ${this.report_list[0].PJ_No}</li>
									<li><strong>Conversion Rate:</strong>${this.report_list[0].Conversion_Rate}</li>
									<li><strong>LC NR.:</strong> ${this.report_list[0].LC_NR}</li>
									<li><strong>LC Sent On:</strong> ${this.report_list[0].LC_Sent_On}</li>
								</ul>
							</div>
						</th>
					</tr>
				</table>
				
			</div>
		</div>
        <div class="col-sm-12">
			<div class="invoice-details" style="margin-top: 12px;">
				<ul class="custom-list">
					<li><strong>Article Details</strong></li>
				</ul>
			</div>
		</div>
		<table border="1" cellspacing="0" cellpadding="0" style="border: 1.6px solid #fff;">
			<tr>
				<th>
					<strong> ${this.report_list[0].ORDER_ARTICLE_CODE} </strong> <strong> ${this.report_list[0].Order_Code} </strong> ${this.report_list[0].Article_Name}
				</th>
				<th>
				</th>
				<th>
				</th>
			</tr>
		</table>
		<div class="table-responsive invoice-table mb-4 mt-0">
			<table border="1" cellspacing="0" cellpadding="0" style="border: 1.6px solid #4e4e6d;">
				
				<thead>
					<tr>
						<th>Item Size</th>
						<th>Mode</th>
						<th>Qty.</th>
						<th>Price</th>
						<th>Inv. Price</th>
						<th>Total Price</th>
					</tr>
				</thead>
				<tbody>
					${printContents}
				</tbody>
				
				<tfoot style="border: 1.6px solid #4e4e6d;">
					<tr>
						<td></td>
						<td><strong>Totals:</strong></td>
						<td><strong>${qty}</strong></td>
						<td><strong>${Price}</strong></td>
						<td><strong>${InvPrice}</strong></td>
						<td><strong>${TotalPrice}</strong></td>
					</tr>
					
					
				</tfoot>
			</table>
			
		</div>
		<div class="table-responsive invoice-table mb-4 mt-0" style="margin-top: 15px;">
			<table border="1" cellspacing="0" cellpadding="0" style="border-bottom: 1.6px solid #4e4e6d;border-top: 1.6px solid #ffffff;border-left: 1.6px solid #ffffff;border-right: 1.6px solid #ffffff;">
				
				<tr>
					<th><strong>Total Qty. : </strong>${this.qtyOrder}</th>
					<th><strong>Total Comm.:</strong> ${this.report_list[0].Total_Order_Commission} </th>
				</tr>
				<tr>
					<th><strong>Remarks : </strong> ${this.report_list[0].Remarks}</th>
					<th></th>
				</tr>
				<tr>
					<th><strong>Payment Terms:</strong> ${this.report_list[0].PAYMENT_TREMS} </th>
					<th></th>
				</tr>
				
			</table>
			
		</div>
		<div class="table-responsive invoice-table mb-4 mt-0" style="margin-top: 15px;">
			<table border="1" cellspacing="0" cellpadding="0" style="border-bottom: 1.6px solid #fff;border-top: 1.6px solid #ffffff;border-left: 1.6px solid #ffffff;border-right: 1.6px solid #ffffff;">
				
				<tr>
					<td colspan="2"></td>
					<td colspan="4" style="text-align: end;"><strong>For Young Fashion India</strong></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td></td>
					<td></td>
				</tr>
				<tr>
					
					<td colspan="2"></td>
					<th colspan="4" style="text-align: end;"><strong>Authoished Signatory</strong></th>
				</tr>
			</table>
			
		</div>
	</body>
</html>	
      `)
      popupWin.document.close();
    }
  };

  onPreviewGShipement = () => {

    let printContents, popupWin;
    printContents = this.PrintGrenteShipment;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
<html>
	<head>
		
		<style type="text/css">
			p {
			font-family: "Times New Roman";
			}
			
			.padding-main-divcls{
			padding: 5px;
			}
			
			.text-center{
			text-align: center
			}
			.width-full{
			width: 100%;
			}
			
			.box{
			border-style: solid;
			border-width: 1px;
			width: 65px;
			height: 100px;
			float: right;
			margin-right: 50px;
			font-size: 10px;
			padding: 5px;
			}
			.box-divcls{
			width: 100%;
			display: inline-block;
			}
			
			.TermsConditionTable, tr , td {
			padding: 4px !important;
			}
			tr, td {
			page-break-inside: avoid !important;
			}
			
			
			.break-after{
			page-break-after: always;
			}
			.top-border-cls{
			border-top: solid black 1.0pt;
			}
			table {
			font-family: arial, sans-serif;
			border-collapse: collapse;
			width: 100%;
			}
			
			td, th {
			border: 0px solid #dddddd;
			text-align: left;
			padding: 8px; 
			}
			
			
			
		</style>
	</head>
	<body onload="window.print();window.close()">
		<div class="card-body border-top pt-20 mt-0">
			<div class="row">
				<div class="col-sm-12">
					<div class="invoice-details">
						<h5 class="mb-10 text-18 text-capitalize"><strong>YOUNG FASHION (INDIA)</strong></h5>
						<div class="invoice-details-inner mt-2">
							<p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
							<br /> INDIA </p>
							<p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
							<p><strong>Email:</strong> mail@youngfashionindia.com</p>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		<div style="border: 1.6px solid #4e4e6d; text-align: center; font-size: larger;">
			<p class=""><strong>Order Shipment Details</strong></p>
		</div>
		<div class="table-responsive invoice-table mb-4 mt-0">
			
			<table border="1" cellspacing="0" cellpadding="0" style="border: 1.6px solid #4e4e6d;">
			
				<thead>
					<tr>
						<th  colspan="2">Order No: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].ORDER_CODE}</span></th>
						<th>Order Date: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].Order_Date}</span></th>
					</tr>
					<tr>
						<th colspan="2">Buyer: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].Buyer_Name}</span></th>
						<th>Supplier: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].Supplier_Name}</span></th>
					</tr>
					<tr>
						<th colspan="2">Shipment No: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].SHIPMENT_SERIAL_NO}</span></th>
						<th>Mode of Shipment: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].SHIPPING_MODE}</span></th>
						<th>Status: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].SHIPMENT_STATUS}</span></th>
					</tr>
				</thead>
				<thead style="border: 1.6px solid #4e4e6d;">
					<tr>
						<th colspan="4">Item List</th>
					</tr>
				</thead>
				<thead style="border: 1.6px solid #4e4e6d;">
					<tr>
						<th>SNo.</th>
						<th>Article Code</th>
						<th>Description</th>
						<th>Item</th>
						<th>Qty</th>
						<th>Price</th>
						<th>Comm(%)</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					${printContents}
				</tbody>
				
				<tfoot style="border: 1.6px solid #4e4e6d;">
					
					<tr>
						<td colspan="5"></td>
						<td></td>
						<td>Total</td>
						<td>${this.total_final_price_shipment}</td>
					</tr>
					
				</tfoot>

			</table>
			<table style="border: 1.6px solid #4e4e6d; text-align: center; font-size: larger; margin-top: 20px;">
				<thead>
					<tr>
						<th  colspan="4">Agent: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].Shipping_Agent_Name}</span></th>
					</tr>
					<tr>
						<th colspan="2">Bill No.: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].BILL_NO}</span></th>
						<th>Bill Date: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].BILLING_DATE}</span></th>
						<th >Invoice Date: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].INVOICE_DATE}</span></th>
					</tr>
					<tr>
						<th colspan="2">Doc. Sent Date: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].DOC_SENT_ON}</span></th>
						<th>Delivery Date: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].DELIVERY_DATE}</span></th>
						<th >Arrival Date: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].ARRIVAL_DATE}</span></th>
					</tr>
					<tr>
						<th colspan="2">GCS: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].GCS}</span></th>
						<th>Courier No: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].COURIER_NO}</span></th>
						<th>Total Cartons: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].TOTAL_CARTONS}</span></th>
					</tr>
					<tr>
						<th colspan="2">Con Vessel No: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].CON_VESSEL_NO}</span></th>
						<th colspan="2">Container No. : <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].CONTAINER_NO}</span></th>
					</tr>
					<tr>
						<th colspan="2">Remarks: <span style="font-weight: 500;">${this.proShowOrderDetailsList_print[0].REMARKS}</span></th>
						<th  colspan="2"></th>
					</tr>
				</thead>
				 
			</table>
		</div>
	</body>
</html>		
      `)
      popupWin.document.close();
    }
  };

}
