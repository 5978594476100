import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service'; 
import { Router } from '@angular/router';

@Component({
  selector: 'app-multiple-order-enquiry',
  templateUrl: './multiple-order-enquiry.component.html',
  styleUrls: ['./multiple-order-enquiry.component.css']
})
export class MultipleOrderEnquiryComponent implements OnInit {
  show = false;
  fullScreen = true;
  template = '';
  report_list: any = [];
  tree_edit_list_print: any = [];
  quarterList: any = [];
  tree_card_Buyer: any = [];
  tree_card_Suppler: any = [];
  quarterList_article_category: any = [];
  tree_article_master: any = [];
  radioSelected: string = '';
  allSuplier: any = "Select Suplier";
  allbuyer: any = "Select Buyer";
  ItemCategory: any = "Select Item Category";
  ArticleCode: any = "Select Article Code";
  page: number = 1;
  constructor( 
    private webService: WebServiceService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    // this.loadTreeSupplie();
    this.loadTreeSuppler();
    this.loadTreeBuyer();
    this.load_muil_article_master();
    this.load_muil_article_category();
  }

  loadTreeBuyer = () => {
    this.tree_card_Buyer = [];
    let url = "ReportViewer/Buyer";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = dataList[0];
        } else {

        }
      }
    );
  }

  load_muil_article_master = () => {
    this.tree_article_master = [];
    let url = "ReportViewer/muil_article_master";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_article_master = dataList[0];
        } else {

        }
      }
    );
  }

  load_muil_article_category = () => {
    this.quarterList_article_category = [];
    let url = "ReportViewer/muil_article_category";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.quarterList_article_category = dataList[0];
        } else {

        }
      }
    );
  }

  redirectToLink(order_code) {
    sessionStorage.setItem('order_code', order_code);
    // Redirect to the desired link or route
    this.router.navigate(['/OrderDetails']);  // Replace 'your-route' with the actual route you want to navigate to
  }

  loadTreeSuppler = () => {
    this.tree_card_Suppler = [];
    let url = "ReportViewer/Suppler";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            this.tree_card_Suppler = dataList[0];
          }
          else {
            this.tree_card_Suppler = [""];
          }


        } else {

        }
      }
    );
  }

  loadTreeSupplie = async (OrderNoContains, Buyer, Supplier, ItemCategory, ArticleCode) => {
    this.show = true;
    console.log('Selected Status:', this.radioSelected);
    this.report_list = [];
    let Order1,Buyer1,Supplier1,ItemCategory1,ArticleCode1;
    debugger
    if (OrderNoContains == "") {
      Order1 ="NoData";
    }
    else{
      Order1 = OrderNoContains;
    }
    if (Buyer == "Select Buyer") {
      Buyer1 ="NoData";
    }
    else{
      Buyer1 =Buyer;
    }
    if (Supplier == "Select Supplier") {
      Supplier1 = "NoData";
    }
    else{
      Supplier1 =Supplier;
    }
    if (ItemCategory == "Select Item Category") {
      ItemCategory1 = "NoData";
    }
    else{
      ItemCategory1 =ItemCategory;
    }
    if (ArticleCode == "Select Article Code") {
      ArticleCode1 = "NoData";
    }
    else{
      ArticleCode1 =ArticleCode;
    }
    if (this.radioSelected == "") {
      this.show = false;
      alert('please select item');
    }
    else {
      let url = "ReportViewer/multipleOrderEnquery?order_no="+Order1+"&buyer="+Buyer1+"&supplier="+Supplier1+"&itemCatgery="+ItemCategory1+"&articleCode="+ArticleCode1+"&statusFilter=" + this.radioSelected;
      await this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          let dataList = "";
          if (data.status === 'OK') {
            debugger
            let data12 = (new Function("return [" + data.data + "]")());
            this.report_list = data12[0];
            this.report_list.forEach(element => {
              dataList += "<tr><td>" + element.Buyer_Code + "</td><td>" + element.Buyer_Name + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.Phone + "</td><td>" + element.Fax + "</td></tr>";
            });
            this.tree_edit_list_print = dataList;
            this.show = false;
          } else {

          }
        }
      );
    }

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Addrss</th>
		      <th>Phone</th>
          <th>Fax</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
