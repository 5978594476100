import { Component, OnInit } from '@angular/core'; 
import { WebServiceService } from 'src/app/services/web-service.service';

@Component({
  selector: 'app-generate-shipments',
  templateUrl: './generate-shipments.component.html',
  styleUrls: ['./generate-shipments.component.css']
})
export class GenerateShipmentsComponent implements OnInit {
  ShipmentTotal:any;
  TotalShipQty:any;
  shipment_status:any;
  ArticleCodeColor: any;
  order_Code_Sipment: any;
  supplier_shipment: any;
  buyer_shipment: any;
  CountUserSip:any=[];
  proShowRecordsList: any = [];
  proShowOrderColorDetalls: any = [];
  proShowOrderDetailsListColorDetalls: any = [];
  shipment_serialList: any = [];
  proFillModeList: any = [];
  originalValues:any=[];
  constructor( 
    private webService: WebServiceService,
  ) { }

  ngOnInit(): void {
  }

  changeTreeContactShipment = (event, strOrderNo) => {
    let strShipmentNo = event.target.value;
    this.proshowPopupGenerateShipmentColorDetalls(strOrderNo, strShipmentNo);

  }

  TreeContactBuyer = (event: any) => {

  }

  showPopupShipmentDetailsSelectItem:any=[];
  showShipmentDetailsSelectItem = (order_code) => {
    this.showPopupShipmentDetailsSelectItem = [];
    let url = "Order/showPopupShipmentDetailsSelectItem?order_code=" + order_code
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());
          debugger
          if(data12[0].length > 0){
            this.showPopupShipmentDetailsSelectItem = data12[0];
            this.CountUserSip =data12[0].length;
            this.showPopupShipmentDetailsSelectItem.forEach((item, index) => {
              this.proShowRecordsList.push(
                { 
                  id:index+1,
                  order_article_code: item.order_article_code,
                  item_description: item.item_description,
                });
              });
            $('#showPopupShipmentDetailsSelectItem').css('display','block');
          }
          else{
            this.showPopupShipmentDetailsSelectItem =[];
          }
          
          //this.proShowRecordsGS(this.shipment_serialList[0].shipment_serial_no,this.order_Code_Sipment)
        } else {
 
        }
      }
    );
  }


  proshowPopupGenerateShipmentColorDetalls = (codedata, sid) => {
    this.proShowOrderDetailsListColorDetalls = [];
    let url = "ColorDetails/ItemList?order_code=" + codedata;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.editList + "]")());
          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              this.proShowOrderDetailsListColorDetalls.push(
                {
                  id: index + 1,
                  order_article_code: item.ORDER_ARTICLE_CODE,
                  item_description: item.Item_Description,
                });
            });
            this.ArticleCodeColor = this.proShowOrderDetailsListColorDetalls[0].order_article_code;
            // this.showOrderColorDetalls (codedata, sid, this.proShowOrderDetailsListColorDetalls[0].order_article_code);
          } else {
            // this.order_Code_Sipment=this.order_Code_Sipment;
            // this.buyer_shipment="";
            // this.supplier_shipment="";
            //this.shipment_serial(this.order_Code_Sipment);
          }

        } else {
          //  this.shipment_serial(code);
        }
      }
    );

    //  this.shipment_serial_GS(codedata);
  }


  changeTreeContactShipment1 = (event, strOrderNo) => {
    const strShipmentNo = event.target.value;
    try {
      this.proShowRecordsList = [];
      let url = "Order/proShowRecords?strShipmentNo=" + strShipmentNo + "&strOrderNo=" + strOrderNo;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let overLayCard = [];
            let qty = 0;
            let totalValue = 0;
            let data12 = (new Function("return [" + data.editList + "]")());
            debugger
            this.proShowRecordsList = data12[0];
            this.proShowRecordsList.forEach(item => {
              debugger
              totalValue += item.ITEM_QTY * item.ITEM_PRICE;
              qty += item.ITEM_QTY;
              this.originalValues.push({ ...item });
            });
            //this.TotalShipQty = qty;
            //this.ShipmentTotal = totalValue;
            //this.shipment_status = this.proShowRecordsList[0].SHIPMENT_STATUS;

          } else {

          }
        }
      );
    }
    catch (e) {

    }

  }

  editRow(index: number) {
    this.proShowRecordsList[index].isEditing = true;
  }

  saveRow(index: number) {
    this.proShowRecordsList[index].isEditing = false;
  }

  cancelEdit(index: number) {
    this.proShowRecordsList[index] = { ...this.originalValues[index] };
    this.proShowRecordsList[index].isEditing = false;
  }

  saveAllChanges(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue) {
    let data = [];
    // Implement save logic for all changes here (e.g., update the backend)
    // After saving, reset editing states
    this.proShowRecordsList.forEach(item => {
      data.push({ ...item });
      item.isEditing = false;
    });
    if (data.length > 0) {
      alert('Update and added')
    }
    else {
      alert('is not Update and added')
    }
    console.log(shipmentStatus, orderCode, ShipmentNo, ModeOfShipment, BuyerValue, SupplierValue, data);
  }

}
