<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
  .pagination {
    margin: -15px 0;
    display: flex;
    justify-content: center;

  }

  .ngx-pagination {
    margin-left: 0 !important;
    margin-bottom: 1rem !important;
    line-height: 20px !important;
    padding: 7px 15px 7px !important;
    font-size: 12px !important;
  }

  .ngx-pagination::before,
  .ngx-pagination::after {
    content: " " !important;
    display: table !important;
  }

  .ngx-pagination::after {
    clear: both !important;
  }

  .ngx-pagination li {
    margin-right: 0.2rem !important;
    border-radius: 0 !important;
  }

  .ngx-pagination li {
    display: inline-block !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    color: #0a0a0a !important;
    display: block !important;
    background-color: rgb(248, 248, 248) !important;
    color: black !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: #788b8d88 !important;
  }

  .ngx-pagination .current {
    padding: 0.1875rem 0.625rem !important;
    background: #2199e8 !important;
    color: #fefefe !important;
    cursor: default !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination .disabled {
    padding: 0.1875rem 0.625rem !important;
    color: #cacaca00 !important;
    cursor: default !important;
  }

  .ngx-pagination .disabled:hover {
    background: transparent !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    cursor: pointer !important;
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    font-family: FontAwesome;
    content: "\f053" !important;
    display: inline-block !important;
    margin-right: 0rem !important;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    font-family: FontAwesome !important;
    content: "\f054" !important;
    display: inline-block !important;
    margin-left: 0rem !important;
  }

  .ngx-pagination .show-for-sr {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  .ngx-pagination .small-screen {
    display: none !important;
  }

  @media screen and (max-width: 601px) {
    .ngx-pagination.responsive .small-screen {
      display: inline-block !important;
      color: black !important;
      background-color: rgb(248, 248, 248) !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
    }

    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
      display: none !important;
    }
  }

  .table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-left: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  tr td {
    border-color: #dddddd00 !important;
  }

  input[type=checkbox],
  input[type=radio] {
    margin: 17px 3px 0px 16px;
    margin-top: 1px\9;
    line-height: normal;
  }

 
</style>
<main class="container">
  <div>
    <div class="table-responsive invoice-table mb-4 mt-0" style="padding: 20px 200px 20px 200px;">
      <div class="container" style="border: 1px solid #e5e5e5;">
        <div class="title"
          style=" margin-top: -58px; font-size: x-large; background-color: aliceblue; padding-left: 10px; width: 195px; ">
          Trunover Report</div>
        <div class="content">
          <form action="#">
            <div class="user-details">
              <div class="input-box">
                <span class="details">From Date</span>
                <input type="date" #FromDate placeholder="From Date" required style=" width: 100%; " />
              </div>
              <div class="input-box">
                <span class="details">To Date</span>
                <input type="date" #ToDate placeholder="To Date" required style=" width: 100%; " />
              </div>
              <div class="input-box">
                <span class="details">Currency Code</span>

                <select #CurrencyCode class="search required" data-placeholder="Choose a Currency"
                  style="width: 100%;top: 5px;height: auto;padding: 6px;">
                  <option [ngValue]="allcurency">all</option>
                  <option *ngFor="let size of SizeCurrencyList" [ngValue]="size.Currency_Code">
                    {{size.Currency_Name}}
                  </option>
                </select>
              </div>
              <div class="input-box">
                <span class="details">Buyer</span>
                <app-multi-select-dropdown [list]="quarterList" (shareCheckedList)="shareCheckedList($event)"
                  (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                </app-multi-select-dropdown>

              </div>
              <div class="input-box">
                <span class="details">Supplier</span>
                <app-multi-select-dropdown [list]="tree_card_Suppler"
                  (shareCheckedList)="shareCheckedListSuplier($event)"
                  (shareIndividualCheckedList)="shareIndividualCheckedListSuplier($event)">
                </app-multi-select-dropdown>
              </div>
              <div class="input-box">
                <input type="radio" [(ngModel)]="readioSelected" value="SupplierReport" id="SupplierReport"
                  name="fav_language" />
                  <label for="SupplierReport">Supplier Wise</label>
                  <input type="radio" [(ngModel)]="readioSelected" value="BuyerReport" id="BuyerReport"
                  name="fav_language" />
                  <label for="BuyerReport">Buyer Wise</label>
              </div>
            </div>
            <div class="button"
              style=" background-color: white; color: black; border: 0px solid #008CBA; padding: 0px 0px; text-align: center; text-decoration: none; display: inline-block; font-size: 12px; margin: 0px 0px; transition-duration: 0.4s; cursor: pointer; border-radius: 8px; ">
              <button type="button" (click)="searchValueitem(FromDate.value,ToDate.value,CurrencyCode.value)"
                value="Search" style="color: white;font-size: larger;">Search</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>

  <div *ngIf="isCheckPage1">
    <!-- invoice Description -->
    <div *ngIf="this.report_list.length > 0">
      <a href="javascript:window.print()" class="tm_invoice_btn tm_color1">
        <span class="tm_btn_icon">
          <svg xmlns="http://www.w3.org/2000/svg" style="width: 34px;" class="ionicon" viewBox="0 0 512 512">
            <path
              d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
              fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
            <rect x="128" y="240" width="256" height="208" rx="24.32" ry="24.32" fill="none" stroke="currentColor"
              stroke-linejoin="round" stroke-width="32" />
            <path d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24" fill="none"
              stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
            <circle cx="392" cy="184" r="24" fill='currentColor' />
          </svg>
        </span>
      </a>
      <button printSectionId="mytodosItemsId" ngxPrint>Print</button><span><button
          style="margin-left: 12px;margin-bottom: 12px;" type="button" (click)="closePage()">Close</button></span>
      <div id="mytodosItemsId">
        <div class="card-body border-top pt-20 mt-0">
          <div class="row">
            <div class="col-sm-12">
              <div class="invoice-details">
                <h5 class="mb-10 text-18 text-capitalize"><strong>YOUNG FASHION (INDIA)</strong></h5>
                <div class="invoice-details-inner mt-2">
                  <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                    <br /> INDIA
                  </p>
                  <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
                  <p><strong>Email:</strong> mail@youngfashionindia.com</p>
                </div>
              </div>
              <hr style="height: 1px; margin: 1px; border-bottom: 2px solid #67676a;" />
            </div>
            <div class="col-sm-12">
              <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Yearly Summary Report
                  ({{showcontentLView}})</strong></h5>
            </div>
            <div class="col-sm-6">
              <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>onwards {{Onword}} in
                  {{curency}}</strong></h5>
            </div>
            <div class="col-sm-6">
              <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Run Date:
                  {{rundate}}</strong>
              </h5>
            </div>
          </div>
        </div>

        <!-- invoice Table -->
        <div class="table-responsive invoice-table mb-4 mt-0">
          <table class="table" style="border: 2px solid #67676a;background: white !important;">
            <thead>
              <tr>
                <th>Buyer Name</th>
                <th>Total No. Of Orders</th>
                <th>Total Quantity</th>
                <th>Total Value</th>
                <th>Avg. Price</th>
                <th>%age Of TurnOver</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.report_list">
                <td>{{item.BuyerName}}</td>
                <td>{{item.TotalNoOfOrders}}</td>
                <td>{{item.TotalQuantity}}</td>
                <td>{{item.TotalValue}}</td>
                <td>{{item.AvgPrice}}</td>
                <td>{{item.AgeOfTurnOver}}</td>

              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <figure class="highcharts-figure">
              <div id="pie-chart"></div>
              <div id="sliders">
                <table>
                  <tr>
                    <td><label for="alpha">Alpha Angle</label></td>
                    <td>
                      <input id="alpha" type="range" min="0" max="90" value="45" />
                      <span id="alpha-value" class="value"></span>
                    </td>
                  </tr>
                  <tr>
                    <td><label for="beta">Beta Angle</label></td>
                    <td>
                      <input id="beta" type="range" min="0" max="90" value="0" />
                      <span id="beta-value" class="value"></span>
                    </td>
                  </tr>
                </table>
              </div>
            </figure>
            <div style="display: block;width: 700px;margin-left: 200px;">
              <!-- <canvas baseChart *ngIf="pieChartData"
            [data]="pieChartData" 
            [labels]="pieChartLabels" 
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins"
            [legend]="pieChartLegend">
          </canvas> -->
              <div id="containerData"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</main>