import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;
@Component({
  selector: 'app-item-price',
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.css']
})
export class ItemPriceComponent implements OnInit {
  fullScreen = true; 
  template = ''; 
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  iconColor: any
  borderColor: any;
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';
  isReadonly= false;
  articalName:'';
  show=false;
  name = "Angular " + VERSION.major;
  page: number = 1;
  tree_card_Buyer: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_card_OrderDropdown:any=[];
  tree_Item_List:any=[];
  tree_Order_Details:any=[];
  selectedAritacleName:any;
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  selectedUser = null;
  selectedSize = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  isCheckDataBase: boolean = false;
  SizeMasterList: any = [];
  ItemCategoryList: any = [];
  progress: number = 0;
  selectedMode:any;
  articalCode:any;
  uploadForm = new FormGroup({
    ItemCode: new FormControl('', Validators.required),
    mode: new FormControl('', Validators.required),
    CostPrice: new FormControl('', Validators.required),
    SellingPrice: new FormControl('', Validators.required),
  });
  isReadItemCode = false;
  constructor( 
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private _fb: FormBuilder,
    private httpClient: HttpClient
  ) {
    this.show = true;
     
  }

  
  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  closePopup() {
      this.show = false;
      $('#modalvIEW').modal('hide');
     }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  loadTreeBuyer = () => {
    let url = "ItemPrice/List_Price";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
 
        let dataList = "";
        if (data.status === 'OK') {
          this.uploadForm.reset();
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = data12[0];
        } else {

        }
      }
    );
  }

  editOrderList = (Acode,name) => {
    this.show = false;
    try {
      this.articalName = name;
      this.articalCode =Acode;
      let data = {
        Item_Code: Acode
      }
      this.tree_Order_Details = [];
      let url = "ItemPrice/FillTree";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.data + "]")());
            this.tree_Order_Details = data12[0];
            this.isReadonly = true;
            $('#modalvIEW').modal('hide');
            // this.uploadForm.patchValue({
            //   OrderNo: code
            // });
            
           // this.uploadForm.get('ConvRate').updateValueAndValidity();
          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }
  options2 = {
    allowDrag: node => {
      return node.data.isDraggable;
    },
    allowDrop: (element, { parent, index }) => {
      // return true / false based on element, to.parent, to.index. e.g.
      //console.log(parent.children?.includes(element));
      return parent.children?.includes(element);
    },
    getNodeClone: (node) => ({
      ...node.data,
      id: 1111,
      name: `copy of ${node.data.name}`
    })
  };
  nodes = [
    {
      id: 1,
      name: "root1",
      type: 'folder',
      children: [{ id: 2, name: "child1" }, { id: 3, name: "child2" }]
    },
    {
      id: 4,
      name: "root2",
      type: 'folder',
      children: [
        { id: 5, name: "child2.1", isDraggable: true },
        { id: 51, name: "child2.1.1", isDraggable: true },
        { id: 52, name: "child2.1.2", isDraggable: true },
        { id: 53, name: "child2.2.1", isDraggable: true },
        { id: 54, name: "child2.2.2", isDraggable: true },
        { id: 55, name: "child2.3.1", isDraggable: true },
        { id: 56, name: "child2.3.2", isDraggable: true },
        { id: 57, name: "child2.4.1", isDraggable: true },
        { id: 58, name: "child2.5.2", isDraggable: true },
        {
          id: 6,
          name: "child2.2",
          type: 'folder',
          isDraggable: true,
          children: [{ id: 7, name: "subsub" }]
        }
      ],
      isExpanded: true
    }
  ];
  options = {};

  scroll(tree) {
    //console.log(tree);
    tree.treeModel.getNodeById(6).focus();
    tree.treeModel.getNodeById(6).scrollIntoView();
  }

  ngOnInit(): void {
    //this.loadTreeBuyer(); 
    let url = "ItemPrice/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_card_OrderDropdown = dataList[0];
          this.show = false;
        } else {
          this.show = false;
        }
      }
    );
  }

  viewDialog = () => {
    debugger
    this.show = false;
    if(this.tree_card_OrderDropdown.length > 0){
      $('#modalvIEW').modal('show');
     // $('.modal-backdrop').remove();
      //this.show = false;
    }

  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.selectedSize = null;
    this.uploadForm.reset();
  }

  TreeListBinding = (Item_Code,ITEM_PRICE_MODE) => {
   
    this.loadTreeBuyer()
    try {
      let data = {
        Item_Code: Item_Code,
        Mode_price:ITEM_PRICE_MODE
      } 
      this.selectedUser = null;
      this.selectedSize = null;
      this.addIn = false;
      this.addvlaue = "Add";
      this.tree_edit_list = [];
      let url = "ItemPrice/EditList";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            
            let data12 = (new Function("return [" + data.editList + "]")());
            this.isReadItemCode = true;
            this.tree_edit_list = data12[0][0];
            this.buyerCode= Item_Code;
            this.uploadForm = this._fb.group({
              ItemCode: [this.tree_edit_list.ITEM_CODE],
              mode: [this.tree_edit_list.ITEM_PRICE_MODE],
              CostPrice: [this.tree_edit_list.ITEM_COST_PRICE],
              SellingPrice: [this.tree_edit_list.ITEM_SELLING_PRICE],
             
            });
           
          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }


  CodeAlredyExist = "";
  CodeCheckUnique(code) {
    // let url = "Buyer/EditBuyerCodeAndNameList";
    // this.webService.loadTreetListEdit(url, code).subscribe(data => {
    //   if (data.status === 'OK') {
    //     this.CodeAlredyExist = "Code Alredy Exist, Please another code ";
    //   }
    //   else {
    //     this.CodeAlredyExist = "";
    //   }
    // });
  }

  reset() {
    this.uploadForm.reset();
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/item/master']);
    });
  }

  onSubmit() {
    if (this.uploadForm.valid) {
      debugger
      let data = {
        item_Code: this.uploadForm.value.ItemCode.toString(),
        price_Mode: this.uploadForm.value.mode.toString(),
        cost_Price: this.uploadForm.value.CostPrice.toString(),
        selling_price: this.uploadForm.value.SellingPrice.toString(),
      }
      let url = "ItemPrice/Edit";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            this.editOrderList (this.articalCode,this.articalName);
            this.TreeListBinding(this.uploadForm.value.ItemCode,this.uploadForm.value.ItemCode) 
            //this.uploadForm.reset();
            alert('Added')
            //  this.myForm.resetForm();
             } else {
            alert('Not Edited')
          }
        }
      );
    }
    else {
      this.validateAllFormFields(this.uploadForm);
    }

  }
 
  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Value</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
