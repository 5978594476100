import { Component, OnInit } from '@angular/core'; 
import { WebServiceService } from '../../services/web-service.service';
import { NavigationEnd, Router } from '@angular/router';
declare var $: any;
//declare var feather:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [[WebServiceService]]
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  
  constructor( 
    private webService: WebServiceService,
    private router: Router, 
  ) { 
  }
  loggedIn: boolean = false;
  userName: string = "";
  mobileNo: string = "";
  password: string = "";

  loadWindow() {
    if ($('.link-btn__book').length > 0) {
      $('.site-footer').css({ 'padding-bottom': '60px' });
    }
  }

  scrollWindow = () => {
    var header = $('.site-header');
    if (header.length > 0) {
      if ($(window).scrollTop() > 80) {
        header.addClass('sticky');
      } else {
        header.removeClass('sticky');
      }
    }

    if ($('.link-btn__book').length > 0) {
      var bookButton = $('.link-btn__book');
      if ($(window).scrollTop() > (parseInt($('.link-btn__checkout').offset().top) - 50)) {
        bookButton.addClass('reveal');
      } else {
        bookButton.removeClass('reveal');
      }
    }
  }
  servicesDropdownOpen: boolean = false;
  nestedDropdownOpen: boolean = false;
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveLinks();
      }
    });
    if (this.webService.isAuthenticated()) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

  }
 
  updateActiveLinks() {
    const current = this.router.url.split('/')[2]; // Get the current route path segment
    const navItems = document.querySelectorAll('.nav li a') as NodeListOf<HTMLAnchorElement>;
    navItems.forEach(item => {
      if (item.href.indexOf(current) !== -1) {
        item.parentElement?.classList.add('active');
      } else {
        item.parentElement?.classList.remove('active');
      }
    });
  }

  logoutData() {
    this.webService.logout();
    this.loggedIn = false;
    console.log("logging out")
    if (navigator.userAgent.match(/Android/i)) {
      console.log("redirecting for android");
      location.assign('/')
    }
    else
      window.location.replace("/");
  }


}
