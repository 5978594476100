import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable, throwError
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { TableCell } from '../components/util/table-cell';
import { TableRow } from '../components/util/table-row';

import 'datatables.net';
import 'datatables.net-buttons';

@Injectable({ providedIn: "root" })

export class WebServiceService {
  serverURL = environment.api_url;
  errorData: {};
  private readonly USERNAME = '';
  tableHeadersObjects: TableCell[] = [];
  tableRowsObjects: TableRow[] = [];
  dataType = [];

  isEditing: TableRow[] = [];

  constructor(
    private router: Router,
    private http: HttpClient
  ) {

  }

  private showHeaderFooterSubject = new BehaviorSubject<boolean>(false);
  showHeaderFooter$ = this.showHeaderFooterSubject.asObservable();

  setAuthStatus(isAuthenticated: boolean) {
    this.showHeaderFooterSubject.next(isAuthenticated);
  }

  AddFileDetails(data: FormData): Observable<string> {

    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');
    const httpOptions = { headers: headers };

    return this.http.post<string>(environment.api_url + '/DocFileUpload/AddFileDetails',
      data, httpOptions);
  }

  downloadFile(itemCode,fileName): Observable<Blob> {
    const url = environment.api_url+`/DocFileUpload/downloadFileDetails?itemCode=${encodeURIComponent(itemCode)}&filename=${encodeURIComponent(fileName)}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  AddFileDetails1(data: FormData): Observable<string> {

    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');
    const httpOptions = { headers: headers };

    return this.http.post<string>(environment.api_url + '/DocFileUpload/AddFileDetailsDatabase',
      data, httpOptions);
  }

  getData(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/ReportViewer/priceEnquiry`);
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private authToken: string = ""; // contains user's authentication token

  private showHeaderFlag = true;
  private showFooterFlag = true;

  hideHeader() {
    this.showHeaderFlag = false;
  }

  showHeader() {
    this.showHeaderFlag = true;
  }

  hideFooter() {
    this.showFooterFlag = false;
  }

  showFooter() {
    this.showFooterFlag = true;
  }

  createTable(headers, content, dataType) {

    this.createHeaders(headers, dataType);

    let tableCells: TableCell[] = [];

    if (content.length > 0) {
      for (const row of content) {
        for (const cell of row) {
          tableCells.push(
            new TableCell(cell),
          );
        }
        this.tableRowsObjects.push(new TableRow(tableCells));
        tableCells = [];
      }
    }
  }

  createTableWithIds(headers, content, dataType) {

    this.createHeaders(headers, dataType);

    let tableCells: TableCell[] = [];

    if (content.length > 0) {
      for (const row of content) {
        for (let i = 1; i < row.length; i++) {
          tableCells.push(
            new TableCell(row[i]),
          );
        }
        this.tableRowsObjects.push(new TableRow(tableCells, row[0]));
        tableCells = [];
      }
    }
  }

  addRow() {
    const newCells: TableCell[] = [];
    let newRow: TableRow;
    for (let i = 0; i < this.tableHeadersObjects.length; i++) {
      switch (this.dataType[i]) {
        case 'boolean':
          newCells.push(new TableCell(false));
          break;
        default:
          newCells.push(new TableCell(''));
      }
    }
    this.tableRowsObjects.push(
      newRow = new TableRow(newCells, -1)
    );

    this.isEditing.push(newRow);
  }

  editRow(selectedRow: TableRow) {
    this.isEditing.push(selectedRow);
  }

  saveRow(selectedRow: TableRow) {
    this.isEditing = this.isEditing.filter(temporalRow => temporalRow !== selectedRow);
  }

  cancelEdition(selectedRow: TableRow) {
    this.tableRowsObjects = this.tableRowsObjects.filter(temporalRow => temporalRow !== selectedRow);
    this.isEditing = this.isEditing.filter(temporalRow => temporalRow !== selectedRow);
    for (const cell of selectedRow.cells) {
      if (cell.content == null || cell.content === '') {
        this.tableRowsObjects = this.tableRowsObjects.filter(err => err !== selectedRow);
      } else if (selectedRow.id === -1) {
        this.tableRowsObjects = this.tableRowsObjects.filter(err => err !== selectedRow);
      }
    }
  }

  deleteRow(selectedRow: TableRow) {
    this.isEditing = this.isEditing.filter(temporalRow => temporalRow !== selectedRow);
    this.tableRowsObjects = this.tableRowsObjects.filter(temporalRow => temporalRow !== selectedRow);
  }

  checkTypeOf(value: any): string {
    if (typeof (value) === 'boolean') {
      return 'boolean';
    }
    return '';
  }

  private createHeaders(headers, dataType) {
    for (const obj of headers) {
      this.tableHeadersObjects.push(
        new TableCell(obj)
      );

      this.dataType = dataType;
    }
  }

  loginUser(mobileNo, password) {
    return this.http.post(this.serverURL + '/User/Login',
      {
        userName: mobileNo, password: password
      })
      .pipe(
        catchError(err => {
          return err;
        }),
        map((response: any) => {
          if (response.status === "OK") {
            sessionStorage.setItem('username', mobileNo);
            sessionStorage.setItem(this.USERNAME, JSON.stringify(response));
          }
          return response;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    sessionStorage.removeItem('username');
    sessionStorage.removeItem(this.USERNAME);
    //this.userSubject.next(null);
  }

  isAuthenticated(): boolean {
    const checkUser = sessionStorage.getItem('username');
    if (checkUser) {
      return true;
    }
    else {
      return false;
    }
    //return !!sessionStorage.getItem(this.USERNAME);
  }

  logoutUser(mobileNo, password) {
    return this.http.post(this.serverURL + '/api/auth/v1/logoutFromDevice', { mobileNo: mobileNo, password: password }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  loadTreeLiat = (url) => {

    return this.http.get<any[]>(`${environment.api_url}/${url}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  loadTreetListEdit = (url, code) => {
    return this.http.post(`${environment.api_url}/${url}`, { code: code }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  loadPost = (url, data) => {
    return this.http.post(`${environment.api_url}/${url}`, data).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  loadPostFile342(url, FormData): Observable<string> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(`${environment.api_url}/${url}`, FormData, httpOptions);
  }

  loadPostFile(url, formData) {

    const body = JSON.stringify(formData);
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'multipart/form-data')
    };

    return this.http.post(`${environment.api_url}/${url}`, body, options)
      .pipe(
        map(res => res)
        , catchError(this.handleError.bind(this))
      );
  }

  ImageUpload(url,
    ArticleNo: string,
    Item_Code: string,
    Description: string,
    Composition: string,
    Fabric: string,
    Size: string,
    SamplingItem: string,
    item_sequence: string,
    GSM: string,
    Item_Category: string,
    supplier_ref_no: string,
    buyer_article_no: string,
    profileImage: File
  ): Observable<any> {

    var formData: any = new FormData();
    formData.append('Item_Code', Item_Code);
    formData.append('Article_Code', ArticleNo);
    formData.append('Article_Category_Code', Item_Category);
    formData.append('Item_Size', Size);
    formData.append('SamplingItem', SamplingItem);
    formData.append('Article_Name', Description);
    formData.append('Item_Description', Description);
    formData.append('Composition', Composition);
    formData.append('Item_Category', Item_Category);
    formData.append('GSM', GSM);
    formData.append('Buyer_Ref_No', buyer_article_no);
    formData.append('Supplier_Ref_No', supplier_ref_no);
    formData.append('Fabric', Fabric);
    formData.append('ITEM_SEQUENCE', item_sequence);
    formData.append('ITEM_IMAGE', profileImage);

    return this.http
      .post(`${environment.api_url}/${url}`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.errorMgmt));
  }

  OrderByUpload(url,
    OrderNo: string,
    buyer: string,
    bContactPerson: string,
    Department: string,
    Supplier: string,
    sContactPerson: string,
    Bank: string,
    Consignee: string,
    Currency: string,
    ConvRate: string,
    PJNo: string,
    LCNo: string,
    LCDate: string,
    DelDateOnLC: string,
    LCSentOn: string,
    Category: string,
    OrderDate: string,
    OrderCompleted: string,
    DispatchDate: string,
    OrderCancelled: string,
    TotalQuentity: string,
    OrderComm: string,
    RolltexComm: string,
    YFIComm: string,
    TotalOrderValue: string,
    SizeRange: string,
    Status: string,
    oDate: string
  ): Observable<any> {

    var formData: any = new FormData();
    formData.append('OrderNo', OrderNo);
    formData.append('buyer', buyer);
    formData.append('bContactPerson', bContactPerson);
    formData.append('Department', Department);
    formData.append('Supplier', Supplier);
    formData.append('sContactPerson', sContactPerson);
    formData.append('Bank', Bank);
    formData.append('Consignee', Consignee);
    formData.append('Currency', Currency);
    formData.append('ConvRate', ConvRate);
    formData.append('PJNo', PJNo);
    formData.append('LCNo', LCNo);
    formData.append('LCDate', LCDate);
    formData.append('DelDateOnLC', DelDateOnLC);
    formData.append('LCSentOn', LCSentOn);
    formData.append('Category', Category);
    formData.append('OrderDate', OrderDate);
    formData.append('OrderCompleted', OrderCompleted);
    formData.append('DispatchDate', DispatchDate);
    formData.append('OrderCancelled', OrderCancelled);
    formData.append('TotalQuentity', TotalQuentity);
    formData.append('OrderComm', OrderComm);
    formData.append('RolltexComm', RolltexComm);
    formData.append('YFIComm', YFIComm);
    formData.append('TotalOrderValue', TotalOrderValue);
    formData.append('SizeRange', SizeRange);
    formData.append('Status', Status);
    formData.append('oDate', oDate);

    return this.http
      .post(`${environment.api_url}/${url}`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  ImageUploadDataBase(url,
    ArticleNo: string,
    Item_Code: string,
    Description: string,
    Composition: string,
    Fabric: string,
    Size: string,
    SamplingItem: string,
    item_sequence: string,
    GSM: string,
    Item_Category: string,
    supplier_ref_no: string,
    buyer_article_no: string
  ): Observable<any> {

    var formData: any = new FormData();
    formData.append('Item_Code', Item_Code);
    formData.append('Article_Code', ArticleNo);
    formData.append('Article_Category_Code', Item_Category);
    formData.append('Item_Size', Size);
    formData.append('SamplingItem', SamplingItem);
    formData.append('Article_Name', Description);
    formData.append('Item_Description', Description);
    formData.append('Composition', Composition);
    formData.append('Item_Category', Item_Category);
    formData.append('GSM', GSM);
    formData.append('Buyer_Ref_No', buyer_article_no);
    formData.append('Supplier_Ref_No', supplier_ref_no);
    formData.append('Fabric', Fabric);
    formData.append('ITEM_SEQUENCE', item_sequence);

    return this.http
      .post(`${environment.api_url}/${url}`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.errorMgmt));
  }

  reportDatatable = (url, columnsData, table_name) => {

    let dataTableConfig = {
      ajax: `${environment.api_url}/${url}`,
      columns: columnsData,
      columnDefs: [
        { sortable: false, targets: [-1] }
      ],
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
      language: {
        paginate: {
          first: ' ',
          previous: ' ',
          next: ' ',
          last: ' '
        },
        lengthMenu: 'Records per page: _MENU_',
        info: 'Total of _TOTAL_ records (showing _START_ to _END_)',
        infoFiltered: '(filtered from _MAX_ total records)'
      }
    };
  //  $(table_name).DataTable(dataTableConfig);
    const table: any = $(table_name).DataTable(dataTableConfig);

  // Event listener for the print button
  $('#print-button').on('click', function () {
    // Print the DataTable content
    table.button('.buttons-print').trigger();
  });
  }

  UpdateItemSize(url,
    p_value: string,
    p_id: number
  ): Observable<any> {

    var formData: any = new FormData();
    formData.append('PARAM_NAME', 'SIZE');
    formData.append('PARAM_VALUE', p_value);
    formData.append('PARAM_UNIQUE_ID', p_id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http
      .post(`${environment.api_url}/${url}`, formData, {
        reportProgress: true,
        //observe: 'events',
        headers: headers,
        responseType: 'text',
        withCredentials: true
      })
      .pipe(catchError(this.errorMgmt));
  }
  loadPostSize = (url, data) => {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}/${url}`, data, {
      reportProgress: true,
      //observe: 'events',
      headers: headers,
      responseType: 'text',
      withCredentials: true
    }).pipe(
      catchError(this.handleError.bind(this))
    );
  }
  createUser(userName, email, mobileNo, password) {
    return this.http.post(this.serverURL + '/api/auth/v1/register', { userName: userName, email: email, mobileNo: mobileNo, password: password }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  sendOTP(user) {
    return this.http.post(this.serverURL + '/sendactivationotp', { userid: user }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  verifyOTP(user, otp) {
    return this.http.post(this.serverURL + '/verifyactivationotp', { userid: user, otp: otp }).pipe(
      catchError(this.handleError.bind(this))
    );
  }


  isLoggedIn = () => {
    if (localStorage.getItem('currentUser')) {
      return true;
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.token;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(`Backend returned code, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
