import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component, 
  ViewEncapsulation,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service'; 
  
@Component({
  selector: 'app-dispatch-receipt-register',
  templateUrl: './dispatch-receipt-register.component.html',
  styleUrls: ['./dispatch-receipt-register.component.css']
  })
export class DispatchReceiptRegisterComponent implements OnInit {
  objArray: any[] = ['All', 'Dispatch', 'Receipt'];
  report_list: any = [];
  tree_edit_list_print: any = [];
  quarterList: any = [];
  tree_card_Buyer: any = [];
  tree_card_Suppler: any = [];
  quarterList_article_category: any = [];
  tree_article_master: any = [];
  radioSelected: string = '';
  allSuplier: any = "Select Suplier";
  allbuyer: any = "Select Buyer";
  ItemCategory: any = "Select Item Category";
  ArticleCode: any = "Select Article Code";
  page: number = 1;
  constructor( 
    private webService: WebServiceService,
  ) {

  }

  ngOnInit(): void {
    this.loadCourinerType();
    this.load_muil_article_master();
    this.load_muil_article_category();
  }

  loadCourinerType = () => {
    this.tree_card_Buyer = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=COURIER_TYPE&order_code=1";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = dataList[0];
        } else {

        }
      }
    );
  }

  load_muil_article_master = () => {
    this.tree_article_master = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=Courier_Name&order_code=1";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.tree_article_master = dataList[0];
        } else {

        }
      }
    );
  }

  load_muil_article_category = () => {
    this.quarterList_article_category = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=get_employee_master&order_code=1";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          this.quarterList_article_category = dataList[0];
        } else {

        }
      }
    );
  }

  loadTreeSupplie = (fromDate,toDate,Type,Route,CounierAgent,ForwordTo)=>{
  console.log('Selected Status:', this.radioSelected);
    this.report_list = [];
    let fromDate1,toDate1,Type1,CounierAgent1,ForwordTo1;
    debugger
    if (fromDate ==undefined) {
      fromDate1 ="NoData";
    }
    else{
      fromDate1 = fromDate;
    }
    if (toDate == undefined) {
      toDate1 ="NoData";
    }
    else{
      toDate1 =toDate;
    }
    if (Type == "Select Type") {
      Type1 = "NoData";
    }
    else{
      Type1 =Type;
    }
    if (CounierAgent == "Select Counier Agent") {
      CounierAgent1 = "NoData";
    }
    else{
      CounierAgent1 =CounierAgent;
    }
    if (ForwordTo =="Select Forword To") {
      ForwordTo1 = "NoData";
    }
    else{
      ForwordTo1 =ForwordTo;
  
      let url = "ReportViewer/dispatchReceiptRegister?startDate="+fromDate1+"&endDate="+toDate1+"&type="+Type1+"&ROUTE="+Route+"&Courier_Name="+CounierAgent1+"&name="+ForwordTo1;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          let dataList = "";
          if (data.status === 'OK') {
            debugger
            let data12 = (new Function("return [" + data.data + "]")());
            this.report_list = data12[0];
            this.report_list.forEach(element => {
              dataList += "<tr><td>" + element.Buyer_Code + "</td><td>" + element.Buyer_Name + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.Phone + "</td><td>" + element.Fax + "</td></tr>";
            });
            this.tree_edit_list_print = dataList;
          } else {

          }
        }
      );
    }

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Addrss</th>
		      <th>Phone</th>
          <th>Fax</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}
