import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component, 
  ViewEncapsulation,
} from '@angular/core'; 
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;  

@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeReportComponent implements OnInit {
  report_list: any = [];
  tree_edit_list_print:any=[];
  page: number = 1;
  constructor( 
    private webService: WebServiceService,
  ) {
   
  }

  ngOnInit(): void {
    this.loadTreeSupplie();
  }

  loadTreeSupplie(): void {
    let url = "ReportViewer/EmployeeCode";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let dataList ="";
          this.report_list = data.data;
          this.report_list.forEach(element => {
            dataList += "<tr><td>" + element.EMP_CODE + "</td><td>" + element.EMP_FNAME + "</td><td>" + element.Adress + "</td><td>" + element.PHONE + "</td><td>" + element.DOB + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;
          this.initializeDataTable();
        } else {
          console.error('Error loading data:', data);
        }
      }
    );
  }
  
  initializeDataTable(): void {
    // Destroy existing DataTable instance
    const table = $('#datatableexampleEmploay').DataTable();
    table.destroy();

    // Initialize DataTable with the updated data
    $('#datatableexampleEmploay').DataTable({
      processing: true,
      columnDefs: [
        { sortable: false, targets: [-1] }
      ],
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
      language: {
        paginate: {
          first: '',
          previous: '',
          next: '',
          last: ''
        },
        lengthMenu: 'Records per page: _MENU_',
        info: 'Total of _TOTAL_ records (showing _START_ to _END_)',
        infoFiltered: '(filtered from _MAX_ total records)'
      },
      data: this.report_list, // Use the updated data
      columns: [
        { data: 'EMP_CODE', title: 'Emp Code' },
        { data: 'EMP_FNAME', title: 'Emp Name' },
        { data: 'Adress', title: 'Address' },
        { data: 'PHONE', title: 'Phone' },
        { data: 'DOB', title: 'DOB' },
        // Add more columns as needed
      ]
    });
  }
 
  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong></h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>List Of Employees</strong></h5>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
        <th>Emp Code</th>
          <th>Name</th>
          <th>Address</th>
          <th>Phone</th>
          <th>Dob</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}
