import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-group-mapping',
  templateUrl: './user-group-mapping.component.html',
  styleUrls: ['./user-group-mapping.component.css']
})
export class UserGroupMappingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
