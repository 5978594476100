import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { WebServiceService } from 'src/app/services/web-service.service';
export class Country {
  constructor(public name: string, public code: string) { }
}
@Component({
  selector: 'app-flow-up-category',
  templateUrl: './flow-up-category.component.html',
  styleUrls: ['./flow-up-category.component.css']
})
export class FlowUpCategoryComponent implements OnInit {
  proFillModeList: any = [];
  tree_card_Suppler: any = [];
  tree_ContactBuyer: any = [];
  ItemMasterList: any = [];
  proShowOrderDetailsList_print:any=[];
  ShipmentTotal: any;
  TotalShipQty: any;
  article_code_Order: any;
  order_id_Order: any;
  shipment_status: any;
  ArticleCodeColor: any;
  order_Code_Sipment: any;
  supplier_shipment: any;
  buyer_shipment: any;
  searchItemMasterList: any;
  order_code_number: any;
  order_id_number: any;
  ConversionCurrencyValue: any;
  MERCHANDISE_NO = "0";
  proShowRecordsList: any = [];
  proShowOrderColorDetalls: any = [];
  proShowOrderColorDetalls_history: any = [];
  proShowOrderDetailsListColorDetalls: any = [];
  shipment_serialList: any = [];
  originalValues: any = [];
  SuplierBuyer: any = [];
  BuyerOrder: any = "";
  proFillModeListData:any;
  selectedCategory = "MERCHAND";
  FOLLOWUP_DATE = "";
  FOLLOWUP_TEXT = "";
  RemarksData="";
  PrintGrenteShipment:any;
  show = false;
  checkdata = false;
  fullScreen = true;
  template = '';
  AddUpdateFollowUpsID ="Insert"
  search_text_order_no: any;
  search_text_order_date: any;
  OrderNoContainsCtrl = new FormControl();
  filteredCountry: Observable<any[]>;
  country_lis: Country[] = [
    { name: '001', code: 'AF' }
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private webService: WebServiceService,
  ) {

    this.OrderNoContainsCtrl = new FormControl();
    this.filteredCountry = this.OrderNoContainsCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filtercountry(country) : this.country_lis.slice()
      )
    );

  }
  filtercountry(name: string) {
    let arr = this.country_lis.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
    return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
  }

  ngOnInit(): void {
    this.lOrderNoSerch();
    this.proFillMode();
    this.route.queryParamMap.subscribe((params) => {
      this.search_text_order_no = params['params'].order_no;
      this.proshowPopupGenerateShipment_print_list(params['params'].order_no);
      this.onSearchIOrderNoSeletion(params['params'].order_no);
      this.check_merchandise(params['params'].order_no);
      this.getshowPopupOrderDetalls(params['params'].order_no);
    });
  }

  loadVIewSingle = (id) =>{
    console.log(id)
    this.AddUpdateFollowUpsID ="";
    this.selectedCategory ="MERCHAND";
    this.FOLLOWUP_DATE ="";
    this.FOLLOWUP_TEXT ="";
    this.RemarksData ="";
    this.tree_ContactBuyer = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=select_flow_up_category_single&order_code=" + id;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            this.tree_ContactBuyer = data12[0][0];
            this.AddUpdateFollowUpsID= data12[0][0].FOLLOWUP_ID;
           // this.selectedCategory = data12[0][0].FOLLOWUP_CATEGORY;
            this.FOLLOWUP_DATE =data12[0][0].FOLLOWUP_DATE;
            this.FOLLOWUP_TEXT =data12[0][0].FOLLOWUP_TEXT;
            this.RemarksData =data12[0][0].Remarks;
          } else {
            this.AddUpdateFollowUpsID= 'Insert';
            this.AddUpdateFollowUpsID ="";
            this.selectedCategory ="MERCHAND";
            this.FOLLOWUP_DATE ="";
            this.FOLLOWUP_TEXT ="";
            this.RemarksData ="";
          }
        } else {
          this.AddUpdateFollowUpsID= 'Insert';
          this.AddUpdateFollowUpsID ="";
          this.selectedCategory ="MERCHAND";
          this.FOLLOWUP_DATE ="";
          this.FOLLOWUP_TEXT ="";
          this.RemarksData ="";
        }
      }
    );
  }
  check_merchandise = (order_code) => {
    let url = "ReportViewer/OrderEnquery_filter?flterName=merchandising_milestonee_list&order_code=" + order_code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            debugger
            this.MERCHANDISE_NO = data12[0][0].MERCHANDISE_NO;
            this.checkdata = true;
          } else {
            this.checkdata = false;
          }
        } else {
          this.checkdata = false;
        }
      }
    );

  }

  lOrderNoSerch = () => {
    this.country_lis = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=order&order_code=7960";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          data12[0].forEach(element => {
            this.country_lis.push({
              name: element.ORDER_CODE,
              code: element.ORDER_DATE
            });
          });
        } else {

        }
      }
    );
  }

  onSearchIOrderNoSeletion = (selectedValue) => {
    this.proshowPopupGenerateShipmentColorDetalls(selectedValue);
    this.getshowPopupOrderDetalls(selectedValue);
    this.proshowPopupGenerateShipment_print_list(selectedValue);
  }

  getshowPopupOrderDetalls = (codedata) => {
    this.proShowOrderDetailsListColorDetalls = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=order_order_date&order_code=" + codedata;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          debugger
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {

            this.search_text_order_date = data12[0][0].ORDER_DATE;
          } else {
          }
        } else {
        }
      }
    );
  }
  
  proshowPopupGenerateShipmentColorDetalls = (codedata) => {
    this.proShowOrderDetailsListColorDetalls = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=select_flow_up_category&order_code=" + codedata;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              this.proShowOrderDetailsListColorDetalls.push(
                {
                  id: item.FOLLOWUP_ID,
                  folowupDate: item.FOLLOWUP_DATE,
                  cat_name: item.CATEGORY_NAME,
                  text: item.FOLLOWUP_TEXT,
                  remarks: item.Remarks
                });
            });
          } else {
          }
        } else {
        }
      }
    );
  }

  closePopup(id) {
    this.show = false;
    $('#modalEditItemView').css('display', 'none');
    document.body.style.overflow = 'auto';
  }

  changeTreeContactShipmentColor = (event, strOrderNo) => {
    let strShipmentNo = event.target.value;
    this.proshowPopupGenerateShipmentColorDetalls(strOrderNo);

  }

  proFillMode = () => {
    this.proFillModeList = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=select_flow_up_category_dropdown&order_code=2";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());

          this.proFillModeList = data12[0];
        } else {

        }
      }
    );
  }

  AddOrderFollowUP = (id, selectedCategory, date,Text,Remark) => {
    if(id == "Insert"){
      let url = "ReportViewer/AddOrderFollowUP?order_code="+this.search_text_order_no+"&Text="+Text+"&Remark="+Remark;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.proshowPopupGenerateShipmentColorDetalls(this.search_text_order_no);
            alert('Data Added successfully.');
           console.log('Add');
          } else {
            alert('Data Added failed.');
            //  this.shipment_serial(code);
          }
        }
      );
    }
    else{
      let url = "ReportViewer/AddOrderFollowUPEdit?id="+id+"&order_code="+this.search_text_order_no+"&Text="+Text+"&Remark="+Remark;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.proshowPopupGenerateShipmentColorDetalls(this.search_text_order_no);
            alert('Data updated successfully.');
          } else {
            alert('Data updated failed.');
            //  this.shipment_serial(code);
          }
        }
      );
    }
    
  }

  proshowPopupGenerateShipment_print_list = (code) => {
    this.proShowOrderDetailsList_print = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=followup_order_single&order_code=" + code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let dataList = "";
          let total= 0;
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            //this.proShowOrderDetailsList_print = data12[0];
            //console.log(data12[0]);
            data12[0].forEach((element, index) => {
              let id = index + 1;
              total +=element.Total;
              dataList +="<thead style='border: 1.6px solid #4e4e6d; text-align: center; font-size: larger; margin-top: 20px;'> <tr> <th  colspan='2'>Follow-Up ID: <span style='font-weight: 500;'>" + element.FOLLOWUP_ID + "</span></th> <th  colspan='2'>Follow-Up Date: <span style='font-weight: 500;'>" + element.FOLLOWUP_DATE + "</span></th> </tr> <tr> <th colspan='2'>Order Code.: <span style='font-weight: 500;'>" + element.ORDER_CODE + "</span></th> <th colspan='2'>Order Date: <span style='font-weight: 500;'>" + element.Order_Date + "</span></th> </tr> <tr> <th colspan='2'>Buyer Code: <span style='font-weight: 500;'>" + element.Buyer_Code + "</span></th> <th colspan='2'>Buyer: <span style='font-weight: 500;'>" + element.Buyer_Name + "</span></th> </tr> <tr> <th colspan='2'>Supplier Code: <span style='font-weight: 500;'>" + element.Supplier_Code + "</span></th> <th colspan='2'>Supplier: <span style='font-weight: 500;'>" + element.Supplier_Name + "</span></th> </tr> <tr> <th colspan='2'>Follow-Up Category: <span style='font-weight: 500;'>" + element.FOLLOWUP_CATEGORY + "</span></th> <th colspan='2'>Follow-Up: <span style='font-weight: 500;'>" + element.CATEGORY_NAME + "</span></th> </tr> <tr> <th colspan='4'>Follow-Up Text: <span style='font-weight: 500;'>" + element.FOLLOWUP_TEXT + "</span></th> </tr> </thead>";
            });
            this.PrintGrenteShipment = dataList;
           // console.log(this.proShowOrderDetailsList_print[0].ARRIVAL_DATE);
          } else {
            this.proShowOrderDetailsList_print =[];
          }
        } else {
          this.proShowOrderDetailsList_print =[];
        }
      }
    );
  }


  onPreviewGShipement = () => {

    let printContents, popupWin;
    printContents = this.PrintGrenteShipment;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
<html>
	<head>
		
		<style type="text/css">
			p {
			font-family: "Times New Roman";
			}
			
			.padding-main-divcls{
			padding: 5px;
			}
			
			.text-center{
			text-align: center
			}
			.width-full{
			width: 100%;
			}
			
			.box{
			border-style: solid;
			border-width: 1px;
			width: 65px;
			height: 100px;
			float: right;
			margin-right: 50px;
			font-size: 10px;
			padding: 5px;
			}
			.box-divcls{
			width: 100%;
			display: inline-block;
			}
			
			.TermsConditionTable, tr , td {
			padding: 4px !important;
			}
			tr, td {
			page-break-inside: avoid !important;
			}
			
			
			.break-after{
			page-break-after: always;
			}
			.top-border-cls{
			border-top: solid black 1.0pt;
			}
			table {
			font-family: arial, sans-serif;
			border-collapse: collapse;
			width: 100%;
			}
			
			td, th {
			border: 0px solid #dddddd;
			text-align: left;
			padding: 8px; 
			}
			
			
			
		</style>
	</head>
  <body onload="window.print();window.close()">
		<div class="card-body border-top pt-20 mt-0">
			<div class="row">
				<div class="col-sm-12">
					<div class="invoice-details">
						<h5 class="mb-10 text-18 text-capitalize"><strong>YOUNG FASHION (INDIA)</strong></h5>
						<div class="invoice-details-inner mt-2">
							<p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
							<br /> INDIA </p>
							<p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
							<p><strong>Email:</strong> mail@youngfashionindia.com</p>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		<div style="border: 1.6px solid #4e4e6d; text-align: center; font-size: larger;">
			<h2 class=""><strong>Order Follow Up Details</strong></h2>
		</div>
		<div class="table-responsive invoice-table mb-4 mt-0">
			<table style="border: 1.6px solid #4e4e6d; text-align: center; font-size: larger; margin-top: 20px;">
      ${printContents}
				 
			</table>
		</div>
	</body>
</html>			
      `)
      popupWin.document.close();
    }
  };
}
