<div>
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="toggleDropDown()">
            <span *ngIf="checkedList.length <= 0">Select</span>
            <span *ngIf="checkedList.length > 0" style="width: 95%;display: inline-block;color: black;overflow: hidden;text-overflow: ellipsis;">{{ checkedList.join(', ') }}</span>
            <i class="fa fa-angle-down"></i>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <p *ngFor="let a of list" style="margin: 4px;">
                <input type="checkbox" [(ngModel)]="a.checked" 
                    (change)="getSelectedValue(a.checked, a.value)" />
                <span> {{ a.name }}</span>
            </p>
        </div>
    </div>
</div>
