<style>
    .login_container {
            width: 340px;
            margin: auto;
            position: relative;
            top: 154.5px;
        }

        .login_header {
            height: 80px;
            margin-bottom: 20px;
            box-shadow: 0px 0px 4px rgba(000, 000, 000, 0.6), 0 1px 0 rgba(255, 255, 255, 0.6) inset;
            -moz-box-shadow: 0px 0px 4px rgba(000, 000, 000, 0.6), 0 1px 0 rgba(255, 255, 255, 0.6) inset;
            -webkit-box-shadow: 0px 0px 4px rgba(000, 000, 000, 0.6), 0 1px 0 rgba(255, 255, 255, 0.6) inset;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            border: #002e43 1px solid;
        }

        .blue_lgel {
            background: #34bbde;
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzM0YmJkZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzExNmJiMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iIzBlNWJhNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwYTUzOWYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(top, #34bbde 0%, #116bb2 50%, #0e5ba7 51%, #0a539f 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #34bbde), color-stop(50%, #116bb2), color-stop(51%, #0e5ba7), color-stop(100%, #0a539f));
            background: -webkit-linear-gradient(top, #34bbde 0%, #116bb2 50%, #0e5ba7 51%, #0a539f 100%);
            background: -o-linear-gradient(top, #34bbde 0%, #116bb2 50%, #0e5ba7 51%, #0a539f 100%);
            background: -ms-linear-gradient(top, #34bbde 0%, #116bb2 50%, #0e5ba7 51%, #0a539f 100%);
            background: linear-gradient(top, #34bbde 0%, #116bb2 50%, #0e5ba7 51%, #0a539f 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#34bbde', endColorstr='#0a539f', GradientType=0);
        }

        .login_header {
            height: 80px;
            margin-bottom: 20px;
            box-shadow: 0px 0px 4px rgba(000, 000, 000, 0.6), 0 1px 0 rgba(255, 255, 255, 0.6) inset;
            -moz-box-shadow: 0px 0px 4px rgba(000, 000, 000, 0.6), 0 1px 0 rgba(255, 255, 255, 0.6) inset;
            -webkit-box-shadow: 0px 0px 4px rgba(000, 000, 000, 0.6), 0 1px 0 rgba(255, 255, 255, 0.6) inset;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            border: #002e43 1px solid;
        }
</style>
<div id="login_page" style=" height: 100%; width: 100%; position: relative;">
    <div className="login_container" style=" width: 340px; margin: auto; position: relative; top: 24.5px; ">
        <div className="login_header blue_lgel" style="height: auto;margin-bottom: 0px;border-radius: 5px;background: white;">
            <ul className="login_branding" style="overflow: hidden;height: 220px;width: 328px;margin: 6px 1px 6px 5px;-moz-border-radius: 3px;border-radius: 3px;background: #fff;position: relative;">
                <li style=" display: inline-block; ">
                    <img src="./assets/img/logo.png" class="rounded mx-auto d-block" alt="..." style="
                    width: 227px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                ">
                </li>
            </ul>
            
        </div>
        <div className="block_container blue_d" style="-moz-border-radius: 6px;-webkit-border-radius: 6px;border-radius: 6px;padding: 10px;border: #002E43 1px solid;background: cornflowerblue;">
            <div className="block_form" style="z-index: 2;position: relative;border-radius: 2px;border: 1px solid #b9b9b9;box-shadow: 0 1px 0 rgba(0, 0, 0, 0.11);margin-bottom: 10px;">
                <div class="message welcome" style="font-size: large; padding: 10px 0px 0px 15px;">Welcome back!</div>
                <ul style="list-style: none; padding: 10px;">
                    <li class="login_user" style="list-style: none;background: rgba(0, 32, 53, 0.50) 70%;padding: 8px;-moz-border-radius: 4px;-webkit-border-radius: 4px;border-radius: 4px;margin-bottom: 10px;border: none;">
                        <input type="text" #mobileNo name="mobile_number" placeholder="User Name" autocomplete="off"  class="form-control mb-0 required" />
                    </li>
                    <li class="login_pass" style="list-style: none;background: rgba(0, 32, 53, 0.50) 70%;padding: 8px;-moz-border-radius: 4px;-webkit-border-radius: 4px;border-radius: 4px;margin-bottom: 10px;border: none;">
                       <input type="password" #password class="form-control mb-0" id="exampleInputPassword2" placeholder="Password" required>
                    </li>
                </ul>
            </div>
            <button type="button" (click)="loginUser(mobileNo.value, password.value)" className="login_btn blue_lgel" style="padding: 4px;width: 100%;font-size: large;font-weight: 600;color: white;">Login</button>
        </div>
    </div>
</div>
<!--  -->