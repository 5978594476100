<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Order Color Details</div>
        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/Add.png"
                                style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>

        <section id="content" class="container_12 clearfix" data-sort="true"
            style="padding: 30px 100px 30px 100px;background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="grid_12">

                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 25px;left: 15px;background: white;width: 185px;z-index: 999;position: relative;">
                    Order Color Details</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <div class="content">
                        <fieldset>
                            <legend> Order Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" value="{{orderCode_value}}" size="15" maxlegth="30"
                                            style="width: 100%;" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" [(ngModel)]="ShipmentNo_Data" style="width: 100%;padding: 5px;" #ShipmentNo
                                            (change)='changeTreeContactShipmentColor($event, orderCode.value)'>
                                            <option [value]="0" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList" [value]="sipmet.shipment_serial_no" >
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td> 
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" #ModeOfShipment [(ngModel)]="Modeofshipment"  [disabled]="true">
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.order_article_code"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); width: 100%;" />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.item_description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22);width: 100%; " />
                                        </td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                           
                            <h4>Color Details of Article - {{ order_article_no}} of Shipment - {{ShipmentNo_Data}}
                                For Order
                                {{orderCode_value}}</h4>
                            
                        </fieldset>
                        <form [formGroup]="userForm">
                            <table class="table">
                                <thead>
                                    <th>Color Name</th>
                                    <th>Composition</th>
                                    <th>Lab Dip/Yarn Bit</th>
                                    <th>Sent Date</th>
                                    <th>Action</th>
                                </thead>
                                <tbody formArrayName="users"
                                    *ngFor="let group of getFormData.controls; let i=index">
                                    <tr [formGroupName]="i">
                                        <td class="form-group">
                                            <input type="text" class="form-control" formControlName="color_name"
                                                placeholder="Color Name" style=" width: 100%; height: 40px; border: 0px solid #a0a0a0; box-shadow: none; " />
                                            <span class="text-danger"
                                                *ngIf="group.get('color_name').touched && group.get('color_name').invalid">Color Name
                                                is required</span>
                                        </td>
                                        <td class="form-group">
                                            <input type="text" class="form-control" formControlName="composition"
                                                placeholder="Composition" style=" width: 100%; height: 40px; border: 0px solid #a0a0a0; box-shadow: none; " />
                                            <span class="text-danger"
                                                *ngIf="group.get('composition').touched && group.get('composition')?.hasError('required')">Composition
                                                required</span>
                                            <span class="text-danger"
                                                *ngIf="group.get('composition').touched && !group.get('composition').hasError('required') && group.get('composition').hasError('validEmail')">Please
                                                enter correct composition </span>
                                        </td>
                                        <td class="form-group">
                                            <input type="text" class="form-control" formControlName="lab_dip_yarn_bit"
                                                placeholder="Lab Dip/Yarn Bit" style=" width: 100%; height: 40px; border: 0px solid #a0a0a0; box-shadow: none; " />
                                        </td>
                                        <td>
                                            <input formControlName="sent_date" matInput [matDatepicker]="picker"
                                                placeholder="Choose a date" style=" height: 40px; border: 0px solid #a0a0a0; box-shadow: none; " />
                                            <span class="text-danger"
                                                *ngIf="group.get('sent_date').touched && group.get('sent_date').invalid">Please
                                                enter valid date </span>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </td>
                                        <td>
                                            <button (click)="remove(i)" class="btn btn-primary mr-5" >Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                        <button (click)="addUser()" class="btn btn-primary mr-5" style="float: right;">Add new</button>

                    </div>
                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">

                        <div class="right">
                            <input type="button"
                                (click)="saveAllChangesColor(orderCode.value, ShipmentNo.value, ModeOfShipment.value, BuyerValue.value, SupplierValue.value)"
                                value="Save" name="submit" style="width: 100px;font-size: small;" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>