import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { WebServiceService } from 'src/app/services/web-service.service';
import Swal from 'sweetalert2';
export class Country {
  constructor(public name: string, public code: string) { }
}
@Component({
  selector: 'app-merchandising-details',
  templateUrl: './merchandising-details.component.html',
  styleUrls: ['./merchandising-details.component.css']
})
export class MerchandisingDetailsComponent implements OnInit {
  proFillModeList: any = [];
  tree_card_Suppler: any = [];
  tree_ContactBuyer: any = [];
  ItemMasterList: any = [];
  ShipmentTotal: any;
  TotalShipQty: any;
  article_code_Order: any;
  order_id_Order: any;
  shipment_status: any;
  ArticleCodeColor: any;
  order_Code_Sipment: any;
  supplier_shipment: any;
  buyer_shipment: any;
  searchItemMasterList: any;
  order_code_number: any;
  order_id_number: any;
  ConversionCurrencyValue: any;
  MERCHANDISE_NO = "0";
  proShowRecordsList: any = [];
  proShowOrderColorDetalls: any = [];
  proShowOrderColorDetalls_history: any = [];
  proShowOrderDetailsListColorDetalls: any = [];
  shipment_serialList: any = [];
  originalValues: any = [];
  SuplierBuyer: any = [];
  BuyerOrder: any = "";
  SuplierOrder: any = "";
  show = false;
  checkdata = false;
  fullScreen = true;
  template = '';
  search_text_order_no: any;
  OrderNoContainsCtrl = new FormControl();
  filteredCountry: Observable<any[]>;
  country_lis: Country[] = [
    { name: '001', code: 'AF' }
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private webService: WebServiceService,
  ) {

    this.OrderNoContainsCtrl = new FormControl();
    this.filteredCountry = this.OrderNoContainsCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filtercountry(country) : this.country_lis.slice()
      )
    );

  }
  filtercountry(name: string) {
    let arr = this.country_lis.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
    return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
  }

  ngOnInit(): void {

    this.loadTreeSuppler();
    this.lOrderNoSerch();
    this.proFillMode();
    this.route.queryParamMap.subscribe((params) => {
      this.search_text_order_no = params['params'].order_no;
      this.onSearchIOrderNoSeletion(params['params'].order_no);
    });
  }

  check_merchandise = (order_code) => {
    let url = "ReportViewer/OrderEnquery_filter?flterName=merchandising_milestonee_list&order_code=" + order_code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            debugger

            this.MERCHANDISE_NO = data12[0][0].MERCHANDISE_NO;

            this.checkdata = true;
          } else {
            this.checkdata = false;

          }
        } else {
          this.checkdata = false;
        }
      }
    );

  }

  lOrderNoSerch = () => {
    this.country_lis = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=order&order_code=7960";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          //this.report_list = data12[0];
          data12[0].forEach(element => {
            this.country_lis.push({
              name: element.ORDER_CODE,
              code: element.ORDER_DATE
            });
          });
        } else {

        }
      }
    );
  }

  onSearchIOrderNoSeletion = (selectedValue) => {
    this.loadSuplierBuyerSerch(selectedValue);
    this.loadShipmentNoSerch(selectedValue);
    this.check_merchandise(selectedValue);
    this.ItemList_merchandise_list(selectedValue);
    this.ItemList_merchandise_list_history(selectedValue);
  }

  loadSuplierBuyerSerch = (orderID) => {
    this.SuplierBuyer = [];
    this.BuyerOrder = "";
    this.SuplierOrder = "";
    let url = "ReportViewer/OrderEnquery_filter?flterName=suplier_buyer&order_code=" + orderID;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.SuplierBuyer = data12[0][0];
          this.BuyerOrder = this.SuplierBuyer.BUYER_NAME;
          this.SuplierOrder = this.SuplierBuyer.SUPPLIER_NAME;
          let text = "Buyer Name : " + this.BuyerOrder + " Supplier Name : " + this.SuplierOrder;
          //this.AddOrderFollowUP(orderID, text, 'No Comment');
          console.log(this.SuplierBuyer);
        } else {

        }
      }
    );
  }

  loadShipmentNoSerch = (orderID) => {
    this.shipment_serialList = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=shipmet&order_code=" + orderID;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.shipment_serialList = data12[0];
          this.proshowPopupGenerateShipmentColorDetalls(orderID,'1');
        } else {

        }
      }
    );
  }

  TreeContactBuyer = (event) => {
    const Item_Code = event.target.value;
    try {
      let data = {
        code: Item_Code.split(': ')[1]
      }
      this.tree_ContactBuyer = [];
      let url = "Buyer/BuyerCode";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.data + "]")());
            if (data12[0].length > 0) {
              this.tree_ContactBuyer = data12[0];
            }
            else {
              this.tree_ContactBuyer = [""];
            }


          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  loadTreeSuppler = () => {
    let url = "Suppler/TreeSupplieList";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            this.tree_card_Suppler = dataList[0];

          }
          else {
            this.tree_card_Suppler = [""];
          }


        } else {

        }
      }
    );
  }

  proshowPopupGenerateShipmentColorDetalls = (codedata,sip_id) => {
    this.proShowOrderDetailsListColorDetalls = [];
    let url ="ReportViewer/MerchandisingDetails_ItemList?order_code="+codedata+"&shipment_shril_no="+sip_id;
    //let url = "ReportViewer/OrderEnquery_filter?flterName=ItemList&order_code=" + codedata;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          debugger
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            this.article_code_Order = data12[0][0].ORDER_ARTICLE_CODE;
            this.order_id_Order = data12[0][0].Order_Id;
            debugger
            data12[0].forEach((item, index) => {
              this.proShowOrderDetailsListColorDetalls.push(
                {
                  id: index + 1,
                  order_article_code: item.ORDER_ARTICLE_CODE,
                  item_description: item.Description,
                  item_qty: item.ITEM_QTY
                });
            });

            this.ArticleCodeColor = this.proShowOrderDetailsListColorDetalls[0].order_article_code;
            let url = "ReportViewer/AddUpdateMerchandising?action=Insert&article_code=" + data12[0][0].ORDER_ARTICLE_CODE + "&order_code=" + data12[0][0].ORDER_CODE + "&order_id=" + data12[0][0].Order_Id + "&shipment_serial_no=" + this.shipment_serialList[0].SHIPMENT_SERIAL_NO + "&remarks=No Remark&MERCHANDISE_NO=2";
           console.log(url)
            this.webService.loadTreeLiat(url).subscribe(
              (data: any) => {
                if (data.status === 'OK') {
                }
              });
            //this.showOrderColorDetalls(codedata, sid, this.proShowOrderDetailsListColorDetalls[0].order_article_code);
          } else {
            // this.order_Code_Sipment=this.order_Code_Sipment;
            // this.buyer_shipment="";
            // this.supplier_shipment="";
            //this.shipment_serial(this.order_Code_Sipment);
          }

        } else {
          //  this.shipment_serial(code);
        }
      }
    );
  }

  ItemList_merchandise_list = (order_code) => {
    this.proShowOrderColorDetalls = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=ItemList_merchandise_list&order_code=" + order_code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          if (data12[0].length > 0) {
            data12[0].forEach((item, index) => {
              this.proShowOrderColorDetalls.push(
                {
                  MERCHANDISE_TYPE_CODE: item.MERCHANDISE_TYPE_CODE,
                  MERCHANDISE_TYPE_NAME: item.MERCHANDISE_TYPE_NAME,
                  MERCHANDISE_NO: item.MERCHANDISE_NO,
                  STATUS: item.STATUS,
                  DO: item.DO,
                  SO: item.SO,
                  BO: item.BO,
                  MERCHANDISE_DATE: this.datePipe.transform(item.MERCHANDISE_DATE, 'yyyy-MM-dd'),
                  REMARKS: item.REMARKS,
                  DUE_ON_DATE: this.datePipe.transform(item.DUE_ON_DATE, 'yyyy-MM-dd'),
                  APP_DATE: this.datePipe.transform(item.APP_DATE, 'yyyy-MM-dd'),
                  SENT_DATE: this.datePipe.transform(item.SENT_DATE, 'yyyy-MM-dd'),
                  AMEND: item.AMEND,
                  MERCHANDISE_DETAIL_NO: item.MERCHANDISE_DETAIL_NO,
                  MERCHANDISE_AMEND_NO: item.MERCHANDISE_AMEND_NO,
                  MERCHANDISE_AMEND_DATE: this.datePipe.transform(item.MERCHANDISE_AMEND_DATE, 'yyyy-MM-dd'),
                  FOLLOWUP_ID: item.FOLLOWUP_ID,
                  MERCHANDISE_PRODUCT_PROCESS: item.MERCHANDISE_PRODUCT_PROCESS,
                });
            });
          } else {
          }
        } else {
          //  this.shipment_serial(code);
        }
      }
    );
  }

  ItemList_merchandise_list_history = (order_code) => {
    this.proShowOrderColorDetalls_history = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=ItemList_merchandise_list_history&order_code=" + order_code;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());

          if (data12[0].length > 0) {
            this.proShowOrderColorDetalls_history = data12[0];
          } else {
          }
        } else {
          //  this.shipment_serial(code);
        }
      }
    );
  }

  editRowColor(index: number) {
    this.proShowOrderColorDetalls[index].isEditing = true;
  }

  saveRowColor(index: number, orderCode, article_code, order_id, ShipmentNo, remarks) {
    this.proShowOrderColorDetalls[index].isEditing = false;
    console.log(this.proShowOrderColorDetalls[index])
    console.log(orderCode, article_code, order_id, ShipmentNo, remarks)
    this.saveAllChangesColor(orderCode, article_code, order_id, ShipmentNo, remarks, this.proShowOrderColorDetalls[index])
  }

  cancelEditColor(index: number) {
    this.proShowOrderColorDetalls[index] = { ...this.originalValues[index] };
    this.proShowOrderColorDetalls[index].isEditing = false;
  }

  saveAllChangesColor(orderCode, article_code, order_id, ShipmentNo, remarfk, item) {
    debugger
    let remarks
    if (remarfk == "") {
      remarks = "NULL";
    }
    console.log(item)
    if (item.MERCHANDISE_TYPE_CODE == null || item.STATUS == null || item.MERCHANDISE_AMEND_DATE == null || item.DUE_ON_DATE == null || item.APP_DATE == null || item.SENT_DATE == null) {
      Swal.fire({
        position: 'top-end',
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;margin-bottom: 0rem;">Please data table the missing values</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
    else {
      if (this.checkdata) {
        let url = "ReportViewer/AddUpdateMerchandising?action=Update&article_code=" + article_code + "&order_code=" + orderCode + "&order_id=" + order_id + "&shipment_serial_no=" + ShipmentNo + "&remarks=" + remarks + "&MERCHANDISE_NO=" + this.MERCHANDISE_NO;
        this.webService.loadTreeLiat(url).subscribe(
          (data: any) => {
            if (data.status === 'OK') {
              let do1, so1, bo1;
              if (item.DO == true) {
                do1 = "True";
              }
              else {
                do1 = "False";
              }
              if (item.SO == true) {
                so1 = "True";
              }
              else {
                so1 = "False";
              }
              if (item.BO == true) {
                bo1 = "True";
              }
              else {
                bo1 = "False";
              }
              let url = "ReportViewer/AddUpdateMerchandisingMilestone?action=Update&MERCHANDISE_TYPE_CODE=" + item.MERCHANDISE_TYPE_CODE + "&STATUS=" + item.STATUS + "&MERCHANDISE_DATE=" + item.MERCHANDISE_DATE + "&DO=" + do1 + "&SO=" + so1 + "&BO=" + bo1 + "&REMARKS=" + item.REMARKS + "&AMEND=True&MERCHANDISE_AMEND_NO=" + item.MERCHANDISE_AMEND_NO + "&MERCHANDISE_AMEND_DATE=" + item.MERCHANDISE_AMEND_DATE + "&FOLLOWUP_ID=" + item.FOLLOWUP_ID + "&DUE_ON_DATE=" + item.DUE_ON_DATE + "&APP_DATE=" + item.APP_DATE + "&SENT_DATE=" + item.SENT_DATE + "&MERCHANDISE_NO=" + item.MERCHANDISE_NO + "&MERCHANDISE_DETAIL_NO=" + item.MERCHANDISE_DETAIL_NO + "";
              let text = "Name : " + item.MERCHANDISE_TYPE_CODE + " Status : " + item.STATUS;
              this.AddOrderFollowUP(orderCode, text, item.REMARKS);
              this.webService.loadTreeLiat(url).subscribe(
                (data: any) => {
                  if (data.status === 'OK') {
                    //let data12 = (new Function("return [" + data.data + "]")());
                    this.ItemList_merchandise_list(orderCode);
                    this.ItemList_merchandise_list_history(orderCode);
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      iconColor: '#000',
                      background: '#FFFFFF',
                      html: '<p style="font-weight: 500;color: black;">Merchandising updated successfully</p>',
                      showConfirmButton: false,
                      timer: 2500
                    });
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      iconColor: '#000',
                      background: '#FFFFFF',
                      html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
                      showConfirmButton: false,
                      timer: 2500
                    });
                  }
                }
              );
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                iconColor: '#000',
                background: '#FFFFFF',
                html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
                showConfirmButton: false,
                timer: 2500
              });
            }
          }
        );
      }
      else {
        // After saving, reset editing states
        let url = "ReportViewer/AddUpdateMerchandising?action=Insert&article_code=" + article_code + "&order_code=" + orderCode + "&order_id=" + order_id + "&shipment_serial_no=" + ShipmentNo + "&remarks=" + remarks + "&MERCHANDISE_NO=" + this.MERCHANDISE_NO;
        this.webService.loadTreeLiat(url).subscribe(
          (data: any) => {
            if (data.status === 'OK') {
              let do1, so1, bo1;
              if (item.DO == true) {
                do1 = "True";
              }
              else {
                do1 = "False";
              }
              if (item.SO == true) {
                so1 = "True";
              }
              else {
                so1 = "False";
              }
              if (item.BO == true) {
                bo1 = "True";
              }
              else {
                bo1 = "False";
              }
              let url = "ReportViewer/AddUpdateMerchandisingMilestone?action=Update&MERCHANDISE_TYPE_CODE=" + item.MERCHANDISE_TYPE_CODE + "&STATUS=" + item.STATUS + "&MERCHANDISE_DATE=" + item.MERCHANDISE_DATE + "&DO=" + do1 + "&SO=" + so1 + "&BO=" + bo1 + "&REMARKS=" + item.REMARKS + "&AMEND=True&MERCHANDISE_AMEND_NO=" + item.MERCHANDISE_AMEND_NO + "&MERCHANDISE_AMEND_DATE=" + item.MERCHANDISE_AMEND_DATE + "&FOLLOWUP_ID=" + item.FOLLOWUP_ID + "&DUE_ON_DATE=" + item.DUE_ON_DATE + "&APP_DATE=" + item.APP_DATE + "&SENT_DATE=" + item.SENT_DATE + "&MERCHANDISE_NO=" + item.MERCHANDISE_NO + "&MERCHANDISE_DETAIL_NO=2000";
              let text = "Name : " + item.MERCHANDISE_TYPE_CODE + " Status : " + item.STATUS;
              this.AddOrderFollowUP(orderCode, text, item.REMARKS);
              this.webService.loadTreeLiat(url).subscribe(
                (data: any) => {
                  if (data.status === 'OK') {
                    // let data12 = (new Function("return [" + data.data + "]")());
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      iconColor: '#000',
                      background: '#FFFFFF',
                      html: '<p style="font-weight: 500;color: black;">Merchandising updated successfully</p>',
                      showConfirmButton: false,
                      timer: 2500
                    });
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      iconColor: '#000',
                      background: '#FFFFFF',
                      html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
                      showConfirmButton: false,
                      timer: 2500
                    });
                  }
                }
              );

            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                iconColor: '#000',
                background: '#FFFFFF',
                html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
                showConfirmButton: false,
                timer: 2500
              });
            }
          }
        );
      }
    }
  }

  addItemModel = (data) => {
    //this.order_code=

    this.show = true;
    if (data == '') {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        iconColor: '#000',
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;">Please fill and save the order. Add item will not open until you save the order.</p>',
        showConfirmButton: false,
        timer: 2500
      });
      this.show = false;
    } else {
      this.loadItemMasterList(data)
    }

  }

  loadItemMasterList = (data) => {
    this.ItemMasterList = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=ItemList_merchandise&order_code=1";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.result === 'success') {

          let data12 = (new Function("return [" + data.data + "]")());
          this.ItemMasterList = data12[0];
          this.show = false;
          // this.ItemMasterList = overLayCard;
          document.body.style.overflow = 'hidden';
          $('#modalEditItemView').css('display', 'flex');
        } else {

        }
      }
    );
  }

  addValueitem = (selectedValue, order_id) => {

    var data12 = this.ItemMasterList.filter(opt => opt.checked).map(opt => opt);
    console.log(data12)
    debugger
    data12.forEach((item, index) => {
      debugger
      console.log(item.Code.split('|')[0])
      let url = "ReportViewer/AddMilestone?MERCHANDISE_TYPE_CODE=" + item.Code.split('|')[0] + "&FOLLOWUP_ID=" + this.MERCHANDISE_NO + "&MERCHANDISE_NO=" + this.MERCHANDISE_NO + "";
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.show = false;
            $('#modalEditItemView').css('display', 'none');
            document.body.style.overflow = 'auto';
            this.proFillModeList = data12[0];
            this.ItemList_merchandise_list(selectedValue);
            this.ItemList_merchandise_list_history(selectedValue);
          } else {
            this.show = false;
            $('#modalEditItemView').css('display', 'none');
            document.body.style.overflow = 'auto';
          }
        }
      );
    });
  }

  closePopup(id) {
    this.show = false;
    $('#modalEditItemView').css('display', 'none');
    document.body.style.overflow = 'auto';
  }

  changeTreeContactShipmentColor = (event, strOrderNo) => {
    let strShipmentNo = event.target.value;
    this.proshowPopupGenerateShipmentColorDetalls(strOrderNo, strShipmentNo);

  }

  proFillMode = () => {
    this.proFillModeList = [];
    let url = "Order/proFillMode";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let overLayCard = [];
          let data12 = (new Function("return [" + data.editList + "]")());

          this.proFillModeList = data12[0];
        } else {

        }
      }
    );
  }

  AddOrderFollowUP = (order_code, Text, Remark) => {
    debugger
    let url = "ReportViewer/AddOrderFollowUP?order_code=" + order_code + "&Text=" + Text + "&Remark=" + Remark;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          console.log('Add');
        } else {
          //  this.shipment_serial(code);
        }
      }
    );
  }
}
