<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Item Price</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img
                                src="./assets/images/Add.png" style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>
        
        <aside style="width: 350px;">
            <ul class="top"
                style="margin-top: 0px; height: 300px; text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                <li><a href="javascript:void(0);" style="font-size: large;">Items</a>

                    <ul *ngFor="let item of this.tree_Order_Details">
                        <div style="padding: 5px;"><a href="javascript:void(0);"
                                (click)="TreeListBinding(item.Item_code, item.Item_code)">{{item.Item_code}}</a></div>
                    </ul>
                </li>
            </ul>
        </aside>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 0;position: relative;">
                        Item Price</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">
                        
                        <div class="modal fade" id="modalvIEW" tabindex="-1" role="dialog" data-backdrop="false">
                            <div class="modal-dialog" role="document">
                                <form id="modal_form_signup" novalidate="novalidate" style="box-shadow: 0 1rem 3rem rgba(0,0,0,.75)!important;">
                                    <div class="modal-content">
                                        <div class="modal-header" style="text-align: start;display: flex;">
                                            <h5 class="modal-title"> Article </h5>
                                            <button type="button" class="close" (click)="closePopup()"
                                                style=" right: 0; position: absolute;">
                                                <span>&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body"
                                            style="max-height: calc(100vh - 200px); overflow-y: auto;">
                                            <div class="row">
                                                <div class="grid_12">
                                                    <table class="dynamic styled" data-filter-Bar="always"
                                                        data-table-tools='{"display":false}'>
                                                        <thead style="top: -16px;">
                                                            <tr>
                                                                <th>#</th>
                                                                <th style="width: 100px;">Code</th>
                                                                <th>Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="gradeX"
                                                                *ngFor="let item1 of this.tree_card_OrderDropdown; let i = index">
                                                                <td class="center"><button
                                                                        style="background: blueviolet;" type="button"
                                                                        (click)="editOrderList(item1.Code,item1.Name)"
                                                                        class="btn btn-secondary"
                                                                        data-dismiss="modal">Add</button></td>
                                                                <td class="center" style="width: 100px;">
                                                                    {{item1.Code}}</td>
                                                                <td class="center">{{item1.Name}}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_12">
                                    <div class="row">
                                        <div class="grid_2">
                                            <label for="v1_normalBank"><strong>Aritacle Name</strong> </label>
                                        </div>
                                        <div clas="row" style="display: flex;">
                                            <div class="grid_7">
                                                <input id="Department" class="required" type="text"
                                                    value="{{articalName}}"  />
                                            </div>
                                            <div class="grid_3" style="margin-left: 8px;">
                                                <button style="background: blueviolet;margin: 8px;" type="button"
                                                    (click)="viewDialog()" class="btn btn-secondary">View</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_12 ">
                                            <div class="scroll-table-container">
                                                <table class="scroll-table dynamic styled">

                                                    <tr>
                                                        <div class="row">
                                                            <div class="grid_3">
                                                                <label for="v1_normal_input"><strong>Item Code</strong>
                                                                </label>
                                                            </div>
                                                            <div class="grid_9">
                                                                <input class="required" type="text"
                                                                    formControlName="ItemCode" [readonly]="isReadItemCode" />
                                                                <app-field-error-display
                                                                    [displayError]="isFieldValid('ItemCode')"
                                                                    errorMsg="Please  enter Conv Rate">
                                                                </app-field-error-display>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid_3">
                                                                <label for="v1_normal_input"><strong>Mode</strong>
                                                                </label>
                                                            </div>
                                                            <div class="grid_9">
                                                                <select formControlName="mode"
                                                                    [(ngModel)]="selectedMode" 
                                                                    data-placeholder="Choose a Name"
                                                                    style="width: 100%;top: 5px;height: 30px;padding: 5px;">
                                                                    <option [ngValue]="null" disabled>Choose your Mode
                                                                    </option>
                                                                    <option *ngFor="let size of tree_card_Buyer"
                                                                        [ngValue]="size.PARAM_VALUE">
                                                                        {{size.PARAM_VALUE}}
                                                                    </option>
                                                                </select>

                                                                <app-field-error-display
                                                                    [displayError]="isFieldValid('mode')"
                                                                    errorMsg="Please  enter buyer">
                                                                </app-field-error-display>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid_3">
                                                                <label for="v1_normal_input"><strong>CostPrice</strong>
                                                                </label>
                                                            </div>
                                                            <div class="grid_9">
                                                                <input class="required" type="text"
                                                                    formControlName="CostPrice" />
                                                                <app-field-error-display
                                                                    [displayError]="isFieldValid('CostPrice')"
                                                                    errorMsg="Please  enter CostPrice.">
                                                                </app-field-error-display>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="grid_3">
                                                                <label for="v1_normal_input"><strong>Selling
                                                                        Price</strong>
                                                                </label>
                                                            </div>
                                                            <div class="grid_9">
                                                                <input class="required" type="text"
                                                                    formControlName="SellingPrice" />
                                                                <app-field-error-display
                                                                    [displayError]="isFieldValid('SellingPrice')"
                                                                    errorMsg="Please  enter Selling Price.">
                                                                </app-field-error-display>
                                                            </div>
                                                        </div>
                                                    </tr>

                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit" value="Save" name="submit"
                                    style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>

        </section>

    </div>
</div>