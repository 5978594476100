import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
import Swal from 'sweetalert2';
declare let $: any;
@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./buyer.component.css']
})
export class BuyerComponent implements OnInit {
  @ViewChild('lazy') private lazyTree!: ElementRef;
  name = "Angular " + VERSION.major;
  page: number = 1;
  tree_card_Buyer: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  selectedUser = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  contactPersonName1: string = '';
  designation1: string = '';
  phone1: string = '';
  mobile1: string = '';
  email1: string = '';

  constructor( 
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    //.webService.toggleHeaderFooterVisibility();
  }
 
  ngOnInit(): void {
    this.loadTreeBuyer();
    let url = "Buyer/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card_Buyer = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card_Buyer
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
            }
          });

        } else {

        }
      }
    );
   // this.loadTreeBuyer();
    this.loadBankMaster();
    
  }

  loadTreeBuyer = () => {
    let url = "Buyer/TreeBuyerList";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        let dataList = "";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          data12[0].forEach(element => {
            dataList += "<tr><td>" + element.Buyer_Code + "</td><td>" + element.Buyer_Name + "</td><td>" + element.Email + "</td><td>" + element.Phone + "</td><td>" + element.Telex + "</td><td>" + element.Fax + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.Bank_Code + "</td><td>" + element.Created_By + "</td><td>" + element.Created_Date + "</td><td>" + element.Modified_By + "</td><td>" + element.Modified_Date + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;
          $('#html').jstree();
        } else {

        }
      }
    );
  }
  deleteDialog = () => {
    this.dialogDelete = true;
  }

  clearInputFields() {
    this.contactPersonName1 = '';
    this.designation1 = '';
    this.phone1 = '';
    this.mobile1 = '';
    this.email1 = '';
  }

  AddDialog = () => {
    this.clearInputFields();
    $('#modal_signup').modal('show');
  }

  editDialog = (id) => {
    $('#modalEdit' + id).modal('show');
  }

  closeDialog = (id) => {
    if(id == "add"){
      $('#modal_signup').modal('hide');
    }else{
      $('#modalEdit' + id).modal('hide');
    }
    
  }

  editSaveDialog = (ContactPersonCode, ContactPersonName, Designation, Phone, Mobile, Email) => {
    debugger
    let data = {
      buyerCode: this.buyerCode,
      contactPersonCode: ContactPersonCode,
      contactPersonName: ContactPersonName,
      designation: Designation,
      phone: Phone,
      mobile: Mobile,
      email: Email
    }
    let url = "Buyer/EditBuyerContactInfo";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Buyer Contact Info Edit successfully</p>',
            showConfirmButton: false,
            timer: 2500
          });
          $('#modalEdit' + ContactPersonCode).modal('hide');
          this.loadContactPersionList(this.buyerCode);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );
  }

  addSaveDialog = (ContactPersonCode, ContactPersonName, Designation, Phone, Mobile, Email) => {
    let data = {
      buyerCode: this.buyerCode,
      contactPersonCode: ContactPersonCode,
      contactPersonName: ContactPersonName,
      designation: Designation,
      phone: Phone,
      mobile: Mobile,
      email: Email
    }
    let url = "Buyer/AddBuyerContactInfo";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          $('#modal_signup').modal('hide');
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Buyer Contact Info Added successfully</p>',
            showConfirmButton: false,
            timer: 2500
          });
          this.loadContactPersionList(this.buyerCode);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );
  }

  cancelDialog = () => {
    this.dialogIn = false;
    this.dialogEdit = false;
    this.dialogDelete = false;
  }

  openDialog = () => {
    this.dialogIn = true;
  }

  loadBankMaster = () => {
    let url = "BankMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.status === 'OK') {
          debugger
          let data12 = (new Function("return [" + data.data + "]")());
          this.bankMasterList = data12[0];
        } else {

        }
      }
    );
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.loadBankMaster();
  }

  TreeListBinding = (data, id) => {
    try {
      this.selectedUser = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url = "Buyer/EditBuyerCodeAndNameList";
      this.webService.loadTreetListEdit(url, data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.tree_edit_list = data12[0][0];
            this.buyerCode = this.tree_edit_list.Buyer_Code;
            this.loadContactPersionList(this.tree_edit_list.Buyer_Code);
            this.selectedUser = this.tree_edit_list.Bank_Code;
            this.loadBankMaster();

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }


  CodeAlredyExist = "";
  CodeCheckUnique(code) {
    let url = "Buyer/EditBuyerCodeAndNameList";
    this.webService.loadTreetListEdit(url, code).subscribe(data => {
      if (data.status === 'OK') {
        this.CodeAlredyExist = "Buyer Code already exists";
      }
      else {
        this.CodeAlredyExist = "Buyer Code is correct";
      }
    });
  }

  loadContactPersionList = (data = "") => {
    this.ContactPersion_list = [];
    let url = "Buyer/BuyerCode";
    this.webService.loadTreetListEdit(url, data).subscribe(
      (data: any) => {
        debugger
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          debugger;
          this.ContactPersion_listCountAdd = data12[0].length + 1;
          this.ContactPersion_list = data12[0];

        } else {

        }

      }
    );
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/buyer']);
    });
    // let currentUrl = this.router.url;
    // this.router.navigateByUrl('/suppler', {skipLocationChange: true}).then(() => {
    //     this.router.navigate([currentUrl]);
    // });
  }



  addBuyerMaster = (Buyer_Code, Buyer_Name, Add1, Add2, Add3, Phone, Fax, Telex, Email, Bank_Name) => {
    if(this.CodeAlredyExist==="Buyer Code is correct"){

      let data = {
        buyer_Code: Buyer_Code,
        buyer_Name: Buyer_Name,
        add1: Add1,
        add2: Add2,
        add3: Add3,
        phone: Phone,
        fax: Fax,
        telex: Telex,
        email: Email,
        bank_Code: Bank_Name.split(': ')[1]
      }
      debugger
      let url = "Buyer/AddBuyer";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;">Buyer Added successfully</p>',
              showConfirmButton: false,
              timer: 2500
            });
            this.reloadCurrentRoute();
            //  this.myForm.resetForm();
            let data12 = (new Function("return [" + data.data + "]")());
            this.ContactPersion_list = data12[0];
            console.log(this.ContactPersion_list);
            this.loadContactPersionList(Buyer_Code);
  
            this.loadBankMaster();
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
              showConfirmButton: false,
              timer: 2500
            });
            this.reloadCurrentRoute();
          }
  
        }
      );
    }
    else{
      alert('Allrady Not Added')
      this.reloadCurrentRoute();
    }
    

  }

  editBuyerMaster = (Buyer_Code, Buyer_Name, Add1, Add2, Add3, Phone, Fax, Telex, Email, Bank_Name) => {
    let data = {
      buyer_Code: Buyer_Code,
      buyer_Name: Buyer_Name,
      add1: Add1,
      add2: Add2,
      add3: Add3,
      phone: Phone,
      fax: Fax,
      telex: Telex,
      email: Email,
      bank_Code: Bank_Name.split(': ')[1]
    }
    let url = "Buyer/EditBuyer";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;">Buyer updated successfully</p>',
            showConfirmButton: false,
            timer: 2500
          });
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.loadBankMaster();
          this.loadContactPersionList(Buyer_Code);
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Somethink want wrong</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong>
            </h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Buyer Master</strong></h5>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Email</th>
		      <th>Phone</th>
          <th>Telex</th>
          <th>Fax</th>
		      <th>Addrss</th>
          <th>Created_By</th>
          <th>Created_Date</th>
		      <th>Modified_By</th>
          <th>Modified_Date</th> 
        </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}