import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { WebServiceService } from 'src/app/services/web-service.service';
import Swal from 'sweetalert2';
declare let $: any;

@Component({
  selector: 'app-item-master',
  templateUrl: './item-master.component.html',
  styleUrls: ['./item-master.component.css']
})
export class ItemMasterComponent implements OnInit {
  @ViewChild('lazy') private lazyTree!: ElementRef;
  show = true;
  fullScreen = true;
  template = '';
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  iconColor: any
  borderColor: any;
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';
  AddCheckItemVlaue = "";
  isCheckArticleNo: any = "";
  isCheckArticleNo1: any = "";
  isCheckItemCode: any = "";
  name = "Angular " + VERSION.major;
  page: number = 1;
  tree_card_Buyer: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  ImageUploadFile: any;
  selectedUser: any = null;
  selectedSize: any = null;
  selectedUser1: any = null;
  selectedSize1: any = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  isCheckDataBase: boolean = false;
  SizeMasterList: any = [];
  ItemCategoryList: any = [];
  progress: number = 0;
  uploadForm = new FormGroup({
    ArticleNo: new FormControl("", Validators.required),
    Item_Code: new FormControl(""),
    Description: new FormControl("", Validators.required),
    Composition: new FormControl("", Validators.required),
    Fabric: new FormControl("", Validators.required),
    Size: new FormControl("", Validators.required),
    SamplingItem: new FormControl(false),
    item_sequence: new FormControl(""),
    GSM: new FormControl(""),
    Item_Category: new FormControl(""),
    supplier_ref_no: new FormControl(""),
    buyer_article_no: new FormControl(""),
    ITEM_IMAGE: new FormControl(null),
  });
  uploadFormEdit = new FormGroup({
    ArticleNo: new FormControl({ value: "", disabled: true }, Validators.required),
    Item_Code: new FormControl({ value: "", disabled: true }, Validators.required),
    Description: new FormControl("", Validators.required),
    Composition: new FormControl("", Validators.required),
    Fabric: new FormControl("", Validators.required),
    Size1: new FormControl("", Validators.required),
    SamplingItem: new FormControl(false),
    item_sequence: new FormControl(""),
    GSM: new FormControl(""),
    Item_Category1: new FormControl(""),
    supplier_ref_no: new FormControl(""),
    buyer_article_no: new FormControl(""),
    ITEM_IMAGE: new FormControl(null),
  });
  constructor(
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private _fb: FormBuilder,
    private httpClient: HttpClient
  ) {

  }

  handleDragEnter() {
    console.log('handleDragEnter');
    this.dragging = true;
  }

  handleDragLeave() {
    console.log('handleDragLeave');
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
    this.iconColor = this.overlayColor;
  }

  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  reset() {
    this.uploadForm.reset();
  }

  handleInputChange(e) {
    console.log('input change');


    //const file = (e.target as HTMLInputElement).files[0];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    this.uploadForm.patchValue({
      ITEM_IMAGE: file
    });
    this.uploadForm.get('ITEM_IMAGE').updateValueAndValidity();
    //this.uploadForm.updateValueAndValidity();
    var reader = new FileReader();

    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  handleInputChangedata(e) {
    console.log('input change');


    //const file = (e.target as HTMLInputElement).files[0];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    this.uploadFormEdit.patchValue({
      ITEM_IMAGE: file
    });
    this.uploadFormEdit.get('ITEM_IMAGE').updateValueAndValidity();
    //this.uploadForm.updateValueAndValidity();
    var reader = new FileReader();
    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    console.log('_handleReaderLoaded');
    this.isCheckDataBase = true;
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }

  _setActive() {
    this.borderColor = this.activeColor;
    if (this.imageSrc.length === 0) {
      this.iconColor = this.activeColor;
    }
  }

  _setInactive() {
    this.borderColor = this.baseColor;
    if (this.imageSrc.length === 0) {
      this.iconColor = this.baseColor;
    }
  }

  cancel() {
    this.imageSrc = 'null';
  }

  options2 = {
    allowDrag: node => {
      return node.data.isDraggable;
    },
    allowDrop: (element, { parent, index }) => {
      // return true / false based on element, to.parent, to.index. e.g.
      //console.log(parent.children?.includes(element));
      return parent.children?.includes(element);
    },
    getNodeClone: (node) => ({
      ...node.data,
      id: 1111,
      name: `copy of ${node.data.name}`
    })
  };

  options = {};

  scroll(tree) {
    //console.log(tree);
    tree.treeModel.getNodeById(6).focus();
    tree.treeModel.getNodeById(6).scrollIntoView();
  }

  ngOnInit(): void {
    let url = "ItemMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card_Buyer = data.listOfIndexes;
          debugger
          if (this.tree_card_Buyer[0].children.length > 0) {
            $('#lazy').jstree({
              core: {
                data: this.tree_card_Buyer
              }
            }).on('changed.jstree', (e, data) => {
              if (data.selected.length) {
                this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
              }
            });
            this.show = false;
          }


        } else {

        }
      }
    );
    this.loadBankMaster();
    this.loadSizeMaster();
    this.loadItemCategory();


  }

  loadTreeBuyer = () => {
    let url = "ItemMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        let dataList = "";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card_Buyer = data12[0];
          // this.tree_card_Buyer.forEach(element => {
          //   dataList += "<tr><td>" + element.Buyer_Code + "</td><td>" + element.Buyer_Name + "</td><td>" + element.Email + "</td><td>" + element.Phone + "</td><td>" + element.Telex + "</td><td>" + element.Fax + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.Bank_Code + "</td><td>" + element.Created_By + "</td><td>" + element.Created_Date + "</td><td>" + element.Modified_By + "</td><td>" + element.Modified_Date + "</td></tr>";
          // });
          // this.tree_edit_list_print = dataList;

        } else {

        }
      }
    );
  }
  deleteDialog = () => {
    this.dialogDelete = true;
  }


  TreeContactItemSize = (event) => {
    const Item_Code = event.target.value;
    try {
      ///this.isCheckItemCode = this.isCheckArticleNo+"/"+Item_Code;
      this.uploadForm.patchValue({
        Item_Code: this.isCheckArticleNo1 + "/" + Item_Code.split(': ')[1]
      });
    }
    catch (e) {

    }

  }


  TreeContactBuyer = (event) => {
    const Item_Code = event.target.value;
    let is_code = this.isCheckArticleNo + "/" + Item_Code.split(': ')[1];
    try {

      let url = "ItemMaster/CodeCheckUniqueItemCode?code=" + this.isCheckArticleNo + "&itemcode=" + is_code;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            alert('ITEM CODE ALREADY EXISTS');
            this.uploadFormEdit.patchValue({
              Item_Code: this.isCheckArticleNo + "/" + Item_Code.split(': ')[1]
            });
          } else {
            this.uploadFormEdit.patchValue({
              Item_Code: this.isCheckArticleNo + "/" + Item_Code.split(': ')[1]
            });
          }
        }
      );
    }
    catch (e) {

    }

  }

  loadBankMaster = () => {
    let url = "BankMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.status === 'OK') {

          let data12 = (new Function("return [" + data.data + "]")());
          this.bankMasterList = data12[0];
        } else {

        }
      }
    );
  }

  loadItemCategory = () => {
    let url = "ItemCategory/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        if (data.status === 'OK') {

          let data12 = (new Function("return [" + data.data + "]")());
          this.ItemCategoryList = data12[0];
        } else {

        }
      }
    );
  }

  loadSizeMaster = () => {
    let url = "SizeMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.SizeMasterList = data12[0];
        } else {

        }
      }
    );
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.selectedSize = null;
    this.loadBankMaster();
    this.handleImageLoad();

    this.cancel();
    this.dragging = false;
    this.imageLoaded = false;
    //overlayColor: string = 'rgb(204, 204, 204)';
    this.iconColor = 'rgb(204, 204, 204)';

  }

  TreeListBinding = (Item_Code, Article_Code) => {
    try {
      let data = {
        Item_Code: Item_Code,
        Article_Code: Article_Code
      }

      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url = "ItemMaster/Single";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            debugger
            this.tree_edit_list = data12[0][0];
            if (this.tree_edit_list.Article_Or_Sample === 'Y') {
              this.isChecked = true;
            }
            else {
              this.isChecked = false;
            }
            this.isCheckArticleNo = this.tree_edit_list.Article_Code;
            this.AddCheckItemVlaue = this.tree_edit_list.Item_Code;
            this.uploadFormEdit = this._fb.group({
              ArticleNo: [this.tree_edit_list.Article_Code],
              Item_Code: [this.tree_edit_list.Item_Code],
              Description: [this.tree_edit_list.Item_Description],
              Composition: [this.tree_edit_list.Composition],
              Fabric: [this.tree_edit_list.Fabric],
              Size1: [this.tree_edit_list.Item_Size],
              SamplingItem: [this.isChecked],
              GSM: [this.tree_edit_list.GSM],
              Item_Category1: [this.tree_edit_list.Article_Category_Code],
              // Item_Category1: [this.tree_edit_list.Item_Category],
              item_sequence: [this.tree_edit_list.item_sequence],
              supplier_ref_no: [this.tree_edit_list.Buyer_Ref_No],
              buyer_article_no: [this.tree_edit_list.Supplier_Ref_No],
              ITEM_IMAGE: [''],
            });
            this.ImageUploadFile = this.tree_edit_list.ITEM_IMAGE;
            this.selectedSize1 = this.tree_edit_list.Item_Size

            this.imageSrc = 'data:image/jpeg;base64,' + this.tree_edit_list.ITEM_IMAGEDisplay;
            this.loadBankMaster();

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }


  CodeAlredyExist = "";
  CodeCheckUnique(data) {
    let code = data.target.value;
    this.isCheckArticleNo1 = code;
    if (code == "") {
      this.CodeAlredyExist = "";
    }
    else {
      let url = "ItemMaster/CodeCheckUnique?code=" + code;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.CodeAlredyExist = "ARTICLE NO ALREADY EXISTS";
          } else {
            this.CodeAlredyExist = "";
          }
        }
      );
    }
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/item/master']);
    });
  }

  onSubmit() {
    console.log(this.isCheckDataBase);
    debugger
    this.show = false;
    if (this.uploadForm.valid) {
      let SamplingItem, item_sequence, buyer_article_no, supplier_ref_no, Item_Category, GSM, Fabric, Size;
      if (this.uploadForm.value.SamplingItem === true) {
        SamplingItem = 'Y';
      } else {
        SamplingItem = 'N';
      }
      if (this.uploadForm.value.item_sequence === null
        || this.uploadForm.value.item_sequence === "") {
        item_sequence = "0";
      }
      else {
        item_sequence = this.uploadForm.value.item_sequence.toString();
      }
      if (this.uploadForm.value.buyer_article_no === null
        || this.uploadForm.value.buyer_article_no === ""
        || this.uploadForm.value.buyer_article_no === "Not Data"
      ) {
        buyer_article_no = "Not Data";
      }
      else {
        buyer_article_no = this.uploadForm.value.buyer_article_no.toString();
      }
      if (this.uploadForm.value.supplier_ref_no === null
        || this.uploadForm.value.supplier_ref_no === ""
        || this.uploadForm.value.supplier_ref_no === "Not Data"
      ) {
        supplier_ref_no = "Not Data";
      }
      else {
        supplier_ref_no = this.uploadForm.value.supplier_ref_no.toString();
      }
      if (this.uploadForm.value.Item_Category === null
        || this.uploadForm.value.Item_Category === ""
        || this.uploadForm.value.Item_Category === "Not Data"
      ) {
        Item_Category = "";
      }
      else {
        Item_Category = this.uploadForm.value.Item_Category.toString();
      }
      if (this.uploadForm.value.GSM === null
        || this.uploadForm.value.GSM === ""
        || this.uploadForm.value.GSM === "0"
      ) {
        GSM = "0";
      }
      else {
        GSM = this.uploadForm.value.GSM.toString();
      }
      if (this.uploadForm.value.Fabric === null
        || this.uploadForm.value.Fabric === ""
        || this.uploadForm.value.Fabric === "0"
      ) {
        Fabric = "";
      }
      else {
        Fabric = this.uploadForm.value.Fabric.toString();
      }
      if (this.uploadForm.value.Size === null
        || this.uploadForm.value.Size === ""
        || this.uploadForm.value.Size === "0"
      ) {
        Size = "";
      }
      else {
        Size = this.uploadForm.value.Size.toString();
      }

      let Item_Code = this.uploadForm.value.ArticleNo + "/" + this.uploadForm.value.Size;
      if (this.uploadForm.value.ITEM_IMAGE == null) {
        debugger
        let url = "ItemMaster/AddWithOutImage";
        this.webService.ImageUploadDataBase(url,
          this.uploadForm.value.ArticleNo,
          Item_Code,
          this.uploadForm.value.Description,
          this.uploadForm.value.Composition,
          this.uploadForm.value.Fabric,
          Size,
          SamplingItem,
          item_sequence,
          GSM,
          Item_Category,
          supplier_ref_no,
          buyer_article_no
        ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.progress}%`);

              break;
            case HttpEventType.Response:
              this.show = false;
              alert('Item Master successfully created!');
              console.log('User successfully created!', event.body);
              this.reloadCurrentRoute()
              setTimeout(() => {
                this.progress = 0;
              }, 1500);
          }
        });
      }
      else {
        let url = "ItemMaster/Add";
        this.webService.ImageUpload(url,
          this.uploadForm.value.ArticleNo,
          Item_Code,
          this.uploadForm.value.Description,
          this.uploadForm.value.Composition,
          this.uploadForm.value.Fabric,
          Size,
          SamplingItem,
          item_sequence,
          GSM,
          Item_Category,
          supplier_ref_no,
          buyer_article_no,
          this.uploadForm.value.ITEM_IMAGE
        ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.progress}%`);

              break;
            case HttpEventType.Response:
              this.show = false;
              alert('Item Master successfully created!');
              console.log('User successfully created!', event.body);
              this.reloadCurrentRoute()
              setTimeout(() => {
                this.progress = 0;
              }, 1500);
          }
        });
      }
    }
    else {
      this.validateAllFormFields(this.uploadForm);
    }

  }

  onEditSubmit() {
    this.show = false;
    if (this.uploadFormEdit.valid) {
      debugger
      let SamplingItem, item_sequence, buyer_article_no, supplier_ref_no, Item_Category1, GSM, Fabric, Size1;
      if (this.uploadFormEdit.value.SamplingItem === true) {
        SamplingItem = 'Y';
      }
      else if (this.uploadFormEdit.value.SamplingItem === false) {
        SamplingItem = 'N';
      }
      else {
        SamplingItem = 'N';
      }
      if (this.uploadFormEdit.value.item_sequence === null
        || this.uploadFormEdit.value.item_sequence === "") {
        item_sequence = "0";
      }
      else {
        item_sequence = this.uploadFormEdit.value.item_sequence.toString();
      }
      if (this.uploadFormEdit.value.buyer_article_no === null
        || this.uploadFormEdit.value.buyer_article_no === ""
        || this.uploadFormEdit.value.buyer_article_no === "Not Data"
      ) {
        buyer_article_no = "Not Data";
      }
      else {
        buyer_article_no = this.uploadFormEdit.value.buyer_article_no.toString();
      }
      if (this.uploadFormEdit.value.supplier_ref_no === null
        || this.uploadFormEdit.value.supplier_ref_no === ""
        || this.uploadFormEdit.value.supplier_ref_no === "Not Data"
      ) {
        supplier_ref_no = "Not Data";
      }
      else {
        supplier_ref_no = this.uploadFormEdit.value.supplier_ref_no.toString();
      }
      if (this.uploadFormEdit.value.Item_Category1 === null
        || this.uploadFormEdit.value.Item_Category1 === ""
        || this.uploadFormEdit.value.Item_Category1 === "Not Data"
      ) {
        Item_Category1 = "";
      }
      else {
        Item_Category1 = this.uploadFormEdit.value.Item_Category1.toString();
      }
      if (this.uploadFormEdit.value.GSM === null
        || this.uploadFormEdit.value.GSM === ""
        || this.uploadFormEdit.value.GSM === "0"
      ) {
        GSM = "0";
      }
      else {
        GSM = this.uploadFormEdit.value.GSM.toString();
      }
      if (this.uploadFormEdit.value.Fabric === null
        || this.uploadFormEdit.value.Fabric === ""
        || this.uploadFormEdit.value.Fabric === "0"
      ) {
        Fabric = "";
      }
      else {
        Fabric = this.uploadFormEdit.value.Fabric.toString();
      }
      if (this.uploadFormEdit.value.Size1 === null
        || this.uploadFormEdit.value.Size1 === ""
        || this.uploadFormEdit.value.Size1 === "0"
      ) {
        Size1 = "";
      }
      else {
        Size1 = this.uploadFormEdit.value.Size1.toString();
      }

      if (this.isCheckDataBase) {

        let url = "ItemMaster/Edit";
        this.webService.ImageUpload(url,
          this.uploadFormEdit.value.ArticleNo,
          this.uploadFormEdit.value.Item_Code,
          this.uploadFormEdit.value.Description,
          this.uploadFormEdit.value.Composition,
          Fabric,
          Size1,
          SamplingItem,
          item_sequence,
          GSM,
          Item_Category1,
          supplier_ref_no,
          buyer_article_no,
          this.uploadFormEdit.value.ITEM_IMAGE
        ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
              this.show = false;
              alert('Item Master successfully edit!');
              console.log('User successfully created!', event.body);
              //this.reloadCurrentRoute()
              this.loadTreeBuyer();
              setTimeout(() => {
                this.progress = 0;
              }, 1500);
          }
        });
      }
      else {
        let url;
        if (this.AddCheckItemVlaue === this.uploadFormEdit.value.Item_Code) {
          url = "ItemMaster/EditData";
          this.webService.ImageUploadDataBase(url,
            this.uploadFormEdit.value.ArticleNo,
            this.uploadFormEdit.value.Item_Code,
            this.uploadFormEdit.value.Description,
            this.uploadFormEdit.value.Composition,
            Fabric,
            Size1,
            SamplingItem,
            item_sequence,
            GSM,
            Item_Category1,
            supplier_ref_no,
            buyer_article_no,
          ).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('Request has been made!');
                break;
              case HttpEventType.ResponseHeader:
                console.log('Response header has been received!');
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round(event.loaded / event.total * 100);
                console.log(`Uploaded! ${this.progress}%`);
                break;
              case HttpEventType.Response:
                this.show = false;
                alert('Item Master successfully edit!');
                console.log('User successfully created!', event.body);
                //this.reloadCurrentRoute()
                this.loadTreeBuyer();
                setTimeout(() => {
                  this.progress = 0;
                }, 1500);
            }
          })
        }
        else {
          url = "ItemMaster/AddWithOutImage";
          this.webService.ImageUploadDataBase(url,
            this.uploadFormEdit.value.ArticleNo,
            this.uploadFormEdit.value.Item_Code,
            this.uploadFormEdit.value.Description,
            this.uploadFormEdit.value.Composition,
            Fabric,
            Size1,
            SamplingItem,
            item_sequence,
            GSM,
            Item_Category1,
            supplier_ref_no,
            buyer_article_no
          ).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('Request has been made!');
                break;
              case HttpEventType.ResponseHeader:
                console.log('Response header has been received!');
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round(event.loaded / event.total * 100);
                console.log(`Uploaded! ${this.progress}%`);
                break;
              case HttpEventType.Response:
                this.show = false;
                alert('Item Master successfully edit!');
                console.log('User successfully created!', event.body);
                this.loadTreeBuyer();
                //this.reloadCurrentRoute()
                setTimeout(() => {
                  this.progress = 0;
                }, 1500);
            }
          })
        }
      }

    }
    else {
      this.validateAllFormFields(this.uploadFormEdit);
    }

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Email</th>
		      <th>Phone</th>
          <th>Telex</th>
          <th>Fax</th>
		      <th>Addrss</th>
          <th>Created_By</th>
          <th>Created_Date</th>
		      <th>Modified_By</th>
          <th>Modified_Date</th> 
        </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}
