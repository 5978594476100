// doc-upload.component.ts

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-copy-order',
  templateUrl: './copy-order.component.html',
  styleUrls: ['./copy-order.component.css']
})
export class CopyOrderComponent implements OnInit {
  order_id: any;
  order_code: any; 
  saveFileForm: any; 
  country_lis:any =[];
  uploadForm = new FormGroup({
    oldOrder: new FormControl('', Validators.required),
    newoldOrder: new FormControl('', Validators.required) 
  });
  constructor(
    private webService: WebServiceService,
    private formBuilder: FormBuilder
  ) {

  }
 
  ngOnInit(): void {
    // this.saveFileForm = this.formBuilder.group({
    //   oldOrder: ['', [Validators.required]],
    //   newoldOrder: ['', [Validators.required]],
    // });
  }

  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

 displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
 
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 
  reset() {
    this.uploadForm.reset();
  }

  onExpSubmit() {
     if (this.uploadForm.valid) {
      let oldOrder = this.uploadForm.value.oldOrder;
      let newoldOrder = this.uploadForm.value.newoldOrder;
      this.country_lis = [];
      let url = "Order/CopyOrder?oldOrderCode="+oldOrder+"&newOrderCode="+newoldOrder;
      this.webService.loadTreeLiat(url).subscribe(
        (data: any) => {
          if (data.status === 'OK') {
            this.reset();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;">'+data.message+'</p>',
              showConfirmButton: false,
              timer: 2500
            });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              iconColor: '#000',
              background: '#FFFFFF',
              html: '<p style="font-weight: 500;color: black;font-size: 20px;">'+data.message+'</p>',
              showConfirmButton: false,
              timer: 2500
            });
          }
        }
      );
      
    } else {
      this.validateAllFormFields(this.uploadForm);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        iconColor: '#000',
        background: '#FFFFFF',
        html: '<p style="font-weight: 500;color: black;font-size: 20px;">Please fill select value</p>',
        showConfirmButton: false,
        timer: 2500
      });
    }
  }
}
