<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Merchandising Details</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>

        <section id="content" class="container_12 clearfix" data-sort="true"
            style="padding: 30px;background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="grid_12">
                <fieldset>
                    <legend> Order Detalls </legend>
                    <table style=" border: 2px solid #666666;" height="50" width="100%">
                        <tr>
                            <td style=" border: none; ">Order No.</td>
                            <td style=" border: none; width: 30%;">
                                <mat-form-field class="width" appearance="outline"
                                    style="width: 100%;padding-top: 12px;">
                                    <mat-label>Order No. Contains</mat-label>
                                    <input matInput placeholder="Order No. Contains" aria-label="Order No. Contains"
                                        [matAutocomplete]="auto" [formControl]="OrderNoContainsCtrl" required
                                        [(ngModel)]="search_text_order_no" #orderCode />
                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="onSearchIOrderNoSeletion($event.option.value)">
                                        <mat-option *ngFor="let country of filteredCountry | async"
                                            [value]="country.name">
                                            <span>{{ country.name }}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <td style="border: none;">Shipment No.</td>
                            <td style="border: none;">
                                <input type="hidden" value="{{article_code_Order}}" #article_code />
                                <input type="hidden" value="{{order_id_Order}}" #order_id />
                                <select id="buyer" style="width: 100%;height: 35px;padding: 5px;"
                                    (change)='changeTreeContactShipmentColor($event, orderCode.value)' #ShipmentNo>
                                    <option [ngValue]="null" disabled>Choose your
                                        Size</option>
                                    <option *ngFor="let sipmet of shipment_serialList"
                                        [ngValue]="sipmet.SHIPMENT_SERIAL_NO">
                                        {{sipmet.SHIPMENT_SERIAL_NO}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td style=" border: none; ">
                                <div style="margin-top: -15px;">Buyer</div>
                            </td>
                            <td style=" border: none;">
                                <input type="text" size="15" maxlegth="30" style="height: 35px;margin-bottom: 15px;"
                                    #BuyerValue value="{{BuyerOrder}}" />
                            </td>
                            <td style=" border: none; ">
                                <div style="margin-top: -15px;">Supplier</div>
                            </td>
                            <td style=" border: none;">
                                <input type="text" size="15" maxlegth="30" style="height: 35px;margin-bottom: 15px;"
                                    #SupplierValue value="{{SuplierOrder}}" />
                            </td>
                        </tr>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>Item List</legend>
                    <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Article Code</th>
                                <th>Description</th>
                                <th>Qty</th>
                            </tr>
                        </thead>
                        <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">
                            <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                <td>
                                    {{i + 1}}
                                </td>
                                <td>{{item3.order_article_code}}</td>
                                <td>{{item3.item_description}}</td>
                                <td>{{item3.item_qty}}</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <br />
                    <legend><a href="javascript:void(0);" (click)="addItemModel(this.order_id_number)"><span>Add Merchandising Milestone</span></a></legend>
                    <div class="popup" id="modalEditItemView" style="display: none;">
                        <div class="popup-content">
                            <div class="modal-content" style="width: 70vh;">
                                <div class="modal-header" style="text-align: start;display: flex;    padding: 10px;">
                                    <h5 class="modal-title"> Merchandising Milestone</h5>
                                </div>
                                <div class="modal-body" style="height: 300px;">
                                    <input type="hidden" #order_code value="{{this.search_text_order_no}}"
                                        [ngModelOptions]="{standalone: true}" />
                                    <input type="hidden" #order_id value="{{this.order_id_Order}}"
                                        [ngModelOptions]="{standalone: true}" />
                                    <div class="row">
                                        <div class="grid_12">
                                            <div class="dropdown-menu" [ngClass]="{ 'show': true }"
                                                style=" background-color: #fff0; background-clip: padding-box; border: none; box-shadow: none; ">
                                                <div *ngFor="let option of ItemMasterList">
                                                    <label>
                                                        <input type="checkbox" [checked]="option.checked"
                                                            (change)="option.checked = !option.checked"
                                                            [ngModelOptions]="{standalone: true}">
                                                        {{ option.Code | slice:0:45 }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer" style="padding: 0;display: contents;">
                                    <div class="row" style="padding: 0;">
                                        <div class="grid_6">

                                        </div>
                                        <div class="grid_6" style="display: flex;">
                                            <button type="button" class="btn btn-primary"
                                                (click)="closePopup('singleModel')"
                                                style="margin: 8px 12px;">CANCEL</button>
                                            <button type="button"
                                                style="height: 25px; width: 80px; top: 7px; position: relative;"
                                                (click)="addValueitem(order_code.value,order_id.value)"
                                                class="btn btn-primary" data-dismiss="modal">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <legend>Merchandising Details</legend>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="scroll-table-container">
                                <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                    <thead style="position: relative;z-index: 2;">
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>DO?</th>
                                            <th>SO?</th>
                                            <th>BO?</th>
                                            <th>Date</th>
                                            <th>Remarks</th>
                                            <th>Due Date</th>
                                            <th>App. Date</th>
                                            <th>Sent Date</th>
                                            <th>Ame</th>
                                            <th>A.no.</th>
                                            <th>A. Date</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">
                                        <tr *ngFor="let item3 of this.proShowOrderColorDetalls; let i = index">
                                            <td>
                                                {{i + 1}}
                                            </td>
                                            <td><input type="text" [(ngModel)]="item3.MERCHANDISE_TYPE_NAME"
                                                    [disabled]="!item3.isEditing" name="name{{i}}"
                                                    style=" background: #ffffff;width: 200px; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>
                                            <td><input type="text" [(ngModel)]="item3.STATUS"
                                                    [disabled]="!item3.isEditing" name="name{{i}}"
                                                    style=" background: #ffffff;width: 200px; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>
                                            <td><input type="checkbox" [(ngModel)]="item3.DO" name="name{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style=" background: #ffffff;width: 100px; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>
                                            <td><input type="checkbox" [(ngModel)]="item3.SO" name="name{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>
                                            <td><input type="checkbox" [(ngModel)]="item3.BO" name="name{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>

                                            <td>
                                                <input type="date" [(ngModel)]="item3.MERCHANDISE_DATE"
                                                    name="name12{{i}}" [disabled]="!item3.isEditing"
                                                    style="background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22);" />
                                            </td>
                                            <td><input type="text" [(ngModel)]="item3.REMARKS" name="name{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style=" background: #ffffff; width: 500px;border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>

                                            <td>
                                                <input type="date" [(ngModel)]="item3.DUE_ON_DATE" name="name12{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style="background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22);" />
                                            </td>
                                            <td>
                                                <input type="date" [(ngModel)]="item3.APP_DATE" name="name12{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style="background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22);" />
                                            </td>
                                            <td>
                                                <input type="date" [(ngModel)]="item3.SENT_DATE" name="name1{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style="background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22);" />
                                            </td>
                                            <td><input type="text" [(ngModel)]="item3.AMEND" name="name{{i}}"
                                                    [disabled]="!item3.isEditing"
                                                    style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                            </td>
                                            <td>{{item3.MERCHANDISE_AMEND_NO}}</td>
                                            <td>
                                                <input type="date" [(ngModel)]="item3.MERCHANDISE_AMEND_DATE"
                                                    name="name{{i}}" [disabled]="!item3.isEditing"
                                                    style="background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22);" />
                                            </td>

                                            <td>
                                                <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                                <button *ngIf="item3.isEditing" (click)="saveRowColor(i,orderCode.value, article_code.value, order_id.value, ShipmentNo.value, remarks.value)">Save</button>
                                                <!-- <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button> -->
                                            </td>
                                        </tr>


                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                </fieldset>
                <fieldset>
                    <legend>Remarks</legend>
                    <input type="text" size="15" maxlegth="30" style="width: 100%;" #remarks value="" />
                </fieldset>
                <fieldset>
                    <br />
                    <legend>Merchandising History Details</legend>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="scroll-table-container">
                                <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>DO?</th>
                                            <th>SO?</th>
                                            <th>BO?</th>
                                            <th>Date</th>
                                            <th>Remarks</th>
                                            <th>Due Date</th>
                                            <th>App. Date</th>
                                            <th>Sent Date</th>
                                            <th>Ame</th>
                                            <th>A.no.</th>
                                            <th>A. Date</th>

                                        </tr>
                                    </thead>
                                    <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">
                                        <tr *ngFor="let item3 of this.proShowOrderColorDetalls_history; let i = index">
                                            <td>
                                                {{i + 1}}
                                            </td>
                                            <td>{{item3.MERCHANDISE_TYPE_NAME}}</td>
                                            <td>{{item3.STATUS}}</td>
                                            <td>{{item3.DO}}</td>
                                            <td>{{item3.SO}}</td>
                                            <td>{{item3.BO}}</td>
                                            <td>{{item3.MERCHANDISE_DATE}}</td>
                                            <td>{{item3.REMARKS}}</td>
                                            <td>{{item3.DUE_ON_DATE}}</td>
                                            <td>{{item3.APP_DATE}}</td>
                                            <td>{{item3.SENT_DATE}}</td>
                                            <td>{{item3.AMEND}}</td>
                                            <td>{{item3.MERCHANDISE_AMEND_NO}}</td>
                                            <td>{{item3.MERCHANDISE_AMEND_DATE}}</td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <!-- <fieldset>
                    <div class="row">
                        <div class="col-sm-12" style="top: 20px;">
                            <div class="box validate" style="padding: 40px 15px 15px 15px;">
                                <div class="actions">

                                    <div class="right">
                                        <input type="button"
                                            (click)="saveAllChangesColor(orderCode.value, article_code.value, order_id.value, ShipmentNo.value, remarks.value)"
                                            value="Save" name="submit" style="width: 100px;font-size: small;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset> -->
            </div>


        </section>

    </div>
</div>