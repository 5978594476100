import { Component, OnInit } from '@angular/core'; 
import { WebServiceService } from '../../services/web-service.service';
import { Router } from '@angular/router'; 
declare var $: any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [[WebServiceService]]
})
export class ResetPasswordComponent implements OnInit {

  constructor( 
    private webService: WebServiceService, 
    private router: Router,
  ) { }
  loggedIn:boolean = false;
  errors = [];
  userMobile:any = "";
  userID:any = "";
  loginStep:number = 1;

  ngOnInit() {
    
  }

  showSnackbarCssStyles(content, action, duration) {
		 
	}

  reastPassword(email) {
    this.errors = [];
    
    this.webService
      .sendOTP(this.userID).subscribe((response:any) => {
        if (response.status == 1001) {
          this.errors.push(response.message);
        } else{
          //OTP has been sent
          this.showSnackbarCssStyles('OTP has been sent on registered number.','Close','4000');
          window.location.href= "/login";
        }
      });
  }
}
