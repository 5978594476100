import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service';
import { async } from '@angular/core/testing';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-price-enquiry-report',
  templateUrl: './price-enquiry-report.component.html',
  styleUrls: ['./price-enquiry-report.component.css']
})
export class PriceEnquiryReportComponent implements OnInit {
	report_list: any = [];
  tree_edit_list_print: any = [];
  data: any[];
  columns: any[] = [];
  loading = true;
  currentPage = 1; // Initialize current page
  perPage = 1000000000; // Initialize records per page

  constructor(
    private webService: WebServiceService,
    private route: ActivatedRoute, 
    private router: Router
  ) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.currentPage = +params['currentPage'] || 1;
      this.perPage = +params['perPage'] || 1000;

      this.setupDataTable();
    });
    this.loadTreeSupplie();
   
  }

  setupDataTable() {
    const self = this;

    const url = `ReportViewer/priceEnquiry?currentPage=${this.currentPage}&perPage=${this.perPage}`;

    const columnsData = [
      // Define your column data as needed
      { data: 'Order_Code', title: 'Order Code' },
        { data: 'Order_Date', title: 'Order Date' },
        { data: 'Buyer_Name', title: 'Buyer Name' },
        { data: 'ORDER_ARTICLE_CODE', title: 'ORDER ARTICLE CODE' },
        { data: 'Article_Name', title: 'Article Name' },
        { data: 'ORDER_ITEM_CODE', title: 'ORDER ITEM CODE' },
        { data: 'ITEM_MODE', title: 'ITEM MODE' },
        { data: 'ITEM_QTY', title: 'ITEM QTY' },
        { data: 'ITEM_PRICE', title: 'ITEM PRICE' },
        { data: 'ITEM_COMMISSION', title: 'ITEM COMMISSION' },
        { data: 'TOTAL_FINAL_PRICE', title: 'TOTAL FINAL PRICE' }
      // Add more columns as needed
    ];

    const dataTableConfig = {
      ajax: `${environment.api_url}/${url}`,
      columns: columnsData,
      columnDefs: [
        { sortable: false, targets: [-1] }
      ],
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
      language: {
        paginate: {
          first: '',
          previous: '',
          next: '',
          last: ''
        },
        lengthMenu: 'Records per page: _MENU_',
        info: 'Total of _TOTAL_ records (showing _START_ to _END_)',
        infoFiltered: '(filtered from _MAX_ total records)'
      },
      
    };

    // Initialize the DataTable
    $('#userIdDatatable').DataTable().destroy();
    $('#userIdDatatable').DataTable(dataTableConfig);
  }

  onPerPageChange(value: number) {
    this.perPage = value;
    this.updateUrl();
  }

  onPageChange(event: any) {
    this.currentPage = event.target.value;
    this.updateUrl();
  }

  private updateUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        currentPage: this.currentPage,
        perPage: this.perPage
      },
      queryParamsHandling: 'merge'
    });
  }

  loadTreeSupplie(): void {
    const url = `ReportViewer/priceEnquiry?currentPage=${this.currentPage}&perPage=${this.perPage}`;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.result === 'success') {
          let dataList="";
          data.data.forEach(element => {
            dataList += "<tr><td>" + element.Order_Code + "</td><td>" + element.Order_Date + "</td><td>" + element.Buyer_Name + "</td><td>" + element.ORDER_ARTICLE_CODE + "</td><td>" + element.Article_Name + "</td><td>" + element.ORDER_ITEM_CODE + "</td><td>" + element.ITEM_MODE + "</td><td>" + element.ITEM_QTY + "</td><td>" + element.ITEM_PRICE + "</td><td>" + element.ITEM_COMMISSION + "</td><td>" + element.TOTAL_FINAL_PRICE + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;
           
        } else {
          console.error('Error loading data:', data);
        }
      }
    );
  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong></h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h3 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Price Enquiry Report</strong></h3>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
        <th>Order Code</th>
        <th>Order Date</th>
        <th>Buyer Name</th>
        <th>ORDER ARTICLE CODE</th>
        <th>Article Name</th>
        <th>ORDER ITEM CODE</th>
        <th>ITEM MODE</th>
        <th>ITEM QTY</th>
        <th>ITEM PRICE</th>
        <th>ITEM COMMISSION</th>
        <th>TOTAL FINAL PRICE</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
  
}
