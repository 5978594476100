<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
  .pagination {
      margin: -15px 0;
      display: flex;
      justify-content: center;

  }

  .ngx-pagination {
      margin-left: 0 !important;
      margin-bottom: 1rem !important;
      line-height: 20px !important;
      padding: 7px 15px 7px !important;
      font-size: 12px !important;
  }

  .ngx-pagination::before,
  .ngx-pagination::after {
      content: " " !important;
      display: table !important;
  }

  .ngx-pagination::after {
      clear: both !important;
  }

  .ngx-pagination li {
      margin-right: 0.2rem !important;
      border-radius: 0 !important;
  }

  .ngx-pagination li {
      display: inline-block !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
      color: #0a0a0a !important;
      display: block !important;
      background-color: rgb(248, 248, 248) !important;
      color: black !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
      background: #788b8d88 !important;
  }

  .ngx-pagination .current {
      padding: 0.1875rem 0.625rem !important;
      background: #2199e8 !important;
      color: #fefefe !important;
      cursor: default !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
  }

  .ngx-pagination .disabled {
      padding: 0.1875rem 0.625rem !important;
      color: #cacaca00 !important;
      cursor: default !important;
  }

  .ngx-pagination .disabled:hover {
      background: transparent !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
      cursor: pointer !important;
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
      font-family: FontAwesome;
      content: "\f053" !important;
      display: inline-block !important;
      margin-right: 0rem !important;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
      font-family: FontAwesome !important;
      content: "\f054" !important;
      display: inline-block !important;
      margin-left: 0rem !important;
  }

  .ngx-pagination .show-for-sr {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
  }

  .ngx-pagination .small-screen {
      display: none !important;
  }

  @media screen and (max-width: 601px) {
      .ngx-pagination.responsive .small-screen {
          display: inline-block !important;
          color: black !important;
          background-color: rgb(248, 248, 248) !important;
          border: 2px solid #8b8b8b !important;
          padding: 0.5rem 1rem !important;
          border-radius: 9% !important;
      }

      .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
          display: none !important;
      }
  }

  .table>thead>tr>th {
      vertical-align: bottom;
      border-bottom: 2px solid #67676a !important;
      border-top: 1px solid #dddddd00 !important;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: top;
      border-left: 2px solid #67676a !important;
      border-top: 1px solid #dddddd00 !important;
  }

  tr td {
      border-color: #dddddd00 !important;
  }

  legend {
      display: block;
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      font-size: 21px;
      line-height: inherit;
      color: #333;
      border: 1px solid #e5e5e5;
  }
</style>
<main class="container" id="section-to-print">
  <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
  <!-- invoice Description -->
  <div class="card-body border-top pt-20 mt-0">
      <div class="row">
          <div class="col-sm-6">
              <div class="invoice-details">
                  <h5 class="mb-10 text-18 text-capitalize"><strong>YOUNG FASHION (INDIA)</strong></h5>
                  <div class="invoice-details-inner mt-2">
                      <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                          <br /> INDIA
                      </p>
                      <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
                      <p><strong>Email:</strong> mail@youngfashionindia.com</p>
                  </div>
              </div>
          </div>
          <div class="col-sm-6"  id="print_display_is_not">
              <fieldset>
                  <legend>
                      <p
                          style=" margin-top: -30px; width: 210px; background: white; padding: 6px 13px; margin-bottom: 10px; ">
                          Report Praameters</p>
                      <div clas="row">
                          <div class="grid_10" style="margin-bottom: 15px;width: 90%;">
                              <mat-form-field class="width" appearance="outline" style="width: 100%;padding-top: 12px;">
                                  <mat-label>Supplier</mat-label>
                                  <input matInput placeholder="Supplier" aria-label="Supplier"
                                      [matAutocomplete]="auto" [formControl]="OrderNoContainsCtrl" required
                                      [(ngModel)]="search_text_order_no" #orderCode />
                                  <mat-autocomplete #auto="matAutocomplete"
                                      (optionSelected)="onSearchIOrderNoSeletion($event.option.value)">
                                      <mat-option *ngFor="let country of filteredCountry | async" [value]="country.name">
                                          <span>{{ country.name }}</span>
                                      </mat-option>
                                  </mat-autocomplete>
                              </mat-form-field>

                          </div>
                           
                      </div>
                  </legend>
              </fieldset>
          </div>
          <div class="col-sm-12">
              <hr style="height: 1px; margin: 1px; border-bottom: 2px solid #67676a;" />
          </div>
          <div class="col-sm-12">
              <h2 class="mb-10 text-18 text-capitalize" style="text-align: center;font-size: x-large;"><strong>Supplier Wise</strong>
              </h2>
          </div>

      </div>

  </div>

  <div class="table-responsive invoice-table mb-4 mt-0" style="margin: 8px;">
    <div class="dt-buttons" id="report_viewer_dt_buttons"><button (click)="printPage1()" class="dt-button buttons-print" style="background: blueviolet;" type="button"><span><i style="font-size:24px;color:#ffffff" class="fa fa fa-print fa-2x"></i></span></button> </div> 
    <table class="table" style="margin: 8px 0px 12px 0px;border: 2px solid #000000;background: white !important;">
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Item Size</th>
            <th>Mode</th>
            <th>Qty.</th>
            <th>Price</th>
            <th>Inv. Price</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of this.report_list; let idata = index ">
            <td>{{idata + 1 }}</td>
            <td>{{item.ORDER_ITEM_CODE}}</td>
            <td>{{item.ITEM_MODE}}</td>
            <td>{{item.ITEM_PRICE_DETAIL_QTY}}</td>
            <td>{{item.ITEM_PRICE}}</td>
            <td>{{item.ITEM_INVOICE_PRICE}}</td>
            <td>{{item.TOTAL_FINAL_PRICE}}</td>
          </tr>
          <tr style="border: 2px solid #000000 !important;">
            <td></td>
            <td></td>
            <td><strong>Totals:</strong></td>
            <td><strong>{{this.qtyOrder}}</strong></td>
            <td><strong>{{this.PriceOrder}}</strong></td>
            <td><strong>{{this.InvPriceOrder}}</strong></td>
            <td><strong>{{this.TotalPriceOrder}}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  
</main>