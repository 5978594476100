<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Item Master</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img
                                src="./assets/images/Add.png" style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>
        <aside style="width: 350px;">
            
            <ul class="top" style="margin-top: 0px; height: 700px; background-color: white;text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                <div id="lazy" class="demo"></div>
            </ul>
        </aside>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                <div class="grid_12" style="margin-top: 0px;">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 15px;background: white;width: 169px;z-index: 1;position: relative;">
                        Item Details</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_9">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Article No</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text"
                                                (keyup)="CodeCheckUnique($event)" name="v1_normal_input"
                                                id="v1_normal_input" formControlName="ArticleNo" />
                                                <app-field-error-display [displayError]="isFieldValid('ArticleNo')" errorMsg="Please  enter Article No">
                                                </app-field-error-display>
                                        </div>
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Item Code</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text"
                                                (change)="CodeCheckUnique($event)" name="v1_normal_input"
                                                id="v1_normal_input" formControlName="Item_Code" />
                                                <app-field-error-display [displayError]="isFieldValid('Item_Code')" errorMsg="Please  enter Item Code">
                                                </app-field-error-display>
                                        </div>
                                        <div class="grid_12">
                                            <div *ngIf="CodeAlredyExist != ''" class="alert alert-danger">
                                                {{CodeAlredyExist}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Description</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <textarea formControlName="Description" rows="5" cols="50"></textarea>
                                            <app-field-error-display [displayError]="isFieldValid('Description')" errorMsg="Please  enter Description">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Composition</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <textarea formControlName="Composition" rows="5" cols="50"></textarea>
                                            <app-field-error-display [displayError]="isFieldValid('Composition')" errorMsg="Please  enter Composition">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Fabric</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="Fabric" />
                                            <app-field-error-display [displayError]="isFieldValid('Fabric')" errorMsg="Please  enter Fabric">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Size</strong> </label>
                                        </div>
                                        <div class="grid_5">
                                            <select formControlName="Size" [(ngModel)]="selectedSize"
                                                 data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;" (change)='TreeContactItemSize($event)'>
                                                <option [ngValue]="null" disabled>Choose your Size</option>
                                                <option *ngFor="let size of SizeMasterList" [ngValue]="size.PARAM_VALUE">
                                                    {{size.PARAM_VALUE}}
                                                </option>
                                            </select>
                                            <app-field-error-display [displayError]="isFieldValid('Size')" errorMsg="Please  enter Size">
                                            </app-field-error-display>
                                        </div>
                                        <div class="grid_4" style="display: flex;">
                                            <input class="required" type="checkbox" formControlName="SamplingItem" [(ngModel)]="isChecked" style="width: 20px;" /><span style="top: 5px;position: relative;left: 8px;">Sampling
                                                Item</span>
                                                <app-field-error-display [displayError]="isFieldValid('SamplingItem')" errorMsg="Please  enter SamplingItem">
                                                </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Item Sequence</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="number" formControlName="item_sequence" />
                                            <app-field-error-display [displayError]="isFieldValid('item_sequence')" errorMsg="Please  enter item sequence">
                                            </app-field-error-display>
                                        </div>
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>GSM</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="GSM" />
                                            <app-field-error-display [displayError]="isFieldValid('GSM')" errorMsg="Please  enter GSM">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Item Category</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <select formControlName="Item_Category"
                                                [(ngModel)]="selectedUser" 
                                                data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your Item Category</option>
                                                <option *ngFor="let icat of ItemCategoryList" [ngValue]="icat.Article_Category_Code">
                                                    {{icat.Name}}
                                                </option>
                                            </select>
                                            <app-field-error-display [displayError]="isFieldValid('Item_Category')" errorMsg="Please  enter Item Category">
                                            </app-field-error-display>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Supplier Ref. No</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="supplier_ref_no" />
                                            <app-field-error-display [displayError]="isFieldValid('supplier_ref_no')" errorMsg="Please  enter Supplier Ref. No">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Buyer Article No</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="buyer_article_no" />
                                            <app-field-error-display [displayError]="isFieldValid('buyer_article_no')" errorMsg="Please  enter Buyer Article No">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid_3">
                                    <label class="uploader" ondragover="return false;" [class.loaded]="loaded"
                                        [style.outlineColor]="dragging ? activeColor : baseColor"
                                        (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()"
                                        (drop)="handleDrop($event)">
                                        <i class="icon icon-upload" [style.color]="dragging  ? ((imageSrc.length > 0) ? overlayColor : activeColor) : ((imageSrc.length > 0) ? overlayColor : baseColor)"></i>
                                        <img [src]="imageSrc" (load)="handleImageLoad()" [class.loaded]="imageLoaded" />
                                    </label>
                                    <input type="file" accept="image/*"  (change)="handleInputChange($event)">
                                    <!-- <app-field-error-display [displayError]="isFieldValid('file')" errorMsg="Please  enter Image">
                                    </app-field-error-display> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit" value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid_12" style="top: -35px;">
                <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                  </span>
                  <!-- <span class="upload" *ngIf="message">
                    {{message}}
                  </span> -->
                </div>
            </form>

        </section>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="addIn" style="padding-left: 0;">

            <form [formGroup] = "uploadFormEdit" (ngSubmit)="onEditSubmit()" enctype="multipart/form-data">
                <div class="grid_12" style="margin-top: -40px;">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 42px;left: 15px;background: white;width: 169px;z-index: 999;position: relative;">
                        Item Details</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_9">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Article No</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" placeholder="Article No"
                                                (keyup)="CodeCheckUnique($event)"  formControlName="ArticleNo" />
                                                <!-- <app-field-error-display [displayError]="isFieldValid('ArticleNo')" errorMsg="Please  enter Article Code">
                                                </app-field-error-display> -->
                                        </div>
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Item Code</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="Item_Code" />
                                                <!-- <app-field-error-display [displayError]="isFieldValid('Item_Code')" errorMsg="Please  enter Item Code">
                                                </app-field-error-display> -->
                                        </div>
                                        <div class="grid_12">
                                            <!-- <div *ngIf="CodeAlredyExist != ''" class="alert alert-danger">
                                                {{CodeAlredyExist}}
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Description</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <textarea formControlName="Description" rows="5" cols="50"></textarea>
                                            <!-- <app-field-error-display [displayError]="isFieldValid('Description')" errorMsg="Please  enter Description">
                                            </app-field-error-display> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Composition</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <textarea formControlName="Composition" rows="5" cols="50"></textarea>
                                            <!-- <app-field-error-display [displayError]="isFieldValid('Composition')" errorMsg="Please  enter Composition">
                                            </app-field-error-display> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Fabric</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="Fabric" />
                                            <!-- <app-field-error-display [displayError]="isFieldValid('Fabric')" errorMsg="Please  enter Fabric">
                                            </app-field-error-display> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Size</strong> </label>
                                        </div>
                                        <div class="grid_5">
                                            <select formControlName="Size1"  [(ngModel)]="selectedSize1"
                                                 data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;" (change)='TreeContactBuyer($event)'>
                                                <option [ngValue]="null" disabled>Choose your Size</option>
                                                <option *ngFor="let size of SizeMasterList" [ngValue]="size.PARAM_VALUE">
                                                    {{size.PARAM_VALUE}}
                                                </option>
                                            </select>
                                            <!-- <app-field-error-display [displayError]="isFieldValid('Size')" errorMsg="Please  enter Size">
                                            </app-field-error-display> -->
                                        </div>
                                        <div class="grid_4" style="display: flex;">
                                            <input class="required" type="checkbox" formControlName="SamplingItem" [(ngModel)]="isChecked" style="width: 20px;" /><span style="top: 5px;position: relative;left: 8px;">Sampling
                                                Item</span>
                                                <!-- <app-field-error-display [displayError]="isFieldValid('SamplingItem')" errorMsg="Please  enter Sampling Item">
                                                </app-field-error-display> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Item Sequence</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="item_sequence" />
                                            <!-- <app-field-error-display [displayError]="isFieldValid('item_sequence')" errorMsg="Please  enter Item Sequence">
                                            </app-field-error-display> -->
                                        </div>
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>GSM</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="GSM" />
                                            <!-- <app-field-error-display [displayError]="isFieldValid('GSM')" errorMsg="Please  enter GSM">
                                            </app-field-error-display> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Item Category</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <select formControlName="Item_Category1"
                                                [(ngModel)]="selectedUser1" 
                                                data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your Item Category</option>
                                                <option *ngFor="let icat of ItemCategoryList" [ngValue]="icat.Article_Category_Code">
                                                   {{icat.Name}}
                                                </option>
                                            </select>
                                            <!-- <app-field-error-display [displayError]="isFieldValid('Item_Category')" errorMsg="Please  enter Item Category">
                                            </app-field-error-display> -->
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Supplier Ref. No</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="supplier_ref_no"  value="{{this.tree_edit_list.Supplier_Ref_No  || '' }}" />
                                            <!-- <app-field-error-display [displayError]="isFieldValid('supplier_ref_no')" errorMsg="Please  enter Supplier Ref. No">
                                            </app-field-error-display> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Buyer Article No</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="buyer_article_no" value="{{this.tree_edit_list.Supplier_Ref_No  || '' }}" />
                                            <!-- <app-field-error-display [displayError]="isFieldValid('buyer_article_no')" errorMsg="Please  enter Buyer Article No">
                                            </app-field-error-display> -->
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="grid_3">
                                    <label class="uploader" ondragover="return false;" [class.loaded]="loaded"
                                        [style.outlineColor]="dragging ? activeColor : baseColor"
                                        (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()"
                                        (drop)="handleDrop($event)">
                                        <i class="icon icon-upload" [style.color]="dragging  ? ((imageSrc.length > 0) ? overlayColor : activeColor) : ((imageSrc.length > 0) ? overlayColor : baseColor)"></i>
                                        <img [src]="imageSrc" (load)="handleImageLoad()" [class.loaded]="imageLoaded" />
                                    </label>
                                    <input type="file" accept="image/*"
                                        (change)="handleInputChangedata($event)">
                                        <!-- <app-field-error-display [displayError]="isFieldValid('file')" errorMsg="Please  enter Image">
                                        </app-field-error-display> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit" value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid_12" style="top: -35px;">
                <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                  </span>
                  <!-- <span class="upload" *ngIf="message">
                    {{message}}
                  </span> -->
                </div>
            </form>

        </section>
     
    </div>
</div>