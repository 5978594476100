import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
declare let $: any;
const ONE_MILLION = 1_000_000;
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class EmployeeComponent implements OnInit {
  _result: Date;
  tree_card_bank: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  //isChecked: boolean = false;
  isCheckedFM: string = "M";
  isChecked: string = "";
  isCheckedBoxFM: boolean = false;
  isCheckedBox: boolean = false;
  buyerCode: any;
  selectedUser = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  constructor( 
    private webService: WebServiceService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    
  }


  ngOnInit(): void {
    this.loadTree(); 
    let url = "Employee/Listtree";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card_bank = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card_bank
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
            }
          });

        } else {

        }
      }
    );
  }

  loadTree = () => {
    let url = "Employee/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        let dataList = "";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          
       //   this.tree_card_bank = data12[0];
          data12[0].forEach(element => {
            dataList += "<tr><td>" + element.EMP_CODE + "</td><td>" + element.EMP_FNAME + " " + element.EMP_MNAME + " " + element.EMP_LNAME + "</td><td>" + element.EMP_GENDER + "</td><td>" + element.DOB + "</td><td>" + element.DOJ + "</td><td>" + element.DEPT_CODE + "</td><td>" + element.TAX_STATUS + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.PHONE + "</td><td>" + element.MOBILE + "</td><td>" + element.EMAIL + "</td><td>" + element.CREATED_BY + "</td><td>" + element.CREATED_DATE + "</td><td>" + element.MODIFIED_BY + "</td><td>" + element.MODIFIED_DATE + "</td><td>" + element.DOL + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;

        } else {

        }
      }
    );
  }

  cancelDialog = () => {
    this.dialogIn = false;
    this.dialogEdit = false;
    this.dialogDelete = false;
  }

  CodeAlredyExist = "";
  CodeCheckUnique(code) {
    let url = "Employee/EditEmployeeCodeAndNameList";
    this.webService.loadTreetListEdit(url, code).subscribe(data => {
      if (data.status === 'OK') {
        this.CodeAlredyExist = "Employee Code already exists";
      }
      else {
        this.CodeAlredyExist = "Employee Code is correct";
      }
    });
  }


  openDialog = () => {
    this.dialogIn = true;
  }
 
  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null; 
  }

  TreeListBinding = (data, id) => {
    try {
      this.selectedUser = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url ="Employee/EditEmployeeCodeAndNameList";
      this.webService.loadTreetListEdit(url,data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.tree_edit_list = data12[0][0];
            debugger
            this.isChecked =this.tree_edit_list.emp_gender;
            if(this.tree_edit_list.tax_status == 'Y'){
              this.isCheckedBox =true;
            }
            else{
              this.isCheckedBox = false;
            }
           
            this.tree_edit_list = {
              emp_code:  this.tree_edit_list.emp_code,
              emp_fname: this.tree_edit_list.emp_fname,
              emp_mname:  this.tree_edit_list.emp_mname,
              emp_lname:  this.tree_edit_list.emp_lname,
              add1:  this.tree_edit_list.add1,
              add2:  this.tree_edit_list.add3,
              add3:  this.tree_edit_list.add3,
              isChecked: this.tree_edit_list.emp_gender,
              isCheckedBox: this.isCheckedBox, // Assuming the checkbox is initially checked
              dob:this.datePipe.transform(this.tree_edit_list.dob, 'yyyy-MM-dd'),
              doj: this.datePipe.transform(this.tree_edit_list.doj, 'yyyy-MM-dd'),
              dept_code: this.tree_edit_list.dept_code,
              phone: this.tree_edit_list.phone,
              mobile:this.tree_edit_list.mobile,
              email: this.tree_edit_list.email,
              DOL: this.datePipe.transform(this.tree_edit_list.DOL, 'yyyy-MM-dd'),
            };
            
            this.buyerCode = this.tree_edit_list.Buyer_Code;
            this.selectedUser = this.tree_edit_list.Bank_Code; 

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  
  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/employee']);
    });
  }
  get timestamp() {
    return this.result.valueOf() * ONE_MILLION;
  }

  set result(value: Date) {
    this._result = value;
    this.location.go('?timestamp=' + this.timestamp);
  }

  get inputDate() {
    return this.result.toISOString().substring(0,10);
  }

  setDate(value: string) {
    if (value) {
      const d = new Date(value);

      if (!Number.isNaN(d.valueOf())) {
        this.result = new Date(this.result.setFullYear(d.getFullYear()));
        this.result = new Date(this.result.setMonth(d.getMonth()));
        this.result = new Date(this.result.setDate(d.getDate()));
      }
    }
  }

  addBankMaster =(emp_Code, fName,mName,lName,Add1,Add2,Add3,Male,female,texPayee,dob,doj,dept_code,Phone,Mobile,Email,leavingDate) => {
    let gender,edittexPayee;
 
    if(Male === true){
      gender = 'M';
    }
    else if(female === true){
      gender = 'F';
    }
    if(texPayee === true){
      edittexPayee = "Y";
    }
    else{
      edittexPayee = "N";
    }
    
    let data = {
      Emp_Code:emp_Code, 
      Emp_fName:fName,
      Emp_MName:mName,
      Emp_LName:lName, 
      Add1:Add1,
      Add2:Add2,
      Add3:Add3,
      Emp_Gender:gender,
      tax_satatus:edittexPayee,
      dob:dob,
      doj:doj,
      dept_code:dept_code,
      Phone:Phone,
      Mobile:Mobile,
      Email:Email,
      dol:leavingDate
    }
    let url = "Employee/AddEmployee";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Added Employee</p>',
            showConfirmButton: false,
            timer: 2500
          });
          this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list); 
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Not Added Employee</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }

      }
    );

  }

  editBankMaster = (editemp_Code, editfName,editmName,editlName,editAdd1,editAdd2,editAdd3,editMale,editfemale,edittexPayee,editdob,editdoj,editdept_code,editPhone,editMobile,editEmail,editleavingDate) => {
    let gender, texPayee;
    if(editMale === true){
      gender = 'M';
    }
    else if(editfemale === true){
      gender = 'F';
    }
    
    if(edittexPayee === true){
      texPayee = "Y";
    }
    else{
      texPayee = "N";
    }
    let data = {
      Emp_Code:editemp_Code, 
      Emp_fName:editfName,
      Emp_MName:editmName,
      Emp_LName:editlName, 
      Add1:editAdd1,
      Add2:editAdd2,
      Add3:editAdd3,
      Emp_Gender:gender,
      tax_satatus:texPayee,
      dob:editdob,
      doj:editdoj,
      dept_code:editdept_code,
      Phone:editPhone,
      Mobile:editMobile,
      Email:editEmail,
      dol:editleavingDate
    }
    let url = "Employee/EditEmployee";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Edited Employee</p>',
            showConfirmButton: false,
            timer: 2500
          });
          //this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.TreeListBinding(editemp_Code,''); 
        } else {
           Swal.fire({
            position: 'top-end',
            icon: 'error',
            iconColor: '#000',
            background: '#FFFFFF',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            },
            html: '<p style="font-weight: 500;color: black;font-size: 20px;">Not Edited Employee</p>',
            showConfirmButton: false,
            timer: 2500
          });
        }
      }
    );

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong>
            </h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>List Of Employees</strong></h5>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>EMP_Gender</th>
          <th>DOB</th>
          <th>DOJ</th>
          <th>Dept_Code</th>
          <th>Tax</th>
          <th>Addrss</th>
          <th>Phone</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Created_By</th>
          <th>Created_Date</th>
		      <th>Modified_By</th>
          <th>Modified_Date</th> 
          <th>Dol</th> 
        </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
