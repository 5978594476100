<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Inspection Sheet</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/Add.png"
                                style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>

        <section id="content" class="container_12 clearfix" data-sort="true" style="padding: 10px;background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="grid_12">
                <h1
                    style="padding: 10px 1px 10px 15px;font-size: large;top: 22px;left: 15px;background: white;width: 169px;z-index: 999;position: relative;">
                    Inspection Sheet</h1>
                <div class="box validate" style="padding: 26px 15px 15px 15px;">

                    <fieldset>
                        <legend> Order Detalls </legend>
                        <table style=" border: 2px solid #666666;" height="50" width="100%">
                            <tr>
                                <td style=" border: none; ">Order No.</td>
                                <td style=" border: none;">
                                    <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                        value="" #orderCode />
                                </td>
                                <td style=" border: none; ">Shipment No.</td>
                                <td style=" border: none;">
                                    <select id="buyer" style="width: 100%;"
                                         
                                        #ShipmentNo>
                                        <option [ngValue]="null" disabled>Choose your
                                            Size</option>
                                        <option>
                                            j
                                        </option>
                                    </select>
                                </td>
                                <td style=" border: none; ">Mode of shipment.</td>
                                <td style=" border: none;">
                                    <select id="buyer" style="width: 100%;" 
                                        #ModeOfShipment>
                                        <option [ngValue]="null" disabled>Choose your
                                            Size</option>
                                         
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style=" border: none; ">Buyer</td>
                                <td style=" border: none;">
                                    <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                        value="" />
                                </td>
                                <td style=" border: none; "></td>
                                <td style=" border: none; "></td>
                                <td style=" border: none; ">Supplier</td>
                                <td style=" border: none;">
                                    <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                        value="" />
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                    <fieldset>
                        <legend>Item List</legend>
                        <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Total Quantity</th>
                                </tr>
                            </thead>
                            <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">
<tr>
    <td>1</td>
    <td>0212</td>
    <td>ldfks fsld f</td>
    <td>23</td>
</tr>
                                <!-- <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                    <td>
                                        {{i + 1}}
                                    </td>
                                    <td><input type="text" [(ngModel)]="item3.order_article_code"
                                            [disabled]="!item3.isEditing"
                                            style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                    </td>
                                    <td><input type="text" [(ngModel)]="item3.item_description"
                                            [disabled]="!item3.isEditing"
                                            style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                    </td>
                                    <td>
                                        <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                        <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                        <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                    </td>
                                </tr> -->

                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset>
                        <legend>Production Details</legend>
                        <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Color</th>
                                    <th>Composition</th>
                                    <th>Lab Dip/Yarn Bit</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                <tr>
                                    <td>
                                      9
                                    </td>
                                    <td>8</td>
                                    <td>k</td>
                                    <td>7</td>
                                    <td>i</td>
                                </tr>

                            </tbody>

                        </table>
                    </fieldset>


                </div>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">

                        <div class="right">
                            <input type="submit" value="Save" name="submit" style="width: 100px;font-size: small;" />
                        </div>
                    </div>
                </div>
            </div>


        </section>

    </div>
</div>