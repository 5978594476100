<style>
    .search {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 6px;
    }
</style>
<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"></ngx-loader>
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Item Master</div>
        </div>
        <div class="modal" id="showPopupCommissionNote">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Commission Note </h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupCommissionNote')">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <fieldset>
                            <legend> Order Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipmentColor($event, orderCode.value)'
                                            #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your Mode of shipment</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                            #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your Mode of shipment</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.order_article_code"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.item_description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>5100</td>
                                        <td>6681.00</td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Color Details of Article -'{{this.ArticleCodeColor}}' of Shipment -'1' For Order
                                No-'{{order_Code_Sipment}}' </legend>
                            <table style=" border: none; width: 100%;">
                                <tr style=" border: none; ">
                                    <td style="border: none;position: relative;top: 15px;">
                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Debit Note No.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Currency
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Debit Note Amt
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    DD/Cheque No.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Dispatch Date.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" name=" Debit Note Date" size="15" maxlegth="30">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Conversion rate
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Remarks
                                                </td>
                                                <td style=" border: none; ">
                                                    <textarea row="4"></textarea>
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    <table style=" border: none; ">
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                <input type="checkbox" id="payment" name="payment recd"
                                                                    value="text">
                                                                <label for="payment"> Payment Recd</label>
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                <input type="checkbox" id="printed" name="printed"
                                                                    value="text">
                                                                <label for="vehicle2"> Printed</label>
                                                            </td>

                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                    </td>
                                    <td style=" border: none; ">
                                        <table style=" border: none; ">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Debit Note Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" name=" Debit Note Date" size="15"
                                                        maxlegth="30" />
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    DD/cheque Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" name=" Debit Note Date" size="15"
                                                        maxlegth="30" />
                                                </td>
                                            </tr>
                                        </table>

                                        <fieldset>
                                            <legend>Tax Details </legend>
                                            <table style=" border: none;width: 100%; ">
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax Rate
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax Amt
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Cess Rate
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Cess Amt
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax No
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax address
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <textarea row="4"></textarea>
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                            </table>

                                        </fieldset>

                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopupStyle('showPopupCommissionNote')"
                            class="btn btn-secondary"
                            style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button"
                            (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                            class="btn btn-primary">Save All</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal" id="showPopupDebitNote">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Debit Note </h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupDebitNote')">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <fieldset>
                            <legend> Order Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipmentColor($event, orderCode.value)'
                                            #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                            #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.order_article_code"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.item_description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>5100</td>
                                        <td>6681.00</td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Color Details of Article -'{{this.ArticleCodeColor}}' of Shipment -'1' For Order
                                No-'{{order_Code_Sipment}}' </legend>
                            <table style=" border: none; width: 100%;">
                                <tr style=" border: none; ">
                                    <td style="border: none;position: relative;top: 15px;">
                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Debit Note No.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Currency
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Debit Note Amt
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    DD/Cheque No.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Dispatch Date.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" name=" Debit Note Date" size="15" maxlegth="30">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Conversion rate
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Remarks
                                                </td>
                                                <td style=" border: none; ">
                                                    <textarea row="4"></textarea>
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    <table style=" border: none; ">
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                <input type="checkbox" id="payment" name="payment recd"
                                                                    value="text">
                                                                <label for="payment"> Payment Recd</label>
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                <input type="checkbox" id="printed" name="printed"
                                                                    value="text">
                                                                <label for="vehicle2"> Printed</label>
                                                            </td>

                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                    </td>
                                    <td style=" border: none; ">
                                        <table style=" border: none; ">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Debit Note Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" name=" Debit Note Date" size="15"
                                                        maxlegth="30" />
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    DD/cheque Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" name=" Debit Note Date" size="15"
                                                        maxlegth="30" />
                                                </td>
                                            </tr>
                                        </table>

                                        <fieldset>
                                            <legend>Tax Details </legend>
                                            <table style=" border: none;width: 100%; ">
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax Rate
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax Amt
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Cess Rate
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Cess Amt
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax No
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <input type="text">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                                <tr style=" border: none; ">
                                                    <td style=" border: none; ">
                                                        Service Tax address
                                                    </td>
                                                    <td style=" border: none; ">
                                                        <textarea row="4"></textarea>
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                    <td style=" border: none; ">
                                                    </td>
                                                </tr>
                                            </table>

                                        </fieldset>

                                    </td>
                                </tr>
                            </table>
                        </fieldset>

                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopupStyle('showPopupDebitNote')" class="btn btn-secondary"
                            style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button"
                            (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                            class="btn btn-primary">Save All</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal" id="showPopupProduction">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Production</h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupProduction')">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <fieldset>
                            <legend> Order Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipmentColor($event, orderCode.value)'
                                            #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                            #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.order_article_code"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.item_description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Color Details of Article -'{{this.ArticleCodeColor}}' of Shipment -'1' For Order
                                No-'{{order_Code_Sipment}}' </legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Color</th>
                                        <th>Composition</th>
                                        <th>Lab Dip/Yarn Bit</th>
                                        <th>Sent Date</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td>{{item3.color_name}}</td>
                                        <td>{{item3.composition}}</td>
                                        <td>{{item3.lab_dip_yarn_bit}}</td>
                                        <td>{{item3.LAB_DIPS_SENT_DATE}}</td>
                                    </tr>

                                </tbody>

                            </table>
                        </fieldset>

                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopupStyle('showPopupProduction')" class="btn btn-secondary"
                            style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button"
                            (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                            class="btn btn-primary">Save All</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal" id="showPopupColorDetalls">
            <div class="modal-dialog modal-dialog-centered modal-lg" style="--bs-modal-width: 900px;">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Color Details</h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupColorDetalls')">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <fieldset>
                            <legend> Shipment Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipmentColor($event, orderCode.value)'
                                            #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                            #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.order_article_code"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.item_description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Color Details of Article -'{{this.ArticleCodeColor}}' of Shipment -'1' For Order
                                No-'{{order_Code_Sipment}}' </legend>

                            <nv-editable-table [table-headers]="tableHeaders" [table-rows-with-id]="tableRowsWithId"
                                [data-type]="dataType" (onRemove)="onRemove($event)" class="ui table">
                            </nv-editable-table>
                            <hr />
                            <!-- <h1>Advance example</h1>
                                <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Color</th>
                                        <th>Composition</th>
                                        <th>Lab Dip/Yarn Bit</th>
                                        <th>Sent Date</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td>{{item3.color_name}}</td>
                                        <td>{{item3.composition}}</td>
                                        <td>{{item3.lab_dip_yarn_bit}}</td>
                                        <td>{{item3.LAB_DIPS_SENT_DATE}}</td>
                                    </tr>

                                </tbody>

                            </table> -->
                        </fieldset>

                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopupStyle('showPopupColorDetalls')"
                            class="btn btn-secondary"
                            style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button"
                            (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                            class="btn btn-primary">Save All</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="popup" id="showPopupTestingDetails" style="display: none;">
            <!-- Popup Content -->
            <div class="popup-content" style="position: relative;margin: 180px;">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: start;display: flex;padding: 10px;">
                        <h5 class="modal-title">Testing</h5>
                        <button type="button" class="close" (click)="closePopupStyle('showPopupTestingDetails')"
                            style=" right: 0; position: absolute;">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;padding: 10px;height: 350px">
                        <div>
                            <table border="6" height="20%" width="100%" style=" border: none; ">
                                <tr style=" border: none; ">
                                    <td style="border: none;">
                                        Order Code
                                    </td>
                                    <td style=" border: none;">
                                        <select style="width:100%">
                                            <option>1</option>
                                            <option>1</option>
                                            <option>1</option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">
                                        Order Date
                                    </td>
                                    <td style=" border: none; ">
                                        <input type="date" name=" Debit Note Date" size="15" maxlegth="30" />
                                    </td>

                                </tr>
                            </table>
                        </div>

                        <fieldset>
                            <legend>Follow-Up Details </legend>
                            <table border="6" height="20%" width="100%">
                                <tr>
                                    <th></th>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th>Text</th>
                                    <th>Remarks</th>
                                </tr>


                                <tr>
                                    <td></td>
                                    <td>3656</td>
                                    <td>11/04/2009</td>
                                    <td>MERCHANDISING</td>
                                    <td>Name:INSPECTION
                                        Status:FINAL INSPN
                                    </td>
                                    <td>FINAL INSPN REPT RECVD FM TEBAS</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>3591</td>
                                    <td>07/04/2009</td>
                                    <td>MERCHANDISING</td>
                                    <td>Name:ACCESSORY
                                        Status:MSG RECVD</td>
                                    <td>MSG RECVD FM HARIKESH-BOTH CAR</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>3590</td>
                                    <td>07/04/2009</td>
                                    <td>MERCHANDISING</td>
                                    <td>Name:ACCESSORY
                                        Status:SENT</td>
                                    <td>CARTON DIMENSIION:48X32X37CM</td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Add/Update Follow-Ups </legend>
                            <table style=" border: none; width: 100%;">
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        Follow-Up Category
                                    </td>
                                    <td style=" border: none; ">
                                        <input type="text">
                                    </td>

                                </tr>
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        Follo-Up Date
                                    </td>
                                    <td style=" border: none; ">
                                        <input type="date" name=" Debit Note Date" size="15" maxlegth="30" />
                                    </td>
                                </tr>
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        Text
                                    </td>
                                    <td style=" border: none; ">
                                        <textarea row="7" style="width: 100%;"></textarea>
                                    </td>
                                </tr>
                                <tr style=" border: none;">
                                    <td style=" border: none;">
                                    </td>
                                    <td style=" border: none; ">
                                        <textarea row="7" style="width: 100%;"> </textarea>
                                    </td>
                                </tr>
                            </table>

                        </fieldset>

                    </div>

                </div>

            </div>
        </div>
        <div class="popup" id="showPopupFollowUpDetails" style="display: none;">
            <!-- Popup Content -->
            <div class="popup-content" style="position: relative;margin: 180px;">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: start;display: flex;padding: 10px;">
                        <h5 class="modal-title"> Follow Up </h5>
                        <button type="button" class="close" (click)="closePopupStyle('showPopupFollowUpDetails')"
                            style=" right: 0; position: absolute;">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;padding: 10px;height: 350px">
                        <div>
                            <table border="6" height="20%" width="100%" style=" border: none; ">
                                <tr style=" border: none; ">
                                    <td style="border: none;">
                                        Order Code
                                    </td>
                                    <td style=" border: none;">
                                        <select style="width:100%">
                                            <option>1</option>
                                            <option>1</option>
                                            <option>1</option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">
                                        Order Date
                                    </td>
                                    <td style=" border: none; ">
                                        <input type="date" name=" Debit Note Date" size="15" maxlegth="30" />
                                    </td>

                                </tr>
                            </table>
                        </div>

                        <fieldset>
                            <legend>Follow-Up Details </legend>
                            <table border="6" height="20%" width="100%">
                                <tr>
                                    <th></th>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th>Text</th>
                                    <th>Remarks</th>
                                </tr>


                                <tr>
                                    <td></td>
                                    <td>3656</td>
                                    <td>11/04/2009</td>
                                    <td>MERCHANDISING</td>
                                    <td>Name:INSPECTION
                                        Status:FINAL INSPN
                                    </td>
                                    <td>FINAL INSPN REPT RECVD FM TEBAS</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>3591</td>
                                    <td>07/04/2009</td>
                                    <td>MERCHANDISING</td>
                                    <td>Name:ACCESSORY
                                        Status:MSG RECVD</td>
                                    <td>MSG RECVD FM HARIKESH-BOTH CAR</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>3590</td>
                                    <td>07/04/2009</td>
                                    <td>MERCHANDISING</td>
                                    <td>Name:ACCESSORY
                                        Status:SENT</td>
                                    <td>CARTON DIMENSIION:48X32X37CM</td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Add/Update Follow-Ups </legend>
                            <table style=" border: none; width: 100%;">
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        Follow-Up Category
                                    </td>
                                    <td style=" border: none; ">
                                        <input type="text">
                                    </td>

                                </tr>
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        Follo-Up Date
                                    </td>
                                    <td style=" border: none; ">
                                        <input type="date" name=" Debit Note Date" size="15" maxlegth="30" />
                                    </td>
                                </tr>
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        Text
                                    </td>
                                    <td style=" border: none; ">
                                        <textarea row="7" style="width: 100%;"></textarea>
                                    </td>
                                </tr>
                                <tr style=" border: none;">
                                    <td style=" border: none;">
                                    </td>
                                    <td style=" border: none; ">
                                        <textarea row="7" style="width: 100%;"> </textarea>
                                    </td>
                                </tr>
                            </table>

                        </fieldset>

                    </div>

                </div>

            </div>
        </div>
        <div class="modal" id="showPopupDocUpload">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Doc. Upload</h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupDocUpload')">&times;</button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <app-doc-upload [OrderNo]="[order_id_no_file_upload, order_id_number]"></app-doc-upload>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="showPopupOrderSpecSheet">
            <div class="modal-dialog modal-dialog-centered modal-lg" style="--bs-modal-width: 400px;">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Order Spec. Sheet</h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupOrderSpecSheet')">&times;</button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <app-order-spec-sheet-file-upload
                            [OrderNo]="[order_id_no_file_upload, order_id_number]"></app-order-spec-sheet-file-upload>

                    </div>
                </div>
            </div>
        </div>
        <div class="popup" id="showPopupShipmentDetailsSelectItem" style="display: none;z-index: 1153;">
            <!-- Popup Content -->
            <div class="popup-content" style="width: 405px; position: fixed; margin: 35px 12px 12px 126px;">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: start;display: flex;padding: 10px;">
                        <h5 class="modal-title"> Shipment Details</h5>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupShipmentDetailsSelectItem')"
                            style=" right: 0; position: absolute;">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;padding: 10px;height: 250px">
                        <fieldset>
                            <legend>Select Item</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of proShowRecordsListGS_Gnrated; let i = index">
                                        <input type="hidden" #CountUserSipshipment value="{{i + 1}}" />
                                        <td><button type="button"
                                                (click)="shipment_serial_GS_nEW(item3.id, item3.order_article_code, item3.order_code)"
                                                class="btn btn-primary">{{item3.order_article_code}} -
                                                {{item3.order_code}}</button></td>
                                        <td>{{item3.item_description}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>

                    </div>

                </div>

            </div>
        </div>
        <div class="popup" id="showPopupShipmentDetails" style="display: none;">
            <!-- Popup Content -->
            <div class="popup-content" style="width: 80%; position: fixed; margin: 35px 12px 12px 126px;">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: start;display: flex;padding: 10px;">
                        <h5 class="modal-title"> Order List </h5>
                        <button type="button" class="close" (click)="closePopup('singleModel')"
                            style=" right: 0; position: absolute;">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;padding: 10px;height: 390px">

                        <fieldset>
                            <legend> Shipment Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">

                                <tr>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Status</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{shipment_status}}" #shipmentStatus />
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipment1($event, orderCode.value)' #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" [(ngModel)]="sip_mode_shipiment"
                                            (change)='TreeContactBuyer($event)' #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List </legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Artical Code</th>
                                        <th>Description</th>
                                        <th>Item Code</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Commission</th>
                                        <th>Total</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; ">
                                    <tr *ngFor="let item3 of proShowRecordsList; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td><input type="text" [(ngModel)]="item3.ARTICLE_CODE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.REMARKS" [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_CODE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_QTY"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_PRICE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_COMM"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_QTY*item3.ITEM_PRICE"
                                                [disabled]="true"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRow(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRow(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEdit(i)">Cancel</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Debit Note Details </legend>
                            <table style=" border: none; width: 100%;">
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Agent Code
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    BI/AW No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    BI/AW Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>

                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Invoice Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Doc Sent Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Delivery date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Arrival date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Total Comm.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>

                                        </table>

                                    </td>
                                    <td style=" border: none; ">
                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    <table style=" border: none; ">
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                GCS
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Courier No
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Total Cartons
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td style=" border: none; ">
                                                    <table style=" border: none; ">
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Total Ship. Qty
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="number" value="{{this.TotalShipQty}}"
                                                                    style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Shipment Total
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="number" value="{{this.ShipmentTotal}}"
                                                                    style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Status
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Container No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Vessel No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Conn Ves, No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Remarks
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Invoice No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>

                                        </table>



                                    </td>
                                </tr>
                            </table>

                        </fieldset>
                    </div>
                    <!-- <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopup('singleModel')" class="btn btn-secondary" style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button" (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)" class="btn btn-primary">Save All</button>
                    </div> -->
                </div>

            </div>
        </div>
        <div class="popup" id="showPopupGenerateShipment" style="display: none;">
            <!-- Popup Content -->
            <div class="popup-content" style="width: 80%; position: fixed; margin: 35px 12px 12px 126px;">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: start;display: flex;padding: 10px;">
                        <h5 class="modal-title"> Order List </h5>
                        <button type="button" class="close" (click)="closePopup('singleModel')"
                            style=" right: 0; position: absolute;">
                            <span>&times;</span>
                        </button>

                    </div>
                    <div class="modal-footer" style="padding: 12px;float: right;position: relative;display: inline;">
                        <button type="button" (click)="closePopup('singleModel')" class="btn btn-secondary"
                            style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button" (click)="onPreviewGShipement()" class="btn btn-primary"
                            style="margin-right: 12px;">Print</button>
                        <button type="button"
                            (click)="saveAllChangesGS(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                            class="btn btn-primary">Save All</button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;padding: 10px;">

                        <fieldset>
                            <legend> Shipment Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">

                                <tr>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Status</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{shipment_status}}" #shipmentStatus />
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">

                                        <select id="buyer" style="width: 100%;padding: 6px;"
                                            (change)='changeTreeContactShipment($event, orderCode.value)' #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" [(ngModel)]="sip_mode_shipiment"
                                            (change)='TreeContactBuyer($event)' #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List </legend><button type="button"
                                (click)="showShipmentDetailsSelectItem(order_Code_Sipment)"
                                class="btn btn-primary">View</button>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Artical Code</th>
                                        <th>Description</th>
                                        <th>Item Code</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Commission</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: table-header-group; ">
                                    <tr *ngFor="let item3 of proShowRecordsListGS; let i = index">
                                        <td style="width: 5%;">{{ i + 1 }}</td>
                                        <td><input type="text" [(ngModel)]="item3.ARTICLE_CODE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.Description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_CODE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_QTY"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_PRICE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_COMM"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td style="width: 10%;">
                                            <button *ngIf="!item3.isEditing" (click)="editRowGS(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowGS(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditGS(i)">Cancel</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>

                </div>

            </div>
        </div>
        <div class="modal" id="showPopupInspection">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Inspection</h4>
                        <button type="button" class="close"
                            (click)="closePopupStyle('showPopupInspection')">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <fieldset>
                            <legend> Shipment Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipmentColor($event, orderCode.value)'
                                            #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                            #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List</legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Article Code</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">

                                    <tr *ngFor="let item3 of this.proShowOrderDetailsListColorDetalls; let i = index">
                                        <td>
                                            {{i + 1}}
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.order_article_code"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.item_description"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRowColor(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRowColor(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEditColor(i)">Cancel</button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Color Details of Article -'{{this.ArticleCodeColor}}' of Shipment -'1' For Order
                                No-'{{order_Code_Sipment}}' </legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; display: contents; ">
                                    <tr>
                                        <td>Fabric</td>
                                        <td><input type="text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="date" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="Text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                    </tr>
                                    <tr>
                                        <td>Inital</td>
                                        <td><input type="text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="date" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="Text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                    </tr>
                                    <tr>
                                        <td>In - Line</td>
                                        <td><input type="text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="date" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="Text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                    </tr>
                                    <tr>
                                        <td>Middle</td>
                                        <td><input type="text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="date" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="Text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                    </tr>
                                    <tr>
                                        <td>Final</td>
                                        <td><input type="text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="date" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                        <td><input type="Text" name=" Debit Note Date" size="15" maxlegth="30" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>

                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopupStyle('showPopupInspection')" class="btn btn-secondary"
                            style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button"
                            (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                            class="btn btn-primary">Save All</button>
                    </div>

                </div>
            </div>
        </div>
        <div class="popup" id="showPopupClaimNote" style="display: none;">
            <!-- Popup Content -->
            <div class="popup-content" style="width: 80%; position: fixed; margin: 35px 12px 12px 126px;">
                <div class="modal-content">
                    <div class="modal-header" style="text-align: start;display: flex;padding: 10px;">
                        <h5 class="modal-title"> Claim Note </h5>
                        <button type="button" class="close" (click)="closePopupStyle('showPopupClaimNote')"
                            style=" right: 0; position: absolute;">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;padding: 10px;height: 390px">

                        <fieldset>
                            <legend> Shipment Detalls </legend>
                            <table style=" border: 2px solid #666666;" height="50" width="100%">

                                <tr>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Status</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{shipment_status}}" #shipmentStatus />
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Order No.</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                            value="{{order_Code_Sipment}}" #orderCode />
                                    </td>
                                    <td style=" border: none; ">Shipment No.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;"
                                            (change)='changeTreeContactShipment1($event, orderCode.value)' #ShipmentNo>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sipmet of shipment_serialList"
                                                [ngValue]="sipmet.shipment_serial_no">
                                                {{sipmet.shipment_serial_no}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style=" border: none; ">Mode of shipment.</td>
                                    <td style=" border: none;">
                                        <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                            #ModeOfShipment>
                                            <option [ngValue]="null" disabled>Choose your
                                                Size</option>
                                            <option *ngFor="let sip_mode of proFillModeList"
                                                [ngValue]="sip_mode.param_value">
                                                {{sip_mode.param_value}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" border: none; ">Buyer</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                            value="{{buyer_shipment}}" />
                                    </td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; "></td>
                                    <td style=" border: none; ">Supplier</td>
                                    <td style=" border: none;">
                                        <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                            value="{{supplier_shipment}}" />
                                    </td>
                                </tr>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Item List </legend>
                            <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Artical Code</th>
                                        <th>Description</th>
                                        <th>Item Code</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Commission</th>
                                        <th>Total</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody style=" overflow-x: scroll; max-height: 160px; ">
                                    <tr *ngFor="let item3 of proShowRecordsList; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td><input type="text" [(ngModel)]="item3.ARTICLE_CODE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.REMARKS" [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_CODE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_QTY"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_PRICE"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_COMM"
                                                [disabled]="!item3.isEditing"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td><input type="text" [(ngModel)]="item3.ITEM_QTY*item3.ITEM_PRICE"
                                                [disabled]="true"
                                                style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                        </td>
                                        <td>
                                            <button *ngIf="!item3.isEditing" (click)="editRow(i)">Edit</button>
                                            <button *ngIf="item3.isEditing" (click)="saveRow(i)">Save</button>
                                            <button *ngIf="item3.isEditing" (click)="cancelEdit(i)">Cancel</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset>
                            <legend>Debit Note Details </legend>
                            <table style=" border: none; width: 100%;">
                                <tr style=" border: none; ">
                                    <td style=" border: none; ">
                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Agent Code
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    BI/AW No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    BI/AW Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>

                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Invoice Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Doc Sent Date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Delivery date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Arrival date
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="date" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Total Comm.
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>

                                        </table>

                                    </td>
                                    <td style=" border: none; ">
                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    <table style=" border: none; ">
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                GCS
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Courier No
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Total Cartons
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td style=" border: none; ">
                                                    <table style=" border: none; ">
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Total Ship. Qty
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="number" value="{{this.TotalShipQty}}"
                                                                    style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Shipment Total
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="number" value="{{this.ShipmentTotal}}"
                                                                    style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                        <tr style=" border: none; ">
                                                            <td style=" border: none; ">
                                                                Status
                                                            </td>
                                                            <td style=" border: none; ">
                                                                <input type="text" style="width: 100%;">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                        <table style=" border: none; width: 100%;">
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Container No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>

                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Vessel No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Conn Ves, No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Remarks
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>
                                            <tr style=" border: none; ">
                                                <td style=" border: none; ">
                                                    Invoice No
                                                </td>
                                                <td style=" border: none; ">
                                                    <input type="text" style="width: 100%;">
                                                </td>
                                            </tr>

                                        </table>



                                    </td>
                                </tr>
                            </table>

                        </fieldset>
                    </div>
                    <!-- <div class="modal-footer" style=" padding: 12px;">
                        <button type="button" (click)="closePopup('singleModel')" class="btn btn-secondary" style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                        <button type="button" (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)" class="btn btn-primary">Save All</button>
                    </div> -->
                </div>

            </div>
        </div>
        <section class="toolbar" style="padding: 5px 0;height: auto;">
            <div class="row">
                <div class="grid_3">

                    <ul class="shortcuts" style="margin-left: 0;">
                        <li style="position: relative;">
                            <a href="javascript:void(0);" (click)="reset()"><span><img src="./assets/images/Add.png"
                                        style="width: 40px;" /></span></a>
                        </li>
                        <li style="position: relative;">
                            <a href="javascript:void(0);" (click)="ReportViewPrint()"><span><img
                                        src="./assets/images/print.png" style="width: 40px;" /></span></a>
                        </li>
                    </ul>
                </div>
                <div class="grid_9" style=" position: relative; color: blue; ">
                    <table>
                        <tr style=" border: none; ">
                            <td style=" border: none; ">

                                <a style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;"
                                    (click)="showPopupGenerateShipment()">Generate Shipment</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupShipmentDetails()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Shipment
                                    Details</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupMerchandise()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Merchandise</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupSpecSheet()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Spec.
                                    Sheet</a>
                            </td>
                            <td style=" border: none; ">
                                <a style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;"
                                    (click)="showPopupColorDetalls()">Color Details</a>
                            </td>

                        </tr>
                        <tr style=" border: none; ">
                            <td style=" border: none; ">
                                <a (click)="showPopupTesting()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Testing</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupFollowUp()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Follow
                                    Up</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupInspection()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Inspection</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupCommissonNote()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Commisson
                                    Note</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupDebitNote()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Debit
                                    Note</a>
                            </td>

                        </tr>
                        <tr style=" border: none; ">
                            <td style=" border: none; ">
                                <a (click)="showPopupClaimNote()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Claim
                                    Note</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupProduction()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Production</a>
                            </td>
                            <td style=" border: none; ">
                                <a (click)="showPopupDocUpload()"
                                    style="font-size: large; padding:5px 20px 5px 20px;position: relative;color: blue;cursor: pointer;">Doc.
                                    Upload</a>
                            </td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; "></td>
                        </tr>
                    </table>

                </div>

            </div>
        </section>

        <section id=" content" class="clearfix" data-sort="true" style="padding-left: 0;padding: 0px 0;">

            <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 0;position: relative;">
                        Item Details</h1>
                    <div class="box validate" style="padding: 16px 15px 15px 15px;">

                        <div class="popup" id="showPopup" style="display: none;">
                            <!-- Popup Content -->
                            <div class="popup-content">
                                <div class="modal-content">
                                    <div class="modal-header"
                                        style="text-align: start;display: flex;justify-content: flex-start;">
                                        <h5 class="modal-title" style="padding: 10px;"> Order List </h5>
                                        <input class="form-control" type="text" #searchText2
                                            (keyup)="applyFilter(searchText2.value)" autocomplete="off"
                                            placeholder="Seacrh"
                                            style="margin-left: 0px;left: 20px;width: 200px;height: 26px;">

                                        <button type="button" class="close" (click)="closePopup('singleModel')"
                                            style=" right: 5px; position: absolute;">
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body"
                                        style="max-height: calc(100vh - 270px); overflow-y: auto;padding: 0px;width: 400px;">

                                        <div class="row">
                                            <div class="grid_12" style="padding-left: 0;">
                                                <table
                                                    class="table table-striped table-bordered table-sm row-border hover"
                                                    data-filter-Bar="always" data-table-tools='{"display":false}'
                                                    style="margin: 0px 0px 8px 0px;">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Order Id</th>
                                                            <th>Order Code</th>
                                                            <!-- <th>Designation</th>
                                                            <th>Phone</th> -->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="gradeX"
                                                            *ngFor="let item1 of this.filteredOrders | filter:searchText ; let i = index">
                                                            <!-- <td class="center"><button style="background: blueviolet;"
                                                                    type="button"
                                                                    (click)="editOrderList(item1.Order_Id, item1.Order_Code)"
                                                                    class="btn btn-secondary"
                                                                    data-dismiss="modal">Add</button>
                                                            </td> -->
                                                            <td class="center"><button style="background: blueviolet;"
                                                                type="button"
                                                                (click)="changedRolltexOrderNo(item1.Order_Code)"
                                                                class="btn btn-secondary"
                                                                data-dismiss="modal">Add</button>
                                                        </td>
                                                            <td class="center">
                                                                {{item1.Order_Id}}</td>
                                                            <td class="center">
                                                                {{item1.Order_Code}}</td>
                                                            <!-- <td class="center">{{item1.Designation}}</td>
                                                            <td class="center">{{item1.Phone}}</td> -->
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer" style="padding: 0;display: contents;">
                                        <div class="row" style="padding: 0;">
                                            <div class="grid_12">

                                                <div class="search-hero" style="display: flex;">
                                                    <input class="form-control" type="text" #searchText3
                                                        autocomplete="off" placeholder="Please Enter Order Code"
                                                        style="margin-left: 15px;margin-right: 15px;">
                                                    <button type="button" (click)="SearchOrder(searchText3.value)"
                                                        style="margin-left: 15px;margin-right: 15px;">Search</button>
                                                    <button type="button"
                                                        (click)="closePopup('singleModel')">Close</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="content" style="padding: 15px;margin-top: 15px;margin-bottom: 15px;">
                            <input type="hidden" formControlName="pageUpdateInsert">
                            <input type="hidden" formControlName="Order_id">
                            <div class="row">
                                <div class="grid_3_5">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="OrderNoSingle"><strong>Order No</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <div clas="row">
                                                <div class="grid_10" style="width: 70%;">
                                                    <!-- <input id="Department" class="required" type="text"
                                                    (keyup)="changedRolltexOrderNo($event)"
                                                    formControlName="OrderNo" [readonly]="isReadonly" /> -->
                                                    <input id="Department" class="required" type="text"
                                                        formControlName="OrderNo" [readonly]="isReadonly" />
                                                    <app-field-error-display [displayError]="isFieldValid('OrderNo')"
                                                        errorMsg="Please  enter OrderNo"> 
                                                    </app-field-error-display>
                                                </div>

                                                <div class="grid_2" style="width: 20%;">
                                                    <button
                                                        style="background: blueviolet;padding: 0px 2px 4px 2px;font-size: initial;"
                                                        type="button" (click)="viewDialog()"
                                                        class="btn btn-secondary">View</button>
                                                </div>
                                            </div>


                                            <!-- <select id="OrderNoSingle" formControlName="OrderNo" [(ngModel)]="selectedOrderNo"
                                                 data-placeholder="Choose a OrderNo"
                                                style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your Order</option>
                                                <option *ngFor="let sizeOrder of tree_card_OrderDropdown"
                                                    [ngValue]="sizeOrder.Order_Id">
                                                    {{sizeOrder.Order_Code}} 
                                                </option>
                                            </select> -->

                                            <!-- <div class="grid_12">
                                                <div *ngIf="CodeAlredyExist != ''" class="alert alert-danger">
                                                    {{CodeAlredyExist}}
                                                </div>
                                            </div> -->

                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="buyer"><strong>Buyer</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <select id="buyer" formControlName="buyer" [(ngModel)]="selectedBuyer"
                                                data-placeholder="Choose a Name" style="width: 100%;top: 5px;"
                                                (change)='TreeContactBuyer($event)'>
                                                <option [ngValue]="null" disabled>Choose your
                                                    Size</option>
                                                <option *ngFor="let size of tree_card_Buyer"
                                                    [ngValue]="size.Buyer_Code">
                                                    {{size.Buyer_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('buyer')"
                                                errorMsg="Please  enter buyer">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="bContactPerson"><strong>Contact
                                                    Person</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <select id="bContactPerson" formControlName="bContactPerson"
                                                [(ngModel)]="selectedContactBuyer" data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your
                                                    Contact Person</option>
                                                <option *ngFor="let size of tree_ContactBuyer"
                                                    [ngValue]="size.Contact_Person_Code">
                                                    {{size.Contact_Person_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('bContactPerson')"
                                                errorMsg="Please  enter Contact Person">
                                            </app-field-error-display>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="Department"><strong>Department</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <input id="Department" class="required" type="text"
                                                formControlName="Department" />
                                            <app-field-error-display [displayError]="isFieldValid('Department')"
                                                errorMsg="Please  enter Department">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="Supplier"><strong>Supplier</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <select formControlName="Supplier" id="Supplier"
                                                [(ngModel)]="selectedSuppler" data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;" (change)='TreeContactSuppler($event)'>
                                                <option [ngValue]="null" disabled>Choose your Supplier</option>
                                                <option *ngFor="let size1 of tree_card_Suppler"
                                                    [ngValue]="size1.Supplier_Code">
                                                    {{size1.Supplier_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('Supplier')"
                                                errorMsg="Please  enter Supplier">
                                            </app-field-error-display>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="sContactPerson"><strong>Contact Person</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <select id="sContactPerson" formControlName="sContactPerson"
                                                [(ngModel)]="selectedContactSuppler" data-placeholder="Choose a Name"
                                                style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your
                                                    Contact Person</option>
                                                <option *ngFor="let size of tree_ContactSuppler"
                                                    [ngValue]="size.Contact_Person_Code">
                                                    {{size.Contact_Person_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('sContactPerson')"
                                                errorMsg="Please  enter Contact Person">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normalBank"><strong>Bank</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <select id="v1_normalBank" formControlName="Bank"
                                                [(ngModel)]="selectedBank_Name" data-placeholder="Choose a Bank"
                                                style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your
                                                    enter Bank</option>
                                                <option *ngFor="let size of bankMasterList" [ngValue]="size.Bank_Code">
                                                    {{size.Bank_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('Bank')"
                                                errorMsg="Please  enterBank">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="ConsigneeData"><strong>Consignee</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <select id="ConsigneeData" formControlName="Consignee"
                                                [(ngModel)]="selectedContactBuyerConsignee"
                                                data-placeholder="Choose a Consignee" style="width: 100%;top: 5px;">
                                                <option [ngValue]="null" disabled>Choose your
                                                    Consignee</option>
                                                <option *ngFor="let size of tree_ContactBuyerConsignee"
                                                    [ngValue]="size.Buyer_Code">
                                                    {{size.Buyer_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('Consignee')"
                                                errorMsg="Please  enter Consignee">
                                            </app-field-error-display>
                                        </div>

                                    </div>

                                </div>
                                <div class="grid_3_5">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="Currency"><strong>Currency</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <select id="Currency" formControlName="Currency"
                                                [(ngModel)]="selectedCurrency" data-placeholder="Choose a Currency"
                                                style="width: 100%;top: 5px;" (change)='ConversionCurrency($event)'>
                                                <option [ngValue]="null" disabled>Choose your
                                                    Currency</option>
                                                <option *ngFor="let size of SizeCurrencyList"
                                                    [ngValue]="size.Currency_Code">
                                                    {{size.Currency_Name}}
                                                </option>
                                            </select>
                                            <i class="fa fa-caret-down" style="position: absolute;margin: 18px -24px;width: 20px;"></i>
                                            <app-field-error-display [displayError]="isFieldValid('Currency')"
                                                errorMsg="Please  enter Currency">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Conv
                                                    Rate</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="ConvRate" />
                                            <app-field-error-display [displayError]="isFieldValid('ConvRate')"
                                                errorMsg="Please  enter Conv Rate">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>PJ No.</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="PJNo" />
                                            <app-field-error-display [displayError]="isFieldValid('PJNo')"
                                                errorMsg="Please  enter PJ No.">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>LC No.</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="LCNo" />
                                            <app-field-error-display [displayError]="isFieldValid('LCNo')"
                                                errorMsg="Please  enter LC No">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>LC
                                                    Date</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="date" formControlName="LCDate" />
                                            <app-field-error-display [displayError]="isFieldValid('LCDate')"
                                                errorMsg="Please  enter LCDate">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Del Date On
                                                    LC</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="date" formControlName="DelDateOnLC" />
                                            <app-field-error-display [displayError]="isFieldValid('DelDateOnLC')"
                                                errorMsg="Please  enter Del DateOn  LC">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>LC Sent
                                                    On</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="date" formControlName="LCSentOn" />
                                            <app-field-error-display [displayError]="isFieldValid('LCSentOn')"
                                                errorMsg="Please  enter LC Sent On">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Category</strong>
                                            </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="Category" />
                                            <app-field-error-display [displayError]="isFieldValid('Category')"
                                                errorMsg="Please  enter Category">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid_5">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Order
                                                    Date</strong> </label>
                                        </div>
                                        <div class="grid_5">
                                            <input class="required" type="date" formControlName="OrderDate" />
                                            <app-field-error-display [displayError]="isFieldValid('OrderDate')"
                                                errorMsg="Please  enter Order Date">
                                            </app-field-error-display>
                                        </div>
                                        <div class="grid_4" style="display: flex;">
                                            <input class="required" type="checkbox" formControlName="OrderCompleted"
                                                style="width: 60px;margin-right: 12px;"
                                                [(ngModel)]="isCheckedCompleted" /><span>Order
                                                Completed</span>
                                            <app-field-error-display [displayError]="isFieldValid('OrderCompleted')"
                                                errorMsg="Please  enter Order Completed">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Dispatch
                                                    Date</strong> </label>
                                        </div>
                                        <div class="grid_5">
                                            <input class="required" type="date" formControlName="DispatchDate" />
                                            <app-field-error-display [displayError]="isFieldValid('DispatchDate')"
                                                errorMsg="Please  enter Dispatch Date">
                                            </app-field-error-display>
                                        </div>
                                        <div class="grid_4" style="display: flex;">
                                            <input class="required" type="checkbox" formControlName="OrderCancelled"
                                                style="width: 50px;margin-right: 12px;"
                                                [(ngModel)]="isCheckedCancelled" /><span>Order
                                                Cancelled</span>
                                            <app-field-error-display [displayError]="isFieldValid('OrderCancelled')"
                                                errorMsg="Please  enter Order Cancelled">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Total
                                                    Quentity</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="TotalQuentity" />
                                            <app-field-error-display [displayError]="isFieldValid('TotalQuentity')"
                                                errorMsg="Please  enter Total Quentity">
                                            </app-field-error-display>
                                        </div>
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Order
                                                    Comm.</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="OrderComm" />
                                            <app-field-error-display [displayError]="isFieldValid('OrderComm')"
                                                errorMsg="Please  enter Order Commplete">
                                            </app-field-error-display>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Rolltex
                                                    Comm</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="RolltexComm"
                                                (keyup)="changedRolltex($event)" />
                                            <app-field-error-display [displayError]="isFieldValid('RolltexComm')"
                                                errorMsg="Please  enter Rolltex Comm">
                                            </app-field-error-display>
                                        </div>
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>YFI
                                                    Comm.</strong> </label>
                                        </div>
                                        <div class="grid_3">
                                            <input class="required" type="text" formControlName="YFIComm"
                                                (keyup)="changedYFIComm($event)" />
                                            <app-field-error-display [displayError]="isFieldValid('YFIComm')"
                                                errorMsg="Please  enter YFI Commplete">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Total Order
                                                    Value</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="TotalOrderValue" />
                                            <app-field-error-display [displayError]="isFieldValid('TotalOrderValue')"
                                                errorMsg="Please  enter Total Order Value">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Size
                                                    Range</strong> </label>
                                        </div>
                                        <div class="grid_9">
                                            <input class="required" type="text" formControlName="SizeRange" />
                                            <app-field-error-display [displayError]="isFieldValid('SizeRange')"
                                                errorMsg="Please  enter Size Range">
                                            </app-field-error-display>
                                        </div>
                                    </div>
                                    <div class="actions">
                                        <div class="row">
                                            <div class="grid_3">
                                                <label for="v1_normal_input"><strong>Status</strong>
                                                </label>
                                            </div>
                                            <div class="grid_3">
                                                <input class="required" type="text" formControlName="Status" />
                                                <app-field-error-display [displayError]="isFieldValid('Status')"
                                                    errorMsg="Please  enter Status">
                                                </app-field-error-display>
                                            </div>
                                            <div class="grid_2">
                                                <label for="v1_normal_input"><strong>Date</strong>
                                                </label>
                                            </div>
                                            <div class="grid_4">
                                                <input class="required" type="date" formControlName="oDate" />
                                                <app-field-error-display [displayError]="isFieldValid('oDate')"
                                                    errorMsg="Please  enter Date">
                                                </app-field-error-display>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div class="actions">
                                <div class="row">
                                    <div class="grid_12">
                                        <button type="button" *ngIf="this.order_code_number; else emptyValueBlock"
                                            (click)="addItemModel(this.order_id_number)" class="btn btn-secondary"
                                            data-dismiss="modal"
                                            style=" width: 128px; background: blueviolet; float: right; ">Add
                                            Item</button>
                                        <ng-template #emptyValueBlock> <button type="button" class="btn btn-secondary"
                                                data-dismiss="modal"
                                                style="width: 128px;background: blueviolet;float: right;opacity: .6;pointer-events: none;">Add
                                                Item</button></ng-template>
                                        <div class="popup" id="modalEditItemView" style="display: none;">
                                            <!-- Popup Content -->
                                            <div class="popup-content">

                                                <div class="modal-content" style="width: 70vh;">
                                                    <div class="modal-header"
                                                        style="text-align: start;display: flex;    padding: 10px;">
                                                        <h5 class="modal-title"> Item List</h5>
                                                        <!-- <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close" style=" right: 0; position: absolute;">
                                                            <span>&times;</span>
                                                        </button> -->
                                                    </div>
                                                    <div class="modal-body" style="height: 300px;">
                                                        <input type="hidden" #ConversionCur_value
                                                            value="{{this.ConversionCurrencyValue}}" />
                                                        <input type="hidden" #order_code
                                                            value="{{this.order_code_number}}"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <input type="hidden" #order_id value="{{this.order_id_number}}"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <div class="row">
                                                            <div class="grid_12">
                                                                <div class="dropdown-menu" [ngClass]="{ 'show': true }"
                                                                    style=" background-color: #fff0; background-clip: padding-box; border: none; box-shadow: none; ">
                                                                    <div
                                                                        *ngFor="let option of ItemMasterList | filter:searchItemMasterList">
                                                                        <label>
                                                                            <input type="checkbox"
                                                                                [checked]="option.checked"
                                                                                (change)="option.checked = !option.checked"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            {{ option.code | slice:0:45 }}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="modal-footer" style="padding: 0;display: contents;">
                                                        <div class="row" style="padding: 0;">
                                                            <div class="grid_6">

                                                            </div>
                                                            <div class="grid_6" style="display: flex;">
                                                                <button type="button" class="btn btn-primary"
                                                                    (click)="closePopup('singleModel')"
                                                                    style="margin: 8px 12px;">CANCEL</button>
                                                                <button type="button"
                                                                    (click)="addValueitem(order_code.value,order_id.value,ConversionCur_value.value)"
                                                                    class="btn btn-primary"
                                                                    data-dismiss="modal">Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="actions">
                                <div class="tabletools">
                                    <div class="left" style="padding: 5px; font-size: large;color: black;">
                                        Item List
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="grid_12 ">
                                        <div class="scroll-table-container">
                                            <table class="scroll-table dynamic styled" data-filter-Bar="always"
                                                data-table-tools='{"display":false}'>
                                                <thead>
                                                    <tr>
                                                        <!-- <th>Id</th> -->
                                                        <th>Style No</th>
                                                        <th>Description</th>
                                                        <th>Qty</th>
                                                        <th>Export Date</th>
                                                        <th>GSM</th>
                                                        <th>Unit</th>
                                                        <th>R.No</th>
                                                        <th>R.Date</th>
                                                        <th>Size</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item1 of this.tree_Contact_ContactItemList; let i = index">
                                                        <!-- <td>{{i + 1 }}</td> -->
                                                        <td>{{ item1.ORDER_ARTICLE_CODE }}</td>
                                                        <td>{{ item1.Item_Description }}</td>
                                                        <td>{{ item1.ITEM_QTY }}</td>
                                                        <td style="padding: 0px 0px 0px 25px;">
                                                            {{item1.EXPORT_DATE}}
                                                            <!--sudeshkumar-->
                                                            <a href="javascript:void(0);"
                                                                (click)="editDialogUpdateChangeDate(i)"
                                                                style="margin-left: 15px;"><span><img
                                                                        src="./assets/images/datepicker.jpg"
                                                                        style="width: 40px;" /></span></a>

                                                            <div class="modal" id="editDialogUpdateChangeDate{{i}}">
                                                                <div class="modal-dialog modal-dialog-centered modal-lg"
                                                                    style="--bs-modal-width: 400px;">
                                                                    <div class="modal-content">

                                                                        <!-- Modal Header -->
                                                                        <div class="modal-header">
                                                                            <h4 class="modal-title">Export Date</h4>
                                                                            <button type="button" class="close"
                                                                                (click)="closePopupStyleDate(i)">&times;</button>
                                                                        </div>
                                                                        <!-- Modal body -->
                                                                        <div class="modal-body">
                                                                            <input type="date" #exportDate
                                                                                [(ngModel)]="item1.EXPORT_DATE"
                                                                                name="name{{i}}"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                placeholder="Choose a date"
                                                                                [value]="item1.EXPORT_DATE | date: 'yyyy-MM-dd'" />
                                                                        </div>
                                                                        <div class="modal-footer"
                                                                            style=" padding: 12px;">
                                                                            <button type="button"
                                                                                (click)="closePopupStyleDate(i)"
                                                                                class="btn btn-secondary"
                                                                                style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                                                                            <button type="button"
                                                                                (click)="handleDatepickerClosed(i,exportDate.value,item1.ORDER_CODE,item1.Order_Id,item1.ORDER_ARTICLE_CODE)"
                                                                                class="btn btn-primary">Save</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- -->
                                                        </td>

                                                        <td>{{ item1.GSM }}</td>
                                                        <td>{{ item1.Unit }}</td>
                                                        <td>{{ item1.Amendment_No }}</td>
                                                        <td>{{ item1.Amendment_Date }}</td>
                                                        <td class="center">
                                                            <div class="left">
                                                                <button type="button"
                                                                    (click)="onDelete(item1.ORDER_ARTICLE_CODE, item1.ORDER_CODE, item1.Order_Id)"
                                                                    class="btn btn-danger"
                                                                    style=" padding: 0px 15px; height: 30px; margin-top: 8px; color: white; ">Delete</button>
                                                            </div>
                                                            <div class="right">
                                                                <button type="button" class="submit"
                                                                    (click)="editDialogUpdate(i,item1.ORDER_ARTICLE_CODE, item1.ORDER_CODE)"
                                                                    style="padding: 7px 18px; height: 30px; margin-top: 8px;color: #ffffff;margin-left: -15px;">Update</button>
                                                                <div class="modal" id="modalEditdataItem{{i}}"
                                                                    style="display: none;">
                                                                    <div class="modal-dialog" role="document"
                                                                        style="max-width: 900px;">
                                                                        <div class="modal-content">
                                                                            <div class="modal-header"
                                                                                style="text-align: start;display: flex;">
                                                                                <h5 class="modal-title">
                                                                                    Item Size
                                                                                </h5>
                                                                                <button type="button" class="close"
                                                                                    data-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                    (click)="closeEditItemAmount(i, item1.ORDER_CODE, item1.Order_Id)"
                                                                                    style="right: 0; position: absolute;">
                                                                                    <span>&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="modal-body"
                                                                                style="overflow: auto; ">
                                                                                <aside style="width: 220px;">
                                                                                    <ul class="top"
                                                                                        style="margin-top: 0px; height: 500px; text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                                                                                        <div id="lazy"
                                                                                            class="demo jstree jstree-2 jstree-default"
                                                                                            role="tree"
                                                                                            aria-multiselectable="true"
                                                                                            tabindex="0"
                                                                                            aria-activedescendant="1"
                                                                                            aria-busy="false">
                                                                                            <ul class="jstree-container-ul jstree-children"
                                                                                                role="presentation">
                                                                                                <li role="none" id="1"
                                                                                                    class="jstree-node  jstree-open jstree-last">
                                                                                                    <i class="jstree-icon jstree-ocl"
                                                                                                        role="presentation"></i>
                                                                                                    <a class="jstree-anchor"
                                                                                                        tabindex="-1"
                                                                                                        role="treeitem"
                                                                                                        aria-selected="false"
                                                                                                        aria-level="1"
                                                                                                        aria-expanded="true"
                                                                                                        id="1_anchor">Items
                                                                                                    </a>
                                                                                                    <ul role="group"
                                                                                                        class="jstree-children">
                                                                                                        <li role="none"
                                                                                                            class="jstree-node  jstree-leaf"
                                                                                                            *ngFor="let item of this.tree_Items_Details">
                                                                                                            <i class="jstree-icon jstree-ocl"
                                                                                                                role="presentation"></i>
                                                                                                            <a class="jstree-anchor"
                                                                                                                href="javascript:void(0);"
                                                                                                                style="color: black;"
                                                                                                                (click)="TreeListBinding(item.Item_code, item1.ORDER_ARTICLE_CODE, item1.ORDER_CODE)"
                                                                                                                aria-selected="true">
                                                                                                                {{item.Item_code}}</a>
                                                                                                        </li>

                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>

                                                                                    </ul>
                                                                                </aside>
                                                                                <section id="content" class="clearfix"
                                                                                    data-sort="true"
                                                                                    style="padding-left: 0;">

                                                                                    <div class="grid_12">
                                                                                        <h1
                                                                                            style="padding: 0px 0px 20px 0px;;font-size: large;top: 28px;left: 15px;background: white;width: 169px;z-index: 0;position: relative;">
                                                                                            Item Price
                                                                                        </h1>
                                                                                        <div class="box validate">
                                                                                            <div class="content"
                                                                                                style="padding: 15px;">
                                                                                                <div class="scroll-table-container"
                                                                                                    style="border: 2px solid green; height: 150px;position: relative; ">
                                                                                                    <table
                                                                                                        class="scroll-table dynamic styled">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>
                                                                                                                    Size
                                                                                                                    Code
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Mode
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Quantity
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Item
                                                                                                                    Price
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Toral
                                                                                                                    Price
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Invoice
                                                                                                                    Price
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <input
                                                                                                                        class="required"
                                                                                                                        type="text"
                                                                                                                        #o_ItemCode
                                                                                                                        value="{{this.tree_edit_list.ORDER_ITEM_CODE}}"
                                                                                                                        [readonly]="isReadItemCode"
                                                                                                                        style="border: none;box-shadow: none;background-color: #dcffff;"
                                                                                                                        [ngModelOptions]="{standalone: true}" />
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <select
                                                                                                                        #o_mode
                                                                                                                        [(ngModel)]="selectedMode"
                                                                                                                        data-placeholder="Choose a Name"
                                                                                                                        style="width: 100%;border: none;;height: 30px;padding: 5px;"
                                                                                                                        [ngModelOptions]="{standalone: true}">
                                                                                                                        <option
                                                                                                                            [ngValue]="null"
                                                                                                                            disabled>
                                                                                                                            Choose
                                                                                                                            your
                                                                                                                            Mode
                                                                                                                        </option>
                                                                                                                        <option
                                                                                                                            *ngFor="let size of tree_card_Buyer_item_prize"
                                                                                                                            [ngValue]="size.PARAM_VALUE">
                                                                                                                            {{size.PARAM_VALUE}}
                                                                                                                        </option>
                                                                                                                    </select>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <input
                                                                                                                        class="required"
                                                                                                                        type="text"
                                                                                                                        #o_ITEM_QTY
                                                                                                                        (keyup)="onChangeItemSzie(o_ITEM_QTY.value, o_ITEM_PRICE.value)"
                                                                                                                        style="border: none;box-shadow: none;"
                                                                                                                        value="{{this.tree_edit_list.ITEM_QTY}}"
                                                                                                                        [ngModelOptions]="{standalone: true}" />
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <input
                                                                                                                        class="required"
                                                                                                                        type="text"
                                                                                                                        #o_ITEM_PRICE
                                                                                                                        style="border: none;box-shadow: none;"
                                                                                                                        value="{{this.tree_edit_list.ITEM_PRICE}}"
                                                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                                                        disabled />
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <input
                                                                                                                        class="required"
                                                                                                                        type="text"
                                                                                                                        #o_totalPrice
                                                                                                                        style="border: none;box-shadow: none;"
                                                                                                                        value="{{this.get_totalPrice}}"
                                                                                                                        [ngModelOptions]="{standalone: true}" />
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <input
                                                                                                                        class="required"
                                                                                                                        type="text"
                                                                                                                        #o_Invoice_price
                                                                                                                        style="border: none;box-shadow: none;"
                                                                                                                        value="{{this.tree_edit_list.ITEM_INVOICE_PRICE}}"
                                                                                                                        [ngModelOptions]="{standalone: true}" />
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="grid_12"
                                                                                        style="margin-top: -38px;">
                                                                                        <h1
                                                                                            style="padding: 0px 0px 20px 0px;;font-size: large;top: 28px;left: 15px;background: white;width: 169px;z-index: 0;position: relative;">
                                                                                            Item Price History
                                                                                        </h1>
                                                                                        <div class="box validate">
                                                                                            <div class="content"
                                                                                                style="padding: 15px;">
                                                                                                <div class="scroll-table-container"
                                                                                                    style="border: 2px solid green; height: 150px;position: relative; ">
                                                                                                    <table
                                                                                                        class="scroll-table dynamic styled">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th>
                                                                                                                    ID
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Size
                                                                                                                    Code
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Mode
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Quantity
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Item
                                                                                                                    Price
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Toral
                                                                                                                    Price
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Invoice
                                                                                                                    Price
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    R
                                                                                                                    No.
                                                                                                                </th>
                                                                                                                <th>
                                                                                                                    Amendment
                                                                                                                    Date
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr
                                                                                                                *ngFor="let item3 of this.itemListHistory; let i = index">
                                                                                                                <td>
                                                                                                                    {{i+1}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.ORDER_ITEM_CODE}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.ITEM_MODE}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.ITEM_QTY}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.ITEM_PRICE}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.TOTAL_FINAL_PRICE}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.ITEM_INVOICE_PRICE}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.Amendment_No}}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {{item3.Amendment_Date}}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="grid_12">
                                                                                        <div class="box validate">
                                                                                            <div class="actions">
                                                                                                <div class="right">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        (click)="closeEditItemAmount(i, item1.ORDER_CODE, item1.Order_Id)"
                                                                                                        class="btn btn-secondary"
                                                                                                        style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        (click)="editItemAmount(i,item1.ORDER_ARTICLE_CODE, item1.ORDER_CODE,o_ItemCode.value,o_mode.value,o_ITEM_QTY.value,o_ITEM_PRICE.value,o_totalPrice.value,o_Invoice_price.value, item1.Order_Id)"
                                                                                                        class="btn btn-primary">Save</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="actions">
                                <div class="tabletools">
                                    <div class="left" style="padding: 5px; font-size: large;color: black;">
                                        Item Size List</div>
                                </div>
                                <div class="row">
                                    <div class="grid_9">
                                        <div class="scroll-table-container">
                                            <table class="scroll-table dynamic styled" data-filter-Bar="always"
                                                data-table-tools='{"display":false}'>
                                                <thead>
                                                    <tr>
                                                        <!-- <th>#</th> -->
                                                        <th>Size Code</th>
                                                        <th>Mode</th>
                                                        <th>Quantity</th>
                                                        <th>Item price</th>
                                                        <th>Invoice Price</th>
                                                        <th>FINAL_PRICE</th>
                                                        <th>R.No</th>
                                                        <th>R.Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item1 of this.tree_Contact_ContactItemSizeList; let idata = index">
                                                        <!-- <td class="center">
                                                            <div class="right">
                                                                <button type="button" class="submit"
                                                                    (click)="editDialog(item1.ORDER_ITEM_CODE,idata,item1.ITEM_MODE)"
                                                                    style="padding: 7px 18px; height: 30px; margin-top: 8px;">Edit</button>

                                                                <div class="modal" id="datamodalEdit{{idata}}"
                                                                    tabindex="-1" role="dialog">
                                                                    <div class="modal-dialog" role="document">
                                                                        <form id="modal_form_signup"
                                                                            novalidate="novalidate">
                                                                            <div class="modal-content">
                                                                                <div class="modal-header"
                                                                                    style="text-align: start;display: flex;">
                                                                                    <h5 class="modal-title">
                                                                                        Add Prize
                                                                                    </h5>
                                                                                    <button type="button" class="close"
                                                                                        (click)="closePopup(idata)"
                                                                                        style=" right: 0; position: absolute;">
                                                                                        <span>&times;</span>
                                                                                    </button>
                                                                                </div>
                                                                                <div class="modal-body">
                                                                                    <input type="hidden"
                                                                                        #orde_id_item_code
                                                                                        value="{{item1.ORDER_ITEM_CODE}}" />
                                                                                    <input type="hidden" #orde_code
                                                                                        value="{{item1.ORDER_CODE}}"
                                                                                        [ngModelOptions]="{standalone: true}" />
                                                                                    <div class="row">
                                                                                        <div class="grid_6">
                                                                                            <label for="d2_username"
                                                                                                style="width: 100%;text-align: start;">
                                                                                                <strong>Item
                                                                                                    Price</strong>
                                                                                            </label>
                                                                                            <div>
                                                                                                <input #amount
                                                                                                    class="required"
                                                                                                    type="text"
                                                                                                    style="margin: 0px 0px 0px 0px;"
                                                                                                    value="{{item1.ITEM_PRICE}}"
                                                                                                    [ngModelOptions]="{standalone: true}" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="grid_6">
                                                                                            <label for="d2_username"
                                                                                                style="width: 100%;text-align: start;">
                                                                                                <strong>Quantity</strong>
                                                                                            </label>
                                                                                            <div>
                                                                                                <input #Quantity
                                                                                                    class="required"
                                                                                                    type="text"
                                                                                                    style="margin: 0px 0px 0px 0px;"
                                                                                                    value="{{item1.ITEM_QTY}}"
                                                                                                    [ngModelOptions]="{standalone: true}" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="grid_6">
                                                                                            <label for="d2_username"
                                                                                                style="width: 100%;text-align: start;">
                                                                                                <strong>Final
                                                                                                    Price</strong>
                                                                                            </label>
                                                                                            <div>
                                                                                                <input #finalAmount
                                                                                                    class="required"
                                                                                                    type="text"
                                                                                                    style="margin: 0px 0px 0px 0px;"
                                                                                                    value="{{item1.TOTAL_FINAL_PRICE}}"
                                                                                                    [ngModelOptions]="{standalone: true}" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="grid_6">
                                                                                            <label for="d2_username"
                                                                                                style="width: 100%;text-align: start;">
                                                                                                <strong>Mode</strong>
                                                                                            </label>
                                                                                            <div>
                                                                                                <select #mode
                                                                                                    [(ngModel)]="item1.ITEM_MODE"
                                                                                                    data-placeholder="Choose a Name"
                                                                                                    style="width: 100%;top: -2px;height: 30px;padding: 5px;"
                                                                                                    [ngModelOptions]="{standalone: true}">
                                                                                                    <option
                                                                                                        [ngValue]="null"
                                                                                                        disabled>
                                                                                                        Choose
                                                                                                        your
                                                                                                        Mode
                                                                                                    </option>
                                                                                                    <option
                                                                                                        *ngFor="let size of tree_card_Buyer_item_prize"
                                                                                                        [ngValue]="size.PARAM_VALUE">
                                                                                                        {{size.PARAM_VALUE}}
                                                                                                    </option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="modal-footer"
                                                                                    style=" padding: 12px;">
                                                                                    <button type="button"
                                                                                        (click)="closePopup(idata)"
                                                                                        class="btn btn-secondary"
                                                                                        style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                                                                                    <button type="button"
                                                                                        (click)="editSaveDialog(orde_code.value,item1.Order_Id,orde_id_item_code.value,item1.ORDER_ARTICLE_CODE,finalAmount.value,mode.value,Quantity.value,amount.value,idata)"
                                                                                        class="btn btn-primary">Save</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td> -->
                                                        <td>{{ item1.ORDER_ITEM_CODE }}</td>
                                                        <td>{{ item1.ITEM_MODE }}</td>
                                                        <td>{{ item1.ITEM_QTY }}</td>
                                                        <td>{{ item1.ITEM_PRICE }}</td>
                                                        <td>{{ item1.ITEM_INVOICE_PRICE }}</td>
                                                        <td>{{ item1.TOTAL_FINAL_PRICE }}</td>
                                                        <td>{{ item1.Amendment_No }}</td>
                                                        <td>{{ item1.Amendment_Date }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="grid_3">
                                        <div
                                            style="width: 300px;height: 210px;text-align: center;margin: 0;padding: 0;margin-top: 12px;border: 2px solid green;border-radius: 5px;">
                                            <ng-image-slider #nav [images]="imageObject" [imagePopup]="false"
                                                [infinite]="false" [autoSlide]="1"
                                                [imageSize]="{width: '100%', height: 210}" slideImage="1"
                                                (imageClick)="imageClickHandler($event)"></ng-image-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="actions">
                                <div class="row">
                                    <div class="grid_2">
                                        <label for="v1_normal_input"><strong> Trems Of
                                                Payment</strong> </label>
                                    </div>
                                    <div class="grid_4">
                                        <input class="required" type="text" formControlName="PAYMENT_TREMS" />
                                        <app-field-error-display [displayError]="isFieldValid('PAYMENT_TREMS')"
                                            errorMsg="Please  enter PAYMENT_TREMS">
                                        </app-field-error-display>
                                    </div>
                                    <div class="grid_2">
                                        <label for="v1_normal_input"><strong>Packing</strong>
                                        </label>
                                    </div>
                                    <div class="grid_4">
                                        <input class="required" type="text" formControlName="Packing" />
                                        <app-field-error-display [displayError]="isFieldValid('Packing')"
                                            errorMsg="Please  enter Packing">
                                        </app-field-error-display>
                                    </div>
                                    <div class="grid_2">
                                        <label for="v1_normal_input"><strong>Remarks</strong>
                                        </label>
                                    </div>
                                    <div class="grid_10">
                                        <input class="required" type="text" formControlName="Remarks" />
                                        <app-field-error-display [displayError]="isFieldValid('Remarks')"
                                            errorMsg="Please  enter Remarks">
                                        </app-field-error-display>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit" value="Save" name="submit"
                                    style="width: 100px;font-size: small;color: white;"
                                    [ngModelOptions]="{standalone: true}" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="grid_12" style="top: -35px;">
                <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                  </span>
                  <span class="upload" *ngIf="message">
                    {{message}}
                  </span>
                </div> -->
            </form>

        </section>
    </div>
</div>