import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;

@Component({
  selector: 'app-size-master',
  templateUrl: './size-master.component.html',
  styleUrls: ['./size-master.component.css']
})
export class SizeMasterComponent implements OnInit {

  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  iconColor: any
  borderColor: any;
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';

  name = "Angular " + VERSION.major;
  page: number = 1;
  tree_card_Buyer: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  selectedUser = null;
  selectedSize = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  isCheckDataBase: boolean = false;
  SizeMasterList: any = [];
  ItemCategoryList: any = [];
  progress: number = 0;
  
  uploadForm = new FormGroup({
    Param_Value: new FormControl('', Validators.required),
  });

  uploadFormEdit = new FormGroup({
    Param_Unique_Id: new FormControl('', Validators.required),
    Param_Name: new FormControl('SIZE'),
    Param_Value: new FormControl('', Validators.required),
  });

  constructor( 
    private webService: WebServiceService,
    private router: Router,
    private _fb: FormBuilder
  ) { 
  }
  
  isFieldValid(field: string) {
    return !this.uploadForm.get(field).valid && this.uploadForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  ngOnInit(): void {
    this.loadTreeBuyer(); 
    this.loadTreeBuyer2();
    
  }

  loadTreeBuyer2 = () => {
    debugger
    let url = "SizeMaster/Listtree";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          this.tree_card_Buyer = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card_Buyer
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
            }
          });
          
        } else {

        }
      }
    );
  }

  loadTreeBuyer = () => {
    let url = "SizeMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {

        let dataList = "";
        if (data.status === 'OK') {
          this.uploadForm.reset();
          let data12 = (new Function("return [" + data.data + "]")());
          debugger
        //  this.tree_card_Buyer = data12[0];
        data12[0].forEach(element => {
            dataList += "<tr><td>" + element.PARAM_UNIQUE_ID + "</td><td>" + element.PARAM_NAME + "</td><td>" + element.PARAM_VALUE + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;

        } else {

        }
      }
    );
  }
  deleteDialog = () => {
    this.dialogDelete = true;
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.selectedSize = null;
    this.loadTreeBuyer();
    this.uploadForm.reset();
  }

  TreeListBinding = (Item_Code,id) => {
    try {
      let data = {
        PARAM_UNIQUE_ID: Item_Code
      } 
      this.selectedUser = null;
      this.selectedSize = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url = "SizeMaster/Single";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {

          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            debugger
            this.tree_edit_list = data12[0][0];
            this.uploadFormEdit = this._fb.group({
              Param_Unique_Id: [this.tree_edit_list.PARAM_UNIQUE_ID],
              Param_Name: [this.tree_edit_list.PARAM_NAME],
              Param_Value: [this.tree_edit_list.PARAM_VALUE],
            });
            this.loadTreeBuyer();

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }


  CodeAlredyExist = "";
  CodeCheckUnique(code) {
    // let url = "Buyer/EditBuyerCodeAndNameList";
    // this.webService.loadTreetListEdit(url, code).subscribe(data => {
    //   if (data.status === 'OK') {
    //     this.CodeAlredyExist = "Code Alredy Exist, Please another code ";
    //   }
    //   else {
    //     this.CodeAlredyExist = "";
    //   }
    // });
  }

  reset() {
    this.uploadForm.reset();
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/item/master']);
    });
  }

  onSubmit() {
    if (this.uploadForm.valid) {
      let data = {
        PARAM_NAME: 'SIZE',
        PARAM_VALUE: this.uploadForm.value.Param_Value
      }
      let url = "SizeMaster/Add";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            this.uploadForm.reset();
            alert('Added')
            window.location.replace("/sizemaster");
          } else {
            alert('Not Edited')
          }
        }
      );
    }
    else {
      this.validateAllFormFields(this.uploadForm);
    }

  }

  onEditSubmit() {
    let data = {
      PARAM_NAME: this.uploadFormEdit.value.Param_Name, 
      PARAM_VALUE: this.uploadFormEdit.value.Param_Value,
      PARAM_UNIQUE_ID: this.uploadFormEdit.value.Param_Unique_Id,
    }
    let url = "SizeMaster/Edit";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Edited')
          this.uploadForm.reset();
          window.location.replace("/sizemaster");
          this.TreeListBinding(this.uploadFormEdit.value.Param_Unique_Id,'');
        } else {
          alert('Not Edited')
        }
      }
    );
    
  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong>
            </h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Size Master
          </strong></h5>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Value</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}
