import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component, 
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service'; 
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
export class Country {
  constructor(public name: string, public code: string) { }
}
  declare let $: any;
@Component({
  selector: 'app-order-wise',
  templateUrl: './order-wise.component.html',
  styleUrls: ['./order-wise.component.css']
})
export class OrderWiseComponent implements OnInit {
  template = '';
  fullScreen = true;
	tree_card_OrderDropdown:any =[];
  isReadonly :any = true;
  report_list: any = [];
  tree_edit_list_print:any=[];
  page: number = 1;
  show:any =false;
  showPopup = false;
  search_text_order_no: any;
  OrderNoContainsCtrl = new FormControl();
  filteredCountry: Observable<any[]>; 
  qtyOrder:any;
  PriceOrder:any;
  InvPriceOrder:any;
  TotalPriceOrder:any;
  country_lis: Country[] = [
    { name: '001', code: 'AF' }
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private webService: WebServiceService,
  ) {

    this.OrderNoContainsCtrl = new FormControl();
    this.filteredCountry = this.OrderNoContainsCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filtercountry(country) : this.country_lis.slice()
      )
    );

  }
  filtercountry(name: string) {
    let arr = this.country_lis.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
    return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
  }
 
  @HostListener('window:click', ['$event'])
  onWindowClick(event: MouseEvent): void {
    // Close the popup if it is open and clicked outside
    if (this.showPopup && !event.target['classList'].contains('popup')) {
      this.closePopup();
    }
  }
  ngOnInit(): void {
    this.lOrderNoSerch();
  }  

  lOrderNoSerch = () => {
    this.country_lis = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=order&order_code=7960";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          data12[0].forEach(element => {
            this.country_lis.push({
              name: element.ORDER_CODE,
              code: element.ORDER_DATE
            });
          });
        } else {

        }
      }
    );
  }
  onSearchIOrderNoSeletion = (selectedValue) => {
    this.show = true;
    this.loadTreeSupplie(selectedValue);
    // this.loadShipmentNoSerch(selectedValue);
    // this.check_merchandise(selectedValue);
    // this.proshowPopupGenerateShipmentColorDetalls(selectedValue);
    // this.ItemList_merchandise_list(selectedValue);
    // this.ItemList_merchandise_list_history(selectedValue);
  }
  
  loadTreeSupplie = async (order_no) => {
    let url = "ReportViewer/singleOrderEnquery?order_no="+order_no;
    await this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList="";
        if (data.status === 'OK') {
          let qty = 0;
          let Price = 0;
          let InvPrice = 0;
          let TotalPrice = 0;
          let data12 = (new Function("return [" + data.data + "]")());
          this.report_list = data12[0];
          this.report_list.forEach(element => {
            console.log(element.TOTAL_FINAL_PRICE);
        
            // Ensure that the values are parsed as numbers
            const itemQty = parseFloat(element.ITEM_PRICE_DETAIL_QTY);
            const itemPrice = parseFloat(element.ITEM_PRICE);
            const itemInvPrice = parseFloat(element.ITEM_INVOICE_PRICE);
            const itemTotalPrice = parseFloat(element.TOTAL_FINAL_PRICE);
            dataList += "<tr><td>" + element.ORDER_ITEM_CODE + "</td><td>" + element.ITEM_MODE + "</td><td>" +  + "</td><td>" + itemQty + "</td><td>" + itemPrice + "</td><td>" + itemInvPrice + "</td><td>" + itemTotalPrice + "</td></tr>";
            // Check if the parsed values are valid numbers
            if (!isNaN(itemQty) && !isNaN(itemPrice) && !isNaN(itemInvPrice) && !isNaN(itemTotalPrice)) {
                qty += itemQty;
                Price += itemPrice;
                InvPrice += itemInvPrice;
                TotalPrice += itemTotalPrice;
            }
        });
          this.qtyOrder = qty;
          this.PriceOrder= Price;
          this.InvPriceOrder= InvPrice;
          this.TotalPriceOrder= TotalPrice;
          console.log(TotalPrice);
          this.tree_edit_list_print = dataList;
          this.show = false;
        } else {

        }
      }
    );
  }

  printPage1() {
    window.print();

  }
  
  togglePopup(): void {
    this.show = true;
    document.body.style.overflow = 'hidden';
    let url = "Order/ListOrder";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          let dataList = [];
          dataList = (new Function("return [" + data.data + "]")());
          if (dataList[0].length > 0) {
            this.tree_card_OrderDropdown = dataList[0];
            this.showPopup = true;
            this.show = false;
          }
          else {
            this.tree_card_OrderDropdown = [""];
            console.log(this.tree_card_OrderDropdown);
          }
          this.show = false;
          // console.log(this.tree_card_OrderDropdown)
        } else {

        }
      }
    );
  }

  closePopup(): void {
    this.showPopup = false;

    // Re-enable scrolling on the background page when the popup is closed
    document.body.style.overflow = 'auto';
  }

  editOrderList = (id, code) => {
    try {
      let data = {
        order_Code: code,
        order_id: id.toString()
      }
     
      let url = "Order/Single";
      this.webService.loadPost(url, data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            if (data12[0].length > 0) {
             // this.tree_Order_Details = data12[0][0];
              this.isReadonly = true;
             
            } else {

            }
          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  printPage = async () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
	  <div class="card-body border-top pt-20 mt-0">
    <div class="row">
      <div class="col-sm-12">
        <div class="invoice-details">
          <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong></h5>
          <div class="invoice-details-inner mt-2">
            <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
              <br /> INDIA
            </p>
            <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
            <p><strong>Email:</strong> mail@youngfashionindia.com</p>
          </div>
        </div>
        <hr style="border-bottom: 1px solid #67676a;" />
      </div>
      <div class="col-sm-12">
        <h3 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>List Of Buyers</strong></h3>
      </div>
    </div>
  </div>
  <!-- invoice Table -->
  <div class="table-responsive invoice-table mb-4 mt-0">
    <table class="table table-striped table-bordered table-sm row-border hover" id="table_companies">
      <thead>
      <tr>
      <th>Item Size</th>
      <th>Mode</th>
      <th>Qty.</th>
      <th>Price</th>
      <th>Inv. Price</th>
      <th>Total Price</th>
    </tr>
      </thead>
      <tbody>
	      ${printContents}
        <tr style="border: 2px solid #000000 !important;">
            <td></td>
            <td><strong>Totals:</strong></td>
            <td><strong> ${this.qtyOrder}</strong></td>
            <td><strong> ${this.PriceOrder}</strong></td>
            <td><strong> ${this.InvPriceOrder}</strong></td>
            <td><strong> ${this.TotalPriceOrder}</strong></td>
          </tr>
      </tbody>
    </table>
    
  </div>
      
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
  
}
