<div class="container">
    <form [formGroup]="saveFileForm" (ngSubmit)="onExpSubmit()" enctype="multipart/form-data">
        <div class="row">
            <div class="col-sm-4" style="padding-left: 0px;">
                <div class="panel panel-dark-outline tabs-panel" style="margin: 0px 0;">
                    <div class="panel-body tab-content" style="padding: 15px 0px 15px 0px;">
                        <div class="tab-pane active documents-panel">
                            <div class="col-md-12" style="padding-left: 0;"><b>Order No</b></div>
                            <div class="col-md-12" style="padding-left: 0;">
                                <input type="text" class="form-control" formControlName="OrderNo" placeholder="Order No"
                                    style="margin: 4px 4px 4px 0px;" readonly>
                            </div>

                            <div class="col-md-12" style="padding-left: 0;"><b>File Upload</b></div>
                            <div class="col-md-12" style="padding-left: 0;">
                                <input type='file' class="form-control" #resumeInput
                                    style="margin: 4px 4px 4px 0px;padding: 7px;">
                            </div>
                            <div class="v-spacing-xs"></div>
                            <button type="submit" class="button" class="btn btn-block btn-success" color="primary"
                                style="margin-top: 12px;"> <i class="fa fa-plus"> </i> Upload
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-sm-8 tab-content no-bg no-border" style="overflow: auto;max-height: 350px;">
                <div class="row clearfix">
                    <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let item3 of this.report_list; let i = index">
                        <div class="card">
                            <div class="file">
                                <div class="hover"
                                    style=" text-align: justify; position: relative; display: flex; justify-content: flex-end; ">
                                    <button type="button" class="btn btn-icon btn-danger"
                                        (click)="fileDelete(item3.fileName)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon btn-danger"
                                        (click)="downloadFile(item3.fileName, item3.Extension)" style="margin-left: 6px;">
                                        <i class="fa fa-download"></i>
                                    </button>
                                </div>
                                <div class="large-view-container" (click)="closeLargeView()" *ngIf="isConditionTrue">
                                    <img [src]="imageUrl" alt="Large Image">
                                  </div>
                                  
                                <div [ngSwitch]="item3.Extension">
                                    <div class="icon" *ngSwitchCase="'.jpg'" style="width: 100px;height: 100px;justify-content: center;display: flex;" (click)="openLargeView(item3.Url)">
                                        <div class="file-img-box"><img src="{{item3.Url}}" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.png'" style="width: 100px;height: 100px;justify-content: center;display: flex;" (click)="openLargeView(item3.Url)">
                                        <div class="file-img-box"><img src="{{item3.Url}}" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.gif'" style="width: 100px;height: 100px;justify-content: center;display: flex;" (click)="openLargeView(item3.Url)">
                                        <div class="file-img-box"><img src="{{item3.Url}}" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.csv'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-csv-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.txt'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-txt-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.PDF'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-pdf-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.pdf'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-pdf-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.TIFF'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-tiff-96.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.BMP'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-bmp-96.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.doc'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-doc-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.docx'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-docx-64.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.psd'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-psd-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.rar'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-rar-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.zip'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-zip-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.dwg'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-dwg-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.JPEG'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="{{item3.Url}}" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.xlsx'" style="width: 100px;height: 100px;justify-content: center;display: flex;" (click)="openLargeView(item3.Url)">
                                        <div class="file-img-box"><img src="./assets/img/icons8-excel-96.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.xls'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-xls-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                    <div class="icon" *ngSwitchCase="'.ppt'" style="width: 100px;height: 100px;justify-content: center;display: flex;">
                                        <div class="file-img-box"><img src="./assets/img/icons8-ppt-100.png" style="height: 75px;width: 75px;" alt="icon"></div>
                                    </div>
                                </div>
                                <div class="file-name">
                                    <p class="m-b-5 text-muted"><a>{{item3.fileName}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>