import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service';


@Component({
  selector: 'app-buyer-report',
  templateUrl: './buyer-report.component.html',
  styleUrls: ['./buyer-report.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class BuyerReportComponent implements OnInit {
  report_list: any = [];
  tree_edit_list_print: any = [];
  page: number = 1;
  constructor(
    private webService: WebServiceService,
  ) {

  }

  ngOnInit() {
    this.loadTreeSupplie();
    let columnsData = [
      { data: 'Buyer_Code' },
      { data: 'Buyer_Name', className: 'company_name' },
      { data: 'Adress' },
      { data: 'Phone', className: 'integer' },
      { data: 'Fax', className: 'integer' },
      { data: 'Telex', className: 'integer' },
      { data: 'Bank_Code', className: 'integer' }
    ];
    let url = "ReportViewer/BuyerCode";
    this.webService.reportDatatable(url, columnsData, '#table_companies');
    // this.webService.loadTreeLiat(url, '#table_companies', columns,)
  }

  printPage1() {
    setTimeout(() => {
      window.print();
    }, 1000); // 1 second delay

  }

  loadTreeSupplie = async () => {
    let url = "ReportViewer/BuyerCode";
    await this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        debugger
        if (data.result === 'success') {
          debugger
          // let data12 = (new Function("return [" + data.data + "]")());
          // this.report_list = data12[0];
          data.data.forEach(element => {
            dataList += "<tr><td>" + element.Buyer_Code + "</td><td>" + element.Buyer_Name + "</td><td>" + element.Adress + "</td><td>" + element.Phone + "</td><td>" + element.Fax + "</td><td>" + element.Bank_Code + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;
        } else {

        }
      }
    );
  }

  printPage = async () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
	  <div class="card-body border-top pt-20 mt-0">
    <div class="row">
      <div class="col-sm-12">
        <div class="invoice-details">
          <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong></h5>
          <div class="invoice-details-inner mt-2">
            <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
              <br /> INDIA
            </p>
            <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
            <p><strong>Email:</strong> mail@youngfashionindia.com</p>
          </div>
        </div>
        <hr style="border-bottom: 1px solid #67676a;" />
      </div>
      <div class="col-sm-12">
        <h3 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>List Of Buyers</strong></h3>
      </div>
    </div>
  </div>
  <!-- invoice Table -->
  <div class="table-responsive invoice-table mb-4 mt-0">
    <table class="table table-striped table-bordered table-sm row-border hover" id="table_companies">
      <thead>
        <tr>
          <th>Buyer Code</th>
          <th>Name</th>
          <th>Address</th>
          <th>Phone</th>
          <th>Fax</th> 
          <th>Bank Code</th>
        </tr>
      </thead>
      <tbody>
	      ${printContents}
      </tbody>
    </table>
    
  </div>
      
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
