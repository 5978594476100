import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;
@Component({
  selector: 'app-accessories-master',
  templateUrl: './accessories-master.component.html',
  styleUrls: ['./accessories-master.component.css']
})
export class AccessoriesMasterComponent implements OnInit {

  tree_card_bank: any = []; 
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  selectedUser = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  constructor( 
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
     
  }


  ngOnInit(): void {
   // this.loadTreeBuyer();
    let url = "AccessoryCategory/Listtree";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card_bank = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card_bank
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
            }
          });

        } else {

        }
      }
    );
  }

  loadTreeBuyer = () => {
    let url = "AccessoryCategory/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        let dataList = "";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card_bank = data12[0];
          this.tree_card_bank.forEach(element => {
            dataList += "<tr><td>" + element.Accessory_Category_Code + "</td><td>" + element.Name + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;

        } else {

        }
      }
    );
  }

  cancelDialog = () => {
    this.dialogIn = false;
    this.dialogEdit = false;
    this.dialogDelete = false;
  }

  openDialog = () => {
    this.dialogIn = true;
  }


  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
  }

  TreeListBinding = (data, id) => {
    try {
      this.selectedUser = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url ="AccessoryCategory/Single";
      this.webService.loadTreetListEdit(url,data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.tree_edit_list = data12[0][0];
            this.buyerCode = this.tree_edit_list.Buyer_Code;
            this.selectedUser = this.tree_edit_list.Bank_Code;
      
          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  
  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/accessories/category']);
    });
  }

  add = (code, name) => {
    debugger
    let data = {
      accessory_Category_Code:code, 
      name:name
    }
    let url = "AccessoryCategory/Add";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Added')
          this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
        } else {
          alert('Not Added')
        }

      }
    );

  }

  edit = (editCode, editName) => {
    let data = {
      accessory_Category_Code:editCode, 
      name:editName
    }
    let url = "BankMaster/EditBank";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Edited')
          //this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.TreeListBinding(editCode, '');
        } else {
          alert('Not Edited')
        }
      }
    );

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Accessory Category Code</th>
          <th>Name</th>
          
        </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
}
