import {
  OnInit,
  Component,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import * as pluginLabels from 'chartjs-plugin-labels';
import { DatePipe } from '@angular/common';
import * as Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);

@Component({
  selector: 'app-trun-order-view',
  templateUrl: './trun-order-view.component.html',
  styleUrls: ['./trun-order-view.component.css']
})
export class TrunOrderViewComponent implements OnInit {
  private chart: Highcharts.Chart;
  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
  report_list: any = [];
  tree_edit_list_print: any = [];
  selectedData: any;
  tree_card_Suppler: any = [];
  tree_card_Buyer: any = [];
  tree_ContactBuyerConsignee: any = [];
  SizeCurrencyList: any = [];
  enrolled:any=[];
  page: number = 1;
  Onword: any = "";
  curency: any = "";
  rundate: any = "";
  showcontentLView: any = "";
  allcurency = "all";
  allbuyer = "all";
  allSuplier = "all";
  isCheckPage: any = true;
  readioSelected: any;
  showcontent: boolean = false;
  isCheckPage1: any = false;
  quarterList: any = [];
  total_order_sum: any;
  TotalQuantity: any;
  total_Vlaue_Sum: any;
  showcontentLViewName:any="";
  constructor(
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private webService: WebServiceService,
  ) {

  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.loadTreeSupplie(params['params'].FromDate, params['params'].ToDate, params['params'].CurrencyCode, params['params'].action)
    });
     
  }
 
  getCurrentDate(): Date {
    let datetime = new Date();
    let date: any = this.datePipe.transform(datetime, 'yyyy-MM-dd')
    return new Date();
  }


  private createOptions(): ChartOptions {
    return {
      responsive: true,
      // maintainAspectRatio: false,
      legend: { position: "right" },
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red', 'blue'],
          precision: 2
        }
      },
    };
  }

 
  loadTreeSupplie = async (FromDate, ToDate, CurrencyCode, action) => {
    const url = `ReportViewer/singleTrunOverReport`;
    try {
      const data: any = await this.webService.loadTreeLiat(url).toPromise();
      let dataList = '';
      if (data.status === 'OK') {
        const items = JSON.parse(data.data);
        this.enrolled=[];
        this.report_list = items;
        let qty = 0;
        let VlaueCount = 0;
        let total_order_count = 0;
        let TotalPrice = 0;
        this.Onword = FromDate;
        this.rundate = ToDate;
        this.curency = CurrencyCode;
        if(action == 'SupplierReport'){
          this.showcontentLView = 'Supplier Wise';
          this.showcontentLViewName ='Supplier Name';
        }
        else{
          this.showcontentLView = 'Buyer Wise';
          this.showcontentLViewName ='Buyer Name';
        }
        for (const element of items) {
          const total_order = parseFloat(element.TOTAL_NO_OF_ORDERS);
          const itemQty = parseFloat(element.TOTAL_QUANTITY);
          const totalVlaue = parseFloat(element.TOTAL_VALUE);
          this.enrolled.push([element.SUPPLIER_OR_BUYER_CODE + ': ' + element.ageOfTurnOver,itemQty]);
          dataList += "<tr><td>" + element.SUPPLIER_OR_BUYER_CODE + "</td><td>" + element.CURRENCY_CODE + "</td><td>" + element.TOTAL_NO_OF_ORDERS + "</td><td>" + element.TOTAL_QUANTITY + "</td><td> " + element.TOTAL_VALUE + "</td><td>" + element.AvgPrice + "</td><td>" + element.ageOfTurnOver + "</td></tr>";
          this.tree_edit_list_print = dataList;
          if (!isNaN(itemQty) && !isNaN(total_order) && !isNaN(totalVlaue)) {
            qty += itemQty;
            VlaueCount += totalVlaue;
            total_order_count += total_order;
          }

        }
        console.log(this.enrolled);
       
        this.total_order_sum = total_order_count;
        this.TotalQuantity = qty;
        this.total_Vlaue_Sum = VlaueCount;

        console.log(TotalPrice);
        await this.piChartTrunOver(this.enrolled, this.showcontentLView);
        this.tree_edit_list_print = '';  // You haven't specified how to set this value

      } else {
        // Handle the case where data.result is not 'success'
      }
    } catch (error) {
      // Handle any errors that occur during the HTTP request
      console.error('Error:', error);
    }
  }

  piChartTrunOver(enrolledData, view) {
    this.chart = new Highcharts.Chart({
      chart: {
        renderTo: 'pie-chart',
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
        },
      },
      title: {
        text: 'Yearly Summary Report ('+view+')',
      },
      credits: {
        enabled: false
       },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
          showInLegend: true
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Browser share',
          data: enrolledData,
        },
      ],
    });

  }

  private showValues(): void {
    document.getElementById('alpha-value').innerHTML = String(
      this.chart.options.chart.options3d.alpha
    );
    document.getElementById('beta-value').innerHTML = String(
      this.chart.options.chart.options3d.beta
    );
  }

  closePage = () => {
    window.close()
  }
  onPreview = () => {
    window.print();
  }


  onPreview2 = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      .pagination {
        margin: -15px 0;
        display: flex;
        justify-content: center;
    
      }
    
      .ngx-pagination {
        margin-left: 0 !important;
        margin-bottom: 1rem !important;
        line-height: 20px !important;
        padding: 7px 15px 7px !important;
        font-size: 12px !important;
      }
    
      .ngx-pagination::before,
      .ngx-pagination::after {
        content: " " !important;
        display: table !important;
      }
    
      .ngx-pagination::after {
        clear: both !important;
      }
    
      .ngx-pagination li {
        -moz-user-select: none !important;
        -webkit-user-select: none !important;
        -ms-user-select: none !important;
        margin-right: 0.2rem !important;
        border-radius: 0 !important;
      }
    
      .ngx-pagination li {
        display: inline-block !important;
      }
    
      .ngx-pagination a,
      .ngx-pagination button {
        color: #0a0a0a !important;
        display: block !important;
        background-color: rgb(248, 248, 248) !important;
        color: black !important;
        border: 2px solid #8b8b8b !important;
        padding: 0.5rem 1rem !important;
        border-radius: 9% !important;
      }
    
      .ngx-pagination a:hover,
      .ngx-pagination button:hover {
        background: #788b8d88 !important;
      }
    
      .ngx-pagination .current {
        padding: 0.1875rem 0.625rem !important;
        background: #2199e8 !important;
        color: #fefefe !important;
        cursor: default !important;
        border: 2px solid #8b8b8b !important;
        padding: 0.5rem 1rem !important;
        border-radius: 9% !important;
      }
    
      .ngx-pagination .disabled {
        padding: 0.1875rem 0.625rem !important;
        color: #cacaca00 !important;
        cursor: default !important;
      }
    
      .ngx-pagination .disabled:hover {
        background: transparent !important;
      }
    
      .ngx-pagination a,
      .ngx-pagination button {
        cursor: pointer !important;
      }
    
      .ngx-pagination .pagination-previous a::before,
      .ngx-pagination .pagination-previous.disabled::before {
        font-family: FontAwesome;
        content: "\f053" !important;
        display: inline-block !important;
        margin-right: 0rem !important;
      }
    
      .ngx-pagination .pagination-next a::after,
      .ngx-pagination .pagination-next.disabled::after {
        font-family: FontAwesome !important;
        content: "\f054" !important;
        display: inline-block !important;
        margin-left: 0rem !important;
      }
    
      .ngx-pagination .show-for-sr {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
      }
    
      .ngx-pagination .small-screen {
        display: none !important;
      }
    
      @media screen and (max-width: 601px) {
        .ngx-pagination.responsive .small-screen {
          display: inline-block !important;
          color: black !important;
          background-color: rgb(248, 248, 248) !important;
          border: 2px solid #8b8b8b !important;
          padding: 0.5rem 1rem !important;
          border-radius: 9% !important;
        }
    
        .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
          display: none !important;
        }
      }
    
      .table>thead>tr>th {
        vertical-align: bottom;
        border-bottom: 2px solid #67676a !important;
        border-top: 1px solid #dddddd00 !important;
      }
    
      .table>tbody>tr>td,
      .table>tbody>tr>th,
      .table>tfoot>tr>td,
      .table>tfoot>tr>th,
      .table>thead>tr>td,
      .table>thead>tr>th {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-left: 2px solid #67676a !important;
        border-top: 1px solid #dddddd00 !important;
      }
    
      tr td {
        border-color: #dddddd00 !important;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
        <th>Buyer Name</th>
        <th>Total No. Of Orders</th>
        <th>Total Quantity</th>
        <th>Total Value</th>
        <th>Avg. Price</th>
        <th>%age Of TurnOver</th>
      </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      <div class="row">
      <div class="col-sm-12" >
        <div style="display: block;width: 700px;margin-left: 200px;">
          <canvas baseChart *ngIf="pieChartData"
            [data]="pieChartData" 
            [labels]="pieChartLabels" 
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins"
            [legend]="pieChartLegend">
          </canvas>
        </div>
        
      </div>
    </div>
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
