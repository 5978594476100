<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
  .pagination {
    margin: -15px 0;
    display: flex;
    justify-content: center;

  }

  .ngx-pagination {
    margin-left: 0 !important;
    margin-bottom: 1rem !important;
    line-height: 20px !important;
    padding: 7px 15px 7px !important;
    font-size: 12px !important;
  }

  .ngx-pagination::before,
  .ngx-pagination::after {
    content: " " !important;
    display: table !important;
  }

  .ngx-pagination::after {
    clear: both !important;
  }

  .ngx-pagination li {
    margin-right: 0.2rem !important;
    border-radius: 0 !important;
  }

  .ngx-pagination li {
    display: inline-block !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    color: #0a0a0a !important;
    display: block !important;
    background-color: rgb(248, 248, 248) !important;
    color: black !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: #788b8d88 !important;
  }

  .ngx-pagination .current {
    padding: 0.1875rem 0.625rem !important;
    background: #2199e8 !important;
    color: #fefefe !important;
    cursor: default !important;
    border: 2px solid #8b8b8b !important;
    padding: 0.5rem 1rem !important;
    border-radius: 9% !important;
  }

  .ngx-pagination .disabled {
    padding: 0.1875rem 0.625rem !important;
    color: #cacaca00 !important;
    cursor: default !important;
  }

  .ngx-pagination .disabled:hover {
    background: transparent !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    cursor: pointer !important;
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    font-family: FontAwesome;
    content: "\f053" !important;
    display: inline-block !important;
    margin-right: 0rem !important;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    font-family: FontAwesome !important;
    content: "\f054" !important;
    display: inline-block !important;
    margin-left: 0rem !important;
  }

  .ngx-pagination .show-for-sr {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  .ngx-pagination .small-screen {
    display: none !important;
  }

  @media screen and (max-width: 601px) {
    .ngx-pagination.responsive .small-screen {
      display: inline-block !important;
      color: black !important;
      background-color: rgb(248, 248, 248) !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
    }

    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
      display: none !important;
    }
  }

  .table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-left: 2px solid #67676a !important;
    border-top: 1px solid #dddddd00 !important;
  }

  tr td {
    border-color: #dddddd00 !important;
  }
</style>
<main>
  <!-- invoice Description -->
  <div class="card-body pt-20 mt-0" style="margin: 40px;position: relative;margin-top: 80px!important;">
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive invoice-table mb-4 mt-0">
          <div class="" style="border: 1px solid #e5e5e5;">
            <div class="title"
              style=" margin-top: -58px; font-size: x-large; position: absolute;background-color: aliceblue; padding-left: 10px; width: 350px; ">
              Dispatch / Receipt Register</div>
            <div class="content">
              <form action="#">
                <div class="user-details">
                  <div class="row" style="padding: 15px;">
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex;">
                        <span class="details" style="width: 20%;padding-top: 12px;padding-left: 20px;">From Date</span>
                        <input type="date" #fromDate placeholder="From Date" required
                          style=" width: 80%;font-size: larger;" />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex;">
                        <span class="details" style="width: 20%;padding-top: 12px;padding-left: 20px;">To Date</span>
                        <input type="date" #toDate placeholder="To Date" required
                          style=" width: 80%;font-size: larger;" />
                      </div>
                    </div>
                    
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex;">
                        <span class="details" style="width: 20%;padding-top: 12px;padding-left: 20px;">Type</span>
                        <select #Type class="search required" data-placeholder="Type"
                          style="width: 80%;top: 5px;height: auto;padding: 6px;">
                          <option [ngValue]="allSuplier">Select Type</option>
                          <option *ngFor="let size1 of tree_card_Buyer" value="{{size1.Code}}">
                            {{size1.Name}}
                          </option>
                        </select>

                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex;">
                        <span class="details" style="width: 20%;padding-top: 12px;padding-left: 20px;">"Select Counier Agent"</span>
                        <select #Route class="search required" data-placeholder="Route"
                          style="width: 80%;top: 5px;height: auto;padding: 6px;">
                          <option value="all">All</option>
                          <option value="D">Dispatch</option>
                          <option value="R">Receipt</option>
                        </select>

                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex;">
                        <span class="details" style="width: 20%;padding-top: 12px;padding-left: 20px;">Counier Agent</span>
                        <select #CounierAgent class="search required" data-placeholder="Counier Agent"
                          style="width: 80%;top: 5px;height: auto;padding: 6px;">
                          <option [ngValue]="ArticleCode">Select Counier Agent</option>
                          <option *ngFor="let size1 of this.tree_article_master" [value]="size1.Code">
                            {{size1.Name}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex;">
                        <span class="details" style="width: 20%;padding-top: 12px;padding-left: 20px;">Forword To</span>
                        <select #ForwordTo class="search required" data-placeholder="Forword To"
                          style="width: 80%;top: 5px;height: auto;padding: 6px;">
                          <option [ngValue]="ArticleCode">Select Forword To</option>
                          <option *ngFor="let size1 of this.quarterList_article_category" [value]="size1.Code">
                            {{size1.Name}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="input-box" style="display: flex; margin: 12px;">
                        <span class="details" style="width: 20%;"></span>
                           <div class="button"
                            style="background-color: white; color: black; border: 0px solid #008CBA; padding: 0px 0px; text-align: center; text-decoration: none; display: inline-block; font-size: 12px; margin: 0px 0px; transition-duration: 0.4s; cursor: pointer; border-radius: 8px;">
                            <button type="button"
                              (click)="loadTreeSupplie(fromDate.value,toDate.value,Type.value,Route.value,CounierAgent.value,ForwordTo.value)"
                              type="button" value="Filter">Seacrh</button>
                          </div>
                        </div> 
                    </div>
                    
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-12">
        <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Dispatch / Receipt Register</strong></h5>
      </div>
      <div class="col-sm-12">
        <div class="scroll-table-container">
          <table class="scroll-table dynamic styled" data-filter-Bar="always" data-table-tools='{"display":false}'>
            <thead>
              <tr>
                <th>SNo</th>
                <th>Date</th>
                <th>Type</th>
                <th>Route</th>
                <th>Courier Date</th>
                <th>Courier To</th>
                <th>Courier Agent</th>
                <th>Forword To</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.report_list; let idata = index">
                <td>{{idata + 1}}</td>
                <td>{{item.COURIER_DATE }}</td>
                <td>{{item.TYPE }}</td>
                <td>{{item.ROUTE }}</td>
                <td>{{item.COURIER_TO}}</td>
                <td>{{item.Courier_Name}}</td> 
                <td>{{item.FORWARD_T}}</td>
                <td>{{item.REMARKS}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="pagination">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div> -->
    </div>

  </div>

  <!-- invoice Table -->

</main>