import { BrowserModule, Title } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { SupplerComponent } from './components/suppler/suppler.component';
import { BuyerComponent } from './components/buyer/buyer.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { BankComponent } from './components/bank/bank.component';
import { CourlerAgentComponent } from './components/courler-agent/courler-agent.component';
import { AccessoriesCategoryComponent } from './components/accessories-category/accessories-category.component';
import { AccessoriesMasterComponent } from './components/accessories-master/accessories-master.component';
import { ShippingAgentComponent } from './components/shipping-agent/shipping-agent.component';
import { ItemCategoryComponent } from './components/item-category/item-category.component';
import { ItemPriceComponent } from './components/item-price/item-price.component';
import { ItemMasterComponent } from './components/item-master/item-master.component';
import { MerchandiseMliestoneTypeComponent } from './components/merchandise-mliestone-type/merchandise-mliestone-type.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { MeasrementTypeComponent } from './components/measrement-type/measrement-type.component';
import { FlowUpCategoryComponent } from './components/flow-up-category/flow-up-category.component';
import { TestingTypeComponent } from './components/testing-type/testing-type.component';
import { SizeMasterComponent } from './components/size-master/size-master.component';
import { FieldErrorDisplayComponent } from './components/field-error-display/field-error-display.component';
import { ActiveReportsModule } from '@grapecity/activereports-angular';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { BuyerReportComponent } from './components/buyer-report/buyer-report.component';
import { SupplerReportComponent } from './components/suppler-report/suppler-report.component';
import { EmployeeReportComponent } from './components/employee-report/employee-report.component';
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CopyOrderComponent } from './components/copy-order/copy-order.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BoldReportViewerModule } from '@boldreports/angular-reporting-components';
// Report viewer
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import { DataTablesModule } from 'angular-datatables';
// data-visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
import { ItemArticleReportComponent } from './components/item-article-report/item-article-report.component';
import { SingleOrderEnquiryComponent } from './components/single-order-enquiry/single-order-enquiry.component';
import { MultipleOrderEnquiryComponent } from './components/multiple-order-enquiry/multiple-order-enquiry.component';
import { DispatchReceiptRegisterComponent } from './components/dispatch-receipt-register/dispatch-receipt-register.component';
import { SamplingRegisterComponent } from './components/sampling-register/sampling-register.component';
import { PriceEnquiryReportComponent } from './components/price-enquiry-report/price-enquiry-report.component';
import { StatusReportComponent } from './components/status-report/status-report.component';
import { InspectionDetailsComponent } from './components/inspection-details/inspection-details.component';
import { TrunOrderComponent } from './components/trun-order/trun-order.component';
import { ProductionLoadComponent } from './components/production-load/production-load.component';
import { OrderWiseComponent } from './components/order-wise/order-wise.component';
import { BuyerWiseComponent } from './components/buyer-wise/buyer-wise.component';
import { SupplierWiseComponent } from './components/supplier-wise/supplier-wise.component';
import { ArticleWiseComponent } from './components/article-wise/article-wise.component';
import { MeasurementTypeComponent } from './components/measurement-type/measurement-type.component';
import { AccessoriesComponent } from './components/accessories/accessories.component';
import { NgxPrintModule } from 'ngx-print';
import { GenerateShipmentsComponent } from './components/generate-shipments/generate-shipments.component';
import { ShipmentDetailsComponent } from './components/shipment-details/shipment-details.component';
import { MerchandisingDetailsComponent } from './components/merchandising-details/merchandising-details.component';
import { AttachSpecSheetComponent } from './components/attach-spec-sheet/attach-spec-sheet.component';
import { ItemSpecSheetComponent } from './components/item-spec-sheet/item-spec-sheet.component';
import { OrderSpecSheetComponent } from './components/order-spec-sheet/order-spec-sheet.component';
import { CopySpecSheetForItemComponent } from './components/copy-spec-sheet-for-item/copy-spec-sheet-for-item.component';
import { ItemColorDetailsComponent } from './components/item-color-details/item-color-details.component';
import { ItemColorSheetDetailsComponent } from './components/item-color-sheet-details/item-color-sheet-details.component';
import { AttachColorSheetComponent } from './components/attach-color-sheet/attach-color-sheet.component';
import { OrderColorDetailsComponent } from './components/order-color-details/order-color-details.component';
import { OrderColorSheetComponent } from './components/order-color-sheet/order-color-sheet.component';
import { OrderItemColorImageComponent } from './components/order-item-color-image/order-item-color-image.component';
import { AccessoriesSheetComponent } from './components/accessories-sheet/accessories-sheet.component';
import { ProductionDetailsComponent } from './components/production-details/production-details.component';
import { TestingDocsUploadComponent } from './components/testing-docs-upload/testing-docs-upload.component';
import { InspectionSheetComponent } from './components/inspection-sheet/inspection-sheet.component';
import { ClaimNoteComponent } from './components/claim-note/claim-note.component';
import { CommissionNoteComponent } from './components/commission-note/commission-note.component';
import { DebtNoteComponent } from './components/debt-note/debt-note.component';
import { SamplingAssignmentEntryComponent } from './components/sampling-assignment-entry/sampling-assignment-entry.component';
import { SamplingAssignmentPriceComponent } from './components/sampling-assignment-price/sampling-assignment-price.component';
import { DalyCouriesComponent } from './components/daly-couries/daly-couries.component';
import { CompanyInformationComponent } from './components/company-information/company-information.component';
import { UserMasterComponent } from './components/user-master/user-master.component';
import { GroupMasterComponent } from './components/group-master/group-master.component';
import { UserGroupMappingComponent } from './components/user-group-mapping/user-group-mapping.component';
import { GroupPermissionsComponent } from './components/group-permissions/group-permissions.component';
import { BulkDeleteComponent } from './components/bulk-delete/bulk-delete.component';
import { UpdateOrderIdComponent } from './components/update-order-id/update-order-id.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { EditableTableComponent } from './components/editable-table/editable-table.component';
import { CeilPipe } from './components/item-article-report/ceil.pipe';
import { SingleOrderEnquiryReportListComponent } from './components/single-order-enquiry-report-list/single-order-enquiry-report-list.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table'; 
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core'; 
import { SingleOrderEnquiryReportsComponent } from './components/single-order-enquiry-reports/single-order-enquiry-reports.component';
import { TrunOrderViewComponent } from './components/trun-order-view/trun-order-view.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { DocUploadComponent } from './components/doc-upload/doc-upload.component';
import { OrderSpecSheetFileUploadComponent } from './components/order-spec-sheet-file-upload/order-spec-sheet-file-upload.component';
import { NgImageSliderModule } from 'ng-image-slider';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ResetPasswordComponent,
    SupplerComponent,
    BuyerComponent,
    EmployeeComponent,
    BankComponent,
    CourlerAgentComponent,
    AccessoriesCategoryComponent,
    AccessoriesMasterComponent,
    ShippingAgentComponent,
    ItemCategoryComponent,
    ItemPriceComponent,
    ItemMasterComponent,
    MerchandiseMliestoneTypeComponent,
    ProductTypeComponent,
    CurrencyComponent,
    MeasrementTypeComponent,
    FlowUpCategoryComponent,
    FieldErrorDisplayComponent,
    TestingTypeComponent,
    SizeMasterComponent,
    OrderDetailsComponent,
    BuyerReportComponent,
    SupplerReportComponent,
    EmployeeReportComponent,
    CopyOrderComponent,
    ItemArticleReportComponent,
    SingleOrderEnquiryComponent,
    MultipleOrderEnquiryComponent,
    DispatchReceiptRegisterComponent,
    SamplingRegisterComponent,
    PriceEnquiryReportComponent,
    StatusReportComponent,
    InspectionDetailsComponent,
    TrunOrderComponent,
    ProductionLoadComponent,
    OrderWiseComponent,
    BuyerWiseComponent,
    SupplierWiseComponent,
    ArticleWiseComponent,
    MeasurementTypeComponent,
    AccessoriesComponent,
    GenerateShipmentsComponent,
    ShipmentDetailsComponent,
    MerchandisingDetailsComponent,
    AttachSpecSheetComponent,
    ItemSpecSheetComponent,
    OrderSpecSheetComponent,
    CopySpecSheetForItemComponent,
    ItemColorDetailsComponent,
    ItemColorSheetDetailsComponent,
    AttachColorSheetComponent,
    OrderColorDetailsComponent,
    OrderColorSheetComponent,
    OrderItemColorImageComponent,
    AccessoriesSheetComponent,
    ProductionDetailsComponent,
    TestingDocsUploadComponent,
    InspectionSheetComponent,
    ClaimNoteComponent,
    CommissionNoteComponent,
    DebtNoteComponent,
    SamplingAssignmentEntryComponent,
    SamplingAssignmentPriceComponent,
    DalyCouriesComponent,
    CompanyInformationComponent,
    UserMasterComponent,
    GroupMasterComponent,
    UserGroupMappingComponent,
    GroupPermissionsComponent,
    BulkDeleteComponent,
    UpdateOrderIdComponent,
    EditableTableComponent,
    CeilPipe,
    SingleOrderEnquiryReportListComponent,
    SingleOrderEnquiryReportsComponent,
    TrunOrderViewComponent,
    MultiSelectDropdownComponent,
    DocUploadComponent,
    OrderSpecSheetFileUploadComponent
  ],
  imports: [
    CommonModule,
    ChartsModule,
    ActiveReportsModule,
    NgSelectModule,
    NgxLoaderModule,
    Ng2SearchPipeModule,
    BrowserModule,
    BoldReportViewerModule,
    HttpClientModule,
    AppRoutingModule, 
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BrowserAnimationsModule, // required animations module 
    ModalModule,
    NgxPrintModule,
    DataTablesModule,
    HighchartsChartModule,
    FilterPipeModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatBadgeModule,
    MatPseudoCheckboxModule,
    MatTableModule, 
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatCheckboxModule,
    MatDialogModule,
    NgxDatatableModule,
    NgImageSliderModule
    //NgxMatSelectSearch
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [DatePipe, BsModalService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
