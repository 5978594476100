<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Shipment Detalls</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/Add.png"
                                style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>

        <section id="content" class="container_12 clearfix" data-sort="true" style="padding: 30px;background-image: linear-gradient(#ffffff,#ffffff);">
            <div class="grid_12">
                <fieldset>
                    <legend> Shipment Detalls </legend>
                    <table style=" border: 2px solid #666666;" height="50" width="100%">

                        <tr>
                            <td style=" border: none; "></td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; ">Status</td>
                            <td style=" border: none;">
                                <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                    value="{{shipment_status}}" #shipmentStatus />
                            </td>
                        </tr>
                        <tr>
                            <td style=" border: none; ">Order No.</td>
                            <td style=" border: none;">
                                <input type="text" size="15" maxlegth="30" style="width: 100%;"
                                    value="{{order_Code_Sipment}}" #orderCode />
                            </td>
                            <td style=" border: none; ">Shipment No.</td>
                            <td style=" border: none;">

                                <select id="buyer" style="width: 100%;"
                                    (change)='changeTreeContactShipment($event, orderCode.value)' #ShipmentNo>
                                    <option [ngValue]="null" disabled>Choose your
                                        Size</option>
                                    <option *ngFor="let sipmet of shipment_serialList"
                                        [ngValue]="sipmet.shipment_serial_no">
                                        {{sipmet.shipment_serial_no}}
                                    </option>
                                </select>
                            </td>
                            <td style=" border: none; ">Mode of shipment.</td>
                            <td style=" border: none;">
                                <select id="buyer" style="width: 100%;" (change)='TreeContactBuyer($event)'
                                    #ModeOfShipment>
                                    <option [ngValue]="null" disabled>Choose your
                                        Size</option>
                                    <option *ngFor="let sip_mode of proFillModeList"
                                        [ngValue]="sip_mode.param_value">
                                        {{sip_mode.param_value}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td style=" border: none; ">Buyer</td>
                            <td style=" border: none;">
                                <input type="text" size="15" maxlegth="30" style="width: 100%;" #BuyerValue
                                    value="{{buyer_shipment}}" />
                            </td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; "></td>
                            <td style=" border: none; ">Supplier</td>
                            <td style=" border: none;">
                                <input type="text" size="15" maxlegth="30" style="width: 100%;" #SupplierValue
                                    value="{{supplier_shipment}}" />
                            </td>
                        </tr>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>Item List </legend><button type="button"
                        (click)="showShipmentDetailsSelectItem(order_Code_Sipment)"
                        class="btn btn-primary">View</button>
                    <table id="yourTableId" style="border: 2px solid #666666;" height="50" width="100%">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Artical Code</th>
                                <th>Description</th>
                                <th>Item Code</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Commission</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody style=" overflow-x: scroll; max-height: 160px; display: table-caption; ">
                            <tr *ngFor="let item3 of proShowRecordsList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td><input type="text" [(ngModel)]="item3.ARTICLE_CODE"
                                        [disabled]="!item3.isEditing"
                                        style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                </td>
                                <td><input type="text" [(ngModel)]="item3.Description"
                                        [disabled]="!item3.isEditing"
                                        style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                </td>
                                <td><input type="text" [(ngModel)]="item3.ITEM_CODE"
                                        [disabled]="!item3.isEditing"
                                        style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                </td>
                                <td><input type="text" [(ngModel)]="item3.ITEM_QTY"
                                        [disabled]="!item3.isEditing"
                                        style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                </td>
                                <td><input type="text" [(ngModel)]="item3.ITEM_PRICE"
                                        [disabled]="!item3.isEditing"
                                        style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                </td>
                                <td><input type="text" [(ngModel)]="item3.ITEM_COMM"
                                        [disabled]="!item3.isEditing"
                                        style=" background: #ffffff; border: 0px solid #a0a0a0; box-shadow: inset 0 0px 0px rgba(0,0,0,0.22); " />
                                </td>
                                <td>
                                    <button *ngIf="!item3.isEditing" (click)="editRow(i)">Edit</button>
                                    <button *ngIf="item3.isEditing" (click)="saveRow(i)">Save</button>
                                    <button *ngIf="item3.isEditing" (click)="cancelEdit(i)">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </div>

            <div class="grid_12" style="top: -35px;">
                <div class="box validate" style="padding: 40px 15px 15px 15px;">
                    <div class="actions">

                        <div class="right">
                            <button type="button" 
                                class="btn btn-secondary"
                                style="background: repeating-radial-gradient(black, transparent 100px);margin-right: 15px;">CANCEL</button>
                            <button type="button"
                                (click)="saveAllChanges(shipmentStatus.value,orderCode.value,ShipmentNo.value,ModeOfShipment.value,BuyerValue.value,SupplierValue.value)"
                                class="btn btn-primary">Save All</button>
                        </div>
                    </div>
                </div>
            </div>


        </section>

    </div>
</div>