import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claim-note',
  templateUrl: './claim-note.component.html',
  styleUrls: ['./claim-note.component.css']
})
export class ClaimNoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
