import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'; 
import { WebServiceService } from '../../services/web-service.service';
import { Subscription } from 'rxjs';

declare var $: any;
//declare var feather:any;
//declare var grecaptcha:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [ [WebServiceService] ]
})
export class FooterComponent implements OnInit {
  showHeader: boolean;
  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private webService: WebServiceService, 
  ) {
    this.subscription = this.webService.showHeaderFooter$.subscribe(show => this.showHeader = show);

  }

  year = (new Date()).getFullYear();
  errors = [];
  btnCloseModal:any;
  successMsg: string = "";

  //Create contact Form
  contactForm = this.fb.group({
    destination: ["", [Validators.required ]],
    inDate : ["", [Validators.required, Validators.minLength(10)] ],
    quantityAdult : [ 1, [Validators.required, Validators.minLength(1), Validators.maxLength(15) ]],
    quantityChildren: [ 0, [Validators.minLength(0), Validators.maxLength(5)]],
    guestName: ["", [Validators.required ]],
    message: ["", [Validators.required ]],
    guestEmail: ["", [Validators.required, Validators.email ]],
    guestMobile: ["", [Validators.required, Validators.maxLength(10), Validators.minLength(10)]]
  });

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

 

}
