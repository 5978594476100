import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import { WebServiceService } from 'src/app/services/web-service.service';
declare let $: any;
@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {

  tree_card_bank: any = [];
  bankMasterList: any = [];
  tree_edit_list: any = [];
  ContactPersion_list: any = [];
  tree_edit_list_print: any;
  ContactPersion_listCountAdd: any;
  addvlaue: any = 'add';
  addIn: boolean = false;
  isChecked: boolean = false;
  buyerCode: any;
  selectedUser = null;
  dialogIn: boolean = false;
  dialogEdit: boolean = false;
  dialogDelete: boolean = false;
  constructor( 
    private webService: WebServiceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    
  }


  ngOnInit(): void {
    this.loadTreeBuyer();
    let url = "BankMaster/Listtree";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          this.tree_card_bank = data.listOfIndexes;
          $('#lazy').jstree({
            core: {
              data: this.tree_card_bank
            }
          }).on('changed.jstree', (e, data) => {
            if (data.selected.length) {
              this.TreeListBinding(data.instance.get_node(data.selected[0]).id, data.instance.get_node(data.selected[0]).id);
            }
          });

        } else {

        }
      }
    );
    this.loadBankMaster();
  }

  loadTreeBuyer = () => {
    let url = "BankMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        let dataList = "";
        if (data.status === 'OK') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.tree_card_bank = data12[0];
          this.tree_card_bank.forEach(element => {
            dataList += "<tr><td>" + element.Bank_Code + "</td><td>" + element.Bank_Name + "</td><td>" + element.Email + "</td><td>" + element.Phone + "</td><td>" + element.Telex + "</td><td>" + element.Fax + "</td><td>" + element.Add1 + ", " + element.Add2 + ", " + element.Add3 + "</td><td>" + element.Created_By + "</td><td>" + element.Created_Date + "</td><td>" + element.Modified_By + "</td><td>" + element.Modified_Date + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;

        } else {

        }
      }
    );
  }

  cancelDialog = () => {
    this.dialogIn = false;
    this.dialogEdit = false;
    this.dialogDelete = false;
  }

  openDialog = () => {
    this.dialogIn = true;
  }

  loadBankMaster = () => {
    let url = "BankMaster/List";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        debugger
        if (data.status === 'OK') {
          debugger
          let data12 = (new Function("return [" + data.data + "]")());
          this.bankMasterList = data12[0];
        } else {

        }
      }
    );
  }

  addSublier = (addvlaue) => {
    this.addIn = false;
    this.addvlaue = "add";
    this.selectedUser = null;
    this.loadBankMaster();
  }

  TreeListBinding = (data, id) => {
    try {
      this.selectedUser = null;
      this.addIn = true;
      this.addvlaue = "edit";
      this.tree_edit_list = [];
      let url ="BankMaster/EditBankCodeAndNameList";
      this.webService.loadTreetListEdit(url,data).subscribe(
        (data: any) => {
          debugger
          if (data.status === 'OK') {
            let data12 = (new Function("return [" + data.editList + "]")());
            this.tree_edit_list = data12[0][0];
            this.buyerCode = this.tree_edit_list.Buyer_Code;
            this.selectedUser = this.tree_edit_list.Bank_Code;
            this.loadBankMaster();

          } else {

          }

        }
      );
    }
    catch (e) {

    }

  }

  
  reloadCurrentRoute() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/bank']);
    });
  }

  addBankMaster = (bank_Code, Bank_Name,Add1,Add2,Add3,Phone,Fax,Email) => {
    debugger
    let data = {
      Bank_Code:bank_Code, 
      Bank_Name:Bank_Name,
      Add1:Add1,
      Add2:Add2,
      Add3:Add3,
      Phone:Phone,
      Fax:Fax,
      Email:Email
    }
    let url = "BankMaster/AddBank";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Added')
          this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.loadBankMaster();
        } else {
          alert('Not Added')
        }

      }
    );

  }

  editBankMaster = (editBank_Code, editBank_Name,editAdd1,editAdd2,editAdd3,editPhone,editFax,editEmail) => {
    let data = {
      Bank_Code:editBank_Code, 
      Bank_Name:editBank_Name,
      Add1:editAdd1,
      Add2:editAdd2,
      Add3:editAdd3,
      Phone:editPhone,
      Fax:editFax,
      Email:editEmail
    }
    let url = "BankMaster/EditBank";
    this.webService.loadPost(url, data).subscribe(
      (data: any) => {
        if (data.status === 'OK') {
          alert('Edited')
          //this.reloadCurrentRoute();
          //  this.myForm.resetForm();
          let data12 = (new Function("return [" + data.data + "]")());
          this.ContactPersion_list = data12[0];
          console.log(this.ContactPersion_list);
          this.TreeListBinding(editBank_Code, '');
          this.loadBankMaster();
        } else {
          alert('Not Edited')
        }
      }
    );

  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <div class="card-body border-top pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details">
            <h5 class="mb-10 text-18 text-capitalize" style="margin-top: 12px;"><strong>YOUNG FASHION (INDIA)</strong>
            </h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
          <hr style="border-bottom: 1px solid #67676a;" />
        </div>
        <div class="col-sm-12">
          <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;"><strong>Bank</strong></h5>
        </div>
      </div>
  
    </div>
      <table>
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Email</th>
		      <th>Phone</th>
          <th>Telex</th>
          <th>Fax</th>
		      <th>Addrss</th>
          <th>Created_By</th>
          <th>Created_Date</th>
		      <th>Modified_By</th>
          <th>Modified_Date</th> 
        </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
