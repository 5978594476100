import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component, 
  ViewEncapsulation,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service'; 
  
@Component({
  selector: 'app-measrement-type',
  templateUrl: './measrement-type.component.html',
  styleUrls: ['./measrement-type.component.css']
})
export class MeasrementTypeComponent implements OnInit {
  report_list: any = [];
  tree_edit_list_print:any=[];
  page: number = 1;
  constructor( 
    private webService: WebServiceService,
  ) {
   
  }

  ngOnInit(): void {
    this.loadTreeSupplie();
    setTimeout(()=>{   
      $('#datatableexample').DataTable( {
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          lengthMenu : [5, 10, 25, 50, 100, 200, 300]
      });
    }, 200);
  }  
  
  loadTreeSupplie = () => {
    let url = "ReportViewer/Measurement";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList="";
        if (data.status === 'OK') {
          debugger
          let data12 = (new Function("return [" + data.data + "]")());
          this.report_list = data12[0];
          this.report_list.forEach(element => {
            dataList += "<tr><td>" + element.MEASUREMENT_CODE + "</td><td>" + element.MEASUREMENT_DESC + "</td></tr>";
          });
          this.tree_edit_list_print = dataList;
        } else {

        }
      }
    );
  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>MEASUREMENT CODE</th>
          <th>MEASUREMENT DESC</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };
  
}
