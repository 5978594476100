import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-permissions',
  templateUrl: './group-permissions.component.html',
  styleUrls: ['./group-permissions.component.css']
})
export class GroupPermissionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
