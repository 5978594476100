<section class="sign-in-page">
   <div class="container">
      <div class="row justify-content-center align-items-center height-self-center">
         <div class="col-lg-5 col-md-12 align-self-center">
            <div class="sign-user_card ">                    
               <div class="sign-in-page-data">
                  <div class="sign-in-from w-100 m-auto">
                     <h3 class="mb-3 text-center">Reset Password</h3>
                     <form class="mt-4">
                        <div class="form-group">                                 
                          <input type="email" #email name="email" placeholder="Your Email" autocomplete="off"  class="form-control mb-0 required" />
                        </div>
                         <div class="sign-info">
							      <input type="button" (click)="reastPassword(email.value)" value="Reset" class="btn btn-hover disabled" />
                       </div>                                    
                     </form>
                  </div>
               </div>
               <div class="mt-3">
                  <div class="d-flex justify-content-center links">
                     Don't have an account? <a href="javascript:void(0);" routerLink="/login" class="text-primary ml-2">Login</a>
                  </div>
                  <div class="d-flex justify-content-center links">
                     <a href="javascript:void(0);" routerLink="/sign-up" class="f-link ">Sign Up</a>
                  </div>
                  <div class="form-submit-errors" tabindex="0" style="display: block;" *ngIf="errors.length">
                    <p *ngFor="let item of errors"> {{item}}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>