<div role="main" id="main" class="container_12 clearfix"
    style="width: 100%; margin-left: 0; margin-right: 0; background-image: linear-gradient(#042f66,#195e83);">
    <div style="min-height: 82vh;">
        <div class="tabletools">
            <div class="left" style="padding: 5px; font-size: large;color: black;">Item Category</div>

        </div>
        <section class="toolbar" style="padding: 30px 0;">
            <ul class="shortcuts" style="margin-left: 0;margin-top: -6px;">
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="addSublier(this.addvlaue)"><span><img
                                src="./assets/images/Add.png" style="width: 40px;" /></span></a>
                </li>
                <li style="bottom: 18px;position: relative;">
                    <a href="javascript:void(0);" (click)="onPreview()"><span><img src="./assets/images/print.png"
                                style="width: 40px;" /></span></a>
                </li>
            </ul>
        </section>
        <aside style="width: 350px;">
            
            <ul class="top" style="margin-top: 0px; height: 300px; background-color: white;text-align: justify; overflow: scroll; white-space: nowrap;padding-left: 12px;">
                <div id="lazy" class="demo"></div>
            </ul>
        </aside>
        
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="!addIn" style="padding-left: 0;">

            <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                        Item Category</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_12">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Category Code</strong> </label>
                                        </div>
                                        <div class="grid_8">
                                            <input class="required" type="text" formControlName="cat_Code" />
                                                <app-field-error-display [displayError]="isFieldValid('cat_Code')" errorMsg="Please  enter Category Code">
                                                </app-field-error-display>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Category Name</strong> </label>
                                        </div>
                                        <div class="grid_8">
                                            <input class="required" type="text" formControlName="cat_Name" />
                                                <app-field-error-display [displayError]="isFieldValid('cat_Name')" errorMsg="Please  enter Category Name">
                                                </app-field-error-display>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Type</strong> </label>
                                        </div>
                                        <div class="grid_8" style="position: relative;display: flex;">
                                            <div style="width: 30%;">
                                              <input id="OG" type="radio" value="OG" name="typeItem" formControlName="typeItem">
                                              <label for="OG" style="padding: 0px 0px 0px 6px;"> OG</label>
                                           </div>
                                   
                                           <div>
                                              <input id="UF" type="radio" value="UF" name="typeItem" formControlName="typeItem">
                                              <label for="UF" style="padding: 0px 0px 0px 6px;"> UG</label>
                                           </div>
                                                
                                        </div>
                                        <div class="grid_8">
                                        <app-field-error-display [displayError]="isFieldValid('typeItem')" errorMsg="Please  enter type">
                                        </app-field-error-display>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit" value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </form>

        </section>
        <section id="content" class="container_12 clearfix" data-sort="true" *ngIf="addIn" style="padding-left: 0;">

            <form [formGroup] = "uploadFormEdit" (ngSubmit)="onEditSubmit()" enctype="multipart/form-data">
                <div class="grid_12">
                    <h1
                        style="padding: 10px 1px 10px 15px;font-size: large;top: 24px;left: 16px;background: white;width: 169px;z-index: 999;position: relative;">
                        Size</h1>
                    <div class="box validate" style="padding: 26px 15px 15px 15px;">

                        <div class="content" style="padding: 15px;">
                            <div class="row">
                                <div class="grid_12">
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Category Code</strong> </label>
                                        </div>
                                        <div class="grid_8">
                                            <input class="required" type="text" formControlName="cat_Code" />
                                                <app-field-error-display [displayError]="isFieldValid('cat_Code')" errorMsg="Please  enter Category Code">
                                                </app-field-error-display>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Category Name</strong> </label>
                                        </div>
                                        <div class="grid_8">
                                            <input class="required" type="text" formControlName="cat_Name" />
                                                <app-field-error-display [displayError]="isFieldValid('cat_Name')" errorMsg="Please  enter Category Name">
                                                </app-field-error-display>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="grid_3">
                                            <label for="v1_normal_input"><strong>Type</strong> </label>
                                        </div>
                                        <div class="grid_8" style="position: relative;display: flex;">
                                            <div style="width: 30%;">
                                              <input id="OG" type="radio" value="OG" name="typeItem" formControlName="typeItem">
                                              <label for="OG" style="padding: 0px 0px 0px 6px;"> OG</label>
                                           </div>
                                   
                                           <div>
                                              <input id="UF" type="radio" value="UF" name="typeItem" formControlName="typeItem">
                                              <label for="UF" style="padding: 0px 0px 0px 6px;"> UG</label>
                                           </div>
                                                
                                        </div>
                                        <div class="grid_8">
                                        <app-field-error-display [displayError]="isFieldValid('typeItem')" errorMsg="Please  enter type">
                                        </app-field-error-display>
                                            </div>
                                        
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid_12" style="top: -35px;">
                    <div class="box validate" style="padding: 40px 15px 15px 15px;">
                        <div class="actions">

                            <div class="right">
                                <input type="submit" value="Save" name="submit" style="width: 100px;font-size: small;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid_12" style="top: -35px;">
                <!-- <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                  </span> -->
                  <!-- <span class="upload" *ngIf="message">
                    {{message}}
                  </span> -->
                </div>
            </form>

        </section>
     
    </div>
</div>