<!-- <bold-reportviewer id="report-viewer" [reportPath]="reportPath"  style="width: 100%;height: 950px">
</bold-reportviewer> -->
<style>
    .pagination {
      margin: -15px 0;
      display: flex;
      justify-content: center;
  
    }
  
    .ngx-pagination {
      margin-left: 0 !important;
      margin-bottom: 1rem !important;
      line-height: 20px !important;
      padding: 7px 15px 7px !important;
      font-size: 12px !important;
    }
  
    .ngx-pagination::before,
    .ngx-pagination::after {
      content: " " !important;
      display: table !important;
    }
  
    .ngx-pagination::after {
      clear: both !important;
    }
  
    .ngx-pagination li {
      margin-right: 0.2rem !important;
      border-radius: 0 !important;
    }
  
    .ngx-pagination li {
      display: inline-block !important;
    }
  
    .ngx-pagination a,
    .ngx-pagination button {
      color: #0a0a0a !important;
      display: block !important;
      background-color: rgb(248, 248, 248) !important;
      color: black !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
    }
  
    .ngx-pagination a:hover,
    .ngx-pagination button:hover {
      background: #788b8d88 !important;
    }
  
    .ngx-pagination .current {
      padding: 0.1875rem 0.625rem !important;
      background: #2199e8 !important;
      color: #fefefe !important;
      cursor: default !important;
      border: 2px solid #8b8b8b !important;
      padding: 0.5rem 1rem !important;
      border-radius: 9% !important;
    }
  
    .ngx-pagination .disabled {
      padding: 0.1875rem 0.625rem !important;
      color: #cacaca00 !important;
      cursor: default !important;
    }
  
    .ngx-pagination .disabled:hover {
      background: transparent !important;
    }
  
    .ngx-pagination a,
    .ngx-pagination button {
      cursor: pointer !important;
    }
  
    .ngx-pagination .pagination-previous a::before,
    .ngx-pagination .pagination-previous.disabled::before {
      font-family: FontAwesome;
      content: "\f053" !important;
      display: inline-block !important;
      margin-right: 0rem !important;
    }
  
    .ngx-pagination .pagination-next a::after,
    .ngx-pagination .pagination-next.disabled::after {
      font-family: FontAwesome !important;
      content: "\f054" !important;
      display: inline-block !important;
      margin-left: 0rem !important;
    }
  
    .ngx-pagination .show-for-sr {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
    }
  
    .ngx-pagination .small-screen {
      display: none !important;
    }
  
    @media screen and (max-width: 601px) {
      .ngx-pagination.responsive .small-screen {
        display: inline-block !important;
        color: black !important;
        background-color: rgb(248, 248, 248) !important;
        border: 2px solid #8b8b8b !important;
        padding: 0.5rem 1rem !important;
        border-radius: 9% !important;
      }
  
      .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: none !important;
      }
    }
  
    .table>thead>tr>th {
      vertical-align: bottom;
      border-bottom: 2px solid #67676a !important;
      border-top: 1px solid #dddddd00 !important;
    }
  
    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: top;
      border-left: 2px solid #67676a !important;
      border-top: 1px solid #dddddd00 !important;
    }
  
    tr td {
      border-color: #dddddd00 !important;
    }
  
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
  
    li {
      font-size: larger;
      margin: 0px 0px 5px 0px;
    }
  
    li a {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
    }
  </style>
  <main class="container" id="section-to-print">
    
    <!-- invoice Description -->
    <div class="card-body pt-20 mt-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="dt-buttons" id="report_viewer_dt_buttons"><button (click)="printPage1()" class="dt-button buttons-print" style="background: blueviolet;" type="button"><span><i style="font-size:24px;color:#ffffff" class="fa fa fa-print fa-2x"></i></span></button> </div> 
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
        <br />
          </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="invoice-details" style="border-bottom: 2px solid #67676a;margin-bottom: 12px;">
            <h5 class="mb-10 text-18 text-capitalize"><strong>YOUNG FASHION (INDIA)</strong></h5>
            <div class="invoice-details-inner mt-2">
              <p>425 UDYOG VIHAR <br /> GURGAON, HARYANA
                <br /> INDIA
              </p>
              <p><strong>Phone:</strong> 2340775 <span><strong>Fax:</strong> 4002304,05</span></p>
              <p><strong>Email:</strong> mail@youngfashionindia.com</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <h5 class="mb-10 text-18 text-capitalize" style="text-align: center;font-size: x-large;margin-bottom: 12px;"><strong>Order Details</strong></h5>
        </div>
        <div>
          <div class="row" style="margin: 8px 0px 12px 0px;border: 2px solid #67676a;padding: 10px;">
            <div class="col-sm-6">
              <div class="invoice-details" style="margin-top: 12px;">
                <ul>
                  <li><strong>Order No.:</strong> {{ this.report_list[0].Order_Code }}</li>
                  <li><strong>Buyer:</strong> {{ this.report_list[0].Buyer_Name }}</li>
                  <li><strong>Supplier:</strong> {{ this.report_list[0].Supplier_Name }}</li>
                  <li><strong>Dispatch Date:</strong> {{ this.report_list[0].Dispatch_Date }}</li>
                  <li><strong>Department:</strong> {{ this.report_list[0].Department }}</li>
                  <li><strong>Consignee:</strong> {{ this.report_list[0].Consignee_name }}</li>
                  <li><strong>Currency:</strong> {{ this.report_list[0].Currency_Code }}</li>
                  <li><strong>LC No.:</strong> {{ this.report_list[0].LC_No }}</li>
                  <li><strong>Delivery Date on LC:</strong> {{ this.report_list[0].Delivery_Date_On_LC }}</li>
                </ul>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="invoice-details" style="margin-top: 12px;">
                <ul>
                  <li><strong>Order Date:</strong> {{ this.report_list[0].Order_Date }}</li>
                  <li><strong>Contact Person:</strong> {{ this.report_list[0].Contact_Person_Name }}</li>
                  <li><strong>Contact Person:</strong> {{ this.report_list[0].Supp_Contact_Person }}</li>
                  <li><strong>Order Category:</strong> {{ this.report_list[0].Order_Category }}</li>
                  <li><strong>Bank:</strong> {{ this.report_list[0].Bank_Name }}</li>
                  <li><strong>PJ No.:</strong> {{ this.report_list[0].PJ_No }}</li>
                  <li><strong>Conversion Rate:</strong> {{ this.report_list[0].Conversion_Rate }}</li>
                  <li><strong>LC NR.:</strong> {{ this.report_list[0].LC_NR }}</li>
                  <li><strong>LC Sent On:</strong> {{ this.report_list[0].LC_Sent_On }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="invoice-details" style="margin-top: 12px;">
            <ul>
              <li><strong>Article Details</strong></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="invoice-details" style="margin: 0px 0px 0px 0px;border: -px solid #ffffff;padding: 2px;">
            <ul>
              <li><strong> {{ this.report_list[0].ORDER_ARTICLE_CODE }} </strong> <strong> {{
                  this.report_list[0].Order_Code }} </strong> {{ this.report_list[0].Article_Name }}</li>
            </ul>
          </div>
        </div>
  
      </div>
  
    </div>
  
    <!-- invoice Table -->
    <div class="table-responsive invoice-table mb-4 mt-0" style="margin: 8px;">
      <table class="table" style="margin: 8px 0px 12px 0px;border: 2px solid #67676a;background: white !important;">
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Item Size</th>
            <th>Mode</th>
            <th>Qty.</th>
            <th>Price</th>
            <th>Inv. Price</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of this.report_list; let idata = index ">
            <td>{{idata + 1 }}</td>
            <td>{{item.ORDER_ITEM_CODE}}</td>
            <td>{{item.ITEM_MODE}}</td>
            <td>{{item.ITEM_PRICE_DETAIL_QTY}}</td>
            <td>{{item.ITEM_PRICE}}</td>
            <td>{{item.ITEM_INVOICE_PRICE}}</td>
            <td>{{item.TOTAL_FINAL_PRICE}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><strong>Totals:</strong></td>
            <td><strong>{{this.qtyOrder}}</strong></td>
            <td><strong>{{this.PriceOrder}}</strong></td>
            <td><strong>{{this.InvPriceOrder}}</strong></td>
            <td><strong>{{this.TotalPriceOrder}}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-6">
          <div class="invoice-details" style="margin-top: 12px;">
            <ul>
              <li><strong>Total Qty. : </strong> {{ this.qtyOrder }}</li>
              <li><strong>Remarks : </strong> {{ this.report_list[0].Remarks }}</li>
              <li><strong>Payment Terms : </strong> {{ this.report_list[0].PAYMENT_TREMS }}</li>
              
            </ul>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="invoice-details" style="margin-top: 12px;">
            <ul>
              <li><strong>Total Comm.:</strong> {{ this.report_list[0].Total_Order_Commission }}</li>
               
            </ul>
          </div>
         
        </div>
        <div class="table-responsive invoice-table mb-4 mt-0" style="margin-top: 15px;">
          <table border="1" cellspacing="0" cellpadding="0" style="border-bottom: 1.6px solid #fff;border-top: 1.6px solid #ffffff;border-left: 1.6px solid #ffffff;border-right: 1.6px solid #ffffff;width: 100%;">
            
            <tr>
              <td colspan="2"></td>
              <td colspan="4" style="text-align: end;"><strong>For Young Fashion India</strong></td>
            </tr>
            <tr>
              <td><br /></td>
              <td> <br /></td>
            </tr>
            <tr>
              <td><br /></td>
              <td><br /></td>
            </tr>
            <tr>
              <td><br /></td>
              <td><br /></td>
            </tr>
             
            <tr>
              
              <td colspan="2"></td>
              <th colspan="4" style="text-align: end;"><strong>Authoished Signatory</strong></th>
            </tr>
          </table>
          
        </div>
      </div>
    </div>
    
  </main>