import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  OnInit,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { WebServiceService } from 'src/app/services/web-service.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
export class Country {
  constructor(public name: string, public code: string) { }
}
export class ReportType {
  constructor(public name: string, public code: string) { }
}
export class ShipmentNo {
  constructor(public name: string, public code: string) { }
}

export interface CheckboxItem {
  code: string;
  name: string;
  isChecked: boolean;
}

@Component({
  selector: 'app-single-order-enquiry',
  templateUrl: './single-order-enquiry.component.html',
  styleUrls: ['./single-order-enquiry.component.css']
})
export class SingleOrderEnquiryComponent implements OnInit {
  isCheckedUser: boolean = false;
  ariaLabelledBy: string;
  report_list: any = [];
  tree_edit_list_print: any = [];
  page: number = 1;
  qtyOrder: any;
  PriceOrder: any;
  InvPriceOrder: any;
  TotalPriceOrder: any;
  selectedCity: any;
  BuyerOrder: any = "";
  SuplierOrder: any = "";
  length: number;
  search_text: any;
  reportType: any = [];
  search_text_order_no: any;
  placeSearchPosData: any = [];
  OrderArticle: any = [];
  SuplierBuyer: any = [];
  OrderNoContainsCtrl = new FormControl();
  reportTypeCtrl = new FormControl();
  reportTypeShipmentNo = new FormControl();
  reportArticlestNo = new FormControl();;
  filteredCountry: Observable<any[]>;
  filteredReportTypes: Observable<any[]>;
  filteredShipmentNoReportTypes: Observable<any[]>;
  myModel = true;
  country_lis: Country[] = [
    { name: '001', code: 'AF' }
  ];

  ShipmentNo_Report_Type: ShipmentNo[] = [
    { name: '1', code: 'AF' },
  ];

  cmbReportTypeItems: string[] = [
    "Order Detail",
    "Shipment",
    "Merchandising",
    "Specification Sheet",
    "Color Sheet",
    "Accessories",
    "Production",
    "Inspection",
    "Testing",
    "Claim Note",
    "Comm. Note",
    "Debit Note",
    "Shipping Advice"
  ];
  Report_Type_Report_Type: ReportType[] = this.cmbReportTypeItems.map((item, index) => ({
    name: item,
    code: `Code_${index + 1}`
  }));

  checkboxes: CheckboxItem[] = [
    { code: '', name: 'Not Checkbox', isChecked: false }
  ];
  selectedItems: number[] = [];
  constructor(
    private webService: WebServiceService,
    private elementRef: ElementRef, private renderer: Renderer2
  ) {
    this.OrderNoContainsCtrl = new FormControl();
    this.filteredCountry = this.OrderNoContainsCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filtercountry(country) : this.country_lis.slice()
      )
    );

    this.reportTypeCtrl = new FormControl();
    this.filteredReportTypes = this.reportTypeCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filterReportType(country) : this.Report_Type_Report_Type.slice()
      )
    );

    this.reportTypeShipmentNo = new FormControl();
    this.filteredShipmentNoReportTypes = this.reportTypeShipmentNo.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this.filterShipmentNoReportType(country) : this.ShipmentNo_Report_Type.slice()
      )
    );
  }

  filtercountry(name: string) {
    let arr = this.country_lis.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    console.log(arr[0].name)
    return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
  }

  filterReportType(name: string) {
    let arr = this.Report_Type_Report_Type.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
    return arr.length ? arr : [{ name: 'No Filter Report', code: 'null' }];
  }

  filterShipmentNoReportType(name: string) {
    let arr = this.ShipmentNo_Report_Type.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
    return arr.length ? arr : [{ name: 'No Shipment No', code: 'null' }];
  }

  ngOnInit(): void {
    this.lOrderNoSerch();
  }


  lOrderNoSerch = () => {
    this.country_lis = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=order&order_code=7960";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          //this.report_list = data12[0];
          data12[0].forEach(element => {
            this.country_lis.push({
              name: element.ORDER_CODE,
              code: element.ORDER_DATE
            });
          });
        } else {

        }
      }
    );
  }

  onSearchItemSeletion = (selectedValue) => {
    debugger

  }

  loadShipmentNoSerch = (orderID) => {
    this.ShipmentNo_Report_Type = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=shipmet&order_code=" + orderID;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          //this.report_list = data12[0];
          data12[0].forEach(element => {
            this.ShipmentNo_Report_Type.push({
              name: element.SHIPMENT_SERIAL_NO,
              code: element.ORDER_CODE
            });
          });
        } else {

        }
      }
    );
  }

  loadOrderArticleSerch = (orderID) => {
    this.checkboxes = [];
    let url = "ReportViewer/OrderEnquery_filter?flterName=singleOrder_article&order_code=" + orderID;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          // this.OrderArticle = data12[0];
          // console.log(this.OrderArticle);
          data12[0].forEach(element => {
            this.checkboxes.push({
              code: element.ORDER_ARTICLE_CODE,
              name: element.ARTICLE_NAME,
              isChecked: false
            });
          });

        } else {

        }
      }
    );
  }

  loadSuplierBuyerSerch = (orderID) => {
    this.SuplierBuyer = [];
    this.BuyerOrder = "";
    this.SuplierOrder = "";
    let url = "ReportViewer/OrderEnquery_filter?flterName=suplier_buyer&order_code=" + orderID;
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.result === 'success') {
          let data12 = (new Function("return [" + data.data + "]")());
          this.SuplierBuyer = data12[0][0];
          this.BuyerOrder = this.SuplierBuyer.BUYER_NAME;
          this.SuplierOrder = this.SuplierBuyer.SUPPLIER_NAME;
          console.log(this.SuplierBuyer);
        } else {

        }
      }
    );
  }

  onSearchIOrderNoSeletion = (selectedValue) => {
    this.loadShipmentNoSerch(selectedValue);
    this.loadSuplierBuyerSerch(selectedValue);
    this.loadOrderArticleSerch(selectedValue);
  }

  loadTreeSupplie = () => {
    let url = "ReportViewer/singleOrderEnquery?order_no=001";
    this.webService.loadTreeLiat(url).subscribe(
      (data: any) => {
        let dataList = "";
        if (data.status === 'OK') {
          let qty = 0;
          let Price = 0;
          let InvPrice = 0;
          let TotalPrice = 0;
          let data12 = (new Function("return [" + data.data + "]")());
          this.report_list = data12[0];
          this.report_list.forEach(element => {
            console.log(element.TOTAL_FINAL_PRICE);

            // Ensure that the values are parsed as numbers
            const itemQty = parseFloat(element.ITEM_QTY);
            const itemPrice = parseFloat(element.ITEM_PRICE);
            const itemInvPrice = parseFloat(element.ITEM_INVOICE_PRICE);
            const itemTotalPrice = parseFloat(element.TOTAL_FINAL_PRICE);

            // Check if the parsed values are valid numbers
            if (!isNaN(itemQty) && !isNaN(itemPrice) && !isNaN(itemInvPrice) && !isNaN(itemTotalPrice)) {
              qty += itemQty;
              Price += itemPrice;
              InvPrice += itemInvPrice;
              TotalPrice += itemTotalPrice;
            }
          });
          this.qtyOrder = qty;
          this.PriceOrder = Price;
          this.InvPriceOrder = InvPrice;
          this.TotalPriceOrder = TotalPrice;
          console.log(TotalPrice);
          this.tree_edit_list_print = dataList;
        } else {

        }
      }
    );
  }

  onSubmit(OrderNoContains, ShipmentNoOrder, Buyer, Suplier, ReportTypeOrder) {
    const selectedCheckboxes = this.checkboxes.filter(item => item.isChecked);
    let returnUrl = "SingleOrderEnquiryReportList?model=model&order_no=" + OrderNoContains;

    // Calculate the center position for the popup window
    let screenWidth = window.screen.availWidth;
    let screenHeight = window.screen.availHeight;
    let windowWidth = 1200; // Adjust as needed
    let windowHeight = 600; // Adjust as needed
    let leftPosition = (screenWidth - windowWidth) / 2;
    let topPosition = (screenHeight - windowHeight) / 2;

    // Define window features
    let windowFeatures = 'width=' + windowWidth + ',height=' + windowHeight + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes';

    // Open the popup window
    let popupWindow = window.open(returnUrl, '_popupWin', windowFeatures);

    // Focus on the popup window
    popupWindow.focus();

    //console.log(selectedCheckboxes);
    // You can now send `selectedCheckboxes` to your server or perform an action
  }

  onPreview = () => {
    let printContents, popupWin;
    printContents = this.tree_edit_list_print;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
      <style>
	  <style type="text/css">
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box{
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls{
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
            

							.break-after{
								page-break-after: always;
							}
              .top-border-cls{
                border-top: solid black 1.0pt;
              }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
      
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      </style>
      </head>
      <body onload="window.print();window.close()">
      <table>
      <thead>
        <tr>
          <th>Buyer_Code</th>
          <th>Buyer_Name</th>
          <th>Addrss</th>
		      <th>Phone</th>
          <th>Fax</th>
		    </tr>
      </thead>
      <tbody>
        ${printContents}
        </tbody>
      </table>
      
      </body>
      </html>
      `)
      popupWin.document.close();
    }
  };

}
