import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './services/web-service.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  loggedIn: boolean = false;
  isAboutOrContactPage: boolean = false;
  constructor(
    private headerService: WebServiceService,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (this.headerService.isAuthenticated()) {
      this.loggedIn = true;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Set isAboutOrContactPage based on current route
        this.isAboutOrContactPage = event.url.includes('/MerchandisingDetails') || event.url.includes('/OrderFollow-Up') || event.url.includes('/OrderColorDetails');
      }
    });

  }

  logout() {
    const checkUser = sessionStorage.getItem('username');
    if (checkUser) {
      this.headerService.logout();
      this.loggedIn = false;
      console.log("logging out")
      if (navigator.userAgent.match(/Android/i)) {
        console.log("redirecting for android");
        location.assign('/')
      }
      else
        window.location.replace("/");
    }
  }
}
